import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import useStyles from './SettingsCss';
import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Tab } from '@mui/material';
import PredefineWidgets from '../../Components/Setting/Dashboard/PredefineWidgets';
import CustomizeWidgets from '../../Components/Setting/Dashboard/CustomizeWidgets';

const DynamicDashboard = () => {
  const classes = useStyles();
  const [value, setValue] = useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <Box p={0} m={0}>
      <Grid container spacing={0}>
        <Grid xs={12} md={12} >
          <TabContext value={value} >
            <AppBar position="static" className={classes.appbarPosition}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" className={classes.tabList}>
                <Tab label="PREDEFINED WIDGET" value="0" className={classes.tabRecords} />
                <Tab label="CUSTOMIZED WIDGET" value="1" className={classes.tabRecords} />
                <Tab label="ACTIVITIES" value="2" className={classes.tabRecords} />
              </TabList>
            </AppBar>

            <TabPanel value="0" sx={{ padding: 1 }}>
              <PredefineWidgets />
            </TabPanel>
            <TabPanel value="1" sx={{ padding: 1 }}>
              <CustomizeWidgets />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 1 }}>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}
export default DynamicDashboard;