/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
//MUI COMPONENTS
import { Box, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
//CUSTOM CSS
import useStyles from '../Egress/EgressCss';
//INTERFACE
import { IEgressTableRow } from '../../../Model/Diagnostics/IEgressTableRow';
//JSON DATA
import EgressEntityData from '../../../MockData/EgressEntityData.json'
//COMPONENTS
import SearchEgressDetails from './SearchEngressDetails';

function EnhancedTableHead() {
  const classes = useStyles();
  const headCells = [
    { id: "jobName", numeric: false, disablePadding: false, label: "JOB NAME", sortable: false },
    { id: "date", numeric: false, disablePadding: false, label: "DATE", sortable: false },
    { id: "startTime", numeric: false, disablePadding: false, label: "START TIME", sortable: false },
    { id: "duration", numeric: false, disablePadding: false, label: "DURATION", sortable: false },
    { id: "previousRun", numeric: false, disablePadding: false, label: "PREVIOUS RUN", sortable: false },
    { id: "currentRun", numeric: false, disablePadding: false, label: "CURRENT RUN ", sortable: false },
    { id: "delta", numeric: false, disablePadding: false, label: "DELTA", sortable: false },
    { id: "isStatus", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell className={classes.tableCellHeader} key={headCell.id} align="left">
            <b> {headCell.label || headCell.sortable}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function statusTemplate(isStatus: string | null) {
  let statusText;
  let chipColor;
  let fontColor;
  let fontWeight;
  let chipRadius;
  let chipMinWidth;

  switch (isStatus) {
    case 'Success':
      statusText = 'Success';
      chipColor = '#dbf6e5'; // Light green color
      fontColor = '#1a915d'; // Green font color
      break;
    case 'Failed':
      statusText = 'Failed';
      chipColor = '#ffe4de'; // Light red color
      fontColor = '#ba2520'; // Red font color
      break;
    case 'In Progress':
      statusText = 'In Progress';
      chipColor = '#fff1d6'; // Light orange color
      fontColor = '#c17f1d'; // Yellow font color
      break;
    default:
      statusText = null;
      break;
  }

  fontWeight = 'bold';
  chipRadius = '6px';
  chipMinWidth = '80px';

  return statusText ? (
    <Chip
      label={statusText}
      style={{ backgroundColor: chipColor, color: fontColor, fontWeight: fontWeight, borderRadius: chipRadius, minWidth: chipMinWidth }}
      size="small"
    />
  ) : null;
}

const EgressEntity: React.FC<{ dataRow?: IEgressTableRow, activityEnable }> = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={1}>
      <Grid component={Paper} container spacing={0} className={classes.pageContainer}>
        <Grid item xs={12}>
          <SearchEgressDetails />
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer className={`${classes.tablebodyContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead />
              <TableBody>
                {EgressEntityData.map((row, index: number) => (
                  <TableRow >
                    <TableCell className={classes.tableCellStyle}>
                      {row?.jobName}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.date}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.startTime}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.duration}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.previousRunRecord}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.currentRecord}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.delta}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {statusTemplate(row?.isStatus)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={100}
            page={page}
            size="small"
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Box >
  );
}
export default EgressEntity;