/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import Scrollbars from "react-custom-scrollbars";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useHistory, withRouter } from "react-router-dom";
import { AppBar, Grid, Paper, Tab, Step, StepLabel, Stepper, IconButton, Box } from "@mui/material";
//Icons
import ColorlibStepIcon from "./style";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
//Css
import useStyles from "../PlacementCss";
//Modules
import PreviewData from "./PreviewData";
import StyledDataGrid from './StyledDataGrid';
import { IFormData } from "../../../MockData/Placement/placement";
import { GetDataSet } from "../../../MockData/Placement/DataRuleMock";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import CustomPagination from "./CustomPagination";
import PlacementOmittedData from "../../../MockData/Placement/PlacementOmittedData.json";

const steps = ["Load", "Transform", "Preview", "Map Target", "Submit"];

const columns: GridColDef[] = [
  { field: 'Id', headerName: 'Id', width: 70, editable: true, },
  { field: 'Account Number', headerName: 'Account Number', width: 180, editable: true, },
  { field: 'First Name', headerName: 'First Name', width: 150, editable: true, },
  { field: 'Last Name', headerName: 'Last Name', width: 150, editable: true, },
  { field: 'Billing Address', headerName: 'Billing Address', width: 200, editable: true, },
  { field: 'Billing City', headerName: 'Billing City', width: 150, editable: true, },
  { field: 'Billing State', headerName: 'Billing State', width: 120, editable: true, },
  { field: 'Billing Zip Code', headerName: 'Billing Zip Code', width: 150, editable: true, },
  { field: 'Billing Phone Number', headerName: 'Billing Phone Number', width: 180, editable: true, },
  { field: 'Email Address', headerName: 'Email Address', width: 200, editable: true, },
  { field: 'Last Charge Date', headerName: 'Last Charge Date', width: 150, editable: true, },
  { field: 'Date of Last Payment', headerName: 'Date of Last Payment', width: 180, editable: true, },
  { field: 'Itemization Date', headerName: 'Itemization Date', width: 180, editable: true, },
];

function Preview(props) {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(2);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const clientCode = props?.location?.state?.clientCode;
  const phaseCode = props?.location?.state?.phaseCode;
  const dataset = props?.location?.state?.dataset;
  const fileName: string = props?.location?.state?.fileName;
  const dataSetId: number = props?.location?.state?.dataSetId;
  const uploadedData: any[] = props?.location?.state?.uploadedData;
  const fieldMappings: any[] = props?.location?.state?.fieldMappings;
  const deletedRecords: any[] = props?.location?.state?.deletedRecords;
  const fileDataLength: number = props?.location?.state?.fileDataLength;
  const ingestDetails: IFormData = props?.location?.state?.ingestDetails;
  const dataSetField: GetDataSet[] = props?.location?.state?.dataSetField;
  const yourFieldOptions: string[] = props?.location?.state?.yourFieldOptions;
  const datasetFieldMappings: any[] = props?.location?.state?.datasetFieldMappings;
  const [addNewRow, setAddNewRow] = useState(props?.location?.state?.addNewRow);

  const acceptedRecords = addNewRow?.map((data) => ({ id: data.Id, ...data }));
  const errorRecords = PlacementOmittedData?.map((data) => ({ id: data.Id, ...data }));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [rowData, setRowData] = useState([]);// for future enhancement  
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const handleExportDelete = () => {
    if (deletedRecords && deletedRecords.length > 0) {
      const csvData = convertToCSV(deletedRecords);
      downloadCSV(csvData, "DeletedRecords.csv");
    }
  };

  const handleExport = () => {
    if (errorRecords && errorRecords.length > 0) {
      const csvData = convertToCSV(errorRecords);
      downloadCSV(csvData, "ErrorRecords.csv");
    }
  };

  const handleExportAccepted = () => {
    if (acceptedRecords && acceptedRecords.length > 0) {
      const csvData = convertToCSV(acceptedRecords);
      downloadCSV(csvData, "AcceptedRecords.csv");
    }
  };

  const dynamicColumns = fieldMappings?.filter((mapping) => mapping?.mappedField?.trim())?.map((mapping) => ({
    field: mapping?.mappedField,
    headerName: mapping?.title,
    width: 210,
    editable: true,
  }));

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row: any) => Object.values(row).join(",")).join("\n");
    return header + rows;
  };

  const downloadCSV = (data: string, filename: string) => {
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${data}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setAddNewRow(addNewRow?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowClick = (params, event) => {
    const rowIndexValue = params.row.id - 1
    const selectedRow = params.row;
    const rowDataArray = Object.entries(selectedRow)?.map(([key, value]) => `${key}: ${value}`);
    setRowData(rowDataArray);
    setSelectedRowIndex(rowIndexValue);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleBackClick = () => {
    history.push('/columnfieldmapping', {
      uploadedData: uploadedData, clientCode: clientCode, phaseCode: phaseCode, dataset: dataset, fieldMappings: fieldMappings, addNewRow: addNewRow, fileName: fileName, dataSetField: dataSetField,
      fileDataLength: fileDataLength, ingestDetails: ingestDetails, dataSetId: dataSetId, yourFieldOptions: yourFieldOptions, datasetFieldMappings: datasetFieldMappings, deletedRecords: deletedRecords
    });
  };

  const handleNextClick = () => {
    history.push("/targetfieldmapping", {
      uploadedData: uploadedData, clientCode: clientCode, phaseCode: phaseCode, dataset: dataset, fieldMappings: fieldMappings, addNewRow: addNewRow, fileName: fileName, dataSetField: dataSetField,
      fileDataLength: fileDataLength, ingestDetails: ingestDetails, dataSetId: dataSetId, yourFieldOptions: yourFieldOptions, datasetFieldMappings: datasetFieldMappings, acceptedRecords: acceptedRecords, errorRecords: errorRecords, deletedRecords: deletedRecords
    });
  };

  return (
    <div>
      <Grid container spacing={0} component={Paper} className={classes.pageContainer} >
        <Grid xs={activeStep > 0 ? 11 : 12} className={activeStep > 0 ? classes.stepperActive : classes.stepperInActive} sx={{ borderBottom: "1px solid lightgray" }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid xs={activeStep > 0 ? 1 : null} className={activeStep > 0 ? classes.buttonStyle : null}>
          <IconButton size="small" onClick={handleToggleFullScreen}>
            <BDebtTooltip title={isFullScreen ? "Close Full Screen" : "Full Screen"}>
              {isFullScreen ? (
                <FullscreenExitIcon fontSize="medium" className={classes.placementIcon} />
              ) : (
                <FullscreenIcon fontSize="medium" className={classes.placementIcon} />
              )}
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleBackClick} >
            <BDebtTooltip title="Back">
              <ArrowBackIcon fontSize="medium" className={classes.placementIcon} />
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleNextClick}>
            <BDebtTooltip title="Next">
              <ArrowForwardIcon fontSize="medium" className={classes.placementIcon} />
            </BDebtTooltip>
          </IconButton>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <AppBar position="static" className={classes.appbarPosition}>
          <TabList onChange={handleChange} aria-label="simple tabs example" variant="fullWidth" className={classes.tabList}>
            <Tab
              label={
                <Box m={0} p={0} display="flex" justifyContent={"flex-end"} alignItems="center" sx={{ padding: 0 }}>
                  <b>Accepted Records ({acceptedRecords.length})</b>
                  <IconButton onClick={handleExportAccepted} sx={{ padding: 0, ml: 1 }}>
                    <BDebtTooltip title="Accepted Records Download" arrow placement="top-end">
                      <DownloadForOfflineIcon fontSize="small" />
                    </BDebtTooltip>
                  </IconButton>
                </Box>
              }
              value="1"
              className={classes.tabRecords}
            />
            <Tab
              label={
                <Box m={0} p={0} display="flex" justifyContent={"flex-end"} alignItems="center">
                  <b>Error Records ({errorRecords.length})</b>
                  <IconButton onClick={handleExport} sx={{ padding: 0, ml: 1 }}>
                    <BDebtTooltip title="Error Records Download" arrow placement="top-end">
                      <DownloadForOfflineIcon fontSize="small" />
                    </BDebtTooltip>
                  </IconButton>
                </Box>
              }
              value="2"
              className={classes.tabRecords}
            />
            <Tab
              label={
                <Box display="flex" justifyContent={"flex-end"} alignItems="center">
                  <b>Deleted Records ({deletedRecords.length})</b>
                  <IconButton onClick={handleExportDelete} sx={{ padding: 0, ml: 1 }}>
                    <BDebtTooltip title="Deleted Records Download" arrow placement="top-end">
                      <DownloadForOfflineIcon fontSize="small" />
                    </BDebtTooltip>
                  </IconButton>
                </Box>
              }
              value="3"
              className={classes.tabRecords}
            />
          </TabList>
        </AppBar>
        <TabPanel value="1" sx={{ padding: 1 }}>
          <Box m={0} bgcolor={"white"} component={Paper}>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.acceptedPreviewContainer} >
              {dynamicColumns?.length === 0 ? (
                <p className={classes.errorMessageStyle}>No records to display! Map the Fields</p> // Display the message
              ) : (
                <StyledDataGrid rows={acceptedRecords}
                  columns={dynamicColumns}
                  autoPageSize={false}
                  rowHeight={30}
                  disableRowSelectionOnClick
                  className={classes.GridFontSize}
                  processRowUpdate={processRowUpdate}
                  onRowClick={handleRowClick}
                  components={{
                    Pagination: (paginationProps) => ( // Pass paginationProps here
                      <CustomPagination {...paginationProps} rows={acceptedRecords} />
                    ),
                  }}
                />
              )}
              <PreviewData isOpen={isPopupOpen} onClose={handleClosePopup} allRows={acceptedRecords} onRowUpdate={processRowUpdate} dataSetField={dataSetField} selectedRowIndex={selectedRowIndex} />
            </Scrollbars>
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 1 }}>
          <Box m={0} bgcolor={"white"} component={Paper}>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.ErrorPreviewContainer}>
              <StyledDataGrid rows={errorRecords}
                columns={columns}
                autoPageSize={false}
                rowHeight={30}
                disableRowSelectionOnClick
                className={classes.GridFontSize} />
            </Scrollbars>
          </Box>
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 1 }}>
          <Box m={0} bgcolor={"white"} component={Paper}>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.ErrorPreviewContainer}>
              <StyledDataGrid rows={deletedRecords}
                columns={dynamicColumns}
                autoPageSize={false}
                rowHeight={30}
                disableRowSelectionOnClick
                className={classes.GridFontSize}
                components={{
                  Pagination: (paginationProps) => ( // Pass paginationProps here
                    <CustomPagination {...paginationProps} rows={acceptedRecords} />
                  ),
                }} />
            </Scrollbars>
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default withRouter(Preview);
