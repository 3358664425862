import { Checkbox, styled } from '@mui/material';
import { Secondary, Success, Warning, Error } from '../theme';

const BDebtCheckbox = styled(Checkbox)<{
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
    size?: 'small' | 'medium';
}>(({ color, size }) => ({
    '&.Mui-checked': {
        color: '#2a3944' || 'defaultColor'
    },
    ...(color === 'primary' && {
        '&.Mui-checked': {
            color: '#2a3944',
        },
    }),
    ...(color === 'secondary' && {
        '&.Mui-checked': {
            color: Secondary.dark,
        },
    }),
    ...(color === 'success' && {
        '&.Mui-checked': {
            color: Success.dark,
        },
    }),
    ...(color === 'error' && {
        '&.Mui-checked': {
            color: Error.dark,
        },
    }),
    ...(color === 'warning' && {
        '&.Mui-checked': {
            color: Warning.dark,
        },
    }),

    // Size styles
    ...(size === 'small' && {
        transform: 'scale(0.8)',
        // padding: '4px'
    }),
    ...(size === 'medium' && {
        transform: 'scale(1)',
    }),
}));

export default BDebtCheckbox;

