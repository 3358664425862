/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
// Controls
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, Fab, FormControl, Grid, Input, InputLabel, Paper, Menu, MenuItem, Box } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import Scrollbars from "react-custom-scrollbars";
// Mock data
import { RuleGroup, Field } from "../../../MockData/Placement/placement";
// Css
import useStyles from "./DatasetCss";
import React from "react";
import { usePost, useFetch } from "../../../utils/apiHelper";
import TreeView from "@mui/lab/TreeView";
import { CloseSquare, MinusSquare, PlusSquare, StyledTreeItem } from "../../../GlobalStyles/TreeView";
import AddIcon from '@mui/icons-material/Add';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CancelIcon from "@material-ui/icons/Cancel";

const DatasetRules: React.FC<{ field: any; onUploadSchema: () => void; selectedDataset: any; }> = (props) => {
  const { field, onUploadSchema, selectedDataset } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const [selectedRules, setSelectedRules] = useState<Field[]>([]);
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
  const [selectedRuleForExplanation, setSelectedRuleForExplanation] = useState<string>("");
  const [inputValue, setInputValue] = useState<number | string>("");
  const [outputValue, setOutputValue] = useState<number>(0);
  const [rule, setRule] = useState<RuleGroup[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState<boolean>(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [showRuleLabel, setShowRuleLabel] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string>("AND");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showButtons, setShowButtons] = useState(false);

  const handleFabClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowButtons(true);
    setAnchorEl(null);
  };

  const handleRuleItemClick = () => {
    setShowRuleLabel(true);
    handleMenuClose();
  };

  useEffect(() => {
    // Initialize the checked state based on selectedRules
    const initialCheckedState: Record<string, boolean> = Object.fromEntries(
      rule.flatMap((group) =>
        group.ruleInformation.map((rule) => {
          const ruleId = `${group.ruleType}-${rule.name}`;
          return [
            ruleId,
            selectedRules.some(
              (selectedRule) => selectedRule.ruleId === rule.ruleId
            ),
          ];
        })
      )
    );
    setChecked(initialCheckedState);

    const uniqueRuleTypes = [...new Set(rule.map((group) => group.ruleType))];
    const initialOpenGroupsState: Record<string, boolean> = uniqueRuleTypes.reduce((acc, ruleType) => {
      acc[ruleType] = true; // Or set the initial state based on your logic
      return acc;
    }, {});
    setOpenGroups(initialOpenGroupsState);

  }, [selectedRules, rule, selectedDataset]);

  const fetchDatasetRules = async () => {
    await useFetch<RuleGroup[]>("Rule/GetRuleInformation")
      .then((result) => setRule(result?.data));

    await usePost<any>("Dataset/GetDatasetSchemaMapRule", {
      dataSetId: field?.dataSetId,
      schemaId: field?.schemaId,
    })
      .then((result) => setSelectedRules(result?.data));
    setIsLoading(false);
  };

  const handleSelectedRuleClick = (explanation: string) => {
    setSelectedRuleForExplanation(explanation);
  };

  const handleGroupToggle = (ruleType) => {
    setExpandedGroups(prevState => ({
      ...prevState,
      [ruleType]: !prevState[ruleType]
    }));
  };


  const handleCheckboxChange = (ruleType: string, ruleName: string) => {
    const ruleId = `${ruleType}-${ruleName}`;
    setChecked((prevChecked) => {
      const updatedChecked = { ...prevChecked, [ruleId]: !prevChecked[ruleId] };

      setSelectedRules((prevSelectedRules) => {
        if (!prevChecked[ruleId]) {
          const alreadySelected = prevSelectedRules.some(
            (selectedRule) => selectedRule.name === ruleId
          );
          if (!alreadySelected) {
            const selectedRule = rule
              .find((group) => group.ruleType === ruleType)
              ?.ruleInformation.find((rule) => rule.name === ruleName);

            if (selectedRule) {
              return [
                ...prevSelectedRules,
                {
                  id: 0,
                  ruleId: selectedRule.ruleId,
                  name: ruleId,
                },
              ];
            }
          }
        } else {
          return prevSelectedRules.filter(
            (selectedRule) => selectedRule.name !== ruleId
          );
        }
        return prevSelectedRules;
      });

      if (selectedRuleForExplanation === ruleId) {
        setSelectedRuleForExplanation(null);
      }
      return updatedChecked;
    });
  };


  const openDialog = () => {
    fetchDatasetRules();
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setInputValue("");
    setOutputValue(null);
  };

  const handleApplyClick = () => {
    if (selectedRuleForExplanation && inputValue !== "") {
      const numericValue = Number(inputValue);

      if (!isNaN(numericValue)) {
        let result: number | null = null;

        switch (selectedRuleForExplanation) {
          case "Numeric-Absolute Value":
            result = Math?.abs(numericValue);
            break;
          case "Numeric-Round Up to the Nearest Integer":
            result = Math?.ceil(numericValue);
            break;
          case "Numeric-Round Down to the Nearest Integer":
            result = Math?.floor(numericValue);
            break;
          case "Numeric-Round to the Nearest Integer":
            result = Math?.round(numericValue);
            break;
          default:
            result = null;
        }

        setOutputValue(result);
      } else {
        setOutputValue(null);
      }
    } else {
      setOutputValue(null);
    }
  };

  const handleSaveClick = async () => {
    let dataSetSchemaMapRuleField: Field[] = selectedRules;

    let request = {
      dataSetId: field?.dataSetId,
      schemaId: field?.schemaId,
      dataSetSchemaMapRuleField: dataSetSchemaMapRuleField,
    };

    await usePost("Dataset/ManageDatasetSchemaMapRule", request);
    onUploadSchema();
    setIsDialogOpen(false);
  };

  const handleButtonClick = (value: string) => {
    setSelectedButton(value);
  };

  const handleRemoveRule = (cancelledRuleName: string) => {
    setSelectedRules((prevSelectedRules) =>
      prevSelectedRules.filter((rule) => rule.name !== cancelledRuleName)
    );

    // Deselect the checkbox associated with the removed rule
    setChecked((prevChecked) => {
      const updatedChecked = { ...prevChecked };
      const ruleId = Object.keys(prevChecked).find((key) => key === cancelledRuleName);
      console.log("Rule ID to deselect:", ruleId); // Log the ruleId
      if (ruleId) {
        updatedChecked[ruleId] = false;
      }
      return updatedChecked;
    });

    if (selectedRuleForExplanation === cancelledRuleName) {
      setSelectedRuleForExplanation(null);
    }
  };

  return (
    <div>
      <IconButton onClick={openDialog} size="small">
        <span
          className={`${classes.ruleIconStyle} ${"e-icons e-hide-formula-bar"}`}
        ></span>
      </IconButton>
      <BDebtDialog
        open={isDialogOpen}
        maxWidth={"md"}
        onClose={closeDialog}
        fullWidth
      >
        <BDebtDialogTitleHeader
          onClose={closeDialog}
          id="customized-dialog-title"
        >
          Choose Rules
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0} component={Paper}>
            <Grid xs={3} className={classes.RuleGridStyle}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BDebtTypography variant="h6" className={classes.headButton}>
                    All Rules
                  </BDebtTypography>
                </Grid>
                <Grid item xs={12}>
                  <Scrollbars className={classes.perviewContainer}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    thumbMinSize={20}
                    universal={true}
                  >
                    <TreeView
                      aria-label="customized"
                      defaultExpanded={['1']}
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare />}
                      defaultEndIcon={<CloseSquare />}
                      sx={{ overflowX: 'hidden' }}
                    >
                      {rule?.map((group) => (
                        <StyledTreeItem key={group?.ruleType} nodeId={group.ruleType} label={group.ruleType}
                          onClick={() => handleGroupToggle(group?.ruleType)}>
                          {group.ruleInformation.map((rule) => (
                            <StyledTreeItem key={rule?.name} nodeId={rule.name} label={rule.name}
                              endIcon={<Checkbox onChange={() => handleCheckboxChange(group?.ruleType, rule?.name)}
                              />
                              }
                              onClick={(e) => {
                                e.stopPropagation(); // Stop event bubbling
                                setShowRuleLabel(false); // Hide the paper component
                              }}
                            />
                          ))}
                        </StyledTreeItem>
                      ))}
                    </TreeView>
                  </Scrollbars>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} className={classes.RuleGridStyle}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BDebtTypography variant="h6" className={classes.headButton}>
                    Selected Rules
                  </BDebtTypography>
                </Grid>
                {isLoading ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress color="primary" size={20} />
                  </div>
                ) : (
                  <Grid item xs={12} spacing={2}>
                    <ToggleButtonGroup
                      aria-label="text formatting"
                      style={{ height: "25px" }}
                    >
                      <ToggleButton
                        value="bold"
                        aria-label="bold"
                        className={selectedButton === "AND" ? classes.selectedButton : classes.normalButton}
                        onClick={() => handleButtonClick("AND")}
                      >
                        AND
                      </ToggleButton>

                      <ToggleButton
                        value="italic"
                        aria-label="italic"
                        className={selectedButton === "OR" ? classes.selectedButton : classes.normalButton}
                        onClick={() => handleButtonClick("OR")}
                      >
                        OR
                      </ToggleButton>

                    </ToggleButtonGroup>
                    <Fab size="small" onClick={handleFabClick} className={classes.fabStyle}>
                      <AddIcon height={"15px"} width={"15px"} />
                    </Fab>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleMenuClose}> <AddIcon sx={{ mr: 1 }} /> Group</MenuItem>
                      <MenuItem onClick={handleRuleItemClick}> <AddIcon sx={{ mr: 1 }} />Rule</MenuItem>
                    </Menu>
                    {showRuleLabel && (
                      <Grid item style={{ display: "flex", justifyContent: "center" }}>
                        <Paper elevation={3} className={classes.dataRulesPaper}>
                          Choose Rules
                        </Paper>
                      </Grid>
                    )}
                    {selectedRules.length > 0 ? (
                      <Scrollbars
                        className={classes.perviewContainer}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        thumbMinSize={20}
                        universal={true}
                      >
                        <Box style={{ minWidth: 150, marginTop: "10px" }} className={classes.selectedRulesContainer}>
                          {selectedRules?.map((selectedRule) => (
                            <div
                              key={selectedRule.id}
                              className={classes.selectedRuleItem}
                              onClick={() =>
                                handleSelectedRuleClick(selectedRule.name)
                              }
                            >
                              <BDebtTypography
                                variant="caption"
                                className={classes?.rulehead}
                              >
                                {selectedRule.name}
                              </BDebtTypography>
                              <CancelIcon
                              // className={classes?.cancelIcon}
                              // onClick={(e) => {
                              //   openDialog();
                              //   e.stopPropagation();
                              //   handleRemoveRule(selectedRule.name);
                              // }}
                              />
                            </div>
                          ))}
                        </Box>
                      </Scrollbars>
                    ) : (
                      <React.Fragment>
                        <BDebtTypography
                          variant="subtitle2"
                          className={classes.loadingContainer}
                        >
                          Please select the rules...
                        </BDebtTypography>
                      </React.Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid xs={3} className={classes.RuleGridStyle}>
              <BDebtTypography variant="h6" className={classes.headButton}>
                Explain
              </BDebtTypography>
              {/* Display the selected rule for explanation */}
              <BDebtTypography variant="subtitle2">
                {selectedRuleForExplanation || "Select a rule for explanation"}
              </BDebtTypography>
              {/* Input field for user value */}
              <FormControl>
                <InputLabel htmlFor="input-value">Input Value</InputLabel>
                <Input
                  id="input-value"
                  type="number"
                  value={inputValue}
                  className={classes.outputStyle}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </FormControl>
              {/* Apply button */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleApplyClick}
                className={classes.outputStyle}
              >
                Apply
              </Button>
              {/* Output value */}
              {outputValue !== null && (
                <BDebtTypography
                  variant="subtitle2"
                  className={classes.outputStyle}
                >
                  Output: {outputValue}
                </BDebtTypography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BDebtButton
            variant="contained"
            color="success"
            size="small"
            onClick={handleSaveClick}
          >
            Save
          </BDebtButton>
          <BDebtButton variant="contained" size="small" color="error" onClick={closeDialog}>
            Cancel
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div >
  );
};

export default DatasetRules;