import React, { useEffect, useState } from "react";
import { DialogContent, IconButton, Tooltip, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, DialogActions} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { Field, GetDataSet } from "../../../MockData/Placement/DataRuleMock";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import useStyles from "../PlacementCss";

interface IRecordCombineViewProps {
  datasetDetail: GetDataSet[];
  onAdd: (newRecord:any) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const AddAccount: React.FC<IRecordCombineViewProps> = (props) => {
  const classes = useStyles();
  const [newRecord, setNewRecord] = useState<any>({});
  const [disableSave, setDisableSave] = useState<boolean>(true);

  useEffect(() => {
    if (props?.datasetDetail) {
      // Initialize newRecord with all fields set to null
      const initialRecord: any = {};
      props?.datasetDetail?.forEach(dataset => {
        dataset?.fieldsDetail?.forEach((field: Field) => {
          initialRecord[field?.fieldName] = null;
        });
      });
      setNewRecord(initialRecord);
    }
  }, [props?.isOpen, props?.datasetDetail]);

  useEffect(() => {
    const isEmptyRequiredField = props?.datasetDetail?.some(dataset =>
      dataset?.fieldsDetail?.some((field: Field) =>
        field?.isRequired && !newRecord[field?.fieldName]
      )
    );
    setDisableSave(isEmptyRequiredField);
  }, [newRecord, props?.datasetDetail]);

  const handleTextFieldChange = (fieldName: string, newValue: string) => {
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      [fieldName]: newValue,
    }));
  };
  

  const handleAdd = () => {
    props.onAdd(newRecord); 
    closeDialog();
  };
  

  const openDialog = () => {
    props?.onOpen();
    setNewRecord("");
  };

  const closeDialog = () => {
   props?.onClose();
  };

  return (
    <div>
      <Tooltip title="Add New Record">
        <IconButton onClick={openDialog} size="small" className={classes.IconButton}>
          <AddIcon fontSize="small"/>
        </IconButton>
      </Tooltip>
      <BDebtDialog open={props?.isOpen} onClose={closeDialog} fullWidth>
        <BDebtDialogTitleHeader onClose={closeDialog} id="customized-dialog-title">
          Add New Record
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid component={Paper} container spacing={1}>
            <Grid item xs={12}>
              <TableContainer sx={{ maxHeight: "400px", overflow: "auto" }} className={`${classes.tablebodyConainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Fields</TableCell>
                      <TableCell align="left">Values</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props?.datasetDetail?.flatMap((dataset) =>
                      dataset?.fieldsDetail?.map((field: Field) => (
                        <TableRow key={field.fieldName}>
                          <TableCell align="left">{field?.fieldName} {field?.isRequired && <span className={classes.isRequired}> * </span>}</TableCell>
                          <TableCell align="left">
                            <BDebtTextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={newRecord[field.fieldName] || ''} 
                              onChange={(e) => handleTextFieldChange(field.fieldName, e.target.value)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
          <BDebtButton color="success" variant="contained" onClick={handleAdd} size="small" disabled={disableSave}>
            Save
          </BDebtButton>
          <BDebtButton variant="contained" color="error" onClick={closeDialog} size="small">
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default AddAccount;

