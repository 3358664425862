export let data: Object[] = [
    { Description: ' Next Work Date Updated', Amount: 100, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Stopped/Returned', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Stopped/Returned', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Stopped/Returned', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Stopped/Returned', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Stopped/Returned', Date: '10-01-2023' },
    { Description: 'Status changed to Closed', Amount: 200, PaymentType: 'Manual Type', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
    { Description: 'Flat Fee Treatment Completed', Amount: 150, PaymentType: 'Paid', Date: '10-01-2023' },
];

export let InvoiceData: Object[] = [
    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },

    {
        CreateDate: '10-01-2023', InvoiceNumber: 'P4058441', InvoiceDescription01: 'Invoice', InvoiceDate: '10-01-2023', ServiceDate: '10-01-2023', InvoiceAmount: 430.50, InvoiceDueDate: '10-01-2023', InvoiceDescription02: '10-01-2023 0:00',
        DEMName: 'Miami Dade Water and sewer Dept- Miami', FLD01: 283540
    },
];
