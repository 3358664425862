/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
import ControlProperties from "./ControlProperties";
import useStyles from "./TemplateCss";
import { Checkbox, Grid, MenuItem, Radio, RadioGroup, Select, Container } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { memo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IControlProps } from "../../Model/AgentCustomForm/IControlProps";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import BDebtButton from "../../GlobalControl/BDebtButton";
import BDebtTypography from "../../GlobalControl/BDebtTypography";


interface FormBuilderProps {
  onSaveAllControlProps: (updatedAllControlProps: IControlProps[]) => void;
}

function FormBuilder(props: FormBuilderProps) {
  const [allControlProps, setAllControlProps] = useState<IControlProps[]>([]);
  const classes = useStyles();
  const [tableItems, setTableItems] = useState<ReactNode[]>([]);
  const [selectedControlIndex, setSelectedControlIndex] = useState<number | null>(null);
  const [controlProps, setControlProps] = useState<IControlProps[]>([]);
  const base: IControlProps = {
    id: "",
    name: "",
    required: false,
    label: "",
    regex: "",
    maxLength: 0,
    value: "",
    options: [],
    type: "",
    draggable: true,
  };

  const [editedControlProps, setEditedControlProps] = useState<IControlProps>(base);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const type = e.dataTransfer.getData("text/plain");
    let newItem: ReactNode | null = null;
    let additionalProps: IControlProps = {
      id: "",
      name: "",
      required: false,
      label: "",
      regex: "",
      maxLength: 0,
      value: "",
      options: [],
      type: type,
      draggable: true,
    };
    switch (type) {
      case "textfield":
        newItem = (
          <BDebtTextField
            size="small"
            key={uuidv4()}
            label="TextField"
            variant="outlined"
          />
        );
        additionalProps.label = "Textfield Label";
        additionalProps.id = uuidv4();
        additionalProps.draggable = true;
        break;
      case "button":
        newItem = (
          <BDebtButton size="small" key={uuidv4()} color="primary">
            Button
          </BDebtButton>
        );
        additionalProps.label = "Button Label";
        additionalProps.id = uuidv4();
        additionalProps.draggable = true;
        break;
      case "Label":
        newItem = (
          <BDebtTypography key={uuidv4()} color="primary" variant={"button"}>
            Label
          </BDebtTypography>
        );
        additionalProps.label = " Label";
        additionalProps.id = uuidv4();
        additionalProps.draggable = true;
        break;
      case "radio":
        newItem = (
          <div key={uuidv4()} className={classes.radioContainer}>
            <RadioGroup row>
              <Radio size="small" aria-label="male" />
              <Radio size="small" aria-label="female" />
            </RadioGroup>
            <span>Radio Button </span>
          </div>
        );
        additionalProps.label = "Radio Label";
        additionalProps.id = uuidv4();
        additionalProps.value = "radio-value";
        additionalProps.draggable = true;
        break;
      case "checkbox":
        newItem = (
          <div key={uuidv4()}>
            <Checkbox />
            <span>Checkbox</span>
          </div>
        );
        additionalProps.label = "Checkbox Label";
        additionalProps.id = uuidv4();
        additionalProps.draggable = true;
        break;
      case "select":
        newItem = (
          <Select key={uuidv4()} label="Select" >
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">Option 2</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
          </Select>
        );
        additionalProps.label = "Select Label";
        additionalProps.id = uuidv4();
        additionalProps.options = ["Option 1", "Option 2", "Option 3"];
        additionalProps.draggable = true;
        break;
      default:
        newItem = null;
    }

    if (newItem) {
      setTableItems([...tableItems, newItem]);
      setControlProps([...controlProps, additionalProps]);
    }
  };

  const handleSaveControlProps = () => {
    if (selectedControlIndex !== null) {
      const updatedControlProps = [...controlProps];
      updatedControlProps[selectedControlIndex] = editedControlProps;
      setControlProps(updatedControlProps);

      const updatedAllControlProps = [...allControlProps];
      updatedAllControlProps[selectedControlIndex] = editedControlProps;
      setAllControlProps(updatedAllControlProps);

      props.onSaveAllControlProps(updatedAllControlProps);
    }
  };


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(tableItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTableItems(items);

    const updatedControlProps = [...controlProps];
    const [reorderedControlProps] = updatedControlProps.splice(result.source.index, 1);
    updatedControlProps.splice(result.destination.index, 0, reorderedControlProps);
    setControlProps(updatedControlProps);
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleControlClick = (index: number) => {
    setSelectedControlIndex(index);

    const clickedControlProps = controlProps[index];

    setEditedControlProps(clickedControlProps);
  };

  const handleRemoveControl = (indexToRemove: number) => {
    const updatedTableItems = [...tableItems];
    updatedTableItems.splice(indexToRemove, 1);

    const updatedControlProps = [...controlProps];
    updatedControlProps.splice(indexToRemove, 1);

    setTableItems(updatedTableItems);
    setControlProps(updatedControlProps);

    if (selectedControlIndex === indexToRemove) {
      setSelectedControlIndex(null);
      setEditedControlProps(base);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={8}>
        <Container>
          <Grid container spacing={0} className={classes.ContainerStyle}  >
            <Grid item xs={3}><BDebtTypography variant="body2" color={"white"} bgcolor={"rgb(59, 74, 84)"}>LABEL</BDebtTypography></Grid>
            <Grid item xs={3}><BDebtTypography variant="body2" color={"white"} bgcolor={"rgb(59, 74, 84)"} >CONTROL</BDebtTypography></Grid>
            <Grid item xs={2}><BDebtTypography variant="body2" color={"white"} bgcolor={"rgb(59, 74, 84)"}>REQUIRED</BDebtTypography></Grid>
            <Grid item xs={2}> <BDebtTypography variant="body2" color={"white"} bgcolor={"rgb(59, 74, 84)"}>LENGTH</BDebtTypography></Grid>
            <Grid item xs={2}><BDebtTypography variant="body2" color={"white"} bgcolor={"rgb(59, 74, 84)"}>ACTION</BDebtTypography></Grid>
          </Grid>

          <Grid
            className={`${classes.formbuild} ${"scrollbox"} ${"on-scrollbar"}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="controls">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {tableItems.map((item, index) => (
                      <Draggable
                        key={uuidv4()}
                        draggableId={`control-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ userSelect: "none" }}
                          >
                            <Grid
                              item
                              xs={12}
                              spacing={2}
                              container
                              alignItems="center"
                              className={classes.ControlBox}
                            >
                              <Grid item xs={2} className={`${classes.ellipsis}`}>
                                <BDebtTypography variant="body1">{controlProps[index].label}</BDebtTypography>
                              </Grid>
                              <Grid item xs={4}>
                                {item}
                              </Grid>
                              <Grid item xs={2}>
                                <BDebtTypography variant="body1">{controlProps[index].required ? "Yes" : "No"}</BDebtTypography>
                              </Grid>
                              <Grid item xs={1}>
                                <BDebtTypography variant="body1">{controlProps[index].maxLength}</BDebtTypography>
                              </Grid>
                              <Grid item xs={2}>

                                <EditIcon fontSize="small"
                                  onClick={() => handleControlClick(index)}
                                />
                                <DeleteIcon
                                  onClick={() => handleRemoveControl(index)}
                                  fontSize="small"
                                />

                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Container>
      </Grid>
      {tableItems.length > 0 && selectedControlIndex !== null && (
        <Grid item xs={4} className={classes.shadowStyle}>
          <ControlProperties
            editedControlProps={editedControlProps}
            setEditedControlProps={setEditedControlProps}
            handleSaveControlProps={handleSaveControlProps}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default memo(FormBuilder);