/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import React, { useEffect, useState } from "react";
import { Checkbox, TableContainer, Paper, IconButton } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { Autocomplete, Grid, Button, Typography, Snackbar, Alert } from "@mui/material";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import Scrollbars from "react-custom-scrollbars";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BlankImage from "../../../asset/image/document.svg";
// Icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyIcon from '@mui/icons-material/Key';
// Css
import useStyles from "./DatasetCss";
// Mock Data
import { dataTypes } from "../../../MockData/Placement/DataRuleMock";
// Modules
import DatasetRules from "./DatasetRules";
import { usePost } from "../../../utils/apiHelper";
import { Box } from "@mui/system";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";

interface Field {
  schemaId: number;
  fieldName: string;
  dataType: string;
  ruleId: number;
  isRequired: boolean;
  isPrimaryKey: boolean;
  dateFormatId: number;
}

interface FieldValue {
  dateFormatId: number;
  dateFormat: string;
}

const CsvField = (props) => {
  const{field, onFieldChange, onFieldDelete, index, onDragStart, onDragOver, onDrop, onUploadSchema, selectedDataset, classes, fields, setFields, onDateFormatSelected } = props;
  const [showDateFormatAutocomplete, setShowDateFormatAutocomplete] = useState<boolean>(false);
  const [isPrimaryKeySet, setIsPrimaryKeySet] = useState(false);
  const [dateFormat, setDateFormat] = useState<FieldValue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = () => {
    const updatedFields = fields?.map((f: Field, i: number) => {
      if (i === index) {
        return {
          ...f,
          isPrimaryKey: true // Set the clicked field as the primary key
        };
      }
      return {
        ...f,
        isPrimaryKey: false // Unset the primary key for other fields
      };
    });
    setFields(updatedFields);
  };

  useEffect(() => {
    setIsPrimaryKeySet(field.isPrimaryKey);
  }, [field.isPrimaryKey]);

  const fetchDateFormats = async (dataType: string) => {
    if (dataType === 'Date') {
      setShowDateFormatAutocomplete(true);
    } else {
      setShowDateFormatAutocomplete(false);
    }
  };

  useEffect(() => {
    const fetchDateFormat = async () => {
      setIsLoading(true);
      usePost<FieldValue[]>('Dataset/GetDateFormat', {}).then((result) => {
        setDateFormat(result?.data);
      }).finally(() => {
        setIsLoading(false);
      });
    };

    // Reset date format when a new dataset is selected
    if (!isLoading && dateFormat.length === 0) {
      fetchDateFormat();
    }
  }, [isLoading, dateFormat]);

  return (
    <div draggable={true} onDragStart={(e) => onDragStart(e, index)} onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, index)} className={classes.draggableField} >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={0}>
            <Grid xs={1}>
              <DragIndicatorIcon />
            </Grid>
            <Grid item xs={1}>
              <BDebtTooltip title="Set Primary Key" arrow>
                <IconButton size="small" onClick={handleClick}>
                  <KeyIcon className={field.isPrimaryKey ? classes.isActiveKeyiconStyle : classes.isInActiveKeyiconStyle} />
                </IconButton>
              </BDebtTooltip>
            </Grid>
            <Grid xs={10}>
              <BDebtTextField
                fullWidth
                type="text"
                size="small"
                variant="outlined"
                value={field.fieldName}
                onChange={(e) => onFieldChange(index, 'fieldName', e.target.value)}
                disabled={field.isPrimaryKey}  // Disable input if already set as primary key
                className={field.isPrimaryKey ? classes.isActiveTextFieldStyle : classes.isInActiveTextFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={showDateFormatAutocomplete ? 3 : 5}>
          <Autocomplete
            fullWidth
            size="small"
            options={dataTypes}
            value={field.dataType}
            onChange={(_, newValue) => { onFieldChange(index, 'dataType', newValue); fetchDateFormats(newValue); }}
            classes={{
              paper: classes.dropdownPaper,
              option: classes.autoCompleteFont,
            }}
            renderInput={(params) => (
              <BDebtTextField
                variant="outlined"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.dropDownInput,
                  }
                }}
                disabled={field.isPrimaryKey}
                className={field.isPrimaryKey ? classes.isActiveTextFieldStyle : classes.isInActiveTextFieldStyle}
              />
            )}
          />
        </Grid>
        {showDateFormatAutocomplete && (
          <Grid item xs={12} sm={12} md={2}>
            <Autocomplete
              fullWidth
              size="small"
              options={dateFormat}
              value={field.dateformatId} // Assuming you have a property for date format in your 'field' object
              onChange={(_, newValue) => onDateFormatSelected(index, 'dateFormatId', newValue)}
              getOptionLabel={(option) => option.dateFormat}
              classes={{
                paper: classes.dropdownPaper,
                option: classes.autoCompleteFont,
              }}
              renderInput={(params) => (
                <BDebtTextField
                  variant="outlined"
                  placeholder="DateFormat"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.dropDownInput,
                    }
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={1} display={"flex"} justifyContent={"end"}>
          {field?.schemaId ? (
            <DatasetRules field={field} onUploadSchema={onUploadSchema} selectedDataset={selectedDataset} />
          ) :
            <IconButton size="small" disabled={true}>
              <span className={`${classes.ruleIconStyle1} ${"e-icons e-hide-formula-bar"}`}>
              </span>
            </IconButton>
          }
        </Grid>
        <Grid item xs={12} sm={6} md={1} display={"flex"} justifyContent={"end"}>
          <Checkbox checked={field.isRequired} size="small"
            onChange={() => onFieldChange(index, "isRequired", !field.isRequired)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1} display={"flex"} justifyContent={"end"}>
          <DeleteOutlineOutlinedIcon
            fontSize="small"
            className={classes.icon}
            onClick={() => onFieldDelete(field.schemaId)}
          />
        </Grid>
      </Grid>
    </div >
  );
};

const UploadSchema: React.FC<{ selectedDataset: any; onUploadSchema: () => void }> = (props) => {
  const { selectedDataset, onUploadSchema } = props;
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [fields, setFields] = useState<Field[]>([]);
  const [draggedIndex, setDraggedIndex] = useState<number>(-1);
  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [resetDateFormat, setResetDateFormat] = useState(true);

  const handleTestConnection = () => {
    setSnackbarMessage('Schema fields saved successfully!');
    setSnackbarOpen(true);
  };

  useEffect(() => {
    if (selectedDataset) {
      setFields(selectedDataset.fields || []);
      setIsDropzoneVisible(false);
    } else {
      setIsDropzoneVisible(true);
      setUploadedFile(null);
      setFields([]);
    }
  }, [selectedDataset]);

  const handleFileUpload = (files: File[]) => {
    if (files.length > 0) {
      const file = files[0];
      setUploadedFile(file);
      const reader = new FileReader();
      setIsDropzoneVisible(false);
      reader.onload = (e) => {
        if (e.target) {
          const contents: ArrayBuffer = e.target.result as ArrayBuffer;
          const text = new TextDecoder().decode(contents);
          const lines = text.split("\n");
          if (lines.length > 0) {
            const headers = lines[0].split(",");
            const initialFields = headers.map((header) => ({
              schemaId: 0,
              fieldName: header,
              dataType: "Text",
              ruleId: 0,
              isRequired: false,
              isPrimaryKey: false,
              dateFormatId: 0,
            }));
            setFields(initialFields);
            setResetDateFormat(false); //Reset the DateFormat state when new dataset selected.
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const addField = () => {
    // Find the maximum schemaId currently in use
    const maxSchemaId = fields.reduce((maxId, field) => Math.max(maxId, field.schemaId), 0);
    // Generate a new unique schemaId by incrementing the maximum schemaId
    const newSchemaId = maxSchemaId + 1;
    // Create the new field with the unique schemaId
    const newField: Field = {
      schemaId: newSchemaId,
      fieldName: "",
      dataType: "",
      ruleId: 0,
      isRequired: false,
      isPrimaryKey: false,
      dateFormatId: 0,
    };
    setFields([newField, ...fields]);
  };

  const handleFieldChange = (index: number, fieldKey: keyof Field, value: Field[keyof Field]) => {
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      [fieldKey]: value,
    };
    setFields(updatedFields);
  };

  const handleDateFormatSelected = (index: number, fieldKey: keyof Field, value: any) => {
    const updatedFields = [...fields];
    updatedFields[index] = {
      ...updatedFields[index],
      [fieldKey]: value?.dateFormatId,
    };
    setFields(updatedFields);
  };

  const handleSaveClick = async () => {
    // Convert isPrimaryKey boolean value to 1 or 0
    const updatedFields = fields.map(field => ({
      ...field,
      isPrimaryKey: field.isPrimaryKey ? true : false
    }));

    let request = {
      "datasetId": selectedDataset?.dataSetId,
      "schemaField": updatedFields
    }

    await usePost("Dataset/ManageDatasetSchema", request);
    handleTestConnection();
    onUploadSchema();
    setSnackbarOpen(true);
  };

  const deleteField = (schemaIdToDelete: number) => {
    usePost("Dataset/DeleteDatasetSchema?SchemaId=" + schemaIdToDelete, {});
    const updatedFields = fields.filter((field) => field.schemaId !== schemaIdToDelete);
    setFields(updatedFields);
  };

  const handleDragStart = (e: React.DragEvent, index: number) => {
    e.dataTransfer.setData("index", index.toString());
    setDraggedIndex(index);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent, targetIndex: number) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("index"));
    if (sourceIndex !== targetIndex) {
      const updatedFields = [...fields];
      const [draggedField] = updatedFields.splice(sourceIndex, 1);
      updatedFields.splice(targetIndex, 0, draggedField);
      setFields(updatedFields);
    }
    setDraggedIndex(null);
  };

  const handleReuploadClick = () => {
    setIsDropzoneVisible(true);
    setUploadedFile(null);
    setFields([]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={0}>
      <Grid container spacing={0}>
        <Grid xs={12}>
          {!selectedDataset?.dataSetId ?
            <div className={classes.contentStyle}>
              <img src={BlankImage} alt="blank-img" width="100px" />
              <Typography variant="body2" color="inherit" className="content-text">
                <b>Please select a Dataset Card</b>
              </Typography>
            </div> : <>
              <Grid container spacing={0}>
                {isDropzoneVisible && (
                  <React.Fragment>
                    <Grid xs={12}>
                      <DropzoneArea
                        acceptedFiles={[".csv"]}
                        showPreviews={false}
                        onChange={handleFileUpload}
                        showPreviewsInDropzone={false}
                        filesLimit={1}
                        dropzoneText="Drag and Drop a CSV file here or Browse"
                        dropzoneClass={classes.DropzoneArea}
                        dropzoneParagraphClass={classes.DropzoneAreaText}
                      />
                    </Grid>
                    <Grid xs={12} display={"flex"} alignItems={"center"} mt={'5px'} ml={'6px'} >
                      <BDebtButton color="secondary" variant="contained" onClick={addField} size="small" >
                        Add Field
                      </BDebtButton>
                      <Button color="success" variant="contained" onClick={handleSaveClick} size="small" className={classes.saveButtonStyle} >
                        Save
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
                <CsvFieldHeader />
                <TableContainer component={Paper}>
                  <Scrollbars className={` ${!isDropzoneVisible ? classes.fieldTable : classes.fieldUploadedTable}`}
                    autoHide autoHideTimeout={1000} autoHideDuration={200} thumbMinSize={20} universal={true} >
                    {fields?.map((field, index) => (
                      <CsvField
                        key={index}
                        field={field}
                        onFieldChange={handleFieldChange}
                        onFieldDelete={deleteField}
                        index={index}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onUploadSchema={props.onUploadSchema}
                        selectedDataset={props.selectedDataset}
                        fields={fields}
                        setFields={setFields}
                        classes={classes}
                        onDateFormatSelected={handleDateFormatSelected}
                      />
                    ))}
                  </Scrollbars>
                </TableContainer>
                {!isDropzoneVisible && (
                  <Grid xs={12} display={"flex"} alignItems={"center"} mt={'5px'} ml={'6px'}>
                    <Grid container spacing={0}>
                      <Grid xs={6} >
                        <BDebtButton color="secondary" variant="contained" onClick={addField} size="small" >
                          Add Field
                        </BDebtButton>
                        <Button color="success" variant="contained" onClick={handleSaveClick} size="small" className={classes.saveButtonStyle} >
                          Save
                        </Button>
                      </Grid>
                      <Grid xs={6} display={"flex"} justifyContent={"flex-end"}>
                        <BDebtButton variant="contained" color="secondary" size="small" startIcon={<CloudUploadIcon />} onClick={handleReuploadClick} >
                          Upload
                        </BDebtButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Optional: Set duration to auto-close the snackbar
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set position to top center
        color='success'
      >
        <Alert onClose={handleCloseSnackbar} severity="success" style={{ color: "white", backgroundColor: "green" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const CsvFieldHeader = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} display={"flex"} justifyContent={"center"}>
        <BDebtTypography variant="subtitle2">Field Name</BDebtTypography>
      </Grid>
      <Grid item xs={12} sm={12} md={3} display={"flex"} justifyContent={"center"}>
        <BDebtTypography variant="subtitle2">Type</BDebtTypography>
      </Grid>
      <Grid item xs={12} sm={12} md={3} display={"flex"} justifyContent={"end"}>
        <BDebtTypography variant="subtitle2">Rules</BDebtTypography>
      </Grid>
      <Grid item xs={12} sm={12} md={1} display={"flex"} justifyContent={"center"}>
        <BDebtTypography variant="subtitle2">Required</BDebtTypography>
      </Grid>
      <Grid item xs={12} sm={12} md={1} display={"flex"} justifyContent={"center"}>
        <BDebtTypography variant="subtitle2">Action</BDebtTypography>
      </Grid>
    </Grid>
  );
};

export default UploadSchema;
