import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      flexGrow: 1,
    },
    tableCellHeader: {
      padding: "3px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
    },
    selectedCard: {
      border: "2px solid #007bff", /* Highlight the border when selected */
      /* Add any other styling you want to apply to highlight the card */
    },
    tableCellStyle: {
      padding: "3px 15px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px!important",
      },
      fontSize: "13px !important",
    },
    tableCellStyle1: {
      padding: "3px 5px !important",
      "& .MuiTableCell-root": {
        padding: "3px 2px!important",
      },
      fontSize: "10px !important"
    },
    tablebodyConainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 250px)',
        minHeight: 'calc( 100vh - 250px)',
      },
    },
    button: { background: "#3b4a54", color: "white" },
    iconclose: { position: "absolute", top: -1, right: 12 },
    aSummary: {
      border: "2px solid black",
      borderRadius: "20px",
      maxHeight: "30",
      minHeight: "20",
    },
    checkBox: {
      color: "white",
      padding: "5px",
      left: "7px",
    },
    gridItem: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center', // Center align on smaller screens
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Display in a single line on screens < 600px wide
      },
    },
    tableCellStyledata: {
      padding: "3px 15px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px!important",
      },
      fontSize: "13px !important",
      popover: {
        padding: theme.spacing(2),
      },
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    typograpy: {
      borderBottom: '1px dashed lightgray',
      fontSize: '14px'
    },
    iconstyle: {
      height: '80px',
      width: '80px',
    },
    card: {
      textAlign: 'center'
    },
    background: {
      backgroundColor: "#ebecf0"
    },
    iconName: {
      backgroundColor: "#ebecf0"
    },
    deleteIcon: {
      color: "red !important",
      marginLeft: "8px !important",
    },
    dropdownPaper: {
      border: "1px solid lightgray",
      fontSize: "12px !important",
      marginTop: "0px",
    },
    autoCompleteFont: {
      fontSize: "12px !important",
      padding: "3px 12px !important",
    },
    dropDownStyle: {
      marginTop: "2px !important",
      borderRadius: 5,
      backgroundColor: "white !important",
      height: "35px !important",
      fontSize: "12px !important",
      "& .MuiInputBase-input": {
        fontSize: "12px !important",
      },
    },
    editIcon: {
      color: "green !important",
    },
  })
)

export default useStyles;