/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
//MUI component
import { CircularProgress } from "@material-ui/core";
import {
  Box, Checkbox, Chip, Collapse, DialogContent, Grid, IconButton, LinearProgress, List, ListItem, ListItemText, TextField,
  InputAdornment, ClickAwayListener
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
//MockData
import { GlobalContext } from "../../../MockData/UserLevel/GlobalContext";
import useStyles from "./MenuLevelCSS";
//Icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddBoxIcon from "@mui/icons-material/AddBox";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { Typography } from "@mui/material";
import { DialogActions } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import { Tooltip } from "@mui/material";
import { IEditMenuSetting, IMenuActionMappingList, IMenuActionResult, IMenuItemResult, IMenuResult, ISubMenuResult } from "../../../Model/Settings/IMenuDetails";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import _ from 'lodash';
import { FormControlLabel } from "@mui/material";
import BDebtCheckbox from "../../../GlobalControl/BDebtCheckBox";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useFetch, usePost } from "../../../utils/apiHelper";
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import SearchIcon from "@material-ui/icons/Search";
import { FormLabel } from "@mui/material";
import EditMenuSetting from "./EditMenuSetting";
import AddMenuAction from "./AddMenuAction";


const MenuLevelSetting: React.FC = () => {
  const classes = useStyles();
  const { menuItems, editMenuItem } = useContext(GlobalContext)!;
  const [loading, setLoading] = useState(true);
  const [menuList, setMenuList] = useState<IMenuResult[]>([]);
  const [subMenuList, setSubMenuList] = useState<ISubMenuResult[]>([]);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [menuId, setMenuId] = useState(0);
  const [subMenuId, setSubMenuId] = useState(0);
  const [menuName, setMenuName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [menuErrorMessage, setMenuErrorMessage] = useState<string>("");
  const [subMenuErrorMessage, setSubMenuErrorMessage] = useState<string>("");
  const [isMenuError, setIsMenuError] = useState<boolean>(false);
  const [isSubMenuError, setIsSubMenuError] = useState<boolean>(false);
  const [finalMenuList, setFinalMenuList] = useState<IMenuItemResult[]>([]);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number>(0);
  const [menuActionList, setMenuActionList] = useState<IMenuActionResult[]>([]);
  const [selectedOptionsList, setSelectedOptionsList] = useState<any>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [MenuActionDetails, setMenuActionDetails] = useState<IMenuActionResult[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<any>(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState<any>(null);
  const [noMenuActionsAssigned, setNoMenuActionsAssigned] = useState(false);
  const [basic, setBasic] = useState<boolean>(false);
  const [sliver, setSliver] = useState<boolean>(false);
  const [gold, setGold] = useState<boolean>(false);

  const [optionOpen, setOptionOpen] = useState(false);
  const [searchActionName, setSearchActionName] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [deselectClient, setDeselectClient] = useState(false);
  // Function to handle selecting all checkboxes
  const handleSelectAll = () => {
    setSelectAllChecked(true);
    setSelectedOptionsList(menuActionList); // Assuming menuactionList contains all menu actions
  };

  const handleClose = () => {
    setOpen(false);
    setMenuName(''); // Reset to initial or empty value
    setBasic(false); // Reset to initial or empty value
    setSliver(false); // Reset to initial or empty value
    setGold(false); // Reset to initial or empty value
  };
  const handleExpandClick = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === 'basic') {
      setBasic(checked ? true : null);
    } else if (name === 'sliver') {
      setSliver(checked ? true : null);
    } else if (name === 'gold') {
      setGold(checked ? true : null);
    }
  };


  const handleActionEditClick = (menuId: number, subMenuId: number) => {
    (async () => {
      let request = {
        "menuId": menuId,
        "subMenuId": subMenuId === undefined || subMenuId === null ? 0 : subMenuId
      }
      await usePost<{ menuActionResults: IMenuActionResult[] }>("Menu/GetActionListBasedOnMenu", request).then((r) => {
        setMenuActionDetails(r?.data?.menuActionResults);
      }).finally(() => {
        setActionDialogOpen(true);
      })
    })().finally(() => {
      setActionDialogOpen(true);
    })
  };

  const handleActionCloseDialog = () => {
    setActionDialogOpen(false);
  };

  const handleSubMenuCheckboxChange = (menuItemIndex: number, subMenuIndex: number) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[menuItemIndex].subMenu[subMenuIndex] = {
      ...updatedMenuItems[menuItemIndex].subMenu[subMenuIndex],
      basic: !updatedMenuItems[menuItemIndex].subMenu[subMenuIndex].basic,
      sliver: !updatedMenuItems[menuItemIndex].subMenu[subMenuIndex].sliver,
      gold: !updatedMenuItems[menuItemIndex].subMenu[subMenuIndex].gold,
    };
    editMenuItem(updatedMenuItems[menuItemIndex]);
  };

  const handleCreateMenuClose = () => {
    setMenuName(null);
  };

  useEffect(() => {
    getMenuDetails();
    GetMenuActionDetails();
  }, []);

  const handleCallback = () => {
    getMenuDetails();
    GetMenuActionDetails();
    handleEdit();
  }

  const GetMenuActionDetails = () => {
    useFetch<IMenuActionResult[]>('Menu/GetMenuActionDetail').then((response) => {
      // setLoading(true);
      setMenuActionList(response.data);
    })
  };

  const getMenuDetails = () => {
    setLoading(true);
    useFetch<{ menuResult: IMenuResult[], subMenuResults: ISubMenuResult[], menuActionResults: IMenuActionResult[] }>('Menu/GetMenuDetail').then((response) => {
      setMenuList(response.data?.menuResult);
      setSubMenuList(response.data?.subMenuResults);
      // Combine menuList and submenuList to create the final data structure
      const formulatedData: IMenuItemResult[] = response.data?.menuResult.map((menu) => {
        const matchingSubmenus = response.data?.subMenuResults.filter((submenu) => submenu.menuId === menu.menuId);
        return {
          ...menu,
          subMenu: matchingSubmenus.map((submenu) => ({
            ...submenu,
          })),
        };
      });

      setFinalMenuList(formulatedData);

    }).finally(() => {
      setLoading(false);
    })
  }

  const handleSaveMenuInformation = async () => {
    (async () => {
      let requestData = {
        "menuName": menuId === 0 ? menuName : null,
        "menuId": menuId,
        "subMenuName": menuName,
        "subMenuId": subMenuId === undefined ? 0 : subMenuId,
        "createdBy": 1,
        "basic": basic,
        "sliver": sliver,
        "gold": gold
      }
      await usePost("Menu/SaveMenuDetail", requestData).then((response) => {
        GetMenuActionDetails();
        getMenuDetails();
        setSnackbarOpen(true);
        setSnackbarMessage("Menu information saved successfully!");

      })
    })().finally(() => {
      handleCreateMenuClose();
      setOpen(false);
      GetMenuActionDetails();
    });
  }

  const onDelete = (selectedOptions: any) => () => {
    setSelectedOptionsList((value: any) =>
      value.filter((option: any) => option.actionName !== selectedOptions)
    );
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEdit = () => {
    // Handle edit logic
    console.log('Editing...');
  };

  const handleCheckboxChange = (menuItemIndex: number, checkboxType: string) => {
    const updatedMenuItems = [...menuItems];

    if (checkboxType === "basic") {
      updatedMenuItems[menuItemIndex] = {
        ...updatedMenuItems[menuItemIndex],
        basic: !updatedMenuItems[menuItemIndex].basic,
      };
    } else if (checkboxType === "silver") {
      updatedMenuItems[menuItemIndex] = {
        ...updatedMenuItems[menuItemIndex],
        sliver: !updatedMenuItems[menuItemIndex].sliver,
      };
    } else if (checkboxType === "gold") {
      updatedMenuItems[menuItemIndex] = {
        ...updatedMenuItems[menuItemIndex],
        gold: !updatedMenuItems[menuItemIndex].gold,
      };
    }
    editMenuItem(updatedMenuItems[menuItemIndex]);
  };

  const handleMenuSelected = (event, selected) => {
    setMenuId(selected?.menuId);
    if (selected?.menuId) {
      setIsMenuError(false);
      setMenuErrorMessage(null);
    }
  }

  const handleValidateMenu = () => {
    if (menuId === 0 || menuId === undefined) {
      setMenuErrorMessage("Please Select Menu Value");
      setIsMenuError(true);
    } else {
      setOpen(true);
    }
  }

  const handleToggleAll = (e) => {
    setDeselectClient(e.target.checked);

    if (e.target.checked) {
      var NewClientList = selectedOptionsList.filter((c) => {
        return !menuActionList.find((r) => {
          return c.actionName === r.actionName
        })
      });
      setSelectedOptionsList(NewClientList);
      setOptionOpen(false);
    }
//Future EnhanceMent
    // if (!selectAllChecked) {
    //   setSelectAllChecked(true);
    //   setSelectedOptionsList(menuactionList); // Select all
    // } else {
    //   setSelectedOptionsList([]); // Deselect all
    //   setDeselectDisabled(true); // Disable the "Deselect all" checkbox
    // }
    setDeselectClient(false);
  };

  const handleSubMenuSelected = (event, selected) => {
    setSubMenuId(selected?.subMenuId);
  }

  const handleMenuActionSelected = (event, selected) => {
    setSelectedOptionsList(selected);
    if (selected?.length <= 0) {
      setCheckAll(false);
    }
  }

  const handleSave = () => {
    if (!menuId) {
      setMenuErrorMessage("Please select a Menu.");
      setIsMenuError(true);
      return;
    }
    if (!selectedOptionsList || selectedOptionsList.length === 0) {
      setNoMenuActionsAssigned(true);
      return;
    }

    (async () => {
      let newActionList = [];
      selectedOptionsList?.map(result => {
        let newList: IMenuActionMappingList = {
          menuId: menuId === undefined ? 0 : menuId,
          subMenuId: subMenuId === undefined || subMenuId === null ? 0 : subMenuId,
          actionId: result?.actionId
        }
        newActionList.push(newList);
      });
      let request = {
        "actionMappingLists": newActionList
      }
      await usePost<any>("Menu/SaveMenuActionMappingDetails", request).then((r) => {
        setSnackbarOpen(true);
        setSnackbarMessage("Menu information saved successfully!");
        setSelectedMenu(null);
        setSelectedSubMenu(null);
        setSelectedOptionsList([]);
        setMenuName('');
        setBasic(false);
        setSliver(false);
        setGold(false);
        setOpen(false);

      })
    })()
  }

//Future EnhanceMent
  const handleClickOpen = () => {
    // (async () => {
    //   setConfirmedClients([]);
    //   setLoading(true);
    //   setShowProgressBar(true);
    //   setOpen(true);
    //   let request = {
    //     "userId": state?.userAccessContext?.id,
    //     "client_code": searchClientCode?.trim().toLocaleUpperCase(),
    //     "isAdmin": true
    //   }
    //   await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
    //     setConfirmedClients(r.data);
    //     setLoading(false);
    //   }).finally(() => {
    //     setShowProgressBar(false);
    //     setCheckAll(false);
    //   });
    // })()
  };

  const handleSearchAction = (e) => {
    setSearchActionName(e.target.value);
  }
//Future EnhanceMent
  const handleClickAway = (e) => {
    setOptionOpen(false);
    // (async () => {
    //   setClientCodeOpen(false);
    //   let request = {
    //     "userId": state?.userAccessContext?.id,
    //     "client_code": "",
    //     "isAdmin": true
    //   }
    //   await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
    //     setConfirmedClients(r.data);
    //   }).finally(() => {
    //     setShowProgressBar(false);
    //   });
    // })()
  };

  return (
    <Box p={0} m={0}>
      <Box m={0} p={0} sx={{ flexGrow: 1 }} bgcolor={"#FBFCFE"}>
        <Grid container spacing={0}>
          <Grid item xs={4} borderRight="1px solid lightgray">
            <Grid container spacing={1} padding="8px 15px" borderRadius={0}>
              <Grid item xs={12}>
                <BDebtTypography variant="body2" padding="5px" align="center" color="primary" bgcolor={"lightgray"} borderRadius={1} >
                  <b>MENU DETAILS</b>
                </BDebtTypography>
              </Grid>
              <Grid item xs={11}>
                <Autocomplete disablePortal id="combo-box-demo"
                  options={menuList} size="small" loading={loading}
                  getOptionLabel={(option) => option?.menuName} fullWidth
                  onChange={handleMenuSelected}
                  classes={{
                    paper: classes.dropdownPaper,
                    option: classes.autoCompleteFont,
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      className={classes.dropDownStyle}
                      placeholder="Menu Name"
                      helperText={menuErrorMessage}
                      error={!!isMenuError}
                      size="small"
                      InputProps={{
                        ...params.InputProps, disableUnderline: true,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="primary" size={15} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment >
                        ),
                        classes: {
                          root: classes.dropDownInput,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={0}>
                <Tooltip title="Create New Menu" arrow placement="top-end">
                  <AddBoxIcon fontSize="medium" color="primary" onClick={() => { setDialogMessage("Menu"); setOpen(true); }} />
                </Tooltip>
              </Grid>
              <Grid item xs={11}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={subMenuList} loading={loading}
                  getOptionLabel={(option) => option?.subMenuName}
                  size="small" fullWidth
                  onChange={handleSubMenuSelected}
                  classes={{
                    paper: classes.dropdownPaper,
                    option: classes.autoCompleteFont,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.dropDownStyle}
                      placeholder="Sub Menu"
                      helperText={subMenuErrorMessage}
                      error={!!isSubMenuError}
                      size="small"
                      InputProps={{
                        ...params.InputProps, disableUnderline: true,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="primary" size={15} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment >
                        ),
                        classes: {
                          root: classes.dropDownInput,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={0} >
                <Tooltip title="Create New SubMenu" arrow placement="top-end">
                  <AddBoxIcon fontSize="medium" color="primary" onClick={() => { setDialogMessage("SubMenu"); handleValidateMenu(); }} />
                </Tooltip>
              </Grid>
              <Grid container spacing={1} m={0}>
                <Grid item xs={9.5} mt={2}>
                  <Box className={classes.LabelBoxStyle}>
                    <FormLabel className={classes.formLabel}><b>Selected Menu Action: {selectedOptionsList?.length}</b></FormLabel>
                  </Box>
                </Grid>
                <Grid item xs={2} mt={2} display={"flex"} justifyContent={"flex-end"} ml={1}>
                  <AddMenuAction onSave={handleCallback} />
                </Grid>
                <Grid item xs={12}>
                  <Box boxShadow={2} borderRadius="5px" p={2} style={{ maxHeight: '150px', overflowY: 'auto', height: '150px' }}>
                    <Scrollbars autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      autoHeight
                      autoHeightMin={50}
                      autoHeightMax={100}
                    >
                      {selectedOptionsList?.length > 0 ? (
                        <Grid container spacing={1}>
                          {selectedOptionsList.map((option: any) => (
                            <Grid item key={option?.actionName}>
                              <Chip
                                size="small"
                                label={option?.actionName}
                                onDelete={onDelete(option?.actionName)}
                                color="success"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography variant="body2"><b>No selected options</b></Typography>
                      )}
                    </Scrollbars>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                      <Autocomplete
                        multiple size="small"
                        loading={loading}
                        filterOptions={(options, state) => options}
                        autoComplete={false}
                        id="checkboxes-tags-demo"
                        options={menuActionList}
                        classes={{
                          paper: classes.dropdownPaper,
                          option: classes.autoCompleteFont,
                        }}
                        disableClearable
                        value={selectedOptionsList}
                        open={optionOpen}
                        renderTags={() => null}
                        onChange={(e, selected) => { handleMenuActionSelected(e, selected); }}
                        onOpen={() => { setOptionOpen(true); }}
                        style={{ maxHeight: "100%" }}
                        onClose={(e: any, reason: AutocompleteCloseReason) => {
                          if (reason === "toggleInput") {
                            setOptionOpen(false);
                          }
                        }}
                        getOptionDisabled={(option) => option?.actionName}
                        getOptionLabel={(option) => option?.actionName}
                        getOptionSelected={(option, value) => option?.actionName === value.actionName}
                        renderOption={(option, { selected }) => (
                          <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '5px', width: 200 }}>
                            {option?.actionName}
                          </span>
                        )}
                        ListboxProps={{ style: { maxHeight: '150px' } }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth size="small" style={{ fontSize: 12 }}
                            value={searchActionName}
                            variant="outlined"
                            type='text' placeholder="Search Actions"
                            onChange={(e) => { setSearchActionName(e.target.value); }}
                            onKeyDown={event => {
                              if (event.key === 'Enter') { handleSearchAction(event); handleClickOpen(); setOptionOpen(true); }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="primary" size={15} /> : null}
                                  {params.InputProps.endAdornment}
                                  <InputAdornment position="end">
                                    <IconButton onClick={(_) => { handleClickOpen(); setOptionOpen(true); }}>
                                      <SearchIcon className={classes.IconSizeStyle} />
                                    </IconButton>
                                  </InputAdornment>
                                  <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                    <Box className={classes.ClientcheckBoxStyle} width="60%">
                                      <Checkbox size="small" disabled={loading || !menuActionList?.length}
                                        checked={checkAll}
                                        onChange={handleSelectAll}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 12 }}>Select All</span>
                                    </Box>
                                    <Box className={classes.ClientcheckBoxStyle} width="40%">
                                      <Checkbox size="small" disabled={loading || !menuActionList?.length}
                                        checked={deselectClient}
                                        onChange={(e) => { handleToggleAll(e); }}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 12 }}>Deselect All</span>
                                    </Box >
                                  </Box >
                                </React.Fragment >
                              ),
                            }}
                            className={classes.textBoxStyle}
                          />
                        )}
                      />
                    </Box>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} >
                <BDebtButton variant="contained" onClick={handleSave} color="primary" size="medium" fullWidth >
                  SUBMIT
                </BDebtButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} padding={1} borderRadius={0}>
              <Grid item xs={12}>
                <BDebtTypography variant="body2" padding="5px" align="center" color="primary" bgcolor={"lightgray"} borderRadius={1} >
                  <b>SUBSCRIPTION DETAILS</b>
                </BDebtTypography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Grid container spacing={0} bgcolor={"#3b4a54"}>
                          <Grid item xs={1} p={1}></Grid>
                          <Grid item xs={4} p={1}>
                            <BDebtTypography variant="body2" color="white">
                              <b>PERMISSION</b>
                            </BDebtTypography>
                          </Grid>
                          <Grid item xs={2} p={1}>
                            <BDebtTypography variant="body2" color="white">
                              <b>Essential</b>
                            </BDebtTypography>
                          </Grid>
                          <Grid item xs={2} p={1}>
                            <BDebtTypography variant="body2" color="white">
                              <b>Premium</b>
                            </BDebtTypography>
                          </Grid>
                          <Grid item xs={1} p={1}>
                            <BDebtTypography variant="body2" color="white">
                              <b>Enterprise</b>
                            </BDebtTypography>
                          </Grid>
                          <Grid item xs={2} p={1}>
                            <BDebtTypography variant="body2" color="white" align="center">
                              <b>Actions</b>
                            </BDebtTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.menuListContainer} >
                          <React.Fragment>
                            {loading ? <LinearProgress /> : null}
                          </React.Fragment >
                          {finalMenuList?.map((r, index) => (
                            <Box m={0.5} mt={1} p={0} key={index}>
                              <Grid container spacing={0} borderRadius={0} boxShadow={2}>
                                <Grid item xs={1} p={0}>
                                  {r.subMenu.length > 0 && (
                                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpandClick(index)} >
                                      {expandedIndex === index ? (<KeyboardArrowUpIcon />)
                                        : (<KeyboardArrowDownIcon />)
                                      }
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item xs={4} p={0}>
                                  <BDebtTypography variant="body2" pt={1} pl={1} >
                                    {r?.menuName}
                                  </BDebtTypography>
                                </Grid>
                                <Grid item xs={2} p={0}>
                                  <Checkbox name="basic" checked={r?.basic} size="small" color="success"
                                    onChange={() => handleCheckboxChange(index, "basic")}
                                  />
                                </Grid>
                                <Grid item xs={2} p={0}>
                                  <Checkbox name="silver" checked={r?.sliver} size="small" color="success"
                                    onChange={() => handleCheckboxChange(index, "silver")}
                                  />
                                </Grid>
                                <Grid item xs={1} p={0}>
                                  <Checkbox name="gold" checked={r?.gold} size="small" color="success"
                                    onChange={() => handleCheckboxChange(index, "gold")}
                                  />
                                </Grid>
                                <Grid item xs={2} p={0} display={'flex'} justifyContent={'center'}>
                                  <EditMenuSetting menuId={r?.menuId} menuName={r?.menuName} basic={r?.basic} gold={r?.gold} sliver={r?.sliver} onEdit={handleCallback} />
                                  <IconButton aria-label="edit" size="small" color="info" onClick={() => handleActionEditClick(r?.menuId, 0)}>
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit >
                                <Box m={0} border={"1px solid lightgray"} bgcolor={"aliceblue"} >
                                  {r?.subMenu.map((result) => (
                                    <Grid container spacing={0} key={result?.subMenuId} >
                                      <Grid item xs={1} p={0}></Grid>
                                      <Grid item xs={4} p={0}>
                                        <BDebtTypography variant="body2" pt={1} pl={1} >
                                          {result?.subMenuName}
                                        </BDebtTypography>
                                      </Grid>
                                      <Grid item xs={2} p={0}>
                                        <Checkbox name="basic" checked={result?.basic} size="small" color="primary" onChange={() => handleSubMenuCheckboxChange(index, subMenuId)} />
                                      </Grid>
                                      <Grid item xs={2} p={0}>
                                        <Checkbox name="silver" checked={result?.sliver} size="small" color="primary" onChange={() => handleSubMenuCheckboxChange(index, subMenuId)} />
                                      </Grid>
                                      <Grid item xs={1} p={0}>
                                        <Checkbox name="gold" checked={result?.gold} size="small" color="primary" onChange={() => handleSubMenuCheckboxChange(index, subMenuId)} />
                                      </Grid>
                                      <Grid item xs={2} display={"flex"} justifyContent={"center"}>
                                        <EditMenuSetting menuId={result?.subMenuId} menuName={result?.subMenuName}
                                          basic={result?.basic} gold={result?.gold} sliver={result?.sliver} onEdit={handleCallback} />
                                        <Tooltip title="View Menu Actions" arrow placement="top-end">
                                          <IconButton aria-label="edit" size="small" color="info" onClick={() => handleActionEditClick(result?.menuId, result?.subMenuId)}>
                                            <VisibilityIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )
                                  )}
                                </Box>
                              </Collapse>
                            </Box>
                          ))}
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <BDebtDialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
        maxWidth={"sm"} PaperProps={{ style: { width: 600 } }}>
        <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleClose}>
          Menu Details
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body2" padding="5px" >
            {dialogMessage} Name
          </BDebtTypography>
          <TextField fullWidth size="small"
            id="standard-bare" variant="outlined"
            value={menuName} className={classes.searchBox}
            type='text' placeholder={`Enter ${dialogMessage} Name`}
            onChange={(e) => setMenuName(e.target.value)}
            inputProps={{ maxLength: 255 }}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <Grid xs={12} justifyContent="center" textAlign='center'>
            <FormControlLabel
              control={<BDebtCheckbox color="success" checked={basic === true} onChange={handleChange} />}
              label="Essential"
              name="basic"
              value={basic}

            />
            <FormControlLabel
              control={<BDebtCheckbox color="success" checked={sliver === true} onChange={handleChange} />}
              label="Premium"
              name="sliver"
              value={sliver}
            />
            <FormControlLabel
              control={<BDebtCheckbox color="success" checked={gold === true} onChange={handleChange} />}
              label="Enterprise"
              name="gold"
              value={gold}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <BDebtButton color='success' variant="contained" size="small" onClick={handleSaveMenuInformation} autoFocus>
            Save
          </BDebtButton>
          <BDebtButton color='error' variant="contained" size="small" onClick={handleClose}>Close</BDebtButton>

        </DialogActions>
      </BDebtDialog>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success" >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <BDebtDialog open={actionDialogOpen} onClose={handleActionCloseDialog} PaperProps={{ style: { width: 400 } }}>
        <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleActionCloseDialog}>Menu Action</BDebtDialogTitleHeader>
        <DialogContent>
          {MenuActionDetails && MenuActionDetails.length > 0 ? (
            <List>
              {MenuActionDetails?.map((menuAction, index) => (
                <ListItem key={index}>
                  <ListItemText>
                    <Typography variant="body2">
                      <AssignmentTurnedInIcon fontSize="small" color="success" /> {menuAction.actionName}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          ) : (
            <BDebtTypography variant="body2" alignItems={"center"} textAlign={"center"}><b>No Records to display</b></BDebtTypography>
          )}
        </DialogContent>
        <DialogActions>
          <BDebtButton variant="contained" color="error" size="small" onClick={handleActionCloseDialog}>Close</BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </Box>
  );
};

export default MenuLevelSetting;