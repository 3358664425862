import { DialogContent, styled } from "@mui/material";

interface BDebtDialogContentProps {
  textAlign?: string; // Add textAlign property to BDebtCardProps
  color?: string;
}

const BDebtDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== "textAlign" && prop !== "color", // Exclude 'borderRadius' and 'textAlign' from being passed to the underlying DOM element
})<BDebtDialogContentProps>(({ theme, textAlign, color }) => ({
  "&.MuiDialogContent-root": {
    padding: theme.spacing(2), // Add your custom styles
    textAlign: textAlign || "left", // Use the provided textAlign or default to "left"
    color: color || "black", // Additional styles if needed
  },
  "&.MuiDialogContent-dividers": {
    borderBottom: "2px solid red", // Add your custom divider styles
  },
}));

export default BDebtDialogContent;
