//Mui Components
import { Grid, Box } from "@mui/material";
//Global Custom CSS
import BDebtTypography from "../../GlobalControl/BDebtTypography";
//Custom CSS
import useStyles from "../settings/SettingsCss";
//JSON Data
import { MenuDetails } from "../../MockData/Settings/GlobalMenu";
import { Forms } from "../../Constant/Constant";
import { Link } from "react-router-dom";

const FormsLayout = () => {
  const classes = useStyles();

  return (
    <Box p={1} m={1}>
      <Grid container spacing={2}>
        <Grid xs={12} md={12}>
          <Box p={1} m={1}>
            <BDebtTypography
              component="div"
              variant="h6"
              className={classes.settingHeader}
            >
              <b>FORMS</b>
            </BDebtTypography>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Grid container spacing={2}>
          {MenuDetails?.map((r) => (
            <>
              {r?.menuGroupName === Forms ? (
                <Grid xs={12} md={1.8} padding={1}>
                   <Link to={r?.menuPath} className={classes.linkStyle}>
                  <Box
                    p={0}
                    m={1}
                    justifyContent={"center"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    borderRadius={3}
                    boxShadow={5}
                    className={classes.cardLayout}
                  >
                    <img
                      src={require(`../../asset/image/Submenu/${r?.menuIcon}`)}
                      alt={r?.menuName}
                      className={classes.Submenuimg}
                    />
                    <Box p={1} borderTop={"1px solid lightgray"}>
                      <BDebtTypography component="div" variant="h6">
                        <b>{r?.menuName.toUpperCase()}</b>
                      </BDebtTypography>
                    </Box>
                  </Box>
                  </Link>
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
export default FormsLayout;
