/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {Table,TableBody,TableContainer,TableHead,TablePagination,TableRow} from "@material-ui/core";
import { Box, Grid, Paper } from "@mui/material";
import TableCell from "@mui/material/TableCell";
//JSON
import TemplateUser from "../../MockData/TemplateUser.json";
//Interface
import { IAgentTableRow } from "../../Model/AgentActivityTemplate/IAgentTableRow";
//CSS
import useStyles from "./ManageTemplateCss";
//Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditClientDetail from "./EditClientDetail";

function EnhancedTableHead() {
  const classes = useStyles();
  const headCells = [
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      sortable: false,
    },
    {
      id: "clientId",
      numeric: false,
      disablePadding: false,
      label: "CLIENT ID",
      sortable: false,
    },
    {
      id: "clientName",
      numeric: false,
      disablePadding: false,
      label: "CLIENT NAME",
      sortable: false,
    },
    {
      id: "istParty",
      numeric: false,
      disablePadding: false,
      label: "FIRST PARTY",
      sortable: false,
    },
    {
      id: "flatFee",
      numeric: false,
      disablePadding: false,
      label: "FLAT FEE",
      sortable: false,
    },
    {
      id: "contingency",
      numeric: false,
      disablePadding: false,
      label: "CONTINGENCY",
      sortable: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "ACTIONS",
      sortable: false,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCellHeader}
            key={headCell.id}
            align="left"
          >
            <b> {headCell.label || headCell.sortable}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ClientDetail: React.FC<{
  dataRow?: IAgentTableRow;
  activityEnable: any; // Assuming you have a type for activityEnable
  onLoadData: (rowData: IAgentTableRow) => void;
}> = (props) => {
  const classes = useStyles();
  const { dataRow, onLoadData } = props;
  const [page, setPage] = React.useState(0); // Start page from 0
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isEditClientOpen, setEditClientOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any | null>(null);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };
  const handleEditClick = (row: any) => {
    setSelectedRow(row);
    setEditClientOpen(true);
  };

  const handleEditFormClose = () => {
    setEditClientOpen(false);
  };



  return (
    <Box p={0}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Paper>
            <TableContainer
              className={`${
                classes.tablebodyConainer
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableHead />
                <TableBody>
                  {TemplateUser.map((row: any, index: number) => (
                    <TableRow
                      onClick={() => onLoadData(row)}
                    >
                      <TableCell
                        className={
                          dataRow?.ClientId
                            ? classes.selectedRow
                            : classes.tableCellStyle
                        }
                      >
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.ClientID}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.Clientname}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.FirstParty}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.Flatfee}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.Contingency}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                          <EditIcon onClick={() => handleEditClick(row)} className={classes.EditIcon}  />
                        <DeleteOutlineIcon className={classes.DeleteIcon}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={100}
              page={page}
              size="small"
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      {isEditClientOpen && (
        <EditClientDetail
          open={isEditClientOpen}
          onClose={handleEditFormClose}
          selectedRow={selectedRow}
        />
      )}
    </Box>
  );
};

export default ClientDetail;