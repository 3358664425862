import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablebodyConainer: {
      borderRadius: 0,
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 175px)",
        minHeight: "calc( 100vh - 175px)",
      },
    },
    CheckBox: {
      color: "rgb(23 144 91)",
      borderRadius: "10px",
      fontWeight: "bold",
    },
    gridStyle: {
      backgroundColor: "smokewhite",
    },
    gridStyle1: {
      padding: "10px 5px",
    },
    yesbutton: {
      backgroundColor: "green",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "green",
        color: "white",
      },
    },
    nobutton: {
      background: "red",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    },
    permittedChecked: {
      color: "green",
      borderRadius: "5px",
      width: "100% !important",
      fontWeight: "bold",
      display: "flex",
    },
    permittedFiled: {
      width: "100% !important",
      backgroundColor: "white",
      color: "black",
      borderRadius: "5px",
    },
    permittedChecked1: {
      backgroundColor: "#dbf6e5",
      width: "100% !important",
      color: "green",
      borderRadius: "5px",
    },
    tableCellHeader: {
      padding: "12px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: "bold",
      position: "sticky",
      top: 0,
      zIndex: 100,
    },
    tableCellStyle: {
      padding: "5px 8px !important",
      "& .MuiTableCell-root": {
        padding: "10px 15px!important",
      },
      fontSize: "12px !important",
    },
    selectedRow: {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      backgroundColor: "white",
    },
    DeleteIcon: {
      color: "red !important",
      marginLeft: "8px !important",
    },
    EditIcon:{
      color:"green !important",
    },
    pageContainer: {
      boxShadow: " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important"
    },
    pageHeader: {
      marginTop: '10px',
      marginBottom: '8px',
      textTransform: 'uppercase',
      fontSize: 13
    },
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "5px !important",
      border: '2px solid #215176 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      }
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
  })
);

export default useStyles;
