/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Chip, Dialog, DialogContent, Grid, IconButton, InputAdornment, Switch, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControlLabel, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "../DebtorsCss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: "rgb(17, 141, 87)" }} />
);

const top100Films = [
  { title: "ClientCode" },
  { title: "AccountId " },
  { title: "Status" },
  { title: "DelinquencyDate" },
];

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SearchDebtorDetails = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<{ title: string }[]>([]);
  const [selectedValuesText, setSelectedValuesText] = useState<string>("");
  const [textAreaRows, setTextAreaRows] = useState(1);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState<string>("");
  const [selectedOptionsList, setSelectedOptionsList] = useState<any>([]);
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);

  const handleSwitchChange = () => {
    setIsSwitchOn((prev) => !prev);
  };

  useEffect(() => {
    const selectedValues = searchValue.map((option) => option.title).join(", ");
    setSelectedValuesText(selectedValues);

    const rows = selectedValues.split("\n").length;
    setTextAreaRows(rows < 3 ? 1 : rows);
  }, [searchValue]);

  const handleClientSelect = (event: any, selected: any) => {
    const updatedList = [...selectedOptionsList];
    for (const item of selected) {
      if (!selectedOptionsList.includes(item)) {
        updatedList.push(item);
      }
    }
    setSelectedOptionsList(updatedList);
  };

  const onDelete = (selectedOptions: any) => () => {
    setSelectedOptionsList((value: any) =>
      value.filter((v: any) => v.title !== selectedOptions)
    );
  };

  const handleHelpClick = () => {
    setShowVideoDialog(true);
  };

  const handleCloseVideoDialog = () => {
    setShowVideoDialog(false);
  };

  return (
    <div>
      <Box p={1} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} >
          <Grid item xs={2} md={1}>
            {isSwitchOn ? (
              <Typography variant="body2" className={classes.pageHeader} color="primary"><b>Agent Inquiry</b></Typography>
            ) : <Typography variant="body2" className={classes.pageHeader} color="primary"><b>Inquiry</b></Typography>}
          </Grid>
          <Grid item xs={2} md={2}>
            <TextField
              fullWidth
              size="small"
              id="standard-bare"
              variant="outlined"
              value={searchDebtor}
              className={classes.searchBox}
              type='text'
              placeholder="Search All"
              onChange={(e) => setSearchDebtor(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && searchDebtor !== "") {
                  setShowSearchIcon(false);
                } else if (event.key === "Enter" && searchDebtor === "") {
                  setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { setShowSearchIcon(false); }} style={{ padding: '3px' }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton style={{ padding: '3px' }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <Autocomplete
              multiple
              size="small"
              id="checkboxes-tags-demo"
              options={top100Films}
              disableCloseOnSelect
              filterOptions={(options, state) => options}
              value={searchValue}
              onChange={(event, selectedOptions) => {
                // setSearchValue(selectedOptions);
                handleClientSelect(event, selectedOptions);
              }}
              renderTags={() => null}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    className={selected ? classes.GreenColor : undefined}
                  />
                  {option.title}
                </React.Fragment>
              )}
              classes={{
                paper: classes.dropdownPaper,
                option: classes.autoCompleteFont
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Search Fields"
                  {...params}
                  className={classes.dropDownStyle}
                  InputProps={{
                    ...params.InputProps, disableUnderline: true,
                    classes: {
                      root: classes.dropDownInput
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={5} md={4}>
            {selectedOptionsList?.length > 0 ? (
              <Scrollbars autoHide
                autoHideTimeout={1000}
                autoHideDuration={10}
                autoHeight
                autoHeightMin={35}
                autoHeightMax={35}
              >
                {selectedOptionsList.map((v: any) => (
                  <Chip
                    key={v.title}
                    size="small"
                    className="chip-style"
                    label={v.title}
                    style={{ marginTop: '5px' }}
                    onDelete={onDelete(v.title)}
                  />
                ))}
              </Scrollbars>
            ) : null}
          </Grid>
          <Grid item xs={3} md={3} className={classes.gridDisplay}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              style={{ fontSize: 12 }}
            >
              Load More
            </Button>
            <IconButton onClick={handleHelpClick} size="small" className={classes.iconButton}>
              <LiveHelpIcon />
            </IconButton>
            <FormControlLabel className={classes.formControl}
              control={<Android12Switch defaultChecked
                checked={isSwitchOn}
                onChange={handleSwitchChange} />}
              label="Swtich"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog open={showVideoDialog} onClose={handleCloseVideoDialog}>
        <BDebtDialogTitleHeader id="" onClose={handleCloseVideoDialog}>Help Video</BDebtDialogTitleHeader>
        <DialogContent>
          <video width="100%" height="400" controls>
            <source src="your-video.mp4" type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default SearchDebtorDetails;

