/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Checkbox, DialogContent, Grid, Paper, Box } from "@material-ui/core";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import Scrollbars from "react-custom-scrollbars";
import CancelIcon from "@material-ui/icons/Cancel";
import { mockrule } from "../../MockData/Placement/DataRuleMock";
import useStyles from "../Placement/PlacementCss";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { MinusSquare, CloseSquare, PlusSquare, StyledTreeItem } from "../../GlobalStyles/TreeView";

const DatasetRules = () => {
  const classes = useStyles();
  const [, setIsDialogOpen] = useState(false);
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
  const [selectedRuleForExplanation, setSelectedRuleForExplanation] = useState<string | null>(null);

  const handleSelectedRuleClick = (explanation: string) => {
    setSelectedRuleForExplanation(explanation);
  };

  const handleGroupToggle = (ruleType: string) => {
    setOpenGroups((prevOpenGroups) => ({
      ...prevOpenGroups,
      [ruleType]: !prevOpenGroups[ruleType],
    }));
  };

  const closeDialog = () => {
    setIsDialogOpen(true);
    setSelectedRuleForExplanation(null);
  };

  const handleCheckboxChange = (ruleType: string, ruleName: string) => {
    const ruleId = `${ruleType}-${ruleName}`;
    setChecked((prevChecked) => {
      const updatedChecked = { ...prevChecked, [ruleId]: !prevChecked[ruleId] };

      setSelectedRules((prevSelectedRules) => {
        if (!prevChecked[ruleId]) {
          return [...prevSelectedRules, ruleId];
        } else {
          return prevSelectedRules.filter((selectedRule) => selectedRule !== ruleId);
        }
      });

      if (selectedRuleForExplanation === ruleId) {
        setSelectedRuleForExplanation(null);
      }

      return updatedChecked;
    });
  };
  
  const handleCancelClick = (cancelledRule: string) => {
    setChecked((prevChecked) => {
      const updatedChecked = { ...prevChecked };
      delete updatedChecked[cancelledRule];
      return updatedChecked;
    });

    setSelectedRules((prevSelectedRules) =>
      prevSelectedRules.filter((rule) => rule !== cancelledRule)
    );

    if (selectedRuleForExplanation === cancelledRule) {
      setSelectedRuleForExplanation(null);
    }
  };

  useEffect(() => {
    const initialOpenGroupsState = mockrule.reduce((acc, group) => {
      acc[group.ruleType] = group.ruleType === 'Numeric';
      return acc;
    }, {});
    setOpenGroups(initialOpenGroupsState);
  }, []);

  return (
    <div>
      <BDebtDialogTitleHeader
        onClose={closeDialog}
        id="customized-dialog-title">        
        Apply Rules
      </BDebtDialogTitleHeader>
      <DialogContent>
        <Grid container spacing={0} component={Paper}>
          <Grid xs={4} className={classes.gridStyle}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BDebtTypography variant="h6" className={classes.headButton}>
                  All Rules
                </BDebtTypography>
              </Grid>
              <Grid item xs={12}>
                <Scrollbars className={classes.perviewrootContainer}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={20}
                  universal={true}
                >
                  <TreeView
                    aria-label="customized"
                    defaultExpanded={[mockrule[0]?.ruleType]}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={<CloseSquare />}
                    sx={{ overflowX: 'hidden' }}
                  >
                    {mockrule?.map((group) => (
                      <StyledTreeItem key={group?.ruleType} nodeId={group.ruleType} label={group.ruleType}
                        onClick={() => handleGroupToggle(group?.ruleType)}>
                        {group.fields?.map((rule) => (
                          <StyledTreeItem key={rule?.ruleName} nodeId={rule.ruleName} label={rule.ruleName}
                            endIcon={<Checkbox onChange={() => handleCheckboxChange(group?.ruleType, rule?.ruleName)} />} />
                        ))}
                      </StyledTreeItem>
                    ))}
                  </TreeView>
                </Scrollbars>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={8} className={classes.gridStyle}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BDebtTypography variant="h6" className={classes.headButton}>
                  Selected Rules
                </BDebtTypography>
              </Grid>
              <Grid item xs={12} spacing={2}>
                <Scrollbars className={classes.perviewrootContainer}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={20}
                  universal={true}
                >
                  <Box style={{ minWidth: 150, marginTop: "10px" }} className={classes.selectedRulesContainer}>
                    {selectedRules?.map((selectedRule) => (
                      <div
                        key={selectedRule}
                        className={`${classes.selectedRuleItem} ${classes.selectedRule}`}
                        onClick={() => handleSelectedRuleClick(selectedRule)}
                      >
                        <BDebtTypography
                          variant="h6"
                          className={classes?.rulehead}
                        >
                          {selectedRule}
                        </BDebtTypography>
                        <CancelIcon
                          className={classes?.cancelIcon}
                          onClick={(e) => {
                            closeDialog();
                            e.stopPropagation();
                            handleCancelClick(selectedRule);
                          }}
                        />
                      </div>
                    ))}
                  </Box>
                </Scrollbars>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
};

export default DatasetRules;
