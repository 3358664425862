/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid, Drawer, Snackbar, DialogActions, Box, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import BDebtButton from '../../../GlobalControl/BDebtButton';
import { Autocomplete } from '@mui/material';
import BDebtTextField from '../../../GlobalControl/BDebtTextField';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { IGetDataDetails, IProcedureList, ITableList, ITestConnectionResponse } from '../../../Model/Configuration';
import { Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useStyles from '../PlacementCss';
import { Tabs } from '@mui/material';
import { Tab } from '@mui/material';
import PowerIcon from '@mui/icons-material/Power';
import Alert from '@mui/material/Alert';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // Import PlayArrowIcon from MUI
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import AddMapTargetConfiguration from './AddMapTargetConfiguration';
import { AxiosResponse } from 'axios';
import { DialogContent } from '@mui/material';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import maptarget from "../../../asset/image/Placement/maptarget.svg";

const DestinationMapping: React.FC<{ onNextClick: (connection: string, tableName: string) => void; onClickSubmitPage: () => void; }> = (props) => {
    const { onNextClick, onClickSubmitPage } = props;
    const classes = useStyles();
    const history = useHistory();
    const [connection, setConnection] = useState();
    const [Sourcevalue, setSourceValue] = useState<ITableList[]>([]);
    const [SourceDataDetails, SetSourceDataDetails] = useState<IGetDataDetails[]>([]);
    const [connectorNameList, setConnectorNameList] = useState<IGetDataDetails[]>([]);
    const [procedurevalue, setProcedureValue] = useState<IProcedureList[]>([]);
    const [procedureDataDetails, setProcedureDataDetails] = useState<IGetDataDetails[]>([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'connectionString' | 'azureKeyVault'>('connectionString');
    const [selectedOption, setSelectedOption] = useState<string>(""); // State to store selected option
    const [isQueryRunning, setIsQueryRunning] = useState(false); // State to track whether the query is running or not  
    const [queryResult, setQueryResult] = useState<string>("");
    const [connectorName, setConnectorName] = useState("");
    const [connectionString, setConnectionString] = useState("");
    const [connectorType, setConnectorType] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tableSchema, setTableSchema] = useState([]);

    const [tableName, setTableName] = useState<string>("");

    const [storeLocally, setStoreLocally] = useState(false);
    const [migrateData, setMigrateData] = useState(false);

    const handleStoreLocallyChange = (event) => {
        setStoreLocally(event.target.checked);
        if (event.target.checked) {
            onClickSubmitPage();
        }
    };

    const handleMigrateDataChange = (event) => {
        setMigrateData(event.target.checked);
    };

    const handleSelectionChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        setSelectedOption(value); // Update selected option when changed
    };

    useEffect(() => {
        GetdataSourceDetails();
    }, []);

    const handleCallBack = () => {
        GetdataSourceDetails();
    }
    const handlePreviewClick = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const renderAutocompleteOrTextField = () => {
        if (selectedOption === 'Table') {
            return (
                <Autocomplete
                    size='small'
                    options={Sourcevalue}
                    onChange={handleTableDataChange}
                    getOptionLabel={(option) => option?.tableList}
                    fullWidth
                    classes={{
                        paper: classes.dropdownPaper,
                        option: classes.autoCompleteFont,
                    }}
                    renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label={"Select the TableName"}
                    />}
                />
            );
        } else if (selectedOption === 'Procedure') {
            return (
                <Autocomplete
                    size='small'
                    options={procedurevalue}
                    getOptionLabel={(option) => option.procedureName}
                    fullWidth
                    classes={{
                        paper: classes.dropdownPaper,
                        option: classes.autoCompleteFont,
                    }}
                    renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label={"Select the ProcedureName"}
                    />}
                />
            );
        } else if (selectedOption === 'Query') {
            return (
                <div style={{ position: 'relative' }}>
                    <BDebtTextField
                        fullWidth
                        size="small"
                        multiline
                        rows={4} // Adjust the number of rows as needed
                        placeholder="Enter your query here"
                        value={queryResult} // Bind the query result to the text field
                        onChange={(event) => setQueryResult(event.target.value)} // Allow manual editing of the result
                    />
                    <BDebtTooltip title={isQueryRunning ? "Pause Query" : "Run Query"} arrow placement="top-end">
                        {isQueryRunning ? (
                            <PauseCircleOutlineIcon style={{ position: 'absolute', top: 8, right: 8, cursor: 'pointer' }} onClick={handleToggleQueryRunning} />
                        ) : (
                            <PlayCircleOutlineIcon style={{ position: 'absolute', top: 8, right: 8, cursor: 'pointer' }} onClick={handleToggleQueryRunning} />
                        )}
                    </BDebtTooltip>
                </div>
            );
        }
        // Default case if no option is selected or none matches
        return null;
    };

    const handleToggleQueryRunning = () => {
        setIsQueryRunning(!isQueryRunning); // Toggle the state to indicate whether the query is running or not
        if (isQueryRunning) {
            // Logic to pause the query
            setQueryResult(""); // Clear the query result when pausing
        } else {
            // Logic to run the query
            // Sample result for demonstration purposes
            const sampleResult = "Sample query result";
            setQueryResult(sampleResult); // Set the sample result in the text field
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: 'connectionString' | 'azureKeyVault') => {
        setSelectedTab(newValue);
    };

    const GetdataSourceDetails = async () => {
        await useFetch<any>("TargetMapping/GetTargetMappingDetail").then((response) => {
            SetSourceDataDetails(response.data);
            setConnectorNameList(response.data);
        });
    };

    const handleTestConnection = () => {
        usePost<ITestConnectionResponse>("Sql/TestDatabaseConnection", {
            connectorType,
            connectorName,
            connectionString,
        })
            .then((response: AxiosResponse<ITestConnectionResponse>) => {
                const responseData = response.data;
                if (responseData.success) {
                    setSnackbarMessage(responseData.message);
                    setSnackbarSeverity("success");
                } else {
                    setSnackbarMessage(responseData.message);
                    setSnackbarSeverity("error");
                    setSnackbarMessage('Test connection succeeded');
                    setSnackbarOpen(true);
                }
            })
            .catch((error) => {
                setSnackbarMessage("Failed to test database connection");
                setSnackbarSeverity("error");
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpenDrawer(open);
    };
    const handleConnectorChange = (event, newValue) => {

        // SourceDataDetails.find((detail) => detail.connectorName === newValue);

        usePost<any>('SqlTable/SqlTableListAsync', { connectionString: newValue.connectionString, }).then((response) => {
            setSourceValue(response.data);
        });
        setConnection(newValue.connectionString);
    };

    const handleProcedureChange = (event, newValue) => {
        procedureDataDetails.find((detail) => detail.connectorName === newValue);

        usePost<any>('SqlProcedure/getProcedureNames', { connectionString: newValue.connectionString, }).then((response) => {
            setProcedureValue(response.data);
        });
        setConnection(newValue.connectionString);
    };

    const handleTableDataChange = (event, newValue) => {
        setTableName(newValue?.tableList);
        const request = {
            "connectionString": connection,
            "tableName": newValue.tableList
        };
        usePost<any>('GetSqlTableData/GetTableData', request)
            .then((response) => {
                console.log("Response from API:", response.data); // Log the response data
                setTableSchema(response.data);
                setIsDialogOpen(true);
            })
    };

    const handleNextClick = () => {
        //handleTableChange(selectedOption); // Call handleTableChange function
        onNextClick(connection, tableName); // call back function to move to next tab -- General Mapping Page
    };

    const columns = tableSchema?.length > 0 ? Object.keys(tableSchema[0])?.map((columnName, index) => {
        return { field: columnName, headerName: columnName, width: 150, index };
    }) : [];


    const handleChangeConnector = (selectedValue) => {
        setConnectorNameList(SourceDataDetails);

        const filteredItems = SourceDataDetails.filter(item => selectedValue?.connectorType === item?.connectorType);
        setConnectorNameList(filteredItems);
        console.log(filteredItems);
    }

    return (
        <React.Fragment>
            {migrateData ? (
                <div className={classes.div}>
                    <div className={classes.root}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} className={classes.padding}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align="center">
                                            <b>Choose your Destination to Migrate Data</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Autocomplete
                                            size='small'
                                            options={SourceDataDetails}
                                            fullWidth
                                            classes={{
                                                paper: classes.dropdownPaper,
                                                option: classes.autoCompleteFont,
                                            }}
                                            getOptionLabel={(option) => option?.connectorType}
                                            renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label="Connector Type" />}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <BDebtTooltip title="Test Connection" arrow placement="top-end">
                                            <PowerIcon onClick={handleTestConnection} />
                                        </BDebtTooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <BDebtTooltip title="Open" arrow placement="top-end">
                                            <OpenInNewIcon onClick={toggleDrawer(true)} />
                                        </BDebtTooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <AddMapTargetConfiguration onSave={handleCallBack} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Drawer anchor='right' open={openDrawer} onClose={toggleDrawer(false)} classes={{ paper: classes.drawerPaper }}>
                                            <Typography variant="h6" ml={2}>
                                                Properties
                                            </Typography>
                                            <br />
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="standard-bare"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="Connector Type"
                                                        color="primary"
                                                        defaultValue="sql"
                                                        focused
                                                    />
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="outlined-multiline-static"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="DatabaseName"
                                                        defaultValue="DatabaseName"
                                                        color="primary"
                                                        focused
                                                        multiline
                                                    />
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="standard-bare"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="Host Id"
                                                        defaultValue="198.567.289"
                                                        color="primary"
                                                        focused
                                                    />
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="standard-bare"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="Host Name"
                                                        defaultValue="HostName"
                                                        color="primary"
                                                        focused
                                                    />
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="standard-bare"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="User Name"
                                                        defaultValue="userName"
                                                        color="primary"
                                                        focused
                                                    />
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    <BDebtTextField
                                                        fullWidth
                                                        size="small"
                                                        id="standard-bare"
                                                        variant="outlined"
                                                        type="text"
                                                        placeholder="Password"
                                                        defaultValue="password"
                                                        color="primary"
                                                        focused
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Connection Type Tabs">
                                                        <Tab value="connectionString" label="Connection String" />
                                                        <Tab value="azureKeyVault" label="Azure Key Vault" />
                                                    </Tabs>
                                                </Grid>
                                                <Grid item xs={12} ml={2}>
                                                    {selectedTab === 'connectionString' && (
                                                        <BDebtTextField
                                                            fullWidth
                                                            size="small"
                                                            id="connection-string-field"
                                                            variant="outlined"
                                                            type="text"
                                                            placeholder="Connection String"
                                                            color="primary"
                                                            focused
                                                        />
                                                    )}
                                                    {selectedTab === 'azureKeyVault' && (
                                                        <BDebtTextField
                                                            fullWidth
                                                            size="small"
                                                            id="azure-key-vault-field"
                                                            variant="outlined"
                                                            type="text"
                                                            placeholder="Azure Key Vault"
                                                            color="primary"
                                                            focused
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Drawer>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Autocomplete
                                            size='small'
                                            options={connectorNameList}
                                            fullWidth
                                            classes={{
                                                paper: classes.dropdownPaper,
                                                option: classes.autoCompleteFont,
                                            }}
                                            getOptionLabel={(option) => option?.connectorName}
                                            renderInput={(params) => (
                                                <BDebtTextField
                                                    type='outlined'
                                                    size='small'
                                                    {...params}
                                                    label='Connector Name'
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                handleConnectorChange(event, value);
                                                handleProcedureChange(event, value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <BDebtTooltip title="Test Connection" arrow placement="top-end">
                                            <PowerIcon onClick={handleTestConnection} />
                                        </BDebtTooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <BDebtTooltip title="Open" arrow placement="top-end">
                                            <OpenInNewIcon onClick={toggleDrawer(true)} />
                                        </BDebtTooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <AddMapTargetConfiguration onSave={handleCallBack} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            size='small'
                                            options={['Table', 'Procedure', 'Query']}
                                            fullWidth
                                            classes={{
                                                paper: classes.dropdownPaper,
                                                option: classes.autoCompleteFont,
                                            }}
                                            value={selectedOption}
                                            onChange={handleSelectionChange}
                                            renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label="Selection" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {renderAutocompleteOrTextField()}
                                    </Grid>
                                    <Grid item xs={12} justifyContent={"flex-end"} display={"flex"} alignItems={"center"}>
                                        <BDebtButton size="small" color="primary" onClick={handlePreviewClick}>
                                            Preview
                                        </BDebtButton>
                                        &nbsp;
                                        <BDebtButton size="small" color="success" onClick={handleNextClick}>
                                            Next
                                        </BDebtButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={3000} // Optional: Set duration to auto-close the snackbar
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set position to top center
                                color='success'
                                className={classes.snackbarStyle}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="success" style={{ color: "white", backgroundColor: "green" }}>
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                            <BDebtDialog maxWidth={"lg"} open={isDialogOpen} onClose={handleCloseDialog} PaperProps={{ style: { width: 800 } }}>
                                <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleCloseDialog}>Preview Table</BDebtDialogTitleHeader>
                                <DialogContent>
                                    <br />
                                    <DataGrid rows={tableSchema?.map((row, index) => ({ id: index, ...row }))} columns={columns} rowHeight={30} className={classes.datagrid} sx={{ fontSize: "smaller" }} />
                                </DialogContent>
                                <DialogActions className={classes.dialogAction}>
                                    <BDebtButton onClick={handleCloseDialog} color="error" size="small" variant="contained">
                                        Close
                                    </BDebtButton>
                                </DialogActions>
                            </BDebtDialog>
                        </Grid>
                    </div>
                </div>
            ) : (
                <div className={classes.finishDiv}>
                    <Box p={2}>
                        <Grid container spacing={1} padding={4}>
                            <Grid item xs={12} sm={12} className={classes.submitedData}>
                                <Typography align="center" fontWeight="bold" className={classes.FontStyle}>
                                    Where would you like to store the data?
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"}>
                                <Typography variant="body2" className={classes.processedData} p={1}>
                                    <b>Choose Your Option</b>
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={storeLocally ? storeLocally : true} defaultChecked onChange={handleStoreLocallyChange} />}
                                        label="Locally(By default)."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={migrateData} onChange={handleMigrateDataChange} />}
                                        label="Choose other destination to Migrate."
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"} marginTop={1} >
                                <img src={maptarget} alt="submit Icon" width={250} height={250} />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </React.Fragment >
    );
};

export default DestinationMapping;
