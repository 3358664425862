/* eslint-disable @typescript-eslint/no-unused-vars */
import { SvgIconComponent } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import EmailIcon from '@material-ui/icons/Email';
import EvStationIcon from '@material-ui/icons/EvStation';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import GamesIcon from '@material-ui/icons/Games';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PeopleIcon from '@material-ui/icons/People';
import PublicIcon from '@material-ui/icons/Public';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SettingsIcon from '@material-ui/icons/Settings';
import StreetviewIcon from '@material-ui/icons/Streetview';
import React from 'react';

// import ClassIcon from '@material-ui/icons/Class';
import ManageActivityTemplate from './Components/ManageTemplate/ManageActivityTemplate';
import AgencyView from './Components/Report/AgencyView';
import ClientAcknowledgement from './Components/Report/ClientAcknowledgement';
import Template from './Components/Setting/Template';
import DashboardLayout from './DashboardLayout';
import GlobalControlView from './Pages/GlobalControlView';
import UserList from './Pages/UserList';
import AccountsLayout from './Pages/accounts/AccountsLayout';
import Collect from './Pages/accounts/Collect';
import Inquiry from './Pages/accounts/Inquiry';
import Archieved from './Pages/diagnostics/Archieved';
import DataMovement from './Pages/diagnostics/DataMovement';
import DiagnosticsLayout from './Pages/diagnostics/DiagnosticsLayout';
import EgressEntity from './Pages/diagnostics/Egress/EgressEntity';
import IngressEntity from './Pages/diagnostics/Ingress/IngressEntity';
import Jobs from './Pages/diagnostics/Jobs';
import FormsLayout from './Pages/forms/FormsLayout';
import Notification from './Pages/notifications/Notification';
import NotificationsLayout from './Pages/notifications/NotificationsLayout';
import AzureFunctions from './Pages/operations/AzureFunctions';
import OperationsLayout from './Pages/operations/OperationsLayout';
import Pipeline from './Pages/operations/Pipeline';
import Trigger from './Pages/operations/Trigger';
import ReportsLayout from './Pages/reports/ReportsLayout';
import SettingsLayout from './Pages/settings/SettingsLayout';
import MenuLevelSetting from "./Components/Setting/MenuSubscription/MenuLevelSetting";
import Configuration from "./Pages/Datasource";
import FileManager from './Pages/operations/FileManager';
import Chatbot from './Pages/settings/Chatbot';
import IngestRules from './Pages/settings/IngestRules';
import Permission from './Pages/settings/Permission';
import Themes from './Pages/settings/Themes';
import UserLevel from './Pages/settings/UserLevel';
import Upload from './Components/Placement/CSV Upload/Upload';
import ColumnMapping from './Components/Placement/CSV Upload/ColumnMapping';
import Preview from './Components/Placement/CSV Upload/CSVPreview';
import Finish from './Components/Placement/CSV Upload/Finish';
import MapTargetFile from './Components/Placement/CSV Upload/MapTargetFile';

export interface IRoute {
  path?: string;
  title: string;
  subRoutes?: IRoute[];
  component?: React.ComponentType<any>; // Update the component type
  isPrivate?: boolean;
  noNavLink?: boolean;
  icon?: SvgIconComponent;
  isDisableMenu?: boolean;
  isSubNavigation?: boolean;
  groupName?: string;
}

const Routes: IRoute[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: DashboardLayout,
    icon: DashboardIcon,
    noNavLink: false,
    isPrivate: false
  },

  {
    path: "/accounts",
    title: "Accounts",
    component: AccountsLayout,
    icon: StreetviewIcon,
    noNavLink: false,
    isPrivate: false
  },

  /* Start Accounts Sub Menu */
  {
    path: "/accounts/Ingest",
    title: "Inject",
    component: Upload,
    icon: StreetviewIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path:"/accounts/Ingest/upload",
    title:"Upload",
    component: Upload,
    isPrivate: true,
    noNavLink: false
  },
  {
    path:"/columnfieldmapping",
    title:"Column Mapping",
    component: ColumnMapping,
    isPrivate: true,
    noNavLink: false
  },
  {
    path:"/accountspreview",
    title:"Preview",
    component: Preview,
    isPrivate: true,
    noNavLink: false
  },
  {
    path:"/targetfieldmapping",
    title:"Map Target File",
    component: MapTargetFile,
    isPrivate: true,
    noNavLink: false
  },
  {
    path:"/accounts/Ingest/finish",
    title:"Finish",
    component: Finish,
    isPrivate: true,
    noNavLink: false
  },
  {
    path: "/accounts/debtors",
    title: "Debtors",
    component: Inquiry,
    icon: AssessmentIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: '/accounts/collect',
    title: 'Collect',
    component: Collect,
    icon: RestorePageIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  /* End Accounts Sub Menu */

  {
    path: "/diagnostics",
    title: "Diagnostics",
    icon: AssignmentIcon,
    component: DiagnosticsLayout,
    noNavLink: false,
    isPrivate: false
  },

  /* Start Diagnostics Sub Menu */
  {
    path: "/diagnostics/ingress",
    title: "Ingress",
    component: IngressEntity,
    icon: ArrowForwardIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/diagnostics/egress",
    title: "Egress",
    component: EgressEntity,
    icon: ArrowBackIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/diagnostics/dataMovement",
    title: "Data Movement",
    component: DataMovement,
    icon: DataUsageIcon,
    isDisableMenu: true,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/diagnostics/jobs",
    title: "Jobs",
    component: Jobs,
    icon: RotateLeftIcon,
    isDisableMenu: true,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/diagnostics/archieved",
    title: "Archieved",
    component: Archieved,
    icon: ArchiveIcon,
    isDisableMenu: true,
    isSubNavigation: true,
    isPrivate: false
  },
  /* End Diagnostics Sub Menu*/

  {
    path: "/operations",
    title: "Operations",
    icon: EvStationIcon,
    component: OperationsLayout,
    noNavLink: false,
    isPrivate: false
  },

  /* Start Operations Sub Menu*/
  {
    path: "/operations/trigger",
    title: "Trigger",
    component: Trigger,
    icon: EvStationIcon,
    isDisableMenu: true,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/operations/azureFunction",
    title: "Azure Function",
    component: AzureFunctions,
    icon: FlashOnIcon,
    isDisableMenu: true,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: '/operations/sftpFiles',
    title: 'File Manager',
    component: FileManager,
    icon: InsertDriveFileIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: '/operations/Pipeline',
    title: 'Pipeline',
    component: Pipeline,
    icon: AssessmentIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  /* End Diagnostics Sub Menu*/

  {
    path: "/users",
    title: "Users",
    component: UserList,
    icon: PeopleIcon,
    isPrivate: false
  },

  {
    path: '/customForms',
    title: 'Forms',
    component: FormsLayout,
    icon: AddToQueueIcon,
    noNavLink: false,
    isPrivate: false
  },

  /* Start Forms Sub Menu*/
  {
    path: '/customForms/Form',
    title: 'Custom Forms',
    component: ManageActivityTemplate,
    icon: AddToQueueIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/customForms/Template",
    title: "Templates",
    component: Template,
    icon: AddToQueueIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  /* End Forms Sub Menu*/

  {
    path: "/reports",
    title: "Report",
    component: ReportsLayout,
    icon: BarChartIcon,
    isPrivate: false
  },

  /* Start reports Sub Menu*/
  {
    path: "/reports/ackReport",
    title: "AckReport",
    component: ClientAcknowledgement,
    icon: BarChartIcon,
    isSubNavigation: true,
    isPrivate: false
  },
  {
    path: "/reports/agencyReport",
    title: "AgencyReport",
    component: AgencyView,
    icon: BarChartIcon,
    isSubNavigation: true,
    isPrivate: false
  },

  /* End reports Sub Menu*/

  {
    path: '/email',
    title: 'Email',
    component: GlobalControlView,
    icon: EmailIcon,
    noNavLink: false,
    isDisableMenu: true,
    isPrivate: false
  },
  {
    path: '/publicWebsite',
    title: 'Website',
    component: GlobalControlView,
    icon: PublicIcon,
    noNavLink: false,
    isDisableMenu: true,
    isPrivate: false
  },
  {
    path: "/notifications",
    title: "Notifications",
    component: NotificationsLayout,
    icon: AccountBalanceIcon,
    isDisableMenu: true,
    noNavLink: false,
    isPrivate: false
  },

  /* Start Notifications Sub Menu */
  {
    path: "/notifications/accountLevel",
    title: "Notifications",
    component: Notification,
    icon: AccountBalanceIcon,
    isDisableMenu: true,
    noNavLink: false,
    isSubNavigation: true,
    isPrivate: false
  },
  /* End Accounts Sub Menu */

  // {
  //   path: "/globalControl",
  //   title: "Components",
  //   component: GlobalControlView,
  //   icon: GamesIcon,
  //   noNavLink: false,
  //   isPrivate: false
  // },
  {
    path: "/settings",
    title: "Settings",
    component: SettingsLayout,
    icon: SettingsIcon,
    noNavLink: true,
    isPrivate: false
  },

  /* Start settings Sub Menu*/
  {
    path: "/settings/menuLevel",
    title: "Menu Level",
    component: MenuLevelSetting,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/userLevel",
    title: "User Level",
    component: UserLevel,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/permissions",
    title: "Permissions",
    component: Permission,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/notification",
    title: "Notification",
    component: Notification,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/themes",
    title: "Themes",
    component: Themes,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/configuration",
    title: "Configuration",
    component: Configuration,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/ingestRules",
    title: "Ingest Rules",
    component: IngestRules,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/dynamicForm",
    title: "Dynamic Form",
    component: Template,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/DynamicDashboard",
    title: "Dynamic Dashboard",
    component: MenuLevelSetting,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },
  {
    path: "/settings/chatbot",
    title: "Chatbot",
    component: Chatbot,
    icon: BarChartIcon,
    isSubNavigation: true,
    groupName: "Settings",
    isPrivate: false
  },

  /* End settings Sub Menu*/

  // {
  //   path: "/settings/Dashboard",
  //   title: "Dynamic Dashboard",
  //   component: DynamicDashboard,
  //   icon: BarChartIcon,
  //   isSubNavigation: true
  // },

  /* Start Settings Dashboard Sub menu */
  // {
  //   path: "/settings/Dashboard/PredefinedWidget",
  //   title: "Predefined Widget",
  //   component: PredefineWidgets,
  //   icon: BarChartIcon,
  //   isSubNavigation: true
  // },
  // {
  //   path: "/settings/Dashboard/CustomizeWidget",
  //   component: CustomizeWidgets,
  //   title: "Customize Widget",
  //   icon: CustomizeWidgets,
  //   isSubNavigation: true
  // },
  // {
  //   path: "/settings/Dashboard/Activities",
  //   component: PredefineWidgets,
  //   title: "Activities",
  //   icon: CustomizeWidgets,
  //   isSubNavigation: true
  // },
  /* End Settings Dashboard Sub menu */

  /* Start Ingest Rule Sub menu */
  // {
  //   path: "/settings/IngestRule/IngestPlacementRules",
  //   component: Dataset,
  //   title: "Ingest Placement Rules",
  //   icon: CustomizeWidgets,
  //   isSubNavigation: true
  // },
  // {
  //   path: "/settings/IngestRule/ManageDataRule",
  //   component: PlacementDataset,
  //   title: "Manage Data Rule",
  //   icon: CustomizeWidgets,
  //   isSubNavigation: true
  // },
  /* End Ingest Rule Sub menu */


]

export default Routes;
