import { TableRow, Theme, styled } from "@mui/material";

const BDebtTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(({ theme }: { theme: Theme; customStyle?: React.CSSProperties }) => ({
  "& .MuiTableRow-hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiTableRow-root": {
    width: "20px",
  },
  '& .MuiTableCell-root': {
    padding: "11px !important"
  }
}));

export default BDebtTableRow;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
