export interface IAgentTableRow {
    ClientId: number,
    ClientName: string,
    FirstParty: string,
    FlatFee: string,
    Contingency: string,
    Edit: string,
    Delete: string
}

export const TemplateField = [
    { label: "Template 1" },
    { label: "Template 2" },
    { label: "Template 3" },
    { label: "Template 4" },
    { label: "Template 5" },
    { label: "Template 6" },
    { label: "Template 7" },
  ];
