import { Box } from '@material-ui/core';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import HotelIcon from '@mui/icons-material/Hotel';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import RepeatIcon from '@mui/icons-material/Repeat';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import Scrollbars from 'react-custom-scrollbars';
import BDebtCard from '../../GlobalControl/Card/BDebtCard';
import useStyles from './DebtorsCss';

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1, padding: 0 }}>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.auditHistoryContainer} >
        <Timeline position="alternate" >
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary" >
              9:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <FastfoodIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Status
                </Typography>
                <Typography className={classes.auditTextStyle}>Changed to Closed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Next
                </Typography>
                <Typography className={classes.auditTextStyle}>Work Date Updated</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Flat Fee
                </Typography>
                <Typography className={classes.auditTextStyle}>Treatment Completed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <RepeatIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Updated
                </Typography>
                <Typography className={classes.auditTextStyle}>Demographic Information</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Next
                </Typography>
                <Typography className={classes.auditTextStyle}>Work Date Updated</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Status
                </Typography>
                <Typography className={classes.auditTextStyle}>Changed to Paid In Full</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Flat Fee
                </Typography>
                <Typography className={classes.auditTextStyle}>Treatment Completed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary" >
              9:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <FastfoodIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Status
                </Typography>
                <Typography className={classes.auditTextStyle}>Changed to Closed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Next
                </Typography>
                <Typography className={classes.auditTextStyle}>Work Date Updated</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Flat Fee
                </Typography>
                <Typography className={classes.auditTextStyle}>Treatment Completed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <RepeatIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Updated
                </Typography>
                <Typography className={classes.auditTextStyle}>Demographic Information</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Next
                </Typography>
                <Typography className={classes.auditTextStyle}>Work Date Updated</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>


          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot className={classes.iconColor}>
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Status
                </Typography>
                <Typography className={classes.auditTextStyle}>Changed to Paid In Full</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              09:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <BDebtCard className={classes.auditPaper}>
                <Typography variant="h6" component="h1" className={classes.auditfontSize}>
                  Flat Fee
                </Typography>
                <Typography className={classes.auditTextStyle}>Treatment Completed</Typography>
              </BDebtCard>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Scrollbars>
    </Box>
  );
}

