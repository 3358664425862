/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import {
    Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, ListItemButton
} from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import routes, { IRoute } from '../../Routes';
import useStyles from "./NavbarCss";

interface INavigationDrawerProps {
    open: boolean,
    onClose: () => void
}

const NavigationDrawer: React.FC<INavigationDrawerProps> = (props) => {
    const classes = useStyles();
    const { open, onClose } = props;
    const [subMenuOpen, setsubMenuOpen] = useState<number | null>(null);
    const location = useLocation();

    const handleClick = (index: number) => {
        if (subMenuOpen === index) {
            setsubMenuOpen(null);
        } else {
            setsubMenuOpen(index);
        }
    };

    // Function to check if the route is active
    const isActiveRoute = (route: IRoute) => {
        return location.pathname === route.path;
    };

    return (
        <React.Fragment>
            <Drawer variant="temporary" open={open} onClose={onClose} anchor="left">
                <nav className={classes.toolbar}>
                    <div className={classes.closeIcon}>
                        <IconButton onClick={() => { onClose(); }}>
                            <CloseIcon color="primary" fontSize="medium" />
                        </IconButton>
                    </div>
                    <List className={classes.listMui} >
                        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.mobileSidebarContainer} >
                            {routes?.map((route, index) => (
                                !route?.noNavLink && !route?.isSubNavigation ?
                                    <ListItem className={classes.listItemMui} key={index}>
                                        <ListItemButton className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                                            key={route.title} component={Link as React.ElementType} to={route?.path}
                                            onClick={() => handleClick(index)} disabled={route?.isDisableMenu}
                                        >
                                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                                {route.icon && <route.icon fontSize="small" color='primary'
                                                    className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                                            </ListItemIcon>
                                            <ListItemText >
                                                <Typography variant='body2' sx={{ fontWeight: isActiveRoute(route) ? "bold" : null }} className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                                    {route?.title}
                                                </Typography>
                                            </ListItemText>

                                        </ListItemButton>
                                    </ListItem>
                                    : null
                            ))}
                        </Scrollbars>
                        {routes?.map((route, index) => (
                            route?.noNavLink && !route?.isSubNavigation ?
                                <ListItem className={classes.listItemMui} key={index} sx={{ borderTop: '1px dashed gray' }}>
                                    <ListItemButton className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                                        key={route.title} component={Link as React.ElementType} to={route?.path}
                                        onClick={() => handleClick(index)} disabled={route?.isDisableMenu}
                                    >
                                        <ListItemIcon sx={{ justifyContent: 'center' }}>
                                            {route.icon && <route.icon fontSize="small" color='primary'
                                                className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                                        </ListItemIcon>
                                        <ListItemText >
                                            <Typography variant='body2' className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                                {route?.title}
                                            </Typography>
                                        </ListItemText>

                                    </ListItemButton>
                                </ListItem>
                                : null
                        ))}
                    </List >
                </nav>
            </Drawer >
        </React.Fragment>
    )
}

export default NavigationDrawer