import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      flexGrow: 1,
    },
    container: {
      paddingTop: "15px",
    },
    tableCellHeader: {
      padding: "3px 12px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
    },
    tableCellStyle: {
      padding: "3px 12px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px!important",
      },
      fontSize: "13px !important",
    },
    boxHeader: {
      width: "100%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      background: "white",
    },
    paddingDiv: {
      padding: 0,
    },
    tabContainer: {
      "& .MuiTabs - flexContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "",
      },
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: 30,
        height: 30,
      },
    },
    expandedAccordion: {
      backgroundColor: " #494F55",
      borderRadius: "40px",
      color: "white", // Set your desired background color
      // Other styles for the expanded AccordionSummary
    },
    tablebodyConainer: {
      borderRadius: 0,
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 230px)",
        minHeight: "calc( 100vh - 230px)",
      },
    },
    button: { background: "#3b4a54", color: "white" },
    iconclose: { position: "absolute", top: -1, right: 12 },
    aSummary: {
      border: "2px solid black",
      borderRadius: "20px",
      maxHeight: "30",
      minHeight: "20",
    },
    tabFont: {
      fontSize: "12px",
    },
    expandmoreIcon: {
      borderRadius: "50%",
      overflow: "hidden",
      width: "24px",
      height: "24px",
      color: "white",
      backgroundColor: " #494F55",
    },
    checkBox: {
      color: "white",
      padding: "5px",
    },
    gridDisplay: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tableCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    typostartdate: {
      marginTop: "15px",
      padding: "10px",
      textAlign: "left",
      marginLeft: "11px",
    },
    DialogHead: {
      backgroundColor: "#76b2fe",
      fontWeight: "bold",
      marginTop: "5px",
      textAlign: "center",
    },
    pivotroot: {
      marginTop: "110px",
    },
    addbutton: {
      textAlign: "center",
    },
    formcontrol: {
      minWidth: "600",
      alignItems: "left",
      padding: "10px",
    },
    textstart: {
      width: "300px",
      marginLeft: "18px",
      backgroundColor: "white",
      border: "2px",
      borderRadius: 5,
      float: "left",
      textAlign: "left",
    },
    typoenddate: {
      marginTop: "10px",
      padding: "10px",
      textAlign: "left",
      marginLeft: "22px",
    },
    textend: {
      width: "300px",
      backgroundColor: "white",
      marginLeft: "18px",
      border: "2px",
      bordeRadius: "5px",
      float: "left",
    },
    typocode: {
      marginTop: "10px",
      padding: "10px",
      textAlign: "left",
    },
    autocompletecode: {
      width: "300px",
      height: "10",
      marginLeft: "25px",
    },
    autocompletephase: {
      width: "300px",
      height: "10",
      marginLeft: "35px",
    },
    typophase: {
      textAlign: "left",
    },
    //AddUser
    header: {
      backgroundColor: "#3b4a54",
      display: "flex",
      justifyContent: "center",
      color: "white",
      padding: theme.spacing(1),
      fontSize: "15px",
      marginBottom: "8px",
    },

    header1: {
      marginLeft: "40px",
      fontSize: "15px",
      height: "50px",

      "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
      {
        minHeight: "30px !important",
        border: "2px solid #888", // Set border color to light black (#888)
        borderRadius: "5px",
        marginBottom: "5px",
      },
    },
    table: {
      widthWidth: 1200,
    },

    smallCell: {
      padding: theme.spacing(0), // Adjust the padding
      fontSize: "13px",
      align: "right", // Reduce the font size
    },
    smallCell2: {
      padding: theme.spacing(0), // Adjust the padding
      fontSize: "13px",

      // Reduce the font size
    },
    smallCell5: {
      padding: theme.spacing(0), // Adjust the padding
      fontSize: "13px",
      height: "20px",
      backgroundColor: "#f1f5f8 !important",
      // Reduce the font size
    },
    smallCell3: {
      padding: theme.spacing(1), // Adjust the padding
      fontSize: "10px",
      width: "65px",
      height: "20px", // Reduce the font size
    },
    smallCell4: {
      padding: theme.spacing(1), // Adjust the padding
      fontSize: "12px",
      width: "70px",
      height: "20px",
      backgroundColor: "#f1f5f8 !important", // Reduce the font size
    },
    summaryContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "auto",
    },
    expandIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    endIcon: {
      marginLeft: "auto",
    },
    accordionSummary: {
      fontSize: "19px",
      fontFamily: "serif",
      // border: "1px solid #888",
      //borderRadius: "5px",
      marginBottom: "5px",
    },
    accordionDetails: {
      border: "1px solid #888",
      borderRadius: "10px",
    },
    gridItem: {
      padding: theme.spacing(0),
    },
    chippadding: {
      maxHeight: "100px",
      overflowY: "auto",

      padding: "6px",
      borderRadius: "4px",
    },
    display: {
      display: "flex",
      alignItems: "right",
    },
    checkboxAlign: { marginLeft: "7px" },
    clientcode: {
      borderBottom: "2px solid #3b4a54",
      marginBottom: "15px",
      marginTop: "10px",
    },
    tableCellStyle1: {
      padding: "3px 1px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px !important",
      },
      fontSize: "13px !important",
    },
    toolbarStyle: {
      backgroundColor: "rgb(0, 127, 255)",
      minHeight: "40px",
      color: "white",
    },
    appBar: {
      position: "relative",
      overflowY: "hidden",
      minHeight: "40px",
      borderBottom: "3px solid #0072E5",
    },
    title: {
      flex: 1,
      color: "white",
      textAlign: "center",
      fontSize: 18,
      marginRight: "100px",
    },
    Note: {
      backgroundColor: "rgba(118, 53, 220, 0.08)",
      color: "rgb(32,10,105)",
      borderRadius: "5px",
      padding: "10px",
      margin: "0px 0px 10px",
      fontSize: "13px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    DropzoneAreaText: {
      fontSize: "18px !important",
      fontWeight: "bold",
      color: "blue",
    },
    DropzoneArea: {
      minHeight: "120px !important",
      fontSize: "12px !important",
      border: "2px dashed blue",
      backgroundColor: "#f1f5ff",
      boxShadow: "rgba(0, 0, 0, 0.35) 3px 0px 3px 0px",
      borderRadius: "5px",
    },
    UploadStyle: {
      textAlign: "center",
      marginTop: "5px",
      padding: "5px",
      backgroundColor: "rgba(16, 185, 129, 0.12)",
      color: "rgb(11, 129, 90)",
      fontSize: 14,
      borderRadius: "5px",
    },
    typoStyle: {
      marginLeft: "10px",
      color: "black",
      fontSize: 13,
      marginTop: "5px",
    },
    flexDisplay: {
      display: "flex",
      justifyContent: "center",
    },
    Iconbutton: {
      padding: "4px 4px",
    },
    Visible: {
      fontSize: 22,
      color: "#1E90FF",
      marginLeft: "5px",
    },
    NonVisible: {
      fontSize: 22,
      color: "#AEAEAE",
      marginLeft: "5px",
    },
    iconStyle: {
      fontSize: 17,
      color: "#1E90FF",
      marginLeft: "5px",
    },
    titleheader: {
      fontWeight: "bold",
      marginTop: "5px",
      color: "white",
      display: "flex",
      justifyContent: "center"
    },
    yesbutton: {
      backgroundColor: "green",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "green",
        color: "white",
      },
    },
    nobutton: {
      background: "red",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    },
    noRecordsColor: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      marginTop: "35px",
    },
    noFiles: {
      color: "red",
      marginTop: "10px",
      fontSize: 14,
      marginLeft: "5px",
      textAlign: "center",
    },
    menuButton1: {
      width: "120px",
      height: "40px",
    },
    StepContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "70px",
      position: "relative",
      ":before": {
        content: "",
        position: "absolute",
        background: "#f3e7f3",
        height: "4px",
        width: "100%",
        top: "50%",
        transform: "translateY(-50%)",
        left: 0,
      },
    },
    horizontalLine: {
      display: "flex",
      alignItems: "center",
      maxHeight: "15px",
      minHeight: "15px",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
    line: {
      width: "100%" /* Adjust the width of the line as needed */,
      borderTop: "1px solid #000" /* Line style */,
      margin: "0 41px",
      borderBottom: "3px solid green"
    },
    circle: {
      borderRadius: '50%',
      width: '34px',
      height: '34px',
      padding: '10px',
      backgroundColor: '#fff',
      border: '3px solid #000',
      color: '#000',
      textAlign: 'center',
      font: '32px Arial, sans-serif',
    },
    icon: {
      margin: "0 8px",
      color: "green",
    },
    line1: {
      width: "50px" /* Adjust the width of the line as needed */,
      borderTop: "1px solid #000",
      margin: "0 10px",
      border: "1px solid orange",
    },
    icon1: {
      margin: "0 8px", // Adjust icon spacing as needed
      color: "rgb(63 176 172)",
    },
    line2: {
      width: "50px" /* Adjust the width of the line as needed */,
      borderTop: "1px solid #000" /* Line style */,
      margin: "0 10px",
      border: "1px solid rgb(255 48 48)",
    },
    icon2: {
      margin: "0 8px", // Adjust icon spacing as needed
      color: "rgb(234 58 114)",
    },
    line3: {
      width: "50px" /* Adjust the width of the line as needed */,
      borderTop: "1px solid #000" /* Line style */,
      margin: "0 10px",
      border: "1px solid rgb(7 141 238)",
    },
    icon3: {
      margin: "0 8px", // Adjust icon spacing as needed
      color: 'rgb(7 141 238)',
    },
    tablebody1: {
      maxHeight: 500,
      minHeight: 500,
      marginTop: '50px',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 120px)',
        minHeight: 'calc( 100vh - 120px)',
      },
    },
    divStyle: {
      width: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 5px'
    },
    IconDivstyle: {
      margin: '0 3px',
      width: '100%'
    },
    Icon1: {
      borderBottom: '3px dashed green'
    },
    Icon2: {
      borderBottom: "3px dashed rgb(63 176 172)"
    },
    Icon3: {
      borderBottom: '3px dashed rgb(234 58 114)',
    },
    Icon4: {
      borderBottom: '3px dashed rgb(7 141 238)'
    },
    VerifyClientIcon: {
      backgroundColor: '#ccf5cb',
    },
    UploadIcon: {
      backgroundColor: "rgb(232 246 245)",
    },
    SetUpIcon: {
      backgroundColor: 'rgb(253 232 239)',
    },
    PermissionIcon: {
      backgroundColor: 'rgb(217 240 253)',
    },
    IconButtonStyle: {
      borderRadius: '20px',
      width: '38px',
      height: '38px'
    },
    divIcon1: {
      color: 'green',
      fontSize: '14px !important',
      marginTop: 5
    },
    divIcon2: {
      color: "rgb(63 176 172)",
      fontSize: '14px !important',
      marginTop: 5
    },
    divIcon3: {
      color: 'rgb(234 58 114)',
      fontSize: '14px !important',
      marginTop: 5
    },
    divIcon4: {
      color: 'rgb(7 141 238)',
      fontSize: '14px !important',
      marginTop: 5
    },
    BadgeIcon1: {
      color: 'green'
    },
    BadgeIcon2: {
      color: "rgb(63 176 172)"
    },
    BadgeIcon3: {
      color: 'rgb(234 58 114)'
    },
    BadgeIcon4: {
      color: 'rgb(7 141 238)'
    },
    CloseIconTooltip: {
      fontSize: 20,
      color: "red"
    },
    roundedTextField: {
      left: "75px"
    },
    prospectpage: {
      justifyContent: "flex-end"
    },
    logo: {
      height: "35px",
      width: "130px"
    },
    dialogdes: {
      borderBottom: '2px solid #000',
      padding: '4px 0px 4px 3px',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12), 0px 0px 0px 1px rgba(0,0,0,0.2)',
    },
    selectedlistitem: {
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      backgroundColor: 'white'
    },
    Tabhead: {
      padding: "10px",
      marginTop: "6px"
    },
    auditHistoryContainer: {

      maxHeight: '500px',
      minHeight: '500px',
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 145px)",
        minHeight: "calc( 100vh - 145px)",
      },
    },
    disabledTab: {
      color: 'rgba(0, 0, 0, 0.5)',
      backgroundColor: '#3b4a54',
      borderRadius: '15px 15px 0 0',
    },
    paperWithTabs: {
      '& .MuiTabs-root .MuiTabs-flexContainer': {
        display: 'flex',
      },
      //padding: '10px',
      marginBottom: '1px',
      backgroundColor: '#3b4a54 !important',
      height: '100%'
    },
    DialogTitlebutton: {
      display: "flex",
     // justifyContent: "flex-end",
     alignItems: 'flex-end',
     justifyContent:'space-between',
    marginBottom: '10px'
      
    },
    dialogsend: {
      marginRight: "10px!important",
      padding: "5px!important",
      //color:"black !important",
      // backgroundColor:"lightgrey !important",
      fontSize: "14px"
    },
    closeicon: {
      left: "30px"
    },
    tabwid: {
      width: '100%'
    },
    dialogsubmit: {
      marginRight: "10px!important",
      //color:"black !important",
      // backgroundColor:"lightgrey !important",
      fontSize: "14px"
    },
    buttondes: {
      '&..MuiTypography-body1': {
        fontSize: '13px !important'
      },
    },
    usertab: {
      minHeight: "35px !important",
      fontSize: '12px !important',
      fontWeight: 'bold'
    },
   
    appbardesign: {
      backgroundColor: 'white !important',
      minHeight: '30 !important',
      padding: '0 !important'
    },
    companygrid: {
      borderRight: '1px solid lightgray'
    },
    verfiyfont: {
      padding: '6px',
      fontSize: "13px"
    },
    commonfont: {
      fontSize: "13px"
    },
    Addbranch: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center'
    },
    dividstyle: {
      borderBottom: '2px solid !important',
      borderColor: 'black !important'
    },
    usertitle: {
      '&.css-bdhsul-MuiTypography-root-MuiDialogTitle-root': {
        padding: '4px 5px!important'
      },

    },
    usergrid: {
      marginTop: "8px"
    },
    dialogHeader: {
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: "center !important"
    },
    dialogcontspac: {
      '&.MuiGrid-spacing-xs-2': {
        width: 'calc(100%) !important',
      }
    },
    headingdialog: {
      '&.MuiDialogTitle-root': {
        padding: '0px 0px !important',
      }
    },
    branchnamegrid: {
      marginTop: "1px !important"
    },
    companypaper: {
      '&.css-3su884-MuiPaper-root': {
        borderRadius: "0px !important"
      }
    },
    companypaper1:{
      borderRadius: "0px !important",
      '& .MuiPaper-rounded': {
        borderRadius: "0px !important"
    }
    },
    dashedborder: {
      borderTop: '1px dashed white',

    },
    iconcolor: {
      color: "white",

    },
    listitem: {
      '&.css-1rki8sg-MuiListItem-root ': {
        paddingLeft: '0px !important',
      }
    },
    branchnamelist: {
      padding: "7px!important"
    },
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "5px !important",
      border: '2px solid #215176 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      }
      // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    dialogContent: {
           padding: '10px !important',
    },
    
  })
);

export default useStyles;
