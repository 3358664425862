import { Box } from '@mui/material'

const Chatbot = () => {
  return (
    <Box p={1} m={1}>
      <div>Chatbot</div>
    </Box>
  )
}

export default Chatbot