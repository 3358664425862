import { IMenuDetails } from "../../Model/Settings/IMenuDetails";

export const MenuDetails: IMenuDetails[] = [
    /* Accounts */
    {
        menuId: 1,
        menuName: "Ingest",
        menuPath: "/accounts/Ingest",
        menuDescription: "Easily submit delinquent accounts to our user-friendly portal",
        menuIcon: "Ingestaccount.svg",
        menuGroupName: 'Accounts'
    },
    {
        menuId: 2,
        menuName: "DEBTORS",
        menuPath: "/accounts/debtors",
        menuDescription: "We take a proactive approach, leveraging advanced technology",
        menuIcon: "Debtor.svg",
        menuGroupName: 'Accounts'
    },
    {
        menuId: 3,
        menuName: "COLLECT",
        menuPath: "/accounts/collect",
        menuDescription: "Navigate our intuitive platform to easily locate and select accounts for the next phase.",
        menuIcon: "Collect.svg",
        menuGroupName: 'Accounts'
    },

    /* Diagnostics */
    {
        menuId: 1,
        menuName: "INGRESS",
        menuPath: "/diagnostics/ingress",
        menuDescription: "Generally refers to the action of incoming records. ",
        menuIcon:"Ingress.svg" ,
        menuGroupName: 'Diagnostics'
    },
    {
        menuId: 2,
        menuName: "EGRESS",
        menuPath: "/diagnostics/egress",
        menuDescription: "Generally refers to the action of outgoing records",
        menuIcon: "Egress.svg",
        menuGroupName: 'Diagnostics'
    },
    {
        menuId: 3,
        menuName: "DATA MOVEMENT",
        menuPath: "/diagnostics/dataMovement",
        menuDescription: " Process of transferring, migrating, or transmitting data from one location",
        menuIcon: "Datamovement.svg",
        menuGroupName: 'Diagnostics'
    },
    {
        menuId: 4,
        menuName: "JOBS",
        menuPath: "/diagnostics/jobs",
        menuDescription: "Tasks or positions of work for which montioring scheduled job movement. ",
        menuIcon: "Job.svg",
        menuGroupName: 'Diagnostics'
    },
    {
        menuId: 5,
        menuName: "ARCHIEVED",
        menuPath: "/diagnostics/archieved",
        menuDescription: "Refers to the act of systematically storing and preserving data",
        menuIcon: "Archieved.svg",
        menuGroupName: 'Diagnostics'
    },

    /* Operations */
    {
        menuId: 1,
        menuName: "Trigger",
        menuPath: "/operations/trigger",
        menuDescription: "Typically denotes an event, action, or stimulus that initiates",
        menuIcon: "Trigger.svg",
        menuGroupName: 'Operations'
    },
    {
        menuId: 2,
        menuName: "Azure Function",
        menuPath: "/operations/azureFunction",
        menuDescription: "Refer to serverless compute resources provided by Microsoft Azure",
        menuIcon: "Azurefunction.svg",
        menuGroupName: 'Operations'
    },
    {
        menuId: 3,
        menuName: "File Manager",
        menuPath: "/operations/sftpFiles",
        menuDescription: " Refers to a software application or tool that provides users with a graphical interface",
        menuIcon: "Filemanager.svg",
        menuGroupName: 'Operations'
    },
    {
        menuId: 4,
        menuName: "Pipeline",
        menuPath: "/operations/Pipeline",
        menuDescription: "A system or structure that facilitates the flow of something from one point to another",
        menuIcon: "Pipeline.svg",
        menuGroupName: 'Operations'
    },

    /* Forms */
    {
        menuId: 1,
        menuName: "CUSTOM FORM",
        menuPath: "/customForms/Form",
        menuDescription: "Personalized or tailor-made document designed to collect specific information",
        menuIcon: "Customform.svg",
        menuGroupName: 'Forms'
    },

    /* Reports */
    {
        menuId: 1,
        menuName: "ACKNOWLEDGEMENT",
        menuPath: "/reports/ackReport",
        menuDescription: "Brief document confirming the receipt or understanding of specific information",
        menuIcon: "Acknowledgement.svg",
        menuGroupName: 'Report'
    },
    {
        menuId: 2,
        menuName: "AGENCY",
        menuPath: "/reports/agencyReport",
        menuDescription: "Document summarizing key information related to the activities",
        menuIcon:"AgencyReport.svg",
        menuGroupName: 'Report'
    },

    // /* Settings */
    {
        menuId: 1,
        menuName: "MENU LEVEL",
        menuPath: "/settings/menuLevel",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 2,
        menuName: "USER LEVEL",
        menuPath: "/settings/userLevel",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 3,
        menuName: "PERMISSIONS",
        menuPath: "/settings/permissions",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 4,
        menuName: "NOTIFICATION",
        menuPath: "/settings/notification",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 5,
        menuName: "THEMES",
        menuPath: "/settings/themes",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 6,
        menuName: "CONFIGURATION",
        menuPath: "/settings/configuration",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 7,
        menuName: "INGEST RULES",
        menuPath: "/settings/ingestRules",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 8,
        menuName: "DYNAMIC FORM",
        menuPath: "/settings/dynamicForm",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 9,
        menuName: "DASHBOARD",
        menuPath: "/settings/dashboard",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },
    {
        menuId: 10,
        menuName: "CHATBAT",
        menuPath: "/settings/chatbot",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/discover.png",
        menuGroupName: 'Settings'
    },

    /* Settings User Level Sub menu */

    {
        menuId: 1,
        menuName: "Super Admin",
        menuPath: "/settings/userLevel/superAdmin",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/showcase.png",
        menuGroupName: 'UserLevel'
    },
    {
        menuId: 2,
        menuName: "Client Admin",
        menuPath: "/settings/userLevel/clientAdmin",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/showcase.png",
        menuGroupName: 'UserLevel'
    },
    {
        menuId: 3,
        menuName: "Client User",
        menuPath: "/settings/userLevel/clientUser",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/showcase.png",
        menuGroupName: 'UserLevel'
    },
    {
        menuId: 4,
        menuName: "File Admin",
        menuPath: "/settings/userLevel/fileAdmin",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/showcase.png",
        menuGroupName: 'UserLevel'
    },
    {
        menuId: 5,
        menuName: "Agent User",
        menuPath: "/settings/userLevel/fileAdmin",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/showcase.png",
        menuGroupName: 'UserLevel'
    },

    /* Settings Dashboard Sub menu */
    {
        menuId: 1,
        menuName: "Predefined Widget",
        menuPath: "/settings/Dashboard/PredefinedWidget",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/train.png",
        menuGroupName: 'Dashboard'
    },
    {
        menuId: 2,
        menuName: "Customize Widget",
        menuPath: "/settings/Dashboard/CustomizeWidget",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/train.png",
        menuGroupName: 'Dashboard'
    },
    {
        menuId: 3,
        menuName: "Activities",
        menuPath: "/settings/Dashboard/Activities",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/train.png",
        menuGroupName: 'Dashboard'
    },

    /* Settings Ingest Rule Sub menu */
    {
        menuId: 1,
        menuName: "Ingest Placement Rules",
        menuPath: "/settings/IngestRule/IngestPlacementRules",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/renew.png",
        menuGroupName: 'IngestRules'
    },
    {
        menuId: 2,
        menuName: "Manage Data Rule",
        menuPath: "/settings/IngestRule/ManageDataRule",
        menuDescription: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic.",
        menuIcon: "https://learn.microsoft.com/en-us/credentials/images/renew.png",
        menuGroupName: 'IngestRules'
    },
]