import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        width: '100%',
        marginTop: '15px'
      },
    },
    formControlCtEt: {
      width: '100%',
      borderRadius: '10px',
      marginTop: '15px',
    },
    adminBorderBottom: {
      '& .MuiOutlinedInput-input': {
        borderBottom: '3px solid blue',
        borderRadius: '4px 4px 8px 8px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px 4px 8px 8px',
      }
    },
    div: {
      flexGrow: 1,
    },

    submenuCheckbox:{
      transform: 'translateX(273px)'
    },
    
    submenuName:{
      transform: 'translateX(90px)'
    },
    menuListContainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 215px)',
        minHeight: 'calc( 100vh - 215px)',
      },
    },
    tableCellHeader: {
      padding: "5px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
    },
    tableCellStyle: {
      padding: "5px !important",
      "& .MuiTableCell-root": {
        padding: "5px!important",
      },
      fontSize: "13px !important",
    },
    tableCellStyle1: {
      padding: "3px 5px !important",
      "& .MuiTableCell-root": {
        padding: "3px 2px!important",
      },
      fontSize: "10px !important"
    },
    boxHeader: {
      width: "100%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      background: "white",
    },
    paddingDiv: {
      padding: 0,
    },
    tabContainer: {
      "& .MuiTabs - flexContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "",
      },
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: 30,
        height: 30,
      },
    },
    expandedAccordion: {
      backgroundColor: " #494F55",
      borderRadius: "40px",
      color: "white", // Set your desired background color
      // Other styles for the expanded AccordionSummary
    },
    tablebodyConainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 230px)',
        minHeight: 'calc( 100vh - 230px)',
      },
    },
    button: { background: "#3b4a54", color: "white" },
    iconclose: { position: "absolute", top: -1, right: 12 },
    aSummary: {
      border: "2px solid black",
      borderRadius: "20px",
      maxHeight: "30",
      minHeight: "20",
    },
    tabFont: {
      fontSize: "12px",
    },
    expandmoreIcon: {
      borderRadius: "50%",
      overflow: "hidden",
      width: "24px",
      height: "24px",
      color: "white",
      backgroundColor: " #494F55",
    },
    checkBox: {
      color: "white",
      padding: "5px",
      left: "7px",
    },
    gridDisplay: {
      display: "flex",
      justifyContent: "flex-end",
    },
    tableCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    typostartdate: {
      marginTop: "15px",
      padding: "10px",
      textAlign: "left",
      marginLeft: "11px",
    },
    DialogHead: {
      backgroundColor: "#76b2fe",
      fontWeight: "bold",
      marginTop: "5px",
      textAlign: "center",
    },
    pivotroot: {
      marginTop: "110px",
    },
    addbutton: {
      textAlign: "center",
    },
    formcontrol: {
      minWidth: "600",
      alignItems: "left",
      padding: "10px",
    },
    textstart: {
      width: "300px",
      marginLeft: "18px",
      backgroundColor: "white",
      border: "2px",
      borderRadius: 5,
      float: "left",
      textAlign: "left",
    },
    typoenddate: {
      marginTop: "10px",
      padding: "10px",
      textAlign: "left",
      marginLeft: "22px",
    },
    textend: {
      width: "300px",
      backgroundColor: "white",
      marginLeft: "18px",
      border: "2px",
      bordeRadius: "5px",
      float: "left",
    },
    typocode: {
      marginTop: "10px",
      padding: "10px",
      textAlign: "left",
    },
    autocompletecode: {
      width: "300px",
      height: "10",
      marginLeft: "25px",
    },
    autocompletephase: {
      width: "300px",
      height: "10",
      marginLeft: "35px",
    },
    typophase: {
      textAlign: "left",
    },
    //AddUser
    header: {
      backgroundColor: "#3b4a54",
      display: "flex",
      justifyContent: "center",
      color: "white",
      padding: theme.spacing(1),
      fontSize: "15px",
      marginBottom: "8px",
    },

    header1: {
      marginLeft: "40px",
      fontSize: "15px",
      height: "50px",

      "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
      {
        minHeight: "30px !important",
        border: "2px solid #888", // Set border color to light black (#888)
        borderRadius: "5px",
        marginBottom: "5px",
      },
    },
    table: {
      widthWidth: 1200,
    },

    smallCell: {
      padding: theme.spacing(0), // Adjust the padding
      fontSize: "11px",
      align: "right", // Reduce the font size
    },
    smallCell2: {
      // padding: theme.spacing(0), // Adjust the padding
      fontSize: "11px",
      padding: '0px'

      // Reduce the font size
    },
    smallCell5: {
      // padding: theme.spacing(0), // Adjust the padding
      fontSize: "11px",
      height: "10px",
      backgroundColor: "#f1f5f8 !important",
      // Reduce the font size
    },
    smallCell3: {
      padding: '0px', // Adjust the padding
      fontSize: "11px",
      // width: "65px",
      height: "5px", // Reduce the font size
    },
    smallCell4: {
      padding: theme.spacing(0), // Adjust the padding
      fontSize: "11px",
      // width: "70px",
      height: "20px",
      backgroundColor: "#f1f5f8 !important", // Reduce the font size
    },
    summaryContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "auto",
    },
    expandIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    endIcon: {
      marginLeft: "auto",
    },
    accordionSummary: {
      fontSize: "19px",
      fontFamily: "serif",
      marginBottom: "5px",
    },
    accordionDetails: {
      border: "1px solid #888",
      borderRadius: "10px",
    },

    chippadding: {
      maxHeight: "100px",
      overflowY: "auto",

      padding: "6px",
      borderRadius: "4px",
    },
    display: {
      display: "flex",
      alignItems: "right",
    },
    checkboxAlign: { marginLeft: "7px" },
    clientcode: {
      borderBottom: "2px solid #3b4a54",
      marginBottom: "15px",
      marginTop: "10px",
    },
    container: {
      padding: '9px',
    },
    inputField: {
      width: '80%',
      padding: "0px 50px 0px 20px",
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px', // Reduce font size on smaller screens
      },
    },
    gridItem: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center', // Center align on smaller screens
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Display in a single line on screens < 600px wide
      },
    },
    tablebody1: {
      maxHeight: 550,
      minHeight: 500,
      marginTop: '10px',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 220px)',
        minHeight: 'calc( 100vh - 220px)',
      },
    },
    titleheader: {
      fontWeight: "bold",
      marginTop: "5px",
      color: "black",
      display: "flex",
      justifyContent: "center"
    },
    accordianStyle: {
      minHeight: "0px",
      height: '40px',
    },
    icon: {
      position: 'absolute',
      top: '0px',
      right: '8px'
    },
    logo: {
      height: "35px",
      width: "130px"
    },
    dialogconent: {
      paddingTop: '10px',
      maxHeight: '553px',
      minHeight: '553px',
    },
    dashedLine: {
      borderBottom: '2px solid #000',
      //padding: '7px'
    },
    Tablecontainer: {
      maxHeight: '553px',
      minHeight: '553px'
    },
    Tablecell: {
      fontSize: "12px",
    },
    buttonWithGreenBackground: {
      backgroundColor: 'green',
    },
    tableCellStyledata: {
      padding: "3px 15px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px!important",
      },
      fontSize: "13px !important",
      popover: {
        padding: theme.spacing(2),
      },
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    typograpy: {
      borderBottom: '1px dashed lightgray',
      fontSize: '14px'
    },
    card: {
      textAlign: 'center'
    },
    align: {
      paddingTop: '15px',
      paddingBottom: '10px'
    },
    background: {
      backgroundColor: "#ebecf0"
    },
    iconName: {
      backgroundColor: "#ebecf0"
    },
    paddingGrid: {
      paddingTop: '50px'
    },
    appbardesign: {
      backgroundColor: 'white !important',
      minHeight: "35px !important",
      padding: '0px !important'
    },
    usertab: {
      minHeight: "35px !important",
      fontSize: '12px !important',
      fontWeight: 'bold'
    },
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "5px !important",
      border: '2px solid #215176 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      }
      // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    dialogAppbar: {
      backgroundColor: '#3b4a54 !important',
      minHeight: '50px !important',
      'MuiToolbar-root': {
        minHeight: '50px !important'
      }
    },
    PermissionIcon: {
      backgroundColor: 'rgb(217 240 253) !important',
    },
    IconButtonStyle: {
      borderRadius: '20px',
      width: '38px',
      height: '38px'
    },
    horizontalLine: {
      display: "flex",
      alignItems: "center",
      maxHeight: "15px",
      minHeight: "15px",
    },
    BadgeIcon4: {
      color: 'rgb(7 141 238)'
    },
    icon3: {
      margin: "0 8px", // Adjust icon spacing as needed
      color: 'rgb(7 141 238)',
    },
    selectform: {
      '& .MuiOutlinedInput-input': {
        padding: '7px !important'
      }
    },
    profilegrid: {
      '& .css-bq7mrf-MuiGrid-root': {
        padding: '3px !important'
      }
    },
    menugrid: {
      '& .css-1el2yk2-MuiGrid-root': {
        padding: '3px !important'
      },

    },
    SetUpIcon: {
      backgroundColor: 'rgb(253 232 239)!important',
    },
    icon2: {
      margin: "0 8px", // Adjust icon spacing as needed
      color: "rgb(234 58 114)",
    },
    BadgeIcon3: {
      color: 'rgb(234 58 114)'
    },
    toolbar: {

      backgroundColor: 'lightgray',
      padding: '8px',

    },
    wrapper: {

      border: '1px solid lightgray',
      borderRadius: '8px',

    },
    editor: {

      minHeight: '120px'

    },
    MenuHeader: {
      paddingLeft:"90px"
    },
    EditIcon: {
      paddingTop: "3px",
    },
    EditIconcolor: {
      color: "green"
    },
    deleteIconcolor: {
      color: "red"
    },

  })
);

export default useStyles;
