import { Button, DialogActions, DialogContent, Grid, Typography, Box } from '@mui/material';
import { useState } from 'react';
import BDebtTextField from '../../../../GlobalControl/BDebtTextField';
import BDebtDialog from '../../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import BDebtButton from '../../../../GlobalControl/BDebtButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RichTextEditor from '../SetupClient/RichTextEditor';
import BDebtCheckbox from '../../../../GlobalControl/BDebtCheckBox';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography';



const SendError = () => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<string>('{}');
   

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleEditorChange = (newContent: string) => {
        setContent(newContent);
      };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <div>
           
            <BDebtButton variant="contained" size="small" color='secondary'  sx={{ fontSize: 13,right:"5px",padding:"5px"}} fullWidth onClick={handleClickOpen}>corrections</BDebtButton>

            <BDebtDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'md'}
            >
                <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleClickClose}>
                    <Typography variant="body1">
                        <b>SEND CORRECTIONS</b>
                    </Typography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={0} >

                        <Grid item xs={12} padding="5px">
                            <Typography variant="body2" padding="4px"><b>To Email</b></Typography>

                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="TO Email"
                                size='small'
                                
                            />
                        </Grid>
                        <Grid item xs={12} padding="5px">
                            <Typography variant="body2"  padding="4px"><b>CC Email</b></Typography>

                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="CC Email"
                                size='small'
                               
                            />
                        </Grid>
                        <Grid container spacing={0} >
                            <Grid item xs={12} padding="5px" >
                                <BDebtTypography variant="body2"><b>PREFER TO</b></BDebtTypography>

                                <FormGroup row >
                                    <FormControlLabel control={<BDebtCheckbox size="small" color="success"/>} label="COMPANY" />
                                    <FormControlLabel control={<BDebtCheckbox size="small" color="success"/>} label="CONTACT"  />
                                    <FormControlLabel control={<BDebtCheckbox size="small"color="success"/>} label="COLLECTION" />
                                    <FormControlLabel control={<BDebtCheckbox size="small"color="success"/>} label="BILLING" />

                                </FormGroup>

                            </Grid>
                            <Grid item xs={12} padding="5px">
                                <Box sx={{ border: '1px grey solid' }} borderRadius={1.5} height={270}>
                                <RichTextEditor initialContent={content} onChange={handleEditorChange} />
                                </Box>

                            </Grid>

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions sx={{ borderTop: '1px dashed gray' }}>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='primary' sx={{ fontSize: 12 }}>
                        Confirm
                    </Button>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
                        Close
                    </Button>
                </DialogActions>
            </BDebtDialog>
        </div>
    )
}

export default SendError;