import React, { createContext, useReducer, ReactNode } from 'react';
import AppReducer from './AppReducer';
import {ContextProps,InitialState,MenuItem } from '../../Model/Settings/IMenuDetails';


const initialState: InitialState = {
    menuItems: [],
};

export const GlobalContext = createContext<ContextProps | null>(null);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    const removeMenuItem = (meniId: number) => {
        dispatch({
            type: 'REMOVE_MENU_ITEM',
            payload: meniId,
        });
    };

    const addMenuItem = (menuItem: MenuItem) => {
        dispatch({
            type: 'ADD_MENU_ITEM',
            payload: menuItem,
        });
    };

    const editMenuItem = (menuItem: MenuItem) => {
        dispatch({
            type: 'EDIT_MENU_ITEM',
            payload: menuItem,
        });
    };

    const contextValue: ContextProps = {
        menuItems: state.menuItems,
        removeMenuItem,
        addMenuItem,
        editMenuItem,
    };

    return (
        <GlobalContext.Provider value={contextValue}>
            {children}
        </GlobalContext.Provider>
    );
};
