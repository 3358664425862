/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Collapse, IconButton, DialogActions } from "@mui/material";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Autocomplete, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import _ from 'lodash';
import { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { NameRegex, emailRegex } from "../../../Constant/Constant";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { BDebtTransition } from "../../../GlobalControl/BDebtTransition";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import { GlobalContext } from "../../../MockData/UserLevel/GlobalContext";
import { ICreateUser, IValidationErrors } from "../../../Model/IUser";
import useStyles from "../UserCss";
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Looks4Icon from '@mui/icons-material/Looks4';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import { Userlevel } from "../../../MockData/UserLevel/Menulist";

const SetClientPermission = () => {
  let AddUser: ICreateUser = {
    userId: 0,
    firstName: "",
    lastName: "",
    userEmailAddress: "",
    userType: 0,
  };

  const classes = useStyles();
  const { menuItems } = useContext(GlobalContext)!;
  const [expand, setExpand] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [Validation, setValidation] = useState<IValidationErrors[]>([]);
  const [localState, setLocalState] = useState(AddUser);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleUserChange = (e: any) => {
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
  };

 const handleValidateName = (event: any) => {
    let controlValue = event.target.value;
    let controlName = event.target.name;

    if (controlName?.includes("firstName") || controlName?.includes("lastName")) {
      let nameRegex = new RegExp(NameRegex);

      // This is to take the copy of the existing state values 
      let currentValidationError = _.cloneDeep(Validation);

      // Check the inuted values are matches with the regex which is provided
      if (!nameRegex.test(controlValue)) {
        let nameValidation: IValidationErrors = {
          errorNumber: 3,
          errorMessage: `${controlName} Should be Valid and Required`,
          fieldName: controlName
        }
        // Checks the dublicate errors are exist or not if exist not pushing the error values to the specific state
        // if (currentValidationError.findIndex((x: { fieldName: number; }) => x.fieldName === controlName) <= -1) {
        //   // push the new Error list values to the current Error list
        //   currentValidationError.push(nameValidation);
        //   setValidation(currentValidationError);
        // }
      }
      else if ((nameRegex.test(controlValue))) {
        // Remove the Error Message if the valid data is entered
        // if (currentValidationError.findIndex((x: { fieldName: number; }) => x.fieldName === controlName) > -1) {
        //   // To filter the error values from the list which is matches with the controlName 
        //   let errorList = currentValidationError.filter((x: { fieldName: number; }) => x.fieldName !== controlName);
        //   setValidation(errorList);
        // }
      }
    }
  }

  const handleValidateEmail = (event: any) => {
    let controlValue = event.target.value;
    let controlName = event.target.name;

    if (controlName?.includes("emailId")) {
      let nameRegex = new RegExp(emailRegex);

      // This is to take the copy of the existing state values 
      let currentValidationError = _.cloneDeep(Validation);

      // Check the inuted values are matches with the regex which is provided
      if (!nameRegex.test(controlValue)) {
        let nameValidation: IValidationErrors = {
          errorNumber: 3,
          errorMessage: `${controlName} Should be Valid and Required`,
          fieldName: controlName
        }
        // Checks the dublicate errors are exist or not if exist not pushing the error values to the specific state
        // if (currentValidationError.findIndex((x: { fieldName: number; }) => x.fieldName === controlName) <= -1) {
        //   // push the new Error list values to the current Error list
        //   currentValidationError.push(nameValidation);
        //   setValidation(currentValidationError);
        // }
      }
      else if ((nameRegex.test(controlValue))) {
        // Remove the Error Message if the valid data is entered
        // if (currentValidationError.findIndex((x: { fieldName: number; }) => x.fieldName === controlName) > -1) {
        //   // To filter the error values from the list which is matches with the controlName 
        //   let errorList = currentValidationError.filter((x: { fieldName: number; }) => x.fieldName !== controlName);
        //   setValidation(errorList);
        // }
      }
    }
  }

  return (
    <Grid container spacing={0}>
      <Tooltip title="SET PERMISSIONS" arrow>
        <IconButton className={`${classes.PermissionIcon} ${classes.IconButtonStyle}`} onClick={openDialog}>
          <Badge overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<Looks4Icon className={`${classes.horizontalLine} ${classes.BadgeIcon4}`} />}>
            <PublishedWithChangesIcon className={classes.icon3} />
          </Badge>
        </IconButton>
      </Tooltip>
      <BDebtDialog
        fullScreen
        open={isDialogOpen}
        onClose={closeDialog}
        TransitionComponent={BDebtTransition}
        fullWidth={true} >
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={closeDialog}
        >
          <BDebtTypography variant="h6" textAlign={"center"} >
            AUTHORIZATION
          </BDebtTypography>
        </BDebtDialogTitleHeader>
        <Box m={1} p={1} sx={{ flexGrow: 1 }} bgcolor={'#FBFCFE'} borderBottom={'1px solid #CDD7E1'}>
          <Grid container spacing={1} className={classes.profilegrid}>
            <Grid item xs={3}>
              <Grid container spacing={1} padding={2} borderRadius={2} >
                <Grid item xs={12}>
                  <BDebtTypography variant="h5" padding="3px" borderBottom={'1px dashed lightgray'} align="center" color="primary"><b>PROFILE INFORMATION</b></BDebtTypography>
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="First Name"
                    size="small"
                    name="firstName"
                    onChange={(e) => {
                      handleValidateName(e);
                      handleUserChange(e);
                    }}
                    error={!!Validation.find((err) => err.fieldName === "firstName")}
                    helperText={Validation.find((err) => err.fieldName === "firstName")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="Last Name"
                    size="small"
                    name="lastName"
                    onChange={(e) => {
                      handleValidateName(e);
                      handleUserChange(e);
                    }}
                    error={!!Validation.find((err) => err.fieldName === "lastName")}
                    helperText={Validation.find((err) => err.fieldName === "lastName")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="Email"
                    size="small"
                    name="emailId"
                    onChange={(e) => {
                      handleValidateEmail(e);
                      handleUserChange(e);
                    }}
                    error={!!Validation.find((err) => err.fieldName === "emailId")}
                    helperText={Validation.find((err) => err.fieldName === "emailId")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    options={Userlevel}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <BDebtTextField
                        {...params}
                        label="User Level"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} borderLeft={'1px solid #CDD7E1'} className={classes.menugrid}>
              <Grid container spacing={1} padding={2} borderRadius={2} bgcolor={'#FBFCFE'}>
                <Grid item xs={12}>
                  <BDebtTypography variant="h5" padding="3px" borderBottom={'1px dashed lightgray'} align="center" color="primary"><b>MENU INFORMATION</b></BDebtTypography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container spacing={0} bgcolor={'#3b4a54'}>
                        <Grid item xs={1} p={0}>
                        </Grid>
                        <Grid item xs={4} p={1} >
                          <BDebtTypography variant="body2" color="white">
                            <b>MENU</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={1} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>CREATE</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={1} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>READ</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={1} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>UPDATE</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={1} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>DELETE</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={1} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>VIEW ALL</b>
                          </BDebtTypography>
                        </Grid>
                        <Grid item xs={2} p={1}>
                          <BDebtTypography variant="body2" color="white">
                            <b>REMOVE ALL</b>
                          </BDebtTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.menuListContainer} >
                        {menuItems?.map((r, index) => {
                          return (
                            r?.sliver && (
                              <Box m={0.5} mt={1} p={0} key={index}>
                                <Grid container spacing={0} borderRadius={0} boxShadow={2}>
                                  <Grid item xs={1} p={0}>
                                    {r?.subMenu && r.subMenu.length > 0 && (
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setExpand(!expand)}
                                      >
                                        {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                  </Grid>
                                  <Grid item xs={3} p={0}>
                                    <BDebtTypography variant="body2" pt={1} pl={1}>
                                      {r?.menuName}
                                    </BDebtTypography>
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                    <Checkbox
                                      name="basic"
                                      checked={r?.basic}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                    <Checkbox
                                      name="silver"
                                      checked={r?.sliver}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                    <Checkbox
                                      name="gold"
                                      checked={r?.gold}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                    <Checkbox
                                      name="gold"
                                      checked={r?.gold}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                  <Grid item xs={1} p={0}>
                                    <Checkbox
                                      name="gold"
                                      checked={r?.gold}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                  <Grid item xs={2} p={0}>
                                    <Checkbox
                                      name="gold"
                                      checked={r?.gold}
                                      size="small"
                                      color='success'
                                    />
                                  </Grid>
                                </Grid>
                                <Collapse in={expand} timeout="auto" unmountOnExit>
                                  <Box m={0} border={'1px solid lightgray'} bgcolor={'aliceblue'}>
                                  {r?.subMenu.map((result) => (
                                      <Grid container spacing={0} key={index}>
                                        <Grid item xs={2} p={0}>
                                        </Grid>
                                        <Grid item xs={3} p={0}>
                                          <BDebtTypography variant="body2" pt={1} pl={1}>
                                            {result?.subMenuName}
                                          </BDebtTypography>
                                        </Grid>
                                        <Grid item xs={1} p={0}>
                                          <Checkbox
                                            name="basic"
                                            checked={result?.basic}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                        <Grid item xs={1} p={0}>
                                          <Checkbox
                                            name="basic"
                                            checked={result?.basic}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                        <Grid item xs={1} p={0}>
                                          <Checkbox
                                            name="basic"
                                            checked={result?.basic}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                        <Grid item xs={1} p={0}>
                                          <Checkbox
                                            name="basic"
                                            checked={result?.basic}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                        <Grid item xs={1} p={0}>
                                          <Checkbox
                                            name="silver"
                                            checked={result?.sliver}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                        <Grid item xs={2} p={0}>
                                          <Checkbox
                                            name="gold"
                                            checked={result?.gold}
                                            size="small"
                                            color='primary'
                                          />
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Box>
                                </Collapse>
                              </Box>
                            )
                          )
                        })}
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
          <Button autoFocus onClick={closeDialog} variant='contained' size="small" color='primary' sx={{ fontSize: 12 }}>
            Submit
          </Button>
          <Button autoFocus onClick={closeDialog} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
            Close
          </Button>
        </DialogActions>
      </BDebtDialog>
    </Grid>
  );
}

export default SetClientPermission;

