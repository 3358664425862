/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import Radio from '@material-ui/core/Radio';
import { Grid,RadioGroup,FormControlLabel} from "@material-ui/core";
import useStyles from '../ProspectUserCss';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography';

const mockLabels = ['Day to Day Contact Name', 'Day to Day Contact Title', 'Day to Day Contact Phone Number', 'Day to Day Contact Email','No.of.Active Customers','Estimated Number of Monthly Placements','Software(ex.pestPac,RCC,Core,QuickBooks)','If You Choose other,What Software Do You Use? '];

const ContactInformation = () => {
    const [numTextboxes, setNumTextboxes] = useState(8);
    const classes = useStyles();
  const [radioValue, setRadioValue] = useState(''); 

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };
    return (
        <Grid container spacing={2} className={classes.Tabhead}>
        {Array.from({ length: numTextboxes }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
           <BDebtTypography variant="body2" className={classes.verfiyfont}>{mockLabels[index] || `Label ${index + 1}`}</BDebtTypography>
                {['Day to Day Contact Phone Number'].includes(mockLabels[index]) ? (
                <>
                <Grid container spacing ={1}>
                  <Grid item xs ={10}>
                    <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index]}`} />
                  </Grid>
                  <Grid item xs ={2}>
                    <BDebtTextField variant="outlined" size='small'fullWidth  placeholder='#EXT' />
                  </Grid>
                </Grid>
                </>
                ) : (
                <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index] || `Label ${index + 1}`}`} />
                )}
          </Grid>
        ))}
         <Grid item xs={12}>
         <BDebtTypography variant="body2" className={classes.verfiyfont}>
          Do you authorize?
        </BDebtTypography>
            <RadioGroup
            aria-label="Radio group for Yes or No"
            name="radioGroup"
            value={radioValue}
            onChange={handleRadioChange}
            className={classes.buttondes}
            row
            >
            <FormControlLabel value="Yes"  control={<Radio/>} label={<span className={classes.commonfont}>Yes</span>}/>
            <FormControlLabel value="No" control={<Radio />} label={<span className={classes.commonfont}>No</span>} />
            </RadioGroup>
        
        </Grid>
      </Grid>
  );
};

export default ContactInformation;