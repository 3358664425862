/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { DialogContent, DialogActions, TextField, IconButton, FormGroup } from '@mui/material';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import { Grid } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import BDebtCheckbox from '../../../GlobalControl/BDebtCheckBox';
import useStyles from './MenuLevelCSS';
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import BDebtButton from '../../../GlobalControl/BDebtButton';
import EditIcon from '@mui/icons-material/Edit';
import { usePost } from '../../../utils/apiHelper';
import { IMenuDetailResultContract } from '../../../Model/Settings/IMenuDetails';

const EditMenuSetting: React.FC<{ menuId: number, menuName: string, basic: boolean, sliver: boolean, gold: boolean, onEdit: () => void }> = (props) => {
  const { menuId, menuName, basic, sliver, gold, onEdit } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  let newMenuList: IMenuDetailResultContract = {
    menuId: menuId,
    menuName: menuName,
    basic: basic,
    sliver: sliver,
    gold: gold
  }
  const [localState, setLocalState] = useState<IMenuDetailResultContract>(newMenuList);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSave = async () => {
    let request = {
      menuName: localState?.menuName,
      menuId: menuId,
      basic: localState?.basic,
      sliver: localState?.sliver,
      gold: localState?.gold
    };

    const result = await usePost<any>("Menu/UpdateMenuDetail", request);
    setLocalState(result?.data || localState);
    onEdit();
    handleClose();
  };

  return (
    <React.Fragment>
      <IconButton aria-label="edit" size="small" color="warning" onClick={handleOpen}>
        <EditIcon fontSize="small" />
      </IconButton>

      <BDebtDialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
        maxWidth={"sm"} PaperProps={{ style: { width: 600 } }}>
        <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleClose}>
          Menu Details
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body2" padding="5px" >
            Menu Name
          </BDebtTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth size="small"
                id="standard-bare" variant="outlined"
                className={classes.searchBox}
                type='text' placeholder={`Enter Menu Name`}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                value={localState?.menuName}
                onChange={(e) => setLocalState({ ...localState, menuName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <BDebtTypography variant="body2" padding="5px" >
                Menu Action Name
              </BDebtTypography>
              <TextField fullWidth size="small"
                id="standard-bare" variant="outlined"
                className={classes.searchBox}
                type='text' placeholder={`Enter MenuAction Name`}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </Grid>
            <Grid xs={12} justifyContent="center" textAlign='center'>
              <FormGroup row sx={{ alignItems: "center", justifyContent: "center" }}>
                <FormControlLabel
                  control={<BDebtCheckbox color="success" checked={localState?.basic} />}
                  label="Essential"
                  onChange={(e) => setLocalState({ ...localState, basic: (e.target as HTMLInputElement).checked })}
                />
                <FormControlLabel
                  control={<BDebtCheckbox color="success" checked={localState?.sliver} />}
                  label="Premium"
                  onChange={(e) => setLocalState({ ...localState, sliver: (e.target as HTMLInputElement).checked })}
                />
                <FormControlLabel
                  control={<BDebtCheckbox color="success" checked={localState?.gold} />}
                  label="Enterprise"
                  onChange={(e) => setLocalState({ ...localState, gold: (e.target as HTMLInputElement).checked })}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BDebtButton size="small" color="success" onClick={handleSave}>
            Update
          </BDebtButton>
          <BDebtButton onClick={handleClose} size="small" color="error">
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
};

export default EditMenuSetting;
