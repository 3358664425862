/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Hidden, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { IRoute } from '../../Routes';
import useStyles from './NavbarCss';

import Scrollbars from 'react-custom-scrollbars';
export interface SidebarProps {
    routes: IRoute[];
}

export interface SidebarProps {
    sidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ routes, sidebarVisible, toggleSidebar }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<number | null>(null);
    const location = useLocation();

    const handleClick = (index: number) => {
        if (open === index) {
            setOpen(null);
        } else {
            setOpen(index);
        }
    };

    // Function to check if the route is active
    const isActiveRoute = (route: IRoute) => {
        return location.pathname === route.path;
    };

    return (
        <Box m={0} p={0} display="flex">
            <Hidden smDown>
                <List className={classes.listMui} >
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.scrollbarContainer} >
                        {routes?.map((route, index) => (
                            !route?.noNavLink && !route?.isSubNavigation && !route?.isPrivate ?
                                <ListItem className={classes.listItemMui} key={index}>
                                    <ListItemButton className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                                        key={route.title} component={Link as React.ElementType} to={route?.path}
                                        onClick={() => handleClick(index)} disabled={route?.isDisableMenu}
                                    >
                                        <ListItemIcon sx={{ justifyContent: 'center' }}>
                                            {route.icon && <route.icon fontSize="small" color='primary'
                                                className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                                        </ListItemIcon>
                                        <ListItemText >
                                            <Typography variant='body2' sx={{ fontWeight: isActiveRoute(route) ? "bold" : null }} className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                                {route?.title}
                                            </Typography>
                                        </ListItemText>

                                    </ListItemButton>
                                </ListItem>
                                : null
                        ))}
                    </Scrollbars>
                    {routes?.map((route, index) => (
                        route?.noNavLink && !route?.isSubNavigation ?
                            <ListItem className={classes.listItemMui} key={index} sx={{ borderTop: '1px dashed gray' }}>
                                <ListItemButton className={isActiveRoute(route) ? classes.listItemActive : classes.listItemInactive}
                                    key={route.title} component={Link as React.ElementType} to={route?.path}
                                    onClick={() => handleClick(index)} disabled={route?.isDisableMenu}
                                >
                                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                                        {route.icon && <route.icon fontSize="small" color='primary'
                                            className={isActiveRoute(route) ? classes.listItemIconActive : classes.listItemIconInActive} />}
                                    </ListItemIcon>
                                    <ListItemText >
                                        <Typography variant='body2' className={isActiveRoute(route) ? classes.listItemTextActive : classes.listItemTextInActive} >
                                            {route?.title}
                                        </Typography>
                                    </ListItemText>

                                </ListItemButton>
                            </ListItem>
                            : null
                    ))}
                </List >
            </Hidden>
            <Container component="main" className={classes.contentContainer} maxWidth="xl">
                <Switch>
                    {
                        routes.map((route: IRoute) => {
                            return (
                                <Route exact path={route.path} key={route.path} component={route.component}>
                                </Route>
                            )
                        })
                    }
                </Switch>
            </Container >
        </Box >
    );
};

export default Sidebar;
