/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';
//Controls
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
//modules
import { Mapping } from "../../../Model/placement/PlacementGridItem";
import useStyles from '../PlacementCss';
import StyledDataGrid from './StyledDataGrid';
import CustomPagination from './CustomPagination';
interface ColumnFieldProps {
  addNewRow: any[];
  selectedCard: { title: string; mappedField: string };
  fieldMappings: Mapping[];
  onRowUpdate: (updatedRow: any) => void;
  onRowClick: (rowData: any) => void;
  selectedAutocompleteField: string;
}

const ColumnField: React.FC<ColumnFieldProps> = (props) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const classes = useStyles();
  const { addNewRow, selectedCard, fieldMappings, onRowClick, selectedAutocompleteField} = props;
  const [rows, setRows] = useState<any[]>([]);
  const [defaultSelectedCard, setDefaultSelectedCard] = useState<{ title: string; mappedField: string }>(null);

  useEffect(() => {
    if (selectedCard) {
      setDefaultSelectedCard(selectedCard);
    } else if (fieldMappings.length > 0) {
      setDefaultSelectedCard({ title: fieldMappings[0].title, mappedField: fieldMappings[0].mappedField });
    }
  }, [selectedCard, fieldMappings]);

  useEffect(() => {
    function generateRows() {
      const newRows = addNewRow?.map((newRow) => {
        // Update rows based on selectedAutocompleteField
        const updatedRow = { ...newRow };
        const mapping = fieldMappings?.find(mapping => mapping?.title === selectedAutocompleteField);
        if (mapping) {
          updatedRow[mapping.mappedField] = newRow[mapping.mappedField];
        }
        updatedRow.isDeleted = selectedRows.includes(updatedRow.id);
        return updatedRow;
      });
      return newRows?.map((row) => ({ ...row }));
    }
    setRows(generateRows());
  }, [selectedAutocompleteField, addNewRow, fieldMappings, selectedRows]);

  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    // Update columns based on defaultSelectedCard or selectedAutocompleteField
    const selectedField = selectedCard ? selectedCard : defaultSelectedCard;
    if (selectedField) {
      const mapping = fieldMappings.find(mapping => mapping.title === selectedField.title);
      if (mapping) {
        const column: GridColDef = {
          field: mapping.mappedField,
          headerName: mapping.title,
          width: 150,
          align: 'left',
          editable: true,
          renderCell: (params) => {
            const isSelected = selectedRows.includes(params.row.id);
            const isError = isSelected && params.row.isDeleted;
            return (
              <div className={isError ? classes.MessageActive : classes.MessageInActive}>
                {isError ? 'This row has been skipped' : params.value}
              </div>
            );
          },
        };
        setColumns([column]);
      }
    }
  }, [selectedCard, defaultSelectedCard, fieldMappings, selectedRows]);

  const handleSelectionChange = (selection: any) => {
    setSelectedRows(selection);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.onRowUpdate(updatedRow); // Call the onRowUpdate prop
    return updatedRow;
  };

  const handleRowClick = (e) => {
    onRowClick(e?.id); // Call the onRowClick prop with the clicked row data
  };

  return (
    <StyledDataGrid
      rows={rows} // Paginate rows
      columns={columns}
      rowHeight={30}
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={handleSelectionChange}
      onRowClick={((e) => { handleRowClick(e) })}
      processRowUpdate={processRowUpdate}
      className={classes.ColumnFieldGrid}
      //Customized Pagination
      components={{
        Pagination: (paginationProps) => ( 
          // I have passed the pagination (Props) here
          <CustomPagination {...paginationProps} rows={rows} />
        ),
      }}
    />
  );
}

export default ColumnField;
