import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import { DialogActions, Grid } from '@mui/material';
import { useState } from "react";
import BDebtButton from "../../../../GlobalControl/BDebtButton";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import BDebtTypography from "../../../../GlobalControl/BDebtTypography";
import BDebtDialog from "../../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogContent from "../../../../GlobalControl/DialogBox/BDebtDialogContent";
import BDebtDialogTitleHeader from "../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import Configuration from "./PredefinedConfiguration";
import useStyles from '../WidgetCss';

const AddWidgets = () => {
  const classes=useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <div>
      <BDebtButton
        variant="outlined"
        size="small"
        sx={{ color: 'white' }}
        onClick={openDialog}
        startIcon={<AddchartOutlinedIcon fontSize="small" />}
      >
        ADD WIDGETS
      </BDebtButton>
      <BDebtDialog
        open={isDialogOpen}
        maxWidth={"md"}
        onClose={closeDialog}
        fullWidth
      >
        <BDebtDialogTitleHeader
          id="customized-dialog-title"
          onClose={closeDialog}
        >
          <BDebtTypography variant="h6"> ADD WIDGETS</BDebtTypography>
        </BDebtDialogTitleHeader>
        <BDebtDialogContent className={classes.borderBottom}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                label="Widgets Name"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                label="Widgets Type"
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                label="Description"
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </BDebtDialogContent>
        <DialogActions>
          <Grid  container justifyContent="flex-end" spacing={0}>
            <Grid item xs={8} padding="0px 10px">
              <Configuration />
            </Grid>
              <Grid item container xs={4} justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <BDebtButton
                    variant="contained"
                    color="success"
                    size="small"
                    startIcon={<TurnedInNotIcon fontSize="small" />}>
                    Save
                  </BDebtButton>
                </Grid>
                <Grid item>
                  <BDebtButton
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<HighlightOffIcon fontSize="small" />}
                    onClick={closeDialog}
                  >
                    Close
                  </BDebtButton>
                </Grid>
              </Grid>
            </Grid>
        </DialogActions>
      </BDebtDialog>
    </div >
  );
}
export default AddWidgets;