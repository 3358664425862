/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import {DialogContent, IconButton} from "@material-ui/core";
import { useState } from "react";
import React from "react";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { DialogActions, Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import { usePost } from "../../../utils/apiHelper";
// Icon
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const DeleteDataRules: React.FC<{ rowId: number; onDelete: () => void }> = (props) => {
  const { rowId } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteRule = async () => {
    let request = rowId
    await usePost("Rule/DeleteRule?RuleId=" + rowId, request).finally(() => {
        props?.onDelete();
        closeDialog();
      })
  };


  return (
    <React.Fragment>
      <IconButton onClick={openDialog} size="small">
        <DeleteOutlineOutlinedIcon color="error" fontSize="small" />
      </IconButton>
      <BDebtDialog open={isDialogOpen} onClose={closeDialog} maxWidth={"sm"}>
        <BDebtDialogTitleHeader  onClose={closeDialog} id="customized-dialog-title">
        <Typography variant="body1">
          <b>DELETE RULE</b>
        </Typography>   
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body1">
            Are you sure you want to Delete this Rule?
          </BDebtTypography>
        </DialogContent>
        <DialogActions  sx={{ borderTop: '1px dashed gray' }}>
        <BDebtButton onClick={handleDeleteRule} color="success" variant="contained" size = "small">
            Yes
          </BDebtButton>
          <BDebtButton onClick={closeDialog} color="error" variant="contained" size="small">
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
};

export default DeleteDataRules;
