/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, IconButton, Snackbar, Table, TableBody, TableCell, TableRow, Box, Grid, TableHead } from "@mui/material";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { GetDataSet, Field } from "../../../MockData/Placement/DataRuleMock";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MuiAlert from "@mui/material/Alert";
import useStyles from "../PlacementCss";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";

interface CSVPreviewProps {
  allRows: any[];
  onClose: () => void;
  isOpen: boolean;
  onRowUpdate: (updatedRow: any) => void;
  dataSetField: GetDataSet[];
  selectedRowIndex: number;
}

const PreviewData: React.FC<CSVPreviewProps> = (props) => {
  const classes = useStyles();
  const { allRows, onClose, isOpen, onRowUpdate, dataSetField, selectedRowIndex } = props;
  const [currentIndex, setCurrentIndex] = useState(selectedRowIndex);
  const [editedRowData, setEditedRowData] = useState<any>(allRows);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [hasEmptyRequiredFields, setHasEmptyRequiredFields] = useState(false);

  useEffect(() => {
    setCurrentIndex(selectedRowIndex);
    setEditedRowData(allRows[selectedRowIndex] || {});
  }, [selectedRowIndex, allRows]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && currentIndex > 0) {
        handlePrevious();
      } else if (event.key === "ArrowRight" && currentIndex < allRows.length - 1) {
        handleNext();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, allRows]);

  const handleSaveChanges = () => {
    const updatedRow = { ...allRows[currentIndex], ...editedRowData };
    onRowUpdate(updatedRow);
    setSnackbarOpen(true);
    setSnackbarMessage("Updated record saved successfully!");
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditedRowData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const calculateDefaultFieldMappings = () => {
    return dataSetField?.flatMap((dataset) =>
      dataset?.fieldsDetail?.map((field: Field) => ({
        fieldName: field?.fieldName,
        dataSetId: field?.dataSetId,
        dataType: field?.dataType,
        ruleId: field?.ruleId,
        isRequired: field?.isRequired,
      }))
    );
  };

  const defaultFieldMappings = calculateDefaultFieldMappings();

  useEffect(() => {
    // Check for empty required fields whenever editedRowData changes
    const requiredFields = defaultFieldMappings?.filter(field => field?.isRequired);
    const emptyRequiredFields = requiredFields?.some(field => !editedRowData[field?.fieldName] || editedRowData[field?.fieldName]?.trim() === '');
    setHasEmptyRequiredFields(emptyRequiredFields);
  }, [editedRowData, defaultFieldMappings]);


  const handleNext = () => {
    const nextIndex = (currentIndex + 1);
    setCurrentIndex(nextIndex);
    setEditedRowData(allRows[nextIndex] || {});
  };

  const handlePrevious = () => {
    const prevIndex = (currentIndex - 1);
    setCurrentIndex(prevIndex);
    setEditedRowData(allRows[prevIndex] || {});
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <BDebtDialog open={isOpen} onClose={onClose}>
      <BDebtDialogTitleHeader onClose={onClose} id="customized-dialog-title">Row Details</BDebtDialogTitleHeader>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Fields</TableCell>
                  <TableCell align="center">Values</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {defaultFieldMappings?.map(field => (
                  <TableRow key={field.fieldName}>
                    <TableCell size="small" >
                      <BDebtTypography variant="body2" color="gray" bgcolor={"lightgray"} border={'1px solid gray'} p={"6px"} borderRadius={"5px"}>
                        {field?.fieldName}  {field?.isRequired && <span className={classes.isRequired}> * </span>}
                      </BDebtTypography>
                    </TableCell>
                    <TableCell size="small" >
                      <BDebtTextField variant="outlined" size="small" fullWidth value={editedRowData[field?.fieldName] || ''} onChange={(e) => handleFieldChange(field?.fieldName, e.target.value)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px dashed gray' }}>
        <div className={classes.previewDataStyle}>
          <IconButton aria-label="play/pause" size="small" onClick={handlePrevious} disabled={currentIndex === 0}>
            <BDebtTooltip title="Previous">
              <PlayArrowIcon className={classes.iconRotateStyle} />
            </BDebtTooltip>
          </IconButton>
          <Box className ={classes.outOfBoxStyle}>
            <BDebtTypography variant="body2">{`${currentIndex + 1} of ${allRows.length}`}</BDebtTypography>
          </Box>
          <IconButton aria-label="play/pause" size="small" onClick={handleNext} disabled={currentIndex === allRows?.length - 1}>
            <BDebtTooltip title="Next">
              <PlayArrowIcon />
            </BDebtTooltip>
          </IconButton>
        </div>
        <Button variant="contained" color="success" size="small" onClick={handleSaveChanges} disabled={hasEmptyRequiredFields}>
          Save
        </Button>
      </DialogActions>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success" >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </BDebtDialog>
  );
};

export default PreviewData;
