import {Button, DialogActions,DialogContent,Grid,} from "@material-ui/core";
import BDebtDialog from "../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "./ManageTemplateCss";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../GlobalControl/BDebtTextField";

interface EditFormTemplateProps {
  open: boolean;
  onClose: () => void;
  selectedRow: any;
}

const EditFormTemplate: React.FC<EditFormTemplateProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  // const handleCheckBoxChange = (index: number) => {
  //   // Check if the checkbox is already selected
  //   const isSelected = selectedCheckboxes.includes(index);

  //   if (isSelected) {
  //     // If selected, remove it from the array
  //     setSelectedCheckboxes(selectedCheckboxes.filter((i) => i !== index));
  //   } else {
  //     // If not selected, add it to the array
  //     setSelectedCheckboxes([...selectedCheckboxes, index]);
  //   }
  // };

  return (
    <div>
      <BDebtDialog
        open={open}
        maxWidth={"lg"}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <BDebtDialogTitleHeader id="responsive-dialog-title" onClose={onClose}>
          <BDebtTypography variant="h6">EDIT FORM TEMPLATE</BDebtTypography>
        </BDebtDialogTitleHeader>
        <DialogContent>
        <Grid container spacing={0}>
            <Grid item xs={12}>
              <BDebtTextField
                id="outlined-basic"
                size="small"
                label="Enter Template Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <BDebtTypography variant="h4">
              <b>Customize Form</b>
              </BDebtTypography> 
              </Grid>
              {/* <TableContainer className={`${"scrollbox"} ${"on-scrollbar"}`}>
                {ActivityList?.map((r, index) => {
                  return (
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value={`${r?.id}`}
                        control={
                          <BDebtCheckbox size="small" name={`${r?.activity}`} />
                        }
                        label={`${r?.activity}`}
                        labelPlacement="end"
                        checked={selectedCheckboxes.includes(index)}
                        onChange={() => handleCheckBoxChange(index)}
                        className={
                          selectedCheckboxes.includes(index)
                            ? classes.permittedChecked
                            : classes.permittedFiled
                        }
                      />
                    </FormControl>
                  );
                })}
              </TableContainer>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes.yesbutton}>
            UPDATE
          </Button>
          <Button onClick={onClose} className={classes.nobutton}>
            CANCEL
          </Button>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default EditFormTemplate;
