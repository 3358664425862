import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Button, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import BDebtTextField from '../../../GlobalControl/BDebtTextField';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import useStyles from '../DebtorsCss';

const CloseAndRemove = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size="small" sx={{ fontSize: 10 }} fullWidth startIcon={<RemoveDoneIcon />} onClick={handleClickOpen}>close & Remove</Button>

            <BDebtDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'sm'}
            >
                <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleClickClose}>
                    <Typography variant="body1">
                        <b>CLOSE AND ACCOUNT</b>
                    </Typography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2"><b>Current Amount</b></Typography>

                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="$ Current Balance Amount in Your System"
                                size='small'
                                className={classes.closeAccountControl}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2"><b>Description</b></Typography>

                            <BDebtTextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                placeholder="Why is the account being closed?"
                                fullWidth
                                className={classes.closeAccountControl}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2"><b>Method</b></Typography>

                            <FormControl className={classes.closeAccountControl}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="Paid" control={<Radio size='small' />} label={<span className={classes.fontSize}>Paid</span>} />
                                    <FormControlLabel value="Disputing" control={<Radio size='small' />} label={<span className={classes.fontSize}>Disputing</span>} />
                                    <FormControlLabel value="Bankruptcy" control={<Radio size='small' />} label={<span className={classes.fontSize}>Bankruptcy</span>} />
                                    <FormControlLabel value="Other" control={<Radio size='small' />} label={<span className={classes.fontSize}>Other</span>} />
                                </RadioGroup>
                            </FormControl>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ borderTop: '1px dashed gray' }}>
                <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='primary' sx={{ fontSize: 12 }}>
                        Confirm
                    </Button>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
                        Close
                    </Button>
                </DialogActions>
            </BDebtDialog>
        </div>
    )
}

export default CloseAndRemove