import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '5px'
        },
        tableCellHeader: {
            padding: "4px 8px !important",
            fontSize: "10px !important",
            color: "white !important",
            background: "#3b4a54 !important",
            fontWeight: 'bold'
        },
        tableCellStyle: {
            padding: "5px 8px !important",
            "& .MuiTableCell-root": {
                padding: "10px 15px!important",
            },
            fontSize: "10px !important",
        },
        tablebodyContainer: {
            borderBottom: '2px solid lightgray',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 72vh - 190px)',
                minHeight: 'calc( 72vh - 190px)',
            },
        },
        pagination: {
            padding: "8px",
            display: 'flex',
            justifyContent: 'center'
          },
    })
);

export default useStyles;