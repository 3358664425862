import sql from "../../asset/image/Configuration/sqlsvg.svg";
import oracle from "../../asset/image/Configuration/oracle.svg";
import mysql from "../../asset/image/Configuration/mysql-icon.svg";
import mongodb from "../../asset/image/Configuration/mongodb.svg";
import post from "../../asset/image/Configuration/postgresql.svg";
import parquet from "../../asset/image/Configuration/parquet.svg";
import csv from "../../asset/image/Configuration/file-csv-color-red-icon.svg";
import D365 from "../../asset/image/Configuration/Dynamics365-color.svg";
import DataLake from "../../asset/image/Configuration/download (1).svg";
import sftp from "../../asset/image/Configuration/sftp.svg";
import sqlLite from "../../asset/image/Configuration/sqlite-icon.svg";
import PowerPlatform from "../../asset/image/Configuration/power-platform-logo.svg";
import json from "../../asset/image/Configuration/Papirus-Team-Papirus-Mimetypes-App-json.svg";
import Awss3 from "../../asset/image/Configuration/amazon-s3-svgrepo-com.svg";
import API from "../../asset/image/Configuration/API.svg";
import Azureblob from "../../asset/image/Configuration/Azureblob.svg";


export const realtional = [
  { src: sql, alt: "SQL Database", name: "Sql" },
  { src: oracle, alt: "Oracle", name: "Oracle" },
  { src: mysql, alt: "MySQL", name: "MySQL" },
  { src: mongodb, alt: "MongoDB", name: "MongoDB" },
  { src: post, alt: "PostgreSQL", name: "PostgreSQL" },
  { src: sqlLite, alt: "SQLite", name: "SQLite" },
];

export const APIConfigure = [{ src: API, alt: "API", name: "API" }];

export const SemiStructure = [
  { src: parquet, alt: "Parquet", name: "Parquet" },
  { src: csv, alt: "CSV", name: "CSV" },
  { src: json, alt: "JSON", name: "JSON" },
];

export const azure = [
  { src: DataLake, alt: "Data Lake", name: "Data Lake" },
  { src: PowerPlatform, alt: "Power Platform", name: "Power Platform" },
  {src: Azureblob , alt:"Azure Blob",name: "Azure Blob"},
];
export const saas = [
  { src: D365, alt: "Dynamics 365", name: "Dynamics 365" },
  { src: sftp, alt: "SFTP", name: "SFTP" },
  { src: Awss3, alt: "Amazon S3", name: "Amazon S3" },
];

export const categories = [
  { title: "Relational", data: realtional },
  { title: "API", data: APIConfigure },
  { title: "Azure service", data: azure },
  { title: "SAAS Products", data: saas },
  { title: "Semi Structure", data: SemiStructure },
];
