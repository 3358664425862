import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: '5px'
        },
        searchBox: {
            marginTop: '2px !important',
            borderRadius: "5px !important",
            border: '1px solid #215176 !important',
            height: '35px !important',
            textIndent: '10px !important',
            fontSize: "12px !important",
            '& .MuiInputBase-root': {
                fontSize: "12px !important"
            },
        },
        outlinedInput: {
            fontSize: "12px !important",
            color: "black !important",
            marginTop: '-2px !important',
            '&$focused $notchedOutline': {
                color: 'white !important',
                border: '0px !important'
            },
        },
        notchedOutline: {
            color: 'white !important',
            border: 'none !important',
            fontSize: "12px !important",
        },
        tableCellHeader: {
            padding: "12px 8px !important",
            fontSize: "12px !important",
            color: "white !important",
            background: "#3b4a54 !important",
            fontWeight: 'bold'
        },
        tableCellStyle: {
            padding: "1px 8px !important",
            "& .MuiTableCell-root": {
                padding: "10px 15px!important",
            },
            fontSize: "12px !important",
        },
        clickableTableRow: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            }
        },
        tablebodyContainer: {
            borderBottom: '2px solid lightgray',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 99vh - 190px)',
                minHeight: 'calc( 99vh - 190px)',
            },
        },
        iconButton: {
            borderRadius: "5px !important",
            border: '2px solid #2a3944 !important',
            "&:hover": {
                backgroundColor: '#2a3944 !important',
                color: 'white'
            }
        },
        pageContainer: {
            boxShadow: " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important"
        },

        buttonIcon:
        {
            padding: '3px',
        },
        chipStyle:
        {
            marginTop: '5px',
        },
        tablePagination: {
            "& .MuiTablePagination-toolbar":{
                minHeight: '48px'
            }
        },
        selectedColumn: {
            backgroundColor: '#f9fafb !important',
            display: 'flex !important',
            justifyContent: 'center !important',
            alignItems: 'center !important',
            flexDirection: 'column',
        },
        gridSearchbar: {
            borderBottom: '1px solid lightgray !important'
          }
    })
);

export default useStyles;