import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Grid, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Dataset from '../../Components/Setting/Dataset/Dataset';
import DataRules from './DataRule';
import useStyles from './SettingsCss';

const IngestRules = () => {
  const classes = useStyles();
  const [value, setValue] = useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <Box p={0} m={0}>
      <Grid container spacing={0}>
        <Grid xs={12} md={12} >
          <TabContext value={value} >
            <AppBar position="static" className={classes.appbarPosition}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" className={classes.tabList}>
                <Tab label="INGEST PLACEMENT RULES" value="0" className={classes.tabRecords} />
                <Tab label="MANAGE DATA RULE" value="1" className={classes.tabRecords} />
              </TabList>
            </AppBar>

            <TabPanel value="0" sx={{ padding: 1 }}>
              <Dataset />
            </TabPanel>
            <TabPanel value="1" sx={{ padding: 1 }}>
              <DataRules />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}
export default IngestRules;