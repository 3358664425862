import { useState } from 'react';
//CSS
import useStyles from '../PlacementCss';
//Controls
import {Button, DialogActions, DialogContent, IconButton} from "@material-ui/core";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
//Module
import Rules from '../Rules';

const RuleSelectionDialog = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className='dialogcontainer'>
      <BDebtTooltip title="Rules">
        <IconButton onClick={openDialog} size='small' className={classes.fontStyle}>
          <span className={`${classes.ruleIconStyle} ${"e-icons e-hide-formula-bar"}`}></span>
        </IconButton>
      </BDebtTooltip>
      <BDebtDialog open={isDialogOpen} maxWidth={'md'} onClose={closeDialog} fullWidth>
        <DialogContent className={classes.dialogroot}>
          <Rules />
        </DialogContent>
        <DialogActions >
          <Button variant="contained" onClick={closeDialog} size="small" color="primary">
            APPLY
          </Button>
          <Button variant="contained" onClick={closeDialog} size="small" >
            CLOSE
          </Button>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default RuleSelectionDialog;
