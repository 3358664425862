export const emailRegex = /^([À-žA-Za-z0-9ñ]+(?:['._-][À-žA-Za-z0-9ñ]+)*)@([À-žA-Za-z0-9ñ]+(?:[.-][À-žA-Za-z0-9ñ]+)*\.[a-z]{2,6})$/;
export const NameRegex = /^(?!\s*$)[a-zA-Z0-9\s]+$/;

export const EmailRegex = /^([A-Za-z0-9]+(?:['+._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,4})$/;
export const PhoneRegex = /^(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/;

export const Accounts = "Accounts";
export const Diagnostics = "Diagnostics";
export const Operations = "Operations";
export const Forms = "Forms";
export const Report = "Report";
export const Settings = "Settings";
export const UserLevel = "UserLevel";
export const Dashboard = "Dashboard";
export const IngestRules = "IngestRules";


export const clientcode = "COGNULT1";
export const Phase = "NEW ACCOUNTS";
export const DateFormat = "MM/DD/YYYY";
export const Dataset = "DATASET1";