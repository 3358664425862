
const SearchandHighlight = (text: string, query: string) => {
    if (query && query.length > 0 && text.toLowerCase().includes(query.toLowerCase())) {
      const regex = new RegExp(`(${query})`, 'gi');
      return text.split(regex).map((part, index) =>
        regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
      );
    } else {
      return text;
    }
  };

export default SearchandHighlight;