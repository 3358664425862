import { CardContent, styled } from "@mui/material";

interface BDebtCardContentProps {
  textAlign?: string;
}

const BDebtCardContent = styled(CardContent)<BDebtCardContentProps>(({ textAlign }) => ({
  "&.MuiCardContent-root": {
    textAlign: textAlign || "justify", // Use the provided value or default to "justify"
    padding: "16px",
  },
}));

export default BDebtCardContent;
