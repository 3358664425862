import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import useStyles from './SettingsCss';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Tab } from '@mui/material';
import PlatformAdmin from '../../Components/Setting/UserLevel/PlatformAdmin';
import ClientAdmin from '../../Components/Setting/UserLevel/ClientAdmin';
import ClientUser from '../../Components/Setting/UserLevel/ClientUser';
import ProspectUser from '../../Components/Setting/UserLevel/ProspectUser';
import AgentUser from '../../Components/Setting/UserLevel/AgentUser';
import api from '../../ApiConfiguration/Configuration';
import { IGetUserTypeList } from '../../Model/IUser';

const UserLevel = () => {
  const classes = useStyles();
  const [value, setValue] = useState('0');
  const [userTypeList, setUserTypeList] = useState<IGetUserTypeList[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(()=>{
    GetUserTypeDetail();
  },[]);


  const GetUserTypeDetail = () => {
    api.get<IGetUserTypeList[]>('User/GetUserTypeDetail').then((response) => {
      setUserTypeList(response.data);
    })
  };
  return (
    <Box p={0} m={0}>
      <Grid container spacing={0}>
        <Grid xs={12} md={12} >
          <TabContext value={value} >
            <AppBar position="static" className={classes.appbarPosition}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" className={classes.tabList}>
              {userTypeList.map((user, index) => (
                  <Tab key={index} label={user?.userRoleName} value={index.toString()} className={classes.tabRecords} />
                ))}
              </TabList>
            </AppBar>

            <TabPanel value="0" sx={{ padding: 1, }} >
              <PlatformAdmin />
            </TabPanel>
            <TabPanel value="1" sx={{ padding: 1 }}>
              <ClientAdmin />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 1 }}>
              <ClientUser />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 1 }}>
              <AgentUser />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 1 }}>
              <ProspectUser />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}
export default UserLevel;