/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import Radio from '@material-ui/core/Radio';
import { Grid,RadioGroup,FormControlLabel, Checkbox} from "@material-ui/core";
import useStyles from '../ProspectUserCss';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography';
const mockLabels = ['Company Name to Dispaly on collection Notices', 'Phone Number for Collection Notices', 'Remit Address for Payments - street', 'Remit Address for Payments - city','Remit Address for Payments-State','Remit Address for Payments - ZipCode','Payment Website for Collection Notices'];

const CollectionInformation = () => {
    const classes = useStyles();
    const [numTextboxes, setNumTextboxes] = useState(7);
    const [radioValue, setRadioValue] = useState(''); 
    const [selectedCreditCards, setSelectedCreditCards] = useState([]);

    const creditCardTypes = [
      { label: 'Visa', value: 'visa' },
      { label: 'MasterCard', value: 'mastercard' },
      { label: 'American Express', value: 'American Express' },
      { label: 'Discover', value: 'Discover' },
      { label: 'None', value: 'None' },

    ];
  
    const handleCheckboxChange = (value) => {
      const isSelected = selectedCreditCards.includes(value);
      if (isSelected) {
        setSelectedCreditCards(selectedCreditCards.filter(card => card !== value));
      } else {
        setSelectedCreditCards([...selectedCreditCards, value]);
      }
    };
  
    const handleRadioChange = (event) => {
      setRadioValue(event.target.value);
    };
    return (
        <Grid container spacing={2} className={classes.Tabhead}>
        {Array.from({ length: numTextboxes }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
        <div>
            <BDebtTypography variant="body2" className={classes.verfiyfont}>{mockLabels[index] || `Label ${index + 1}`}</BDebtTypography>
                {['phone Number for Collection Notices'].includes(mockLabels[index]) ? (
                <>
                <Grid container spacing ={1}>
                  <Grid item xs={10}>
                    <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index]}`} />
                   </Grid>
                  <Grid item xs={2}>
                    <BDebtTextField variant="outlined" size='small' fullWidth   placeholder='#EXT' />
                  </Grid>
                </Grid>
                </>
                ) : (
                <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index] || `Label ${index + 1}`}`}/>
                )}

        </div>
              
        </Grid>
        ))}
        <Grid container spacing={2}>
        <Grid item xs={6}>
        <BDebtTypography variant="body2"className={classes.verfiyfont}>
          Credit Card
        </BDebtTypography>

       {creditCardTypes.map((card) => (
          <FormControlLabel
            key={card.value}
            control={
              <Checkbox
                checked={selectedCreditCards.includes(card.value)}
                onChange={() => handleCheckboxChange(card.value)}
               />
            }
            label={<span className={classes.commonfont}>{card.label}</span>}
          />
        ))}
      </Grid>
         <Grid item xs={6}>
         <BDebtTypography variant="body2" className={classes.verfiyfont}>
          Are Your Accounts Mostly?
        </BDebtTypography>
            <RadioGroup
            aria-label="Radio group for Yes or No"
            name="radioGroup"
            value={radioValue}
            onChange={handleRadioChange}
             row
            >
            <FormControlLabel value="Residential" control={<Radio/>}  label={<span className={classes.commonfont}>Residential</span>} />
            <FormControlLabel value="Commercial" control={<Radio />} label={<span className={classes.commonfont}>Commercial</span>}/>
            <FormControlLabel value=" Even Mix of Both" control={<Radio />}  label={<span className={classes.commonfont}>Even Mix of Both</span>} />
            </RadioGroup>
        
        </Grid>
        </Grid>
      </Grid>
  );
};
export default CollectionInformation