/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogContent, IconButton, Snackbar, } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import api from '../../../ApiConfiguration/Configuration';
import { Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useStyles from "../UserCss";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteProspectUser: React.FC<{ prospectUserId: number; onDelete: () => void }> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { prospectUserId } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleDelete = async () => {
    await api.post("User/DeleteProspectUser?ProspectUserId=" + prospectUserId)
      .then(() => {
        setIsSnackbarOpen(true);
        handleClose();
        props?.onDelete();
      })
      .finally(() => {
      });
  };  

  return (
    <div>
      <IconButton className={classes.EditIcon} onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon className={classes.deleteIconcolor} />
      </IconButton>
      <BDebtDialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
        fullWidth maxWidth={'xs'} >
        <BDebtDialogTitleHeader onClose={handleClose} id="customized-dialog-title">Delete User</BDebtDialogTitleHeader>
        <DialogContent>
          <Typography variant="h6"> Are You Sure want to delete this ProspectUser?</Typography>
        </DialogContent>
        <DialogActions>
          <BDebtButton onClick={handleDelete} variant="contained" color="primary" size="small">
            Delete
          </BDebtButton>
          <BDebtButton onClick={handleClose} variant="outlined" color="primary" size="small">
            Cancel
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          User deleted successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}
export default DeleteProspectUser
