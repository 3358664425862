/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//Control
import { useState } from "react";
import { Paper } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import { IconButton, Step, StepLabel, Stepper, Grid } from "@mui/material";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
//Icons
import ColorlibStepIcon from "./style";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
//Css
import useStyles from "../PlacementCss";
//Modules
import ConfigureTargetFile from "./ConfigureTargetFile";
import { IFormData } from "../../../MockData/Placement/placement";
import { GetDataSet } from "../../../MockData/Placement/DataRuleMock";
import PlacementOmittedData from "../../../MockData/Placement/PlacementOmittedData.json";

const steps = ["Load", "Transform", "Preview", "Map Target", "Submit"];

function MapTargetFile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(3);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const clientCode = props?.location?.state?.clientCode;
  const phaseCode = props?.location?.state?.phaseCode;
  const dataset = props?.location?.state?.dataset;
  const fileName: string = props?.location?.state?.fileName;
  const dataSetId: number = props?.location?.state?.dataSetId;
  const uploadedData: any[] = props?.location?.state?.uploadedData;
  const fieldMappings: any[] = props?.location?.state?.fieldMappings;
  const deletedRecords: any[] = props?.location?.state?.deletedRecords;
  const fileDataLength: number = props?.location?.state?.fileDataLength;
  const ingestDetails: IFormData = props?.location?.state?.ingestDetails;
  const dataSetField: GetDataSet[] = props?.location?.state?.dataSetField;
  const yourFieldOptions: string[] = props?.location?.state?.yourFieldOptions;
  const datasetFieldMappings: any[] = props?.location?.state?.datasetFieldMappings;
  const [addNewRow, setAddNewRow] = useState(props?.location?.state?.addNewRow);
  const acceptedRecords = addNewRow?.map((data) => ({ id: data.Id, ...data }));
  const errorRecords = PlacementOmittedData?.map((data) => ({ id: data.Id, ...data }));

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleNextClick = () => {
    history.push('/accounts/Ingest/finish', {
      uploadedData: uploadedData, clientCode: clientCode, phaseCode: phaseCode, dataset: dataset, fieldMappings: fieldMappings, addNewRow: addNewRow, fileName: fileName, dataSetField: dataSetField,
      fileDataLength: fileDataLength, ingestDetails: ingestDetails, dataSetId: dataSetId, yourFieldOptions: yourFieldOptions, datasetFieldMappings: datasetFieldMappings, deletedRecords: deletedRecords, acceptedRecords: acceptedRecords, errorRecords: errorRecords,
    });
  };

  const handleBackClick = () => {
    // Use the goBack method to go back in history
    history.push("/accountspreview", {
      uploadedData: uploadedData, clientCode: clientCode, phaseCode: phaseCode, dataset: dataset, fieldMappings: fieldMappings, addNewRow: addNewRow, fileName: fileName, dataSetField: dataSetField,
      fileDataLength: fileDataLength, ingestDetails: ingestDetails, dataSetId: dataSetId, yourFieldOptions: yourFieldOptions, deletedRecords: deletedRecords, datasetFieldMappings: datasetFieldMappings, acceptedRecords: acceptedRecords, errorRecords: errorRecords,
    });
  };

  return (
    <div className={classes.divStyle}>
      <Grid container spacing={0} component={Paper} className={classes.pageContainer} >
        <Grid xs={activeStep > 0 ? 11 : 12} className={activeStep > 0 ? classes.stepperActive : classes.stepperInActive} sx={{ borderBottom: "1px solid lightgray" }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid xs={activeStep > 0 ? 1 : null} className={activeStep > 0 ? classes.buttonStyle : null}>
        <IconButton size="small" onClick={handleToggleFullScreen}>
            <BDebtTooltip title={isFullScreen ? "Close Full Screen" : "Full Screen"}>
              {isFullScreen ? (
                <FullscreenExitIcon fontSize="medium" className={classes.placementIcon} />
              ) : (
                <FullscreenIcon fontSize="medium" className={classes.placementIcon} />
              )}
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleBackClick} >
            <BDebtTooltip title="Back">
              <ArrowBackIcon fontSize="medium" className={classes.nextButton} />
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleNextClick}>
            <BDebtTooltip title="Submit">
              <ArrowForwardIcon fontSize="medium" className={classes.nextButton} />
            </BDebtTooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ConfigureTargetFile uploadedData={uploadedData} fieldMappings={fieldMappings} yourFieldOptions={yourFieldOptions} datasetFieldMappings={datasetFieldMappings} onClickNextPage={handleNextClick}/>
        </Grid>
      </Grid>
    </div>
  );
};
export default withRouter(MapTargetFile);