import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

// Define ResponsiveStyleValue type
type ResponsiveStyleValue<T> =
  | T
  | { desktop?: T; tablet?: T; mobile?: T }
  | ((theme: Theme) => T);

interface DynamicTypographyProps extends TypographyProps {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "body1"
    | "body2"
    | "subtitle1"
    | "subtitle2"
    | "button"
    | "caption"
    | "overline";
  textPadding?: string;
  textAlign?: ResponsiveStyleValue<"left" | "center" | "right" | "justify">;
  color?: string; // Accept any color string
}

const variantStyles: Record<
  DynamicTypographyProps["variant"],
  {
    fontSize: { desktop: number; tablet: number; mobile: number };
    lineHeight?: number;
    letterSpacing?: number;
    fontWeight?: number;
  }
> = {
  h1: { fontSize: { desktop: 34, tablet: 28, mobile: 24 } },
  h2: { fontSize: { desktop: 30, tablet: 24, mobile: 18 } },
  h3: { fontSize: { desktop: 24, tablet: 20, mobile: 18 } },
  h4: { fontSize: { desktop: 20, tablet: 18, mobile: 16 } },
  h5: { fontSize: { desktop: 18, tablet: 16, mobile: 14 } },
  h6: { fontSize: { desktop: 16, tablet: 14, mobile: 12 } },
  body1: {
    fontSize: { desktop: 16, tablet: 14, mobile: 12 },
    lineHeight: 1.5,
    letterSpacing: 0.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: { desktop: 14, tablet: 12, mobile: 10 },
  },
  subtitle1: {
    fontSize: { desktop: 18, tablet: 16, mobile: 14 },
    lineHeight: 1.75,
    letterSpacing: 0.15,
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: { desktop: 16, tablet: 14, mobile: 12 },
    lineHeight: 1.5,
    letterSpacing: 0.1,
    fontWeight: 600,
  },
  button: {
    fontSize: { desktop: 12, tablet: 8, mobile: 8 },
    lineHeight: 1.5,
    letterSpacing: 0.1,
    fontWeight: 600,
  },
  caption: {
    fontSize: { desktop: 12, tablet: 8, mobile: 8 },
    lineHeight: 1.5,
    letterSpacing: 0.1,
    fontWeight: 600,
  },
  overline: {
    fontSize: { desktop: 12, tablet: 8, mobile: 8 },
    lineHeight: 1.5,
    letterSpacing: 0.1,
    fontWeight: 600,
  },
  // ... other variants
};

const BDebtTypography: React.FC<DynamicTypographyProps> = ({
  variant,
  textPadding,
  textAlign,
  color,
  ...props
}) => {
  const isTablet = useMediaQuery("(max-width:960px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const { fontSize, lineHeight, letterSpacing, fontWeight } =
    variantStyles[variant] || {};
  const fontSizeValue = isMobile
    ? fontSize?.mobile || 16
    : isTablet
    ? fontSize?.tablet || 16
    : fontSize?.desktop || 16;

  return (
    <Typography
      {...props}
      variant={variant}
      style={{
        fontSize: `${fontSizeValue}px`,
        lineHeight: lineHeight && `${lineHeight}`,
        letterSpacing: letterSpacing && `${letterSpacing}px`,
        fontWeight: fontWeight && fontWeight,
        padding: textPadding,
        textAlign: textAlign as any,
        color: color || "inherit", // Use the provided color or "inherit"
      }}
    />
  );
};

export default BDebtTypography;
