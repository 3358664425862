import IconButton from '@mui/material/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BDebtDialogTitleHeader = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            style={{
                color: "white", textAlign: 'center', padding: '8px 15px', backgroundColor: '#3b4a54'
            }} {...other} className='line-break'>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 6,
                        color: 'white',
                        padding: '4px'
                    }}
                >
                    <CloseIcon fontSize='small' />
                </IconButton >
            ) : null}
        </DialogTitle >
    );
};

export default BDebtDialogTitleHeader;