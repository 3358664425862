import { Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TestIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import BDebtAutoComplete from "../../../../GlobalControl/BDebtAutocomplete";
import BDebtButton from "../../../../GlobalControl/BDebtButton";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import BDebtTypography from "../../../../GlobalControl/BDebtTypography";
import BDebtDialog from "../../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { Data } from "../../../../MockData/Widget";
import useStyles from "../WidgetCss";
import SampleDashboardLayout from "./SampleDashboardLayout";

export default function EditWidget() {
  const [open, setOpen] = React.useState(false);
  const [selectedDataSource, setSelectedDataSource] = React.useState(null);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDataSourceChange = (event, newValue) => {
    setSelectedDataSource(newValue);
  };

  return (
    <React.Fragment>
        <EditIcon  onClick={handleClickOpen} color="success"/>
      <BDebtDialog
        maxWidth={"md"}
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BDebtDialogTitleHeader id={"widget"} onClose={handleClose}>
          Edit CUSTOM WIDGET
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <BDebtTextField
                size="small"
                fullWidth
                autoFocus
                margin="dense"
                id="widgetName"
                label="Widget Name"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <BDebtAutoComplete
                fullWidth
                size="small"
                options={Data.defaultWidgets}
                renderInput={(params) => (
                  <BDebtTextField
                    {...params}
                    margin="dense"
                    label="Choose Default Widget"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                fullWidth
                options={Data.dataSources}
                getOptionLabel={(option) => option.label}
                onChange={handleDataSourceChange}
                renderOption={(props, option) => (
                  <li {...props}>

                    <IconButton size="small">
                      {option.icon}

                    </IconButton>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <BDebtTextField {...params} label="Data Source" />
                )}
              />
            </Grid>
            {selectedDataSource && (
              <Grid item xs={12}>
                {selectedDataSource.label === "Azure Cloud" && (
                  <AzureStorageConfig />
                )}
                {selectedDataSource.label === "SQL Database" && (
                  <SqldatabaseConfig />
                )}
                {selectedDataSource.label === "Google Drive" && <DriveConfig />}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <SampleDashboardLayout />
          <div>
            <BDebtButton
              onClick={handleClose}

              size="small"
              style={{ margin:"5px" }}
              color="success"
              startIcon={<AddIcon className={classes.AddIcon} />}
            >
              Save Widget
            </BDebtButton>

            <BDebtButton
            size="small"
              color="error"
              onClick={handleClose}
     
              startIcon={<CloseIcon  />}
            >
              Close
            </BDebtButton>
          </div>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
}

const SqldatabaseConfig = () => {
  const classes = useStyles();
  return (
    <>
      <Grid>
        <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgb(59, 74, 84)"} color={"white"}>SQL CONFIGURATION</BDebtTypography>
        <BDebtTextField
          size="small"
          fullWidth
          margin="dense"
          id="widgetName"
          label="Connector Name"
          type="text"
        />
        <BDebtTextField
          size="small"
          fullWidth
          margin="dense"
          id="widgetName"
          label="Host Name"
          type="text"
        />
        <BDebtTextField
          size="small"
          fullWidth
          autoFocus
          margin="dense"
          id="widgetName"
          label="Port Number"
          type="text"
        />
        <BDebtTextField
          size="small"
          fullWidth
          autoFocus
          margin="dense"
          id="widgetName"
          label="User Name"
          type="text"
        />
        <BDebtTextField
          size="small"
          fullWidth
          autoFocus
          margin="dense"
          id="widgetName"
          label="Password"
          type="password"
        />
        <BDebtTextField
          size="small"
          fullWidth
          autoFocus
          margin="dense"
          id="widgetName"
          label="Connection String"
          type="text"
        />
        <BDebtButton
          size="small"
          className={classes.buttonStyle}
          color="success"
          startIcon={<TestIcon fontSize="small" color="action" style={{ backgroundColor: "white" }} />}
        >
          Test Connection
        </BDebtButton>
        <FormControlLabel
          className={classes.formStyle}
          control={<Checkbox defaultChecked />}
          label={
            <Typography variant="h5" style={{ fontSize: "16px", }} >
              Shown in Dashboard
            </Typography>
          }
        />
      </Grid>
    </>

  );
}

const AzureStorageConfig = () => {
  const classes = useStyles();
  return (
    <>
      <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgb(59, 74, 84)"} color={"white"}>AZURE STORAGE CONFIGURATION</BDebtTypography>
      <BDebtTextField
        size="small"
        fullWidth
        autoFocus
        margin="dense"
        id="widgetName"
        label="AzureStorage Account Key"
        type="text"
      />
      <BDebtTextField
        size="small"
        fullWidth
        autoFocus
        margin="dense"
        id="widgetName"
        label="Storage Account Name"
        type="text"
      />
      <BDebtButton
        size="small"
        className={classes.buttonStyle}
        color="success"
        startIcon={<TestIcon fontSize="small" shapeRendering="Rounded" color="action" style={{ backgroundColor: "white" }} />}
      >
        Test Connection
      </BDebtButton>
      <FormControlLabel
        className={classes.formStyle}
        control={<Checkbox defaultChecked />}
        label={
          <Typography variant="h5" style={{ fontSize: "16px", }} >
            Shown in Dashboard
          </Typography>
        }
      />
    </>
  );
}
const DriveConfig = () => {
  const classes = useStyles();
  return (
    <>
      <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgb(59, 74, 84)"} color={"white"}>DRIVE CONFIGURATION</BDebtTypography>
      <BDebtTextField
        size="small"
        fullWidth
        autoFocus
        margin="dense"
        id="widgetName"
        label="Drive Configuration Field 1"
        type="text"
      />
      <BDebtButton
        size="small"
        className={classes.buttonStyle}
        color="success"
        startIcon={<TestIcon fontSize="small" shapeRendering="Rounded" color="action" style={{ backgroundColor: "white" }} />}
      >
        Test Connection
      </BDebtButton>
      <FormControlLabel
        className={classes.formStyle}
        control={<Checkbox defaultChecked />}
        label={
          <Typography variant="h5" style={{ fontSize: "16px", }} >
            Shown in Dashboard
          </Typography>
        }
      />
    </>
  );
}
