import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Dataset.tsx
    div: {
      flexGrow: 1,
    },
    container: {
      paddingTop: "15px",
    },
    gridStyle: {
      marginTop: 5,
    },
    fieldStyle: {
      fontSize: "13px",
    },
    classCard: {
      padding: "15px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      background: "honeydew",
      color: "#333",
      marginBottom: "10px",
    },
    dividerStyle: {
      margin: "10px 0",
      backgroundColor: "#ddd",
      borderTop: "1px solid black !important",
    },
    propertiesStyle: {
      fontSize: "13px",
      fontWeight: "bold",
    },
    dasetSidebar: {
      maxHeight: '600px !important',
      minHeight: '600px !important',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 220px) !important',
        minHeight: 'calc( 100vh - 220px) !important',
      },
    },
    divHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    divElipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    cardStyle: {
      border: "1px solid green",
      backgroundColor: "honeydew !important"
    },
    cardContentColor: {
      color: "rgb(0, 75, 80)"
    },
    actionsContainer: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    // UploadSchema.tsx
    draggableField: {
      cursor: "grab",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginTop: "10px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    typographyStyle: {
      marginLeft: "5px",
      fontWeight: "bold",
      display: "flex !important",
      justifyContent: "center !important",
    },
    typographyStyle1: {
      marginLeft: "5px",
    },
    divStyle: {
      marginTop: "5px",
    },
    dragIcon: {
      marginTop: '2px'
    },
    fieldTable: {
      maxHeight: '600px !important',
      minHeight: '600px !important',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 286px) !important',
        minHeight: 'calc( 100vh - 286px) !important',
      }
    },
    fieldUploadedTable: {
      maxHeight: '600px !important',
      minHeight: '600px !important',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 425px) !important',
        minHeight: 'calc( 100vh - 425px) !important',
      }
    },
    DropzoneArea: {
      minHeight: '140px !important',
      fontSize: '12px !important',
      border: '1px dashed blue !important',
      backgroundColor: '#f1f5ff !important',
      boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px !important',
      borderRadius: '5px',
      marginLeft: '6px',
    },
    DropzoneAreaText: {
      fontSize: '14px !important',
      fontWeight: 'bold',
      color: 'blue'
    },
    // DatasetRules.tsx
    ruleIconStyle: {
      fontSize: "16px !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItem: "center !important",
      color: "black",
      fontWeight: "bold",
    },
    ruleIconStyle1: {
      fontSize: "16px !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItem: "center !important",
      color: "lightgray",
      fontWeight: "bold",
    },
    RuleGridStyle: {
      padding: "3px",
      border: "1px solid lightgrey",
    },
    headButton: {
      padding: "4px",
      color: "white !important",
      backgroundColor: "#3b4a54",
      textTransform: "none",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
    },
    perviewContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      WebkitScrollSnapPointsX: "5px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc(100vh - 240px) !important",
        minHeight: "calc(100vh - 240px) !important",
      },
      overflowY: "auto",
    },
    ruleGroupHeader: {
      background: "#1976D2 !important",
      padding: "3px !important",
      marginBottom: "8px !important",
      cursor: "pointer !important",
      display: "flex !important",
      justifyContent: "center !important",
      borderRadius: "8px !important",
      color: "white !important"
    },
    rulehead: {
      marginRight: "8px",
    },
    ruleDisplay: {
      marginLeft: "10px",
    },
    ruleNameStyle: {
      display: "flex",
      alignItems: "center",
    },
    selectedRulesContainer: {
      flexWrap: "wrap",
      cursor: "pointer",
      
      // border:"green !important",
      // backgroundColor:"honeydew !important"
    },
    selectedRuleItem: {
      border: "2px solid green",
      borderRadius: "10px",
      padding: "5px",
      margin: "5px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "honeydew",
      justifyContent: "space-between",
      color: "black",
      marginLeft:"50px"
    },
    outputStyle: {
      marginTop: "10px"
    },
    saveButtonStyle: {
      marginLeft: '10px !important'
    },
    loadingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc(100vh - 240px) !important",
        minHeight: "calc(100vh - 240px) !important",
      },
    },

    dropDownInput: {
      fontSize: "13px !important",
      color: "black",
      "&$focused $notchedOutline": {
        color: "white",
        border: "none",
      },
      "& .MuiInputBase-input": {
        fontSize: "12px !important",
      },
    },
    dropdownPaper: {
      border: "1px solid lightgray",
      fontSize: "12px !important",
      marginTop: "0px",
    },
    autoCompleteFont: {
      fontSize: "12px !important",
      padding: "3px 12px !important",
    },
    dropDownStyle: {
      borderRadius: 5,
      backgroundColor: "white !important",
      fontSize: "12px !important",
      "& .MuiInputBase-input": {
        fontSize: "12px !important",
      },
    },
    contentStyle:{
      textAlign:"center",
      marginTop: "9rem !important", 
      marginBottom: "1rem !important",
    },
    isActiveKeyiconStyle:{
    fontSize: "15px !important",
    cursor: "pointer !important",
    color:  "#ffae42 !important",
    transform: "rotate(90deg) !important"
  },
  isInActiveKeyiconStyle:{
    fontSize: "15px !important",
    cursor: "pointer !important",
    color:  "gray !important",
    transform: "none !important",
  },
  isActiveTextFieldStyle:{
    border:'1px solid green !important',
    borderRadius:'5px !important' ,
    backgroundColor:'honeydew !important',
  },
  isInActiveTextFieldStyle:{
    border:'none !important',
    borderRadius:'5px !important',
    backgroundColor:'transparent !important',
  },
  fabStyle: {
    marginLeft: "11px !important",
    height: "31px !important",
    width: "31px !important",
  },
  dataRulesPaper: {
    padding: "9px",
    margin: "1px 0px",
    backgroundColor: "honeydew",
    width: "118px",
    height: "40px",
    border: "2px solid green"
  },
  selectedButton: {
    backgroundColor: "#2a3944 !important",
    color: "white !important",
  },
  normalButton: {
    backgroundColor: "transparent !important",
    color: "black !important",
  }
  })
);

export default useStyles;
