/* eslint-disable @typescript-eslint/no-unused-vars */
//MUI Components
import { Grid,IconButton,InputAdornment,Typography,Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Box } from "@mui/material";
import { useState } from "react";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@mui/icons-material/Close";
//CSS
import useStyles from "./ManageTemplateCss";
//Interface
import { IAgentTableRow } from "../../Model/AgentActivityTemplate/IAgentTableRow";
//Components
import FormTemplate from "./FormTemplate";
import ClientDetail from "./ClientDetail";
//Constant
import CreateFormTemplate from "./CreateFormTemplate";


const ManageActivityTemplate = () => {
  const classes = useStyles();
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchClient, setsearchClient] = useState<string>("");
  const [searchTemplate, setsearchTemplate] = useState<string>("");
  const [activityEnable, setActivityEnable] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IAgentTableRow>();


  const handleClickActivity = (activityEnable) => {
    // Handle the flag passed from the child component
    setActivityEnable(activityEnable);
  };
  const handleRowClick = (rowData: IAgentTableRow) => {
    setSelectedRow(rowData);
  };

  return (
    <div>
      <Box p={1}>
        <Grid component={Paper} container spacing={2} className={classes.pageContainer}>
          <Grid item xs={2}>
          <Typography
              variant="body2"
              className={classes.pageHeader}
              color="primary"
            >
              <b>Manage Template</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
          <TextField
              fullWidth
              size="small"
              id="standard-bare"
              variant="outlined"
              value={searchTemplate}
              className={classes.searchBox}
              type="text"
              placeholder="Search Client"
              onChange={(e) => setsearchTemplate(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && searchTemplate !== "") {
                  setShowSearchIcon(false);
                } else if (event.key === "Enter" && searchTemplate === "") {
                  setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ? (
                      <IconButton
                        onClick={(e) => {
                          setShowSearchIcon(false);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton style={{ padding: "3px" }}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              fullWidth
              size="small"
              id="standard-bare"
              variant="outlined"
              value={searchClient}
              className={classes.searchBox}
              type="text"
              placeholder="Search Template"
              onChange={(e) => setsearchClient(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && searchClient !== "") {
                  setShowSearchIcon(false);
                } else if (event.key === "Enter" && searchClient === "") {
                  setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ? (
                      <IconButton
                        onClick={(e) => {
                          setShowSearchIcon(false);
                        }}
                        style={{ padding: "3px" }}
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton style={{ padding: "3px" }}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2} style={{display:"flex",justifyContent:"flex-end"}}>
          <CreateFormTemplate/>
          </Grid>
        </Grid>
      </Box>
      <Box m={0}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <ClientDetail
            onLoadData={handleRowClick}
            activityEnable={handleClickActivity}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTemplate />
        </Grid>
      </Grid>
      </Box>
    </div>
    
  );
};
export default ManageActivityTemplate;