import { Box } from '@mui/material'

const Archieved = () => {
  return (
    <Box p={1} m={1}>
      <div>Archieved</div>
    </Box>
  )
}

export default Archieved