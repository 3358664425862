/* eslint-disable @typescript-eslint/no-unused-vars */
import Grid from "@mui/material/Grid";
import { Typography, DialogContent, DialogActions, } from "@mui/material";
import useStyles from "../../Setting/Connection_configuration/ConfigurationCss";
import { useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Button, Snackbar } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import BDebtCardContent from "../../../GlobalControl/Card/BDebtCardContent";
import { categories } from "../../../MockData/Configuration/Logo";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import BDebtButton from "../../../GlobalControl/BDebtButton";
import Autocomplete from "@mui/material/Autocomplete";
import { IConnectorType, ICreateData } from "../../../Model/Configuration";
import { Category } from "../../../MockData/Pipeline/Pipeline";
import api from "../../../ApiConfiguration/Configuration";
import { Box } from "@mui/system";

const CreateDataSource: React.FC<{ onCreate: () => void }> = (props: { onCreate: () => void; }) => {

    let AddData: ICreateData = {
        connectorType: "",
        connectorName: "",
        createdBy: "",
        connectionString: "",

    };

    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [localState, setLocalState] = useState(AddData);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setLocalState(AddData);
    };

    const handleChange = (e: any) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value,
        });
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const handleConfiguration = async () => {
        await api.post("TestDatabaseConnection", localState);
        setOpenSnackbar(true);
        props.onCreate();
        setLocalState(AddData);
        props.onCreate();
        closeDialog();

    };

    const handleUserChange = (event: any, newValue: IConnectorType) => {
        if (newValue) {
            setLocalState({
                ...localState,
                connectorType: newValue.Name,
            });
        }
    };

    return (
        <Grid>
          <BDebtButton
            variant="contained"
            size="small"
            color="primary"
            startIcon={<GroupAddIcon />}
            onClick={openDialog}
          >
            Add DataSource
          </BDebtButton>
          <BDebtDialog
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={isDialogOpen}
            maxWidth={"md"}
            fullWidth
          >
            <BDebtDialogTitleHeader
              onClose={closeDialog}
              id="customized-dialog-title"
            >
              CONFIGURATION
            </BDebtDialogTitleHeader>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={500}
                    autoHeightMax={500}
                    thumbMinSize={540}
                    universal={true}
                  >
                    {categories.map((category, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography className={classes.typograpy}>
                          <b>{category.title}</b>
                        </Typography>
                        <Box p={1} m={1}>
                        <Grid container spacing={1}>
                          {category.data.map((item, itemIndex) => (
                            <Grid item xs={4} key={itemIndex}>
                              <BDebtCard>
                                <BDebtCardContent textAlign="center">
                                  <img
                                    src={item.src}
                                    alt={item.alt}
                                    className={classes.iconstyle}
                                  />
                                </BDebtCardContent>
                                <Typography textAlign={"center"} fontSize={"11px"}>
                                  {" "}
                                  {item.name}
                                </Typography>
                              </BDebtCard>
                            </Grid>
                          ))}
                        </Grid>
                        </Box>
                      </Grid>
                    ))}
                  </Scrollbars>
                </Grid>
                <Grid item xs={6}>
                  <Box p={1} m={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          placeholder="ConnectorType"
                          options={Category}
                          onChange={handleUserChange}
                          getOptionLabel={(option) => option.Name}
                          classes={{
                            paper: classes.dropdownPaper,
                            option: classes.autoCompleteFont,
                          }}
                          renderInput={(params) => (
                            <BDebtTextField
                              {...params}
                              className={classes.dropDownStyle}
                              variant="outlined"
                              label="ConnectorType"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BDebtTextField
                          variant="outlined"
                          fullWidth
                          label="Connector Name"
                          name="connectorName"
                          onChange={handleChange}
                          size="small"
                        />
                      </Grid>
    
                      <Grid item xs={12}>
                        <BDebtTextField
                          size="small"
                          fullWidth
                          label="CreatedBy"
                          onChange={handleChange}
                          name="createdBy"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <BDebtTextField
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          label="Connection String"
                          name="connectionString"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <BDebtButton
                variant="contained"
                color="success"
                size="small"
                onClick={handleConfiguration}
              >
                Connect
              </BDebtButton>
              <BDebtButton
                variant="contained"
                color="error"
                size="small"
                onClick={closeDialog}
              >
                Close
              </BDebtButton>
            </DialogActions>
          </BDebtDialog>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              Database Connected successfully!
            </MuiAlert>
          </Snackbar>
        </Grid>
      );
    };
    
    export default CreateDataSource;
    