/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
//Controls
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import { Box } from "@mui/system";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtCardContent from "../../../GlobalControl/Card/BDebtCardContent";
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import { Typography, DialogContent, DialogActions} from "@mui/material";
//Module
import ConnectionForm from "./ConnectionForm";
import { IGetDataDetails } from "../../../Model/Configuration";
//Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { categories } from "../../../MockData/Configuration/Logo";
//CSS
import useStyles from "../../Setting/Connection_configuration/ConfigurationCss";
import { useFetch, usePost } from "../../../utils/apiHelper";
import { AxiosResponse } from 'axios';
import Scrollbars from "react-custom-scrollbars";
interface ITestConnectionResponse {
    success: boolean;
    message: string;
}

const AddMapTargetConfiguration: React.FC<{ onSave: () => void }> = (props) => {
    const { onSave } = props;
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [connectorName, setConnectorName] = useState("");
    const [connectionString, setConnectionString] = useState("");
    const [connectorType, setConnectorType] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [SourceDataDetails, SetSourceDataDetails] = useState<IGetDataDetails[]>([]);
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [buttonText, setButtonText] = useState("Test Connection");
    const [buttonColor, setButtonColor] = useState("primary");

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        GetdataSourceDetails();
    }, []);

    const handleItemClick = (categoryName: string, itemName: string) => {
        console.log("Clicked item:", categoryName, itemName);
        setSelectedCategory(categoryName);
        setSelectedItem(itemName);
    };

    const GetdataSourceDetails = async () => {
        await useFetch<any>("TargetMapping/GetTargetMappingDetail").then((response) => {
            SetSourceDataDetails(response.data);
        });
    };

    const handleTestConnection = () => {
        usePost<ITestConnectionResponse>("Sql/TestDatabaseConnection", {
            connectorType,
            connectorName,
            connectionString,
        })
            .then((response: AxiosResponse<ITestConnectionResponse>) => {
                const responseData = response.data;
                if (responseData.success) {
                    setSnackbarMessage(responseData.message);
                    setSnackbarSeverity("success");
                    setButtonText("Connection Successful");
                    setButtonColor("success");
                    onSave();
                } else {
                    setSnackbarMessage(responseData.message);
                    setSnackbarSeverity("error");
                    setButtonText("Connection Failed");
                    setButtonColor("error");
                }
            })
            .catch((error) => {
                setSnackbarMessage("Failed to test database connection");
                setSnackbarSeverity("error");
                setButtonText("Connection Failed");
                setButtonColor("error");
            });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        switch (name) {
            case 'connectorType':
                setConnectorType(value);
                break;
            case 'connectorName':
                setConnectorName(value);
                break;
            case 'connectionString':
                setConnectionString(value);
                break;
            default:
                break;
        }
    };


    return (
        <Grid>
            <BDebtTooltip title="Add New" arrow placement="top-end">
                <AddCircleOutlineIcon onClick={openDialog} />
            </BDebtTooltip>
            <BDebtDialog
                onClose={closeDialog}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                maxWidth={"md"}
                fullWidth
            >
                <BDebtDialogTitleHeader
                    onClose={closeDialog}
                    id="customized-dialog-title"
                >
                    CONFIGURATION
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} borderRight={"1px solid lightgrey"}>
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                autoHeight
                                autoHeightMin={500}
                                autoHeightMax={500}
                                thumbMinSize={540}
                                universal={true}
                            >
                                {categories.map((category, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Typography className={classes.typograpy}>
                                            <b>{category.title}</b>
                                        </Typography>
                                        <Box p={1} m={1}>
                                            <Grid container spacing={1}>
                                                {category.data.map((item, itemIndex) => (
                                                    <Grid item xs={4} key={itemIndex}>
                                                        <BDebtCard onClick={() => handleItemClick(category.title, item.name)} className={
                                                            selectedCategory === category.title && selectedItem === item.name
                                                                ? classes.selectedCard // Apply custom selectedCard class when selected
                                                                : classes.card // Default card class
                                                        }>
                                                            <BDebtCardContent textAlign="center">
                                                                <img
                                                                    src={item.src}
                                                                    alt={item.alt}
                                                                    className={classes.iconstyle}
                                                                />
                                                            </BDebtCardContent>
                                                            <Typography textAlign={"center"} fontSize={"11px"}>
                                                                {" "}
                                                                {item.name}
                                                            </Typography>
                                                        </BDebtCard>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                            </Scrollbars>
                        </Grid>
                        <Grid item xs={6}>
                            {selectedCategory && selectedItem ? (
                                selectedCategory === "Relational" && selectedItem === "Sql" ? (
                                    <ConnectionForm
                                        handleChange={handleChange}
                                        handleTestConnection={handleTestConnection}
                                        snackbarMessage={snackbarMessage}
                                        snackbarSeverity={snackbarSeverity}
                                    />
                                ) : (
                                    <Box textAlign="center" justifyContent="center" alignItems="center" display="flex">
                                        <Typography>Please select the server</Typography>
                                    </Box>
                                )
                            ) : (
                                <Box textAlign="center" justifyContent="center" alignItems="center" display="flex">
                                    <Typography>Please select the server</Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ borderTop: "1px dashed lightgrey" }}>
                <BDebtButton
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={closeDialog}
                    >
                        Save
                    </BDebtButton>
                    <BDebtButton
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={closeDialog}
                    >
                        Close
                    </BDebtButton>
                </DialogActions>
            </BDebtDialog>
        </Grid>
    );
};

export default AddMapTargetConfiguration;
