/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Typography } from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Dialog, DialogContent, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "../../Inquiry/DebtorsCss";

const SearchCollectDetails = () => {
  const classes = useStyles();
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState<string>("");
  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const handleHelpClick = () => {
    setShowVideoDialog(true);
  };

  const handleCloseVideoDialog = () => {
    setShowVideoDialog(false);
  };

  return (
    <div>
      <Box p={1} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2} md={1}>
            <Typography variant="body2" className={classes.pageHeader} color="primary"><b>Collect</b></Typography>
          </Grid>
          <Grid item xs={2} md={8}>
            <TextField
              fullWidth
              size="small"
              id="standard-bare"
              variant="outlined"
              value={searchDebtor}
              className={classes.searchBox}
              type='text'
              placeholder="Search All"
              onChange={(e) => setSearchDebtor(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && searchDebtor !== "") {
                  setShowSearchIcon(false);
                } else if (event.key === "Enter" && searchDebtor === "") {
                  setShowSearchIcon(true);
                }
              }}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { setShowSearchIcon(false); }} style={{ padding: '3px' }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton style={{ padding: '3px' }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} className={classes.gridDisplay}>
            <Button
              size="small"
              color="primary"
              variant="contained"
            >
              0 Left
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
            >
              Collect ALL
            </Button>
            <IconButton size="small" className={classes.iconButton}>
              <DownloadIcon color="inherit" />
            </IconButton>
            <IconButton onClick={handleHelpClick} size="small" className={classes.iconButton}>
              <LiveHelpIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={showVideoDialog} onClose={handleCloseVideoDialog}>
        <BDebtDialogTitleHeader id="" onClose={handleCloseVideoDialog}>Help Video</BDebtDialogTitleHeader>
        <DialogContent>
          <video width="100%" height="400" controls>
            <source src="your-video.mp4" type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default SearchCollectDetails;

