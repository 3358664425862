import { Box } from '@mui/material'
import React from 'react'

const NotificationsLayout = () => {
    return (
        <Box m={1} p={1} bgcolor={'blue'}>
            NotificationsLayout
        </Box>
    )
}

export default NotificationsLayout