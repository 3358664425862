/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
//Controls
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogContent from "../../../GlobalControl/DialogBox/BDebtDialogContent";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtAccordion from "../../../GlobalControl/BDebtAccordion";
import BDebtAccordionSummary from "../../../GlobalControl/BDebtAccordionSummary";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import { DialogActions, Grid, Checkbox, Typography, AccordionDetails, Box} from "@mui/material";
//CSS
import useStyles from "../PlacementCss";
//Scroll
import Scrollbars from "react-custom-scrollbars";
//MockData
import { GetDataSet } from "../../../MockData/Placement/DataRuleMock";
//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import databaseImage from '../../../asset/image/database2.svg';
import { usePost } from "../../../utils/apiHelper";

interface DataSetProps {
  selectedClientCode: string;
  onSave: (dataset: GetDataSet) => void;
  dataSetId: number;
}

const DataSet: React.FC<DataSetProps> = (props) => {
  const classes = useStyles();
  const { selectedClientCode, onSave, dataSetId } = props;
  const [getField, setGetField] = useState<GetDataSet[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(`panel_${dataSetId}`);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    handleGetDataSetFieldRequest(selectedClientCode);
  }, [selectedClientCode]);

  const handleGetDataSetFieldRequest = async (selectedClientCode: string) => {
    let request = {
      "clientCode": selectedClientCode,
      "dataSetId": null
    };
    const response = await usePost<GetDataSet[]>("Dataset/GetDataSetField", request);
    setGetField(response.data);
  };

  const handleImageClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setExpandedPanel(`panel_${dataSetId}`);
  };

  const handleSaveClick = () => {
    const selectedDataset = getField.find(
      (dataset) => expandedPanel === `panel_${dataset?.dataSetId}`
    );
    onSave(selectedDataset);
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Box m={0} onClick={handleImageClick} className={classes.DatabaseIcon}>
        <BDebtTooltip title="Dataset">
          <img src={databaseImage} alt="Database" width="20px" height="20px" />
        </BDebtTooltip>
      </Box>
      <BDebtDialog maxWidth={"sm"} open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <BDebtDialogTitleHeader onClose={handleCloseDialog} id="customized-dialog-title">
          Dataset
        </BDebtDialogTitleHeader>
        <BDebtDialogContent>
          {getField.map((dataset) => (
            <BDebtAccordion key={dataset?.dataSetId} expanded={expandedPanel === `panel_${dataset?.dataSetId}`} onChange={handleChange(`panel_${dataset?.dataSetId}`)} className={classes.customAccordion}>
              <BDebtAccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${dataset?.dataSetId}-content`} id={`panel${dataset?.dataSetId + 1}-header`}
                className={`${expandedPanel === `panel_${dataset?.dataSetId}` ? classes.datasetActiveAccordion : classes.datasetInActiveAccordion}`} >
                <Grid container spacing={0}>
                  <Grid item xs={1}>
                    <Checkbox color="primary" size="small" sx={{ padding: 0 }} checked={expandedPanel === `panel_${dataset?.dataSetId}`}
                      onChange={handleChange(`panel_${dataset?.dataSetId}`)} inputProps={{ 'aria-label': 'Select checkbox' }} />
                  </Grid>
                  <Grid item xs={11}>
                    {dataset.dataSetName && (
                      <Typography variant="body2" color={`${expandedPanel === `panel_${dataset?.dataSetId}` ? "black" : "inherit"}`}>
                        {dataset.dataSetName}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </BDebtAccordionSummary>
              <AccordionDetails className={classes.accordionDetails} >
                <Grid container spacing={0}>
                  <Grid item xs={12} bgcolor={'rgb(59, 74, 84)'} >
                    <Grid container spacing={0} p={"4px 8px"}>
                      <Grid item xs={9}>
                        <Typography variant="body2" color="white" >Fields</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" color="white" >Type</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} >
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                      thumbMinSize={30}
                      universal={true}
                    >
                      {dataset.fieldsDetail.map((field, fieldIndex) => (
                        <Grid container spacing={0} p={1} key={fieldIndex} border={'1px solid lightgray'}>
                          <Grid item xs={9}>
                            <Typography variant="body2" color="black" >{field.fieldName}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2" color="black" >{field.dataType}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Scrollbars>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </BDebtAccordion>
          ))}
        </BDebtDialogContent>
        <DialogActions>
          <Grid container spacing={0}>
            <Grid item xs={12} display=" flex" justifyContent="end">
              <BDebtButton variant="contained" color="success" size="small" sx={{ marginRight: "2px" }} onClick={handleSaveClick}>
                Save
              </BDebtButton>
              <BDebtButton
                variant="contained"
                color="error"
                size="small"
                onClick={handleCloseDialog}
              >
                Close
              </BDebtButton>
            </Grid>
          </Grid>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
};

export default DataSet;
