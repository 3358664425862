import React, { useState, useEffect } from "react";
import { GridColDef } from '@mui/x-data-grid';
import { Mapping } from "../../../Model/placement/PlacementGridItem";
import { Box } from "@material-ui/core";
import useStyles from "../PlacementCss";
import StyledDataGrid from './StyledDataGrid';
import CustomPagination from "./CustomPagination";

interface DisplayFieldProps {
  selectedFields: string[];
  uploadedData: any[];
  selectedCard: { title: string; mappedField: string };
  fieldMappings: Mapping[];
  selectedAutocompleteField: string; // FutureEnhacement
}

const DisplayField: React.FC<DisplayFieldProps> = (props) => {
  const { selectedFields, uploadedData, selectedCard, fieldMappings, selectedAutocompleteField } = props;
  const classes = useStyles();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [defaultSelectedCard, setDefaultSelectedCard] = useState<{ title: string; mappedField: string }>(null);

  useEffect(() => {
    if (selectedCard) {
      setDefaultSelectedCard(selectedCard);
    } else if (fieldMappings.length > 0) {
      setDefaultSelectedCard({ title: fieldMappings[0].title, mappedField: fieldMappings[0].mappedField });
    }
  }, [selectedCard, fieldMappings]);

  useEffect(() => {
    let newColumns: GridColDef[] = [];
    // Add columns for selected fields
    newColumns = selectedFields.map((field, index) => ({
      field,
      headerName: field,
      key: { index },
      width: 150,
      align: "left",
      renderCell: (params) => (
        <Box
          className={`${classes.filtercolumnStyle} ${field === defaultSelectedCard?.title && classes.selectedCardColumn}`}
        >
          {params.value}
        </Box>
      ),
    }));

    // Check if defaultSelectedCard should be displayed
    if (defaultSelectedCard) {
      // Display column for the defaultSelectedCard only if it's different from selectedFields
      if (!selectedFields.includes(defaultSelectedCard.title)) {
        const cardColumn: GridColDef = {
          field: defaultSelectedCard.title,
          headerName: defaultSelectedCard.title,
          width: 150,
          align: "left",
          renderCell: (params) => (
            <Box
              className={`${classes.filtercolumnStyle} ${selectedFields.includes(defaultSelectedCard.title) && classes.selectedCardColumn}`}
            >
              {params.value}
            </Box>
          ),
        };
        newColumns.push(cardColumn);
      }
    }

    setColumns(newColumns);
  }, [defaultSelectedCard, selectedFields, classes.filtercolumnStyle, classes.selectedCardColumn]);

  useEffect(() => {
    // Update rows based on selected card, uploaded data, selected fields, field mappings, and selected autocomplete field
    const newRows = uploadedData.map((record, index) => {
      const baseRecord: Record<string, any> = {
        id: index + 1,
      };
  
      // Include values from selectedFields
      selectedFields.forEach((field) => {
        baseRecord[field] = record[field];
      });
  
      // Include values from selectedCard or selectedAutocompleteField
      if (selectedCard) {
        baseRecord[selectedCard.title] = record[selectedCard.mappedField];
      } else if (selectedAutocompleteField) {
        const mapping = fieldMappings.find((mapping) => mapping.title === selectedAutocompleteField);
        if (mapping) {
          baseRecord[mapping.title] = record[mapping.mappedField];
        }
      }
  
      return baseRecord;
    });
  
    setRows(newRows);
  }, [selectedCard, uploadedData, selectedFields, fieldMappings, selectedAutocompleteField]);
  
  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      rowHeight={30}
      className={classes.ColumnFieldGrid}
      components={{
        Pagination: (paginationProps) => (
          <CustomPagination {...paginationProps} rows={rows} />
        ),
      }}
    />
  );
};

export default DisplayField;
