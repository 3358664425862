import Alert, { AlertProps } from "@mui/material/Alert";
import { styled } from "@mui/system";

const BDebtAlert= styled(Alert)<AlertProps>(({ theme }) => ({
  color: "#333", // Change the text color
  borderRadius: "5px", // Apply theme's border radius
  boxShadow: "theme.shadows[3]", // Apply a box shadow
}));

export default BDebtAlert;
