//MUI Components
import {Button,DialogActions,DialogContent,Grid} from "@material-ui/core";
import { useState } from "react";
//Custom Css
import BDebtDialog from "../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "./ManageTemplateCss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../GlobalControl/BDebtTextField";

const CreateFormTemplate = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleCheckBoxChange = (index: number) => {
  //   // Check if the checkbox is already selected
  //   const isSelected = selectedCheckboxes.includes(index);

  //   if (isSelected) {
  //     // If selected, remove it from the array
  //     setSelectedCheckboxes(selectedCheckboxes.filter((i) => i !== index));
  //   } else {
  //     // If not selected, add it to the array
  //     setSelectedCheckboxes([...selectedCheckboxes, index]);
  //   }
  // };

  return (
    <div>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        style={{ fontSize: 12 }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp; ADD FORM
      </Button>
      <BDebtDialog
        open={open}
        maxWidth={"lg"}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={handleClose}
        >
          <BDebtTypography variant="h6">CREATE FORM TEMPLATE</BDebtTypography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <BDebtTextField
                id="outlined-basic"
                size="small"
                label="Enter Template Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <BDebtTypography variant="h4">
              <b>Customize Form</b>
              </BDebtTypography> 
              </Grid>
              {/* <TableContainer className={`${"scrollbox"} ${"on-scrollbar"}`}>
                {ActivityList?.map((r, index) => {
                  return (
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value={`${r?.id}`}
                        control={
                          <BDebtCheckbox size="small" name={`${r?.activity}`} />
                        }
                        label={`${r?.activity}`}
                        labelPlacement="end"
                        checked={selectedCheckboxes.includes(index)}
                        onChange={() => handleCheckBoxChange(index)}
                        className={
                          selectedCheckboxes.includes(index)
                            ? classes.permittedChecked
                            : classes.permittedFiled
                        }
                      />
                    </FormControl>
                  );
                })}
              </TableContainer>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.yesbutton}>
            SAVE
          </Button>
          <Button onClick={handleClose} className={classes.nobutton}>
            CANCEL
          </Button>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default CreateFormTemplate;
