import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/lab/Autocomplete';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, DialogActions, DialogContent, FormControl,
    FormControlLabel, Grid, TableContainer, TextField, Typography
} from '@mui/material';
import { useState } from 'react';
import BDebtTextField from '../../../GlobalControl/BDebtTextField';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import AgentGroupData from "../../../MockData/AgentActivity.json";
import useStyles from '../DebtorsCss';

const GroupName = [
    { title: 'Phone' },
    { title: 'Email ' },
    { title: 'Text' },
    { title: 'Letter' },
    { title: 'Default' },
    { title: 'Dispute' }
];

const AgentActivity = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [selectedCheckbox, setSelectedCheckbox] = useState<number>(0);

    const handleCheckBoxChange = (index: number) => {
        setSelectedCheckbox(index);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size="small" sx={{ fontSize: 10 }} fullWidth startIcon={<ChecklistIcon />} onClick={handleClickOpen}>Activity</Button>

            <BDebtDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xl'}
            >
                <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleClickClose}>
                    <Typography variant="body1">
                        <b>AGENT ACTIVITY</b>
                    </Typography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1} sx={{ paddingTop: 2 }}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={GroupName}
                                size="small"
                                sx={{ fontSize: 10 }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.title}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                            className={classes.checkBox}
                                        />
                                        {option.title}
                                    </li>
                                )}
                                classes={{
                                    paper: classes.dropdownPaper,
                                    option: classes.autoCompleteFont
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        // label="Group Name"
                                        placeholder="Choose Group Name"
                                        variant="outlined"
                                        // className={classes.dropDownStyle}
                                        InputProps={{
                                            ...params.InputProps, disableUnderline: true,
                                            classes: {
                                                root: classes.dropDownInput
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={0} >
                                <TableContainer className={`${classes.DocumentContainer} `}>
                                    <Typography variant="body2" className={classes.UploadTextStyle} ><b>PHONE</b></Typography>
                                    <Grid container spacing={0} sx={{ padding: '8px' }}>
                                        <Grid item xs={9} sx={{ borderRight: '1px dashed gray' }}>
                                            {AgentGroupData?.map((r, index) => {
                                                return (
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            value={`${r?.id}`}
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    name={`${r?.activity}`}
                                                                    className={classes.checkBox}
                                                                />
                                                            }
                                                            label={<span className={classes.fontSize}>{r?.activity}</span>}
                                                            labelPlacement="end"
                                                            checked={selectedCheckbox === index}
                                                            onChange={() => handleCheckBoxChange(index)}
                                                            className={
                                                                selectedCheckbox === index
                                                                    ? classes.permittedChecked
                                                                    : classes.permittedFiled
                                                            }
                                                        />
                                                    </FormControl>
                                                );
                                            })}
                                        </Grid>
                                        <Grid item xs={3} sx={{ padding: '0px 13px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        className={classes.checkBox}
                                                    />
                                                }
                                                label={<span className={classes.fontSize}>Equipment List Incorrect</span>}
                                                labelPlacement="end"
                                                className={classes.permittedFiled}
                                            />
                                            <Box m={0.5}>
                                                <Accordion className={classes.accordion}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon className={classes.expandStyle} />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.summaryStyle}
                                                    >
                                                        <Typography className={classes.expandStyle}>Click to Expand</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <BDebtTextField
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Name"
                                                            size='small'
                                                            className={classes.closeAccountControl}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ borderTop: '1px dashed gray' }}>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='primary' sx={{ fontSize: 12 }}>
                        Submit
                    </Button>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
                        Close
                    </Button>
                </DialogActions>
            </BDebtDialog >
        </div >
    )
}

export default AgentActivity