/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import BDebtTextField from '../../GlobalControl/BDebtTextField';
import useStyles from "../../Components/DataPipeline/DataPipelineCss";
import { Field, IGetDataDetails, IGetTableSchemaResponse, ITableList } from '../../Model/Configuration';
import api from '../../ApiConfiguration/Configuration';
import { options } from '../../MockData/Pipeline/Pipeline';

const Destination: React.FC<{ destinationSchema: (data: Field[], connection: string, tableName: string) => void }> = (props ) => {
  const {destinationSchema} = props;
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('Tables');
  const [connection, setconnection] = useState();
  const [value, setValue] = useState<ITableList[]>([]);
  const [DestinationDataDetails, SetDestinationDataDetails] = useState<IGetDataDetails[]>([]);

  useEffect(() => {
    GetdataSourceDetails();
  }, []);

  const GetdataSourceDetails = async () => {
    await api.get("GetAllDatasourceDetails").then((response) => {
      SetDestinationDataDetails(response.data);
    });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleConnectorChange = (event, newValue) => {
    DestinationDataDetails.find((detail) => detail.connectorName === newValue);
    api.post('SqlTableListAsync', {
      connectionString: newValue.connectionString,
    }).then((response) => {
      setValue(response.data);
    });
    setconnection(newValue.connectionString);
  };

  const handleTableChange = (event, newValue) => {
    value.find(
      (detail) => detail.tableList === newValue
    );
    let request = {
      "connectionString": connection,
      "tableName": newValue.tableList
    }
    api.post<IGetTableSchemaResponse[]>('GetTableSchemaAsync', request).then((response) => {
      destinationSchema(response.data, connection, newValue.tableList);
    });
  };

  return (
    <React.Fragment>
      < div className={classes.div}  >
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} className={classes.padding}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    <b>Choose Destination</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size='small'
                    options={DestinationDataDetails}
                    fullWidth
                    getOptionLabel={(option) => option.connectorType}
                    renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label="Connector Type" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size='small'
                    options={DestinationDataDetails}
                    fullWidth
                    getOptionLabel={(option) => option.connectorName}
                    renderInput={(params) => (
                      <BDebtTextField
                        type='outlined'
                        size='small'
                        {...params}
                        label='Connector Name'
                      />
                    )}
                    onChange={handleConnectorChange}
                  />
                </Grid>
                <Grid xs={12} className={classes.RadioButton}>

                  <RadioGroup
                    row
                    aria-label="Options"
                    name="options"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size='small'
                    options={value}
                    getOptionLabel={(option) => option.tableList}
                    onChange={handleTableChange}
                    fullWidth
                    renderInput={(params) => <BDebtTextField type='outlined' size='small' {...params} label={selectedOption} />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div >
      </div >
    </React.Fragment >
  );
};

export default Destination;
