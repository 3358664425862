import { Dialog, Theme, styled } from '@mui/material';

const BDebtDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== 'customStyle',
})(
    ({ theme, customStyle }: { theme: Theme; customStyle?: React.CSSProperties }) => ({
        '& .MuiDialog-paper': {
            borderRadius: '10px', // Customize the dialog paper (content area)
            ...(customStyle || {
            }), // Apply custom styles if provided
        },
        '.MuiDialog-container': {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(145, 158, 171, 0.24)"
        }
    })
);

export default BDebtDialog;