import React, { useState } from "react";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import BDebtButton from "../../../../GlobalControl/BDebtButton";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import BDebtDialog from "../../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import Predefine from "../../../../MockData/PredefineWidget.json";
import HorizontalNonLinearStepper from "./ConfigurationWidget/StepperPage";
import { Grid } from "@mui/material";
import BDebtTypography from "../../../../GlobalControl/BDebtTypography";


export default function AddWidget() {
  const [open, setOpen] = useState(false);
  // const [selectedDataSource, setSelectedDataSource] =useState(null);
  const [stepper, setStepper] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const dataSources = [
    "File",
    "Database",
    "Power BI",
    "Azure",
    "OneDrive",
    "Online",
    "Others",
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleDataSourceChange = (newValue) => {
    setStepper(true);
    // setSelectedDataSource(newValue);
  };
  const handleWidgetChange = (newValue) => {
    // setSelectedDataSource(newValue);
  };


  return (
    <React.Fragment>
      <BDebtButton
        variant="contained"
        size="small"
        color="primary"
        onClick={handleClickOpen}
      >
        <AddchartOutlinedIcon />
        Add Widgets
      </BDebtButton>
      <BDebtDialog
        maxWidth={"lg"}
        fullWidth
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BDebtDialogTitleHeader id={"widget"} onClose={handleClose}>
          <BDebtTypography variant="h6">ADD CUSTOM WIDGET</BDebtTypography>  
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={1} mt={"6px"} paddingLeft={"2px"}>
            <Grid item xs={4}>
              <BDebtTextField
                size="small"
                fullWidth
                autoFocus
                margin="dense"
                id="widgetName"
                label="Widget Name"
                type="text"
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                size="small"
                options={Predefine.map((title) => [title.title])}
                onChange={handleWidgetChange}
                renderInput={(params) => (
                  <BDebtTextField
                    {...params}
                    margin="dense"
                    label="Choose Default Widget"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                size="small"
                fullWidth
                options={dataSources}
                getOptionLabel={(option) => option}
                onChange={handleDataSourceChange}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <BDebtTextField {...params}
                    margin="dense" label="Data Source" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {stepper ? (<Grid item xs={12}>
              <HorizontalNonLinearStepper />
            </Grid>) : null}
          </Grid>
        </DialogContent>
      </BDebtDialog>
    </React.Fragment>
  );
}
