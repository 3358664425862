/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAPIError } from '../Model/IApiError';
import { AxiosResponse } from 'axios';
import { ErrorConstants } from '../Constant/ErrorConstants';
import { instance } from './axisoInstance';
 
export function parseApiResponse<T>(
  apiCall: Promise<AxiosResponse<any>>,
  resolve: (value: AxiosResponse<T> | PromiseLike<AxiosResponse<T>>) => void,
  reject: any
) {
  apiCall
    .then((res) => {
      return res
    })
    .then((body) => {
      if (body.status === 200 || body.status === 202) {
        resolve(body)
      } else {
        const unhandledError: IAPIError = {
          code: ErrorConstants.UnhandledError.Code,
          message: ErrorConstants.UnhandledError.Message,
        }
        reject('error')
      }
    })
    .catch((err) => {
      reject(err)
    })
}
 
export async function
  useFetch<T>(
    requestUrl: string
  ): Promise<AxiosResponse<T>> {
 
  var promise = instance.get(requestUrl, {})
 
  return new Promise<AxiosResponse<T>>((resolve1, reject1) => {
    parseApiResponse(promise, resolve1, reject1)
  })
}
 
export async function usePost<T>(
  requestUrl: string,
  requestData: any
): Promise<AxiosResponse<T>> {
  let promise = instance.post(requestUrl, requestData, {})

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject)
  })
}
 
export async function useDelete<T>(
  requestUrl: string
): Promise<AxiosResponse<T>> {
 
  let promise = instance.delete(requestUrl, {})
 
  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject)
  })
}