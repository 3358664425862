import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '5px'
    },
    GreenColor: {
      color: "green",
    },
    pageHeader: {
      marginTop: '10px',
      marginBottom: '8px',
      textTransform: 'uppercase',
      fontSize: 13
    },
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "5px !important",
      border: '2px solid #215176 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      }
      // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: '-2px !important',
      '&$focused $notchedOutline': {
        color: 'white !important',
        border: '0px !important'
      },
    },
    notchedOutline: {
      color: 'white !important',
      border: 'none !important',
      fontSize: "12px !important",
    },
    dropdownPaper: {
      border: "1px solid lightgray",
      fontSize: "12 !important",
      marginTop: '0px',
    },
    autoCompleteFont: {
      fontSize: "12px !important",
      padding: '2px 12px !important'
    },
    dropDownStyle: {
      marginTop: '2px !important',
      // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 5,
      border: '2px solid #215176 !important',
      backgroundColor: 'white !important',
      height: '35px !important',
      fontSize: "12px !important",
    },
    dropDownInput: {
      fontSize: "13px",
      color: "black",
      padding: '4px 30px 4px 10px !important',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    tableCellHeader: {
      padding: "12px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: 'bold'
    },
    tableCellStyle: {
      padding: "5px 8px !important",
      backgroundColor:"#f9fafb inherit!important",
      "& .MuiTableCell-root": {
        padding: "10px 15px!important",
        backgroundColor:"#f9fafb inherit!important",
      },
      fontSize: "12px !important",
    },
    tablebodyConainer: {
      borderBottom: '2px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 193px)',
        minHeight: 'calc( 100vh - 193px)',
      },
    },
    activeRow: {
      fontSize: "12px !important",
      color: '#283593 !important',
      backgroundColor:"white !important",
      fontWeight: 'bold',
      borderLeft:"3px solid #283593 !important",
    },
    infoGrid: {
      display: 'flex',
      borderBottom: '1px dashed lightgray',
      padding: 0.5
    },
    cardContent: {
      padding: '8px 10px !important'
    },
    infoStatus: {
      backgroundColor: 'rgb(237, 247, 237)',
      color: 'green',
      borderRadius: 5,
      padding: '4px 6px',
      fontSize: 10
    },
    infoFontSize: {
      fontSize: 13,
      // fontWeight:'bold'
    },
    infoFontSize1: {
      fontSize: 13,
      marginTop: '5px',
      // fontWeight:'bold'
    },
    selectedRow: {
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      backgroundColor: 'white'
    },
    inputField: {
      marginTop: '12px !important',
      fontSize: '12px !important',
      "& .MuiOutlinedInput-input": {
        padding: "8px 10px !important",
        backgroundColor: "#f2f3f4 !important",
        fontSize: '12px !important',
        marginTop: "-4px",
        "& fieldset": {
          borderColor: "black !important",
          width: '200px',
        },
        "& .MuiOutlinedInput-adornedStart": {
          paddingLeft: "14px",
          width: "130px",
        },
      },
    },
    accountHistoryConainer: {
      borderBottom: '2px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 238px)',
        minHeight: 'calc( 100vh - 238px)',
      },
    },
    accountHistoryHeader: {
      padding: "5px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: 'bold'
    },
    accountHistoryStyle: {
      padding: "7px 15px !important",
      "& .MuiTableCell-root": {
        padding: "7px 15px!important",
      },
      fontSize: "12px !important",
    },
    auditPaper: {
      padding: '8px 15px !important',
    },
    auditfontSize: {
      "&.MuiTypography-h6": {
        fontSize: '0.90rem !important'
      }
    },
    auditTextStyle: {
      "&.MuiTypography-body1": {
        fontSize: '0.75rem'
      }
    },
    auditHistoryRoot: {
      "&.MuiTimeline-root": {
        padding: '3px 16px !important'
      }
    },
    pagination: {
      padding: "8px",
      display: 'flex',
      justifyContent: 'center'
    },
    iconColor: {
      backgroundColor: '#3b4a54 !important'
    },
    closeAccountControl: {
      fontSize: 12,
      marginTop: '8px !important',
    },
    DropzoneArea: {
      minHeight: '190px !important',
      fontSize: '12px !important',
      border: '1px dashed blue !important',
      backgroundColor: '#f1f5ff !important',
      boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px !important',
      borderRadius: '5px'
    },
    DropzoneAreaText: {
      fontSize: '14px !important',
      fontWeight: 'bold',
      color: 'blue'
    },
    Note: {
      backgroundColor: 'rgba(118, 53, 220, 0.08)',
      color: 'rgb(32,10,105)',
      borderRadius: '5px',
      padding: '8px',
      // margin: '0px 0px 10px',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px !important'
    },
    paperStyle: {
      overflow: "hidden",
      borderRadius: 0,
      borderBottomLeftRadius: 4,
    },

    DocumentContainer: {
      backgroundColor: "#ffffff",
      padding: "8px 10px",
      // marginTop: "15px",
      borderRadius: "5px",
      overflowY: "auto",
      overflowX: "hidden",
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      filter: 'drop-shadow(0 -6mm 4mm gray))',
    },
    UploadTextStyle: {
      textAlign: 'center',
      marginTop: "5px",
      padding: '5px 0px',
      backgroundColor: 'rgba(16, 185, 129, 0.12)',
      color: 'rgb(11, 129, 90)',
      fontSize: 14,
      borderRadius: '5px'
    },
    paidLetter: {
      fontSize: "11px !important",
      marginTop: '8px !important',
      backgroundColor: '#3b4a54 !important'
    },
    checkBox: {
      color: "#008216 !important",
      fontSize: "11px !important",
      padding: '3px !important'
    },
    permittedChecked: {
      backgroundColor: 'rgba(16, 185, 129, 0.12)',
      color: 'rgb(11, 129, 90)',
      borderRadius: "5px",
      marginTop: "5px",
      width: "330px",
      fontWeight: "bold",
      fontSize: "11px !important",
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
      marginLeft: "0px",
      // whiteSpace:'nowrap !important'
    },
    permittedFiled: {
      backgroundColor: "white",
      color: "black",
      borderRadius: "5px",
      marginTop: "5px",
      marginLeft: "0px",
      width: "330px",
      fontSize: "11px !important",
      textOverflow: 'ellipsis !important',
      overflow: 'hidden !important',
    },
    fontSize: {
      fontSize: 13,
      "& .MuiFormLabel-root": {
        fontSize: "12px !important",
      },
      "& ..MuiFormControlLabel-label": {
        fontSize: "12px !important",
      }
    },
    settingIcon: {
      marginTop: '4px !important',
    },
    expandStyle: {
      color: "black",
      transition: "transform 0.2s",
      fontSize: 12,
      // marginLeft: theme.spacing(1),
      '& .MuiTypography-root': {
        fontSize: "12px !important"
      },
      '& .css-ahj2mt-MuiTypography-root': {
        fontSize: "12px !important"
      }
    },
    summaryStyle: {
      fontSize: 12,
      "&.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
        height: "40px !important",
        minHeight: "0px !important",
        backgroundColor: "#f1f5f8 !important",
        fontSize: 12
      },
    },
    accordion: {
      "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root": {
        minHeight: "35px !important",
      }
    },
    auditHistoryContainer: {
      borderBottom: "2px solid lightgray",
      maxHeight: '660px',
      minHeight: '660px',
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 180px)",
        minHeight: "calc( 100vh - 180px)",
      },
    },
    iconButton: {
      borderRadius: "5px !important",
      border: '2px solid #2a3944 !important',
      "&:hover": {
        backgroundColor: '#2a3944 !important',
        color: 'white'
      }
    },
    formControl: {
      marginLeft: "0px !important",
      marginRight: "0px !important",
      borderRadius: "5px !important",
      border: '2px solid #2a3944 !important',
      padding: '0px 5px'
    },
    gridDisplay: {
      display: 'flex !important',
      justifyContent: 'space-between !important',
      // padding: "5px !important"
    },
    removeText:
    {
      color: "red",
      textDecoration: 'line-through'
    },
    pageContainer: {
      boxShadow: " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important"
    },
    selectedColumn: {
      backgroundColor: '#f9fafb !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      flexDirection: 'column',
    },
    gridSearchbar: {
      borderBottom: '1px solid lightgray !important'
    },
    gridPayment: {
      borderRight: '1px solid lightgray !important'
    },
    appbarPosition: {
      backgroundColor: 'white !important',
      minHeight: "30px !important",
      padding: "0px !important"
    },
    tabRecords: {
      minHeight: "30px !important",
      fontSize: "12px !important",
      fontWeight: 'bold'
    },
    tabList: {
      minHeight: "30px !important",
      padding: "0px !important"
    },
  })
);

export default useStyles;