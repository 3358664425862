/* eslint-disable @typescript-eslint/no-unused-vars */
import { DialogContent, DialogTitle, Grid, IconButton, Paper } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Badge, Dialog, List, ListItem, Tab } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";
import Scrollbars from 'react-custom-scrollbars';
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import logo from '../../../asset/image/Login/Cog_Logo.png';
import useStyles from '../ProspectUser/ProspectUserCss';
import BillingInformation from "../ProspectUser/VerifyClient/BillingInformation";
import CollectionInformation from '../ProspectUser/VerifyClient/CollectionInformation';
import CompanyInformation from '../ProspectUser/VerifyClient/CompanyInformation';
import ContactInformation from '../ProspectUser/VerifyClient/ContactInformation';
import SendError from './VerifyClient/SendError';



const VerifyClient = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [value, setValue] = useState<string>('0');
  const [companyBranches, setCompanyBranches] = useState([]);
  const companyName = 'Company A';

  const handleTabChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <IconButton className={`${classes.VerifyClientIcon} ${classes.IconButtonStyle}`} onClick={openDialog}>
        <Badge overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<LooksOneIcon className={`${classes.horizontalLine} ${classes.BadgeIcon1}`} />}>
          <HowToRegIcon className={classes.icon} />
        </Badge>
      </IconButton>
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth={true}
      >
      <DialogTitle className={classes.headingdialog} >
          <Grid container spacing={0} className={classes.dialogdes}>
            <Grid item xs={2}>
              <BDebtTypography variant="h6" align="left" mt="10px">
                <img src={logo} className={classes.logo} alt="companylogo" />
              </BDebtTypography>
            </Grid>
            <Grid item xs={8} className={classes.dialogHeader}>
              <BDebtTypography variant="h6" >
                VERIFY CLIENT INFORMATION
              </BDebtTypography>
            </Grid>
            <Grid item xs={2} className={classes.DialogTitlebutton} >
               <SendError/>
              <BDebtButton variant="contained"  size="small"  sx={{ fontSize: 13}}color='secondary'>
                Submit
              </BDebtButton>

              <Tooltip title="close the dialog">
              <IconButton
                onClick={closeDialog}  style={{top:"8px"}}>
                <CloseIcon fontSize="small"  />
              </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={`${classes.dialogcontspac} ${classes.dialogContent}`} >
          <Grid container spacing={0}>
            <Grid item xs={2} sm={2} className={classes.companygrid}>
              <Paper className={`${classes.paperWithTabs} ${classes.companypaper1}`}>
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.auditHistoryContainer}>
                   <BDebtTypography variant="h6" color='white' className={classes.branchnamelist} >{companyName}</BDebtTypography>
                    <div className={classes.dashedborder} />
                    <List>
                    {companyBranches.map((branch, index) => (
                      <ListItem key={index}>
                        <ListItemText
                       className={classes.iconcolor}
                    primary={branch}                   
                  />
                      </ListItem>
                    ))}
                  </List>
                </Scrollbars>
              </Paper>
            </Grid>
            <Grid item xs={10} sm ={10} component={Paper}>
              <TabContext value={value} >
                <AppBar position="static" className={classes.appbardesign}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="fullWidth"
                    sx={{ minHeight: 30, padding: 0 }}
                  >
                    <Tab label="COMPANY INFORMATION" value="0" className={classes.usertab} />
                    <Tab label="CONTACT INFORMATION" value="1" className={classes.usertab} />
                    <Tab label="BILLING INFORMATION" value="2" className={classes.usertab} />
                    <Tab label="COLLECTION INFORMATION" value="3" className={classes.usertab} />
                  </TabList>
                </AppBar>
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.auditHistoryContainer}>
                  <TabPanel value="0" sx={{ padding: 0 }}>
                    <CompanyInformation />
                  </TabPanel>
                  <TabPanel value="1" sx={{ padding: 0 }}>
                    < ContactInformation />
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: 0 }}>
                    <BillingInformation />
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: 0 }}>
                    <CollectionInformation />
                  </TabPanel>
                </Scrollbars>
              </TabContext>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VerifyClient;