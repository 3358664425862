/* eslint-disable @typescript-eslint/no-unused-vars */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Checkbox, Collapse, Grid, IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import useStyles from './MenuPermissionCss';
import { IMenuItemResult, IMenuResult, ISubMenuResult, ISubNestedMenuResult } from '../../../Model/Settings/IMenuDetails';
import api from '../../../ApiConfiguration/Configuration';

const AgentUser = () => {
    const classes = useStyles();
    const [expand, setExpand] = useState(false);
    const [menuList, setMenuList] = useState<IMenuResult[]>([]);
    const [subMenuList, setSubMenuList] = useState<ISubMenuResult[]>([]);
    const [subNestedMenuList, setSubNestedMenuList] = useState<ISubNestedMenuResult[]>([]);
    const [loading, setLoading] = useState(true);
    const [finalMenuList, setFinalMenuList] = useState<IMenuItemResult[]>([]);

    useEffect(() => {
        getMenuDetails();
      }, []);

    const getMenuDetails = () => {
        setLoading(true);
        api.get<{ menuResult: IMenuResult[], subMenuResults: ISubMenuResult[], subNestedMenuResults: ISubNestedMenuResult[] }>('/Menu/GetMenuDetail').then((response) => {
          setMenuList(response.data?.menuResult);
          setSubMenuList(response.data?.subMenuResults);
          setSubNestedMenuList(response.data?.subNestedMenuResults);
          // Combine menuList and submenuList to create the final data structure
          const formulatedData: IMenuItemResult[] = response.data?.menuResult.map((menu) => {
            const matchingSubmenus = response.data?.subMenuResults.filter((submenu) => submenu.menuId === menu.menuId);
    
            return {
              ...menu,
              subMenu: matchingSubmenus.map((submenu) => ({
                ...submenu,
              })),
            };
          });
    
          setFinalMenuList(formulatedData);
    
        }).finally(() => {
          setLoading(false);
        })
      }

    return (
        <Grid container spacing={0} padding={1} bgcolor={'#FBFCFE'}>
            <Grid item xs={10} borderBottom={'1px dashed lightgray'}>
                <BDebtTypography variant="h6" pt="5px" align="left" color="primary"><b>AGENT USER</b></BDebtTypography>
            </Grid>
            <Grid item xs={2} borderBottom={'1px dashed lightgray'} pb={0.8} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                <Button variant="outlined" color='primary' size='small'><b>APPLY CHANGES</b></Button>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={0} mt={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={0} bgcolor={'#3b4a54'}>
                                    <Grid item xs={1} p={1} >

                                    </Grid>
                                    <Grid item xs={9} p={1} >
                                        <BDebtTypography variant="body2" color="white">
                                            <b>PERMISSION</b>
                                        </BDebtTypography>
                                    </Grid>
                                    <Grid item xs={2} p={1}>
                                        <BDebtTypography variant="body2" color="white">
                                            <b>SILVER</b>
                                        </BDebtTypography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.menuContainer} >
                                <React.Fragment>
                                            {loading ? <LinearProgress /> : null}
                                    </React.Fragment >
                                        {finalMenuList?.map((r, index) => (
                                            <Box m={0.5} mt={1} p={0}>
                                                <Grid container spacing={0} key={index} borderRadius={0} boxShadow={2} >
                                                    <Grid item xs={1} p={0} >
                                                        {r?.subMenu && r.subMenu.length > 0 && (
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => setExpand(!expand)}
                                                            >
                                                                {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={9} p={0} >
                                                        <BDebtTypography variant="body2" pt={1} pl={1}>
                                                            {r?.menuName}
                                                        </BDebtTypography>
                                                    </Grid>
                                                    <Grid item xs={2} p={0}>
                                                        <Checkbox name="basic" checked={r?.sliver} disabled={!r?.sliver} size="small" color='success' />
                                                    </Grid>

                                                </Grid>

                                                <Collapse in={expand} timeout="auto" unmountOnExit >
                                                    <Box m={0} border={'1px solid lightgray'} bgcolor={'aliceblue'}>
                                                    {r?.subMenu.map((result) => (
                                                            <Grid container spacing={0} key={index} >
                                                                <Grid item xs={1} p={0} >
                                                                </Grid>
                                                                <Grid item xs={9} p={0} >
                                                                    <BDebtTypography variant="body2" pt={1} pl={1}>
                                                                        {result?.subMenuName}
                                                                    </BDebtTypography>
                                                                </Grid>
                                                                <Grid item xs={2} p={0}>
                                                                    <Checkbox name="basic" checked={result?.sliver} size="small" color='primary' />
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Box>
                                                </Collapse>
                                            </Box> 
                                        ))}
                                </Scrollbars>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default AgentUser