import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, DialogActions, DialogContent, Grid, TableContainer, Typography } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { useState } from 'react';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import useStyles from '../DebtorsCss';
import pdfIcon from "../../../asset/image/pdf-icon.png"
import { IconButton } from '@material-ui/core';
import PageviewIcon from '@mui/icons-material/Pageview';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import Scrollbars from 'react-custom-scrollbars';

const DocumentUpload = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const documentDetails = [
        { title: '001204560-022123-BU.pdf', clientCode: 'CLIENT 1' },
        { title: '001204560-022123-BU.pdf', clientCode: 'CLIENT 2' },
        { title: '001204560-022123-BU.pdf', clientCode: 'CLIENT 3' },
        { title: '001204560-022123-BU.pdf', clientCode: 'CLIENT 4' },
        { title: '001204560-022123-BU.pdf', clientCode: 'CLIENT 5' },
    ]


    return (
        <div>
            <Button variant="contained" size="small" sx={{ fontSize: 10 }} fullWidth startIcon={<CloudUploadIcon fontSize='small' />} onClick={handleClickOpen}>Document</Button>

            <BDebtDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'md'}
            >
                <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleClickClose}>
                    <Typography variant="body1">
                        <b>DOCUMENT UPLOAD</b>
                    </Typography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' align='justify' className={classes.Note}>
                                <span><b>Note : </b></span>The uploaded document will be saved automatically.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <DropzoneArea
                                acceptedFiles={['application/pdf']}
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                dropzoneText="Drag and Drop an PDF file here or Browse"
                                dropzoneClass={classes.DropzoneArea}
                                dropzoneParagraphClass={classes.DropzoneAreaText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TableContainer className={`${classes.DocumentContainer} `}>
                                <Typography variant="body2" className={classes.UploadTextStyle} ><b>Uploaded Document</b></Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Scrollbars
                                            autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            autoHeightMin={145}
                                            autoHeightMax={145}
                                        >
                                            <Box sx={{ padding: 0.5, marginTop: '5px' }}>
                                                {documentDetails?.map((r) => (
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={1}>
                                                            <img src={pdfIcon} width="25px" alt="pdf-icon" />
                                                        </Grid>
                                                        <Grid item xs={8} >
                                                            <Typography variant="body2" sx={{ fontSize: '12px', marginLeft: '5px' }}>
                                                                <b>{r?.title}</b>
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ fontSize: '10px', marginLeft: '5px' }}>
                                                                {r?.clientCode}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1} >
                                                            <IconButton size='small'>
                                                                <PageviewIcon fontSize='small' color='primary' />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={1} >
                                                            <IconButton size='small'>
                                                                <DownloadIcon fontSize='small' color='success' />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={1} >
                                                            <IconButton size='small'>
                                                                <ClearIcon fontSize='small' color='error' />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Scrollbars>
                                    </Grid>
                                </Grid>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer className={`${classes.DocumentContainer} `}>
                                <Typography variant="body2" className={classes.UploadTextStyle} ><b>Request Letter</b></Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <Button autoFocus variant='contained' size="small" color='primary' fullWidth className={classes.paidLetter}>
                                            PAID LETTER
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button autoFocus variant='contained' size="small" color='primary' fullWidth className={classes.paidLetter}>
                                            Credit Update Letter
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button autoFocus variant='contained' size="small" color='primary' fullWidth className={classes.paidLetter}>
                                            Credit Not Mentioned Letter
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button autoFocus variant='contained' size="small" color='primary' fullWidth className={classes.paidLetter}>
                                            Credit Not Affected Letter
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ borderTop: '1px dashed gray' }}>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
                        Close
                    </Button>
                </DialogActions>
            </BDebtDialog>
        </div>
    )
}

export default DocumentUpload