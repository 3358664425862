import { IConnectorType } from "../../Model/Configuration";

export const Category: readonly IConnectorType[] = [
    { Name: "Sql" },
    { Name: "Mysql" },
    { Name: "Oracle" },
];

export const options = [
    { value: 'Tables', label: 'Tables' },
    { value: 'Procedures', label: 'Procedures' },
    { value: 'Query', label: 'Query' },
  ];

 export const Time = ["minute", "hour","day", "week", "month"]; 