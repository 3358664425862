import { List, styled } from '@mui/material';

const BDebtList = styled(List)(({ theme }) => ({
  // Your custom styles for the List component
  '&.MuiList-root': {
    // Add your styles here
    backgroundColor: "white",
    color:"black" ,
  },
  // Example styles for list items
  '& .MuiListItem-root': {
    // Add styles for list items here
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
})); 

export default BDebtList;