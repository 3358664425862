import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    BoxStyles: {
      // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    },
    userboxviewStyle: {
      display: "block",
      borderRadius: "10px",
      borderColor: "black",
      padding: "8px",
      "&:hover": {
        color: "green",
        backgroundColor: "white",
        transform: "scale(1)",
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)",
        borderRadius: "5px",
      },
    },
    LabelBoxStyle: {
      margin: "6px 2px",
    },
    formLabel: {
      fontWeight: "bold",
      color: "green",
      fontSize: 12,
      marginTop: "5px",
    },
    boxBorder: {
      border: "2px solid gray",
      padding: "2px 8px",
      borderRadius: "5px",
      width: "100%",
      marginTop: "12px",
      boxShadow:
        "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
    },
    textBoxStyle2: {
      fontSize: 10,
      "& .MuiChip-labelSmall": {
        fontSize: 10,
      },
    },
    scrollMargin: {
      "& div:first-child": {
        marginBottom: "5px",
      },
      "& div:nth-child(3) div": {
        backgroundColor: "#2377e4 !important",
      },
      "& div:nth-child(3)": {
        top: "10px !important",
        bottom: "5px !important",
      },
    },
    valueContainer: {
      "& > :not(:last-child)": {
        marginRight: "5px",
      },
      "& > *": {
        marginBottom: "5px",
      },
    },
    clientCodeBox: {
      width: "100%",
      marginTop: "5px",
      borderRadius: "4px 4px 8px 8px",
      "& .MuiOutlinedInput-root": {
        borderBottom: "3px solid blue",
        borderRadius: "4px 4px 8px 8px",
      },
    },
    paper2: {
      border: "2px solid black",
      height: "auto",
      margin: 0,
    },
    GreenColor: {
      color: "green",
    },
    IconSizeStyle1: {
      height: "30px",
      width: "30px",
      marginTop: "-85px",
      marginLeft: "315px",
      "&:hover": {
        background: "none",
      },
    },
    CheckBoxBorder: {
      borderTop: "1px solid gray",
    },
    ClientcheckBoxStyle: {
      padding: "0px",
    },
    IconSizeStyle: {
      height: "20px",
      width: "20px",
    },
    textBoxStyle: {
      marginTop: "12px",
      fontSize: 10,
      "& .MuiChip-labelSmall": {
        paddingLeft: "5px",
        paddingLight: "5px",
        fontSize: "10px",
      },
    },
    yesbutton: {
      backgroundColor: "green",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "green",
        color: "white",
      },
    },
    nobutton: {
      background: "red",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    },
    rowhead: {
      background: "lightgray",
      color: "black",
      fontSize: "12px",
      padding: "2px 5px 2px 8px",
      fontWeight: "bold",
    },
    sidebarItem: {
      display: "flex",
      alignItems: "center",
      borderRadius: "5px",
      transition: "0.2s",
      cursor: "pointer",
      padding: "10px 10px",
      marginTop: "4px",
      height: "auto",
      "&:hover": {
        backgroundColor: "#c8facd",
        color: "#00ab55",
      },
    },
    tablebodyConainer: {
      borderRadius: 0,
      maxHeight: "40px",
      minHeight: "40px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc(100vh - 550px)",
        minHeight: "calc(100vh - 550px)",
      },
    },
    tableCellStyle: {
      padding: "5px !important",
      "& .MuiTableCell-root": {
        padding: "5px !important",
      },
      fontSize: "13px !important",
    },
    checkboxStyle1: {
      width: "180px",
      fontSize: 12,
      marginLeft: "2px",
    },
    inputFontSize1: {
      fontSize: "15px",
      whiteSpace: "nowrap",
      width: "175px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      position: "relative",
      display: "inline-block",
      marginTop: "6px",
      color: "rgb(22 177 255)",
    },
    permissionCheckbox: {
      minHeight: 150,
      maxHeight: 150,
      maxWidth: 669,
      marginTop: "5px",
      padding: "5px",
      overflowX: "hidden",
      border: "2px solid #222b34",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 600px)",
        minHeight: "calc( 100vh - 600px)",
      },
    },
    testBox: {
      width: "100%",
      padding: "10px",
    },
    typography: {
      padding: "3px",
      backgroundColor: "rgb(211 211 211)",
      color: "black",
      justifyContent: "center",
      display: "flex",
    },
    FormControl: {
      width: "50%",
    },
    header: {
      backgroundColor: "rgb(59 74 84)",
      color: "white",
      justifyContent: "center",
      display: "flex",
    },
    phaseButton: {
      backgroundColor: "#e8def8",
      color: "#1d192b",
      borderRadius: "20px",
    },
    search: {
      width: "100%",
      borderRadius: "20px",
    },
    gridBox: {
      border: "1px solid gray",
    },
    tableCell1: {
      padding: "5px",
      fontSize: "12px",
    },
    pagination: {
      minHeight: "16px",
      paddingRight: "2px",
    },
    GridStyle: {
      border: "1px solid gray",
    },
    typography1: {
      justifyContent: "center",
      display: "flex",
      backgroundColor: "rgb(211 211 211)",
      color: "black",
    },
    chipStyle: {
      backgroundColor: "rgb(227 246 255)",
      color: "rgb(22, 177, 255)",
      fontWeight: "bold",
    },
    SwitchButton: {
      borderRadius: "20px",
      backgroundColor: "rgb(227 246 255)",
      color: "rgb(22, 177, 255)",
      fontWeight: "bold",
      fontSize: "0.8125rem",
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    box1: {
      width: "80%",
      justifyItems: "center",
      display: "flex",
      marginLeft: "50px",
      backgroundColor: "rgb(227 246 255)",
    },
  })
);

export default useStyles;
