import { styled } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BDebtTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: 'white',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      border:'2px solid black',
      backgroundImage: "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%)",
    
    },
  }));
  export default BDebtTooltip;