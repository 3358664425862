import React from 'react';
import DevOpsIcon from "@mui/icons-material/Build";
import SecurityIcon from "@mui/icons-material/Security";
import MonitorIcon from "@mui/icons-material/Computer";
import AnalyticsIcon from "@mui/icons-material/Assessment";
import CollaborationIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@material-ui/icons/Search";
import CloudIcon from "@material-ui/icons/Cloud";
import AddToDriveIcon from '@mui/icons-material/AddToDrive';

export const Data = {
    defaultWidgets: ["Widget 1", "Widget 2", "Widget 3"],
    dataSources: [
      { label: "SQL Database", value: "sqldatabase", icon: <SearchIcon  fontSize='small' color='secondary'/> },
      { label: "Azure Cloud", value: "Azurecloud", icon: <CloudIcon   fontSize='small' color='secondary'/> },
      { label: "Google Drive", value: "Googledrive", icon: <AddToDriveIcon  fontSize='small' color='secondary'/> }
    ],
    
  Database: ["SQL Database", "MongoDB", "MariaDB", "MS-SQL Server",
   "Redis", "PostgreSQL","MS-Access", "Amazon RDS",
  ],

  };


interface MockData {
  name: string;
  userId: string;
  modifiedDate: string;
  icon: React.ReactNode;
}

function createData(
  name: string,
  userId: string,
  modifiedDate: string,
  icon: React.ReactNode
): MockData {
  return { name, userId, modifiedDate, icon };
}

const mockData: MockData[] = [
  
  
  createData("DevOps Widget", "test1@test.com", "22/12/2033", <DevOpsIcon fontSize="medium" />),
  createData("Security Widget  test", "test2@test.com", "21/12/2033", <SecurityIcon fontSize="medium" />),
  createData("Monitoring Widget", "test3@test.com", "20/12/2033", <MonitorIcon fontSize="medium" />),
  createData("Analytics ", "test4@test.com", "19/12/2033", <AnalyticsIcon fontSize="medium" />),
  createData("Collaboration Widget", "test5@test.com", "18/12/2033", <CollaborationIcon fontSize="medium" />),
  createData("DevOps Widget", "test1@test.com", "22/12/2033", <DevOpsIcon fontSize="medium" />),
  createData("Security Widget", "test2@test.com", "21/12/2033", <SecurityIcon fontSize="medium" />),
  createData("Monitoring Widget", "test3@test.com", "20/12/2033", <MonitorIcon fontSize="medium" />),
  createData("Analytics Widget", "test4@test.com", "19/12/2033", <AnalyticsIcon fontSize="medium" />),
  createData("Collaboration Widget", "test5@test.com", "18/12/2033", <CollaborationIcon fontSize="medium" />),
  createData("DevOps Widget", "test1@test.com", "22/12/2033", <DevOpsIcon fontSize="medium" />),
  createData("Security Widget", "test2@test.com", "21/12/2033", <SecurityIcon fontSize="medium" />),
  createData("Monitoring Widget", "test3@test.com", "20/12/2033", <MonitorIcon fontSize="medium" />),
  createData("Analytics Widget", "test4@test.com", "19/12/2033", <AnalyticsIcon fontSize="medium" />),
  createData("Collaboration Widget", "test5@test.com", "18/12/2033", <CollaborationIcon fontSize="medium" />),
  createData("DevOps Widget", "test1@test.com", "22/12/2033", <DevOpsIcon fontSize="medium" />),
  createData("Security Widget", "test2@test.com", "21/12/2033", <SecurityIcon fontSize="medium" />),
  createData("Monitoring Widget", "test3@test.com", "20/12/2033", <MonitorIcon fontSize="medium" />),
  createData("Analytics Widget", "test4@test.com", "19/12/2033", <AnalyticsIcon fontSize="medium" />),
  createData("Collaboration Widget", "test5@test.com", "18/12/2033", <CollaborationIcon fontSize="medium" />),
  createData("DevOps Widget", "test1@test.com", "22/12/2033", <DevOpsIcon fontSize="medium" />),
  createData("Security Widget", "test2@test.com", "21/12/2033", <SecurityIcon fontSize="medium" />),
  createData("Monitoring Widget", "test3@test.com", "20/12/2033", <MonitorIcon fontSize="medium" />),
  createData("Analytics Widget", "test4@test.com", "19/12/2033", <AnalyticsIcon fontSize="medium" />),
  // createData("Collaboration Widget", "test5@test.com", "18/12/2033", <CollaborationIcon fontSize="medium" />),
  // Add more mock data as needed
];

export default mockData;
