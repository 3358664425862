import React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  ILoadedEventArgs,
  ChartTheme,
  Inject,
} from "@syncfusion/ej2-react-charts";
import BarChart from "../../../../MockData/BarChart.json";

const SampleBarChart = () => {
  const load = (args: ILoadedEventArgs): void => {
    let selectedTheme: string = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    ) as ChartTheme;
  };

  return (
    <div style={{ height: "350px" }}>
      <ChartComponent
        id="bar"
        primaryXAxis={{ valueType: "Category" }}
        legendSettings={{ visible: true }}
        tooltip={{ enable: true }}
        width="100%"
        height="100%"
        load={load.bind(this)}
      >
        <Inject services={[Category, Legend, Tooltip, DataLabel]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={BarChart}
            xName="Month"
            yName="Value"
            name="Revenue"
            type="Bar"
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};

export default SampleBarChart;
