/* eslint-disable @typescript-eslint/no-unused-vars */
import { Hidden } from '@mui/material';
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Content from '../Components/NavbarSection/Content';
import NavbarHeader from '../Components/NavbarSection/NavHeader';
import Sidebar from '../Components/NavbarSection/Sidebar';
import routes from '../Routes';

const PageLayout: React.FC = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    return (
        <Router>
            <NavbarHeader />
            <Hidden smDown>
                <Sidebar routes={routes} sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />
            </Hidden>
            <Hidden smUp>
                <Content />
            </Hidden>            
        </Router>
    )
};

export default PageLayout;
