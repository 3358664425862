/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import { useState} from "react";
import { DialogContent, Grid } from "@material-ui/core";
import { Autocomplete, DialogActions, Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { IconButton } from "@material-ui/core";
// Icon
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CreateDataSet } from "../../../MockData/Placement/placement";
import {clientcodes } from "../../../MockData/Placement/placement";
import { usePost } from "../../../utils/apiHelper";

const EditDataset: React.FC<{ row: any; onEdit: () => void  }> = (props) => {
  const { row, onEdit} = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [localState, setLocalState] = useState<CreateDataSet>({
    dataSetId: row?.dataSetId,
    dataSetName: row?.dataSetName,
    createdBy: row?.createdBy, // Assuming a default value or you prompt the user for this
    clientCodeList: row?.clientCode, 
  });

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleClientCodeChange = (event, value) => {
    setLocalState((prevDataset) => ({
      ...prevDataset,
      clientCodeList: value || "",
    }));
  };

  const handleSave = async () => {
    let request = {
      dataSetId: localState.dataSetId,
      dataSetName: localState.dataSetName,
      createdBy: localState.createdBy,
      clientList: [
        {clientCode: localState.clientCodeList}],
    };

    const result = await usePost<any>("Dataset/CreateDataSetDetail", request);
    setLocalState(result?.data || localState);
    onEdit();
    closeDialog();
  };

  const handleNameChange = (e) => {
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
  };

  return (
    <div>
      <IconButton size="small" onClick={openDialog}>
        <EditOutlinedIcon color="success" sx={{ fontSize: 17 }} />
      </IconButton>
      <BDebtDialog open={isDialogOpen} maxWidth={"sm"} onClose={closeDialog} fullWidth>
        <BDebtDialogTitleHeader onClose={closeDialog} id="customized-dialog-title">
          <Typography variant="body1">
          <b>EDIT DATASET</b>
        </Typography>  
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                name="dataSetName"
                label="Dataset Name"
                size="small"
                variant="outlined"
                value={localState?.dataSetName}
                onChange={(e) => { handleNameChange(e); }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={clientcodes}
                value={localState?.clientCodeList}
                onChange={handleClientCodeChange}
                renderInput={(params) => (
                  <BDebtTextField variant="outlined" {...params} label="Client Code" name="Tag" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
        <BDebtButton
            color="success" 
            variant="contained"
            onClick={handleSave}
            size="small"
          >
            Save
          </BDebtButton>
          <BDebtButton
            variant="contained"
            color="error"
            onClick={closeDialog}
            size="small"
          >
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default EditDataset;
