import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)`
  .MuiTablePagination-toolbar {
    display: flex;
    justify-content: center;
  }

  .MuiTablePagination-caption {
    flex: 1;
    text-align: center;
  }

  .MuiTablePagination-select {
    margin-left: 20px; /* Adjust as needed */
  }

  .MuiTablePagination-selectIcon {
    top: 8px; /* Adjust as needed */
  }

  .MuiTablePagination-actions {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .MuiTablePagination-spacer {
    display: none; /* Hide the spacer */
  }
  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    display: block;
    margin-top: 17px;
}
.css-levciy-MuiTablePagination-displayedRows {
    margin-top: 21px;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    margin-top: 6px;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  background-color: aliceblue !important;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  padding-right: 23px !important;
  min-width: 112px;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
  width: min-content !important;
  height: min-content !important;
  margin:0px -27px !important;
}
`;
export default StyledDataGrid;



