import { Accordion, Theme, styled } from '@mui/material';

const BDebtAccordion = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== 'customStyle',
})(
    ({ theme, customStyle }: { theme: Theme; customStyle?: React.CSSProperties }) => ({
        '&.MuiAccord-root ': {
                    borderRadius: '10px',
                    marginTop:'5px !important'
                },
                '& .css-1t174w4-MuiPaper-root-MuiAccordion-root': {
                    backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)',
                    // - webkit - transition: box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms;
                    transition: 'boxShadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
                    borderRadius: '10px',
                    boxShadow: '0px 2px 1px - 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                    // position: 'relative',
                    // -webkit - transition: margin 150ms cubic - bezier(0.4, 0, 0.2, 1) 0ms;
                    // transition: 'margin 150ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
                    overflowAnchor: 'none',
                    marginTop:'5px !important'
                }
    })
);

export default BDebtAccordion;