import { data } from '../../MockData/DataSource';
import { Box, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useStyles from './DebtorsCss';

function EnhancedTableHead() {
    const classes = useStyles();
    const headCells = [
        {
            id: "date",
            numeric: false,
            disablePadding: false,
            label: "DATE",
            sortable: false,
        },
        {
            id: "description",
            numeric: false,
            disablePadding: false,
            label: "Description",
            sortable: false,
        },
        {
            id: "amount",
            numeric: false,
            disablePadding: false,
            label: "Amount",
            sortable: false,
        },
        {
            id: "paymentType",
            numeric: false,
            disablePadding: false,
            label: "PaymentType",
            sortable: false,
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.accountHistoryHeader} key={headCell.id} align="left">
                        <b> {headCell.label.toUpperCase() || headCell.sortable}</b>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const AccountHistory = () => {
    const classes = useStyles();

    return (
        <Box sx={{ flexGrow: 1, padding: 0 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                    <TableContainer component={Paper} className={`${classes.accountHistoryConainer } ${"scrollbox"} ${"on-scrollbar"}`} >
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead />
                            <TableBody>
                                {data.map((row: any, index: number) => (
                                    <TableRow >
                                        <TableCell className={classes.accountHistoryStyle} >
                                            {row?.Date}
                                        </TableCell>
                                        <TableCell className={classes.accountHistoryStyle} >
                                            {row?.Description}
                                        </TableCell>
                                        <TableCell className={classes.accountHistoryStyle} >
                                            {row?.Amount}
                                        </TableCell>
                                        <TableCell className={classes.accountHistoryStyle}>
                                            {row?.PaymentType}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={12} >
                    <Paper className={classes.pagination}>
                        <Pagination count={10} color="primary" size='small' />
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    );
}

export default AccountHistory;