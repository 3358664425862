/* eslint-disable @typescript-eslint/no-unused-vars */
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Grid } from "@material-ui/core";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import useStyles from "./TemplateCss";
import RadioButtonIcon from '@mui/icons-material/RadioButtonChecked';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import SelectIcon from '@mui/icons-material/ArrowDropDown';
import BDebtList from '../../GlobalControl/BDebtList';

function FormControl() {
  const classes = useStyles();
  const handleDragStart = (e: any, type: any) => {
    e.dataTransfer.setData("text/plain", type);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}  >
        <BDebtTypography variant="body2" className={classes.ContainerStyle} > COMMON CONTROL</BDebtTypography>
        <BDebtList>
          <ListItem
            draggable
            className={classes.commonControl}
            onDragStart={(e) => handleDragStart(e, 'textfield')}
          >
            <ListItemIcon>
            </ListItemIcon>
            <BDebtTypography variant="h6">TextField</BDebtTypography>
          </ListItem>

          <ListItem draggable onDragStart={(e) => handleDragStart(e, 'radio')} className={classes.commonControl}>
            <ListItemIcon>
              <RadioButtonIcon />
            </ListItemIcon>
            <BDebtTypography variant="h6">Radio Button</BDebtTypography>
          </ListItem>
          <ListItem draggable onDragStart={(e) => handleDragStart(e, 'Label')} className={classes.commonControl}>
            <ListItemIcon>
              <ListItemIcon>
              </ListItemIcon>
              <BDebtTypography variant="h6">Label</BDebtTypography>
            </ListItemIcon>
          </ListItem>

          <ListItem draggable onDragStart={(e) => handleDragStart(e, 'checkbox')} className={classes.commonControl}>
            <ListItemIcon>
              <CheckboxIcon />
            </ListItemIcon>
            <BDebtTypography variant="h6">Checkbox</BDebtTypography>
          </ListItem>

          <ListItem draggable onDragStart={(e) => handleDragStart(e, 'select')} className={classes.commonControl}>
            <ListItemIcon>
              <SelectIcon />
            </ListItemIcon>
            <BDebtTypography variant="h6">Select</BDebtTypography>
          </ListItem>

          <ListItem draggable onDragStart={(e) => handleDragStart(e, 'button')} className={classes.commonControl}>
            <BDebtTypography variant="h6">Button</BDebtTypography>
          </ListItem>
        </BDebtList>
      </Grid>
    </Grid>
  );
}

export default FormControl;