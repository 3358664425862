/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import { DialogContent, DialogActions, TextField, Tooltip } from '@mui/material';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import { Grid } from '@mui/material';
import useStyles from './MenuLevelCSS';
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import BDebtButton from '../../../GlobalControl/BDebtButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IMenuActionResult } from '../../../Model/Settings/IMenuDetails';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Snackbar } from '@mui/material';
import MuiAlert from "@mui/material/Alert";

const AddMenuAction: React.FC<{ onSave: () => void }> = (props) => {
  const { onSave } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [menuActionList, setMenuActionList] = useState<IMenuActionResult[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [actionName, setActionName] = useState("");

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    GetMenuActionDetails();
  }, []);

  const GetMenuActionDetails = () => {
    useFetch<IMenuActionResult[]>('Menu/GetMenuActionDetail').then((response) => {
      setMenuActionList(response.data);
    })
  };
  const handleMenuActionInformation = async () => {
    (async () => {
      let requestData = {
        "actionId": 0,
        "actionName": actionName,
      }
      await usePost("Menu/SaveMenuActionDetail", requestData).then((response) => {
        setSnackbarOpen(true);
        handleClose();
        setSnackbarMessage("Menu information saved successfully!");
      })
    })().finally(() => {
      GetMenuActionDetails();
      onSave();
    });
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleActionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionName(event.target.value);
  };

  return (
    <React.Fragment>
      <Tooltip title="Create New MenuActionName" arrow placement="top-end">
        <BDebtButton variant="contained" size="small" fullWidth color="primary" sx={{ marginBottom: "10px" }} onClick={handleOpen} ><AddCircleOutlineIcon fontSize="small" />Add</BDebtButton>
      </Tooltip>
      <BDebtDialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
        maxWidth={"sm"} PaperProps={{ style: { width: 600 } }}>
        <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleClose}>
          Menu Details
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body2" padding="5px" >
            Menu Action Name
          </BDebtTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth size="small"
                id="standard-bare" variant="outlined"
                className={classes.searchBox}
                type='text' placeholder={`Enter Menu Action Name`}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                onChange={handleActionNameChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BDebtButton size="small" color="success" onClick={handleMenuActionInformation}>
            Save
          </BDebtButton>
          <BDebtButton onClick={handleClose} size="small" color="error">
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success" >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddMenuAction;
