/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import useStyles from "../UserCss";
import { IGetClientCode, SelectedOption } from "../../../Model/IUser";
import { Client } from "../../../MockData/UserLevel/Menulist";

interface ContsoProps {
  ClientCode:IGetClientCode[];
  onSelectedClientListChange: (newClientList: SelectedOption[]) => void;
}

const EditClientCode = ({ ClientCode, onSelectedClientListChange}: ContsoProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<SelectedOption[]>([]);
  const [selectedOptionsList, setSelectedOptionsList] = useState<SelectedOption[]>([]);
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckboxChange = (selectAll: boolean) => {
    if (selectAll) {
      setSearchValue(Client);
      setSelectedOptionsList(Client);
    } else {
      setSearchValue([]);
      setSelectedOptionsList([]);
    }
    setChecked(selectAll);
  };

  useEffect(() => {
    onSelectedClientListChange(selectedOptionsList);
  }, [selectedOptionsList]);

  useEffect(() => {
    // Set the state based on the ClientCode prop when it changes
    if (ClientCode && ClientCode.length > 0) {
      // Assuming each item in the array has a property named clientCode
      const selectedOptions = ClientCode.map((item) => ({
        title: item.clientCode,
      }));
  
      setSearchValue(selectedOptions);
      setSelectedOptionsList(selectedOptions);
    }
  }, [ClientCode]);
  
  
  
  const handleClientSelect = (event: any, selectedOptions: any) => {
    // Filter out duplicates before updating the state
    const uniqueSelectedOptions = selectedOptions.filter(
      (option: SelectedOption) => !selectedOptionsList.find((v) => v.title === option.title)
    );
  
    setSearchValue(uniqueSelectedOptions);
    setSelectedOptionsList([...selectedOptionsList, ...uniqueSelectedOptions]);
  };
  
  const onDelete = (title: string) => () => {
    setSearchValue(searchValue.filter((v) => v.title !== title));
    setSelectedOptionsList(
      selectedOptionsList.filter((v) => v.title !== title)
    );
  };
  

  return (
    <Grid>
      <Grid />
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.checkboxAlign}>
          <Typography>
            Selected client code: {selectedOptionsList.length}
          </Typography>
        </Grid>
        <Grid className={classes.chippadding}>
          {selectedOptionsList.map((v) => (
            <Chip
              key={v.title}
              size="small"
              className="chip-style"
              label={v.title}
              onDelete={onDelete(v.title)}
            />
          ))}
        </Grid>
        <Grid item xs={12} className={classes.checkboxAlign}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => handleCheckboxChange(true)}
                  color="primary"
                  size="small"
                />
              }
              label={"Select all"}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!checked}
                  onChange={() => handleCheckboxChange(false)}
                  color="primary"
                  size="small"
                />
              }
              label={"Remove all"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            size="small"
            id="checkboxes-tags-demo"
            options={Client}
            value={searchValue}
            onChange={(event, selectedOptions) => {
              handleClientSelect(event, selectedOptions);
            }}
            getOptionLabel={(option) => option.title}
            renderTags={() => null}
            renderInput={(params) => (
              <BDebtTextField
                {...params}
                variant="outlined"
                label="Client Code"
                placeholder="Filter"
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditClientCode;

