import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea, DropzoneAreaProps } from 'material-ui-dropzone';

const useStyles = makeStyles({
  root: (props: { minHeight?: number; maxHeight?: number }) => ({
    width: '100%',
    border: '1px dashed blue !important',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: props.minHeight ? `${props.minHeight}px !important` : '190px !important',
    maxHeight: props.maxHeight ? `${props.maxHeight}px !important` : 'none',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    backgroundColor: '#f1f5ff !important',
    boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px !important',
  }),
  text: {
    color: 'blue',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.87)',
    width: '40px',
    height: '30px',
  },
  textContainer: {
    textAlign: 'center',
  },
});

interface BDebtFileUploadProps extends DropzoneAreaProps {
  minHeight?: number;
  maxHeight?: number;
  className?: string;
}

const BDebtFileUpload: React.FC<BDebtFileUploadProps> = ({ minHeight, maxHeight, className, ...props }) => {
  const classes = useStyles({ minHeight, maxHeight });

  return (
    <DropzoneArea
      {...props}
      classes={{
        root: `${classes.root} ${className || ''}`,
        text: classes.text,
        icon: classes.icon,
        textContainer: classes.textContainer,
      }}
    />
  );
};

export default BDebtFileUpload;
