import { Theme, alpha, createStyles, makeStyles } from "@material-ui/core";

const drawerWidth = "6rem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha('#919EAB', 0.30),
            '&:hover': {
                backgroundColor: alpha('#919EAB', 0.35),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            color: 'black',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '20ch',
                '&:focus': {
                    width: '50ch',
                },
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBar: {
            backgroundColor: '#f1f5f8 !important',
            boxShadow: 'none !important',
            borderBottom: '1px solid lightgray',
            '& .MuiPaper-elevation4': {
                boxShadow: 'none !important'
            },
            minHeight: '50px !important'
        },
        toolBar: {
            backgroundColor: '#f1f5f8 !important',
            minHeight: '50px !important',
            '& .MuiToolbar-regular': {
                minHeight: '50px'
            }
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },

        /* Mobile Navigation Css */
        closeIcon: {
            backgroundColor: '#f1f5f8 !important',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        },
        toolbar: theme.mixins.toolbar,
        listMenu: {
            padding: '8px',
            '&:hover': {
            },
            '&:hover a': {
                color: '#fff',
            }
        },
        mobileSidebarContainer: {
            maxHeight: '660px',
            minHeight: '660px',
            [theme.breakpoints.down("sm")]: {
                maxHeight: "calc( 100vh - 98px)",
                minHeight: "calc( 100vh - 98px)",
            },
        },

        // Sidebar 
        scrollbarContainer: {
            maxHeight: '660px',
            minHeight: '660px',
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 110px)",
                minHeight: "calc( 100vh - 110px)",
            },
        },
        listMui: {
            padding: "0px !important",
            width: drawerWidth,
            borderRight: '1px solid lightgray',
            background: '#f1f5f8'
        },
        listItemMui: {
            padding: "2px !important",
            borderRadius: 5
        },
        listItemActive: {
            justifyContent: 'center',
            flexDirection: 'column',
            padding: "4px !important",
            borderLeft: "3px solid #283593 !important",
            backgroundColor: "white !important"
        },
        listItemInactive: {
            justifyContent: 'center',
            flexDirection: 'column',
            padding: "4px !important",
        },
        listItemIconActive: {
            fontSize: 20,
            color: '#283593 !important'
        },
        listItemIconInActive: {
            fontSize: 20,
            color: '#000000 !important'
        },
        listItemTextActive: {
            fontSize: "12px !important",
            color: '#283593 !important',
            fontWeight: 'bold'
        },
        listItemTextInActive: {
            fontSize: "12px !important",
        },
        contentContainer: {
            flexGrow: 1,
            maxWidth: '100% !important',
            margin: '0 auto',
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            background: 'white !important',
            [theme.breakpoints.up("sm")]: {
                boxShadow: '0 0 2px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.14) !important',
                // boxShadow: " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
                marginLeft: '-2px !important',
                zIndex: 1,
            }
        }
    }),
);


export default useStyles;