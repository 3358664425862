import { useState } from "react";
//MUI COMPONENTS
import { Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete, Box } from "@mui/material";
//ICONS
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
//CUSTOM CSS
import useStyles from '../Egress/EgressCss';
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtButton from "../../../GlobalControl/BDebtButton";

const SearchEgressDetails = () => {
    const classes = useStyles();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [egressEntity, setEgressEntity] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [actionFilter, setActionFilter] = useState<string>("");

    const handleSearch = () => {
        if (
            egressEntity.trim() !== "" ||
            (startDate.trim() !== "" && endDate.trim() !== "") ||
            actionFilter.trim() !== ""
        ) {
            setShowSearchIcon(false);
        } else {
            setShowSearchIcon(true);
        }
    };

    const handleCancelSearch = () => {
        setEgressEntity("");
        setStartDate("");
        setEndDate("");
        setActionFilter("");
        setShowSearchIcon(true);
    };

    return (
        <div>
            <Box p={1}>
                <Grid container spacing={2} >
                    <Grid item xs={1} >
                        <BDebtTypography variant="body2" textPadding="10px 0 0 0" color='primary' ><b>EGRESS</b></BDebtTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            size="small"
                            id="standard-bare"
                            variant="outlined"
                            className={classes.searchBox}
                            type="text"
                            value={egressEntity}
                            placeholder="Job Name"
                            onChange={(e) => setEgressEntity(e.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && egressEntity !== "") {
                                    setShowSearchIcon(false);
                                } else if (event.key === "Enter" && egressEntity === "") {
                                    setShowSearchIcon(true);
                                }
                            }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon ? (
                                            <IconButton onClick={handleSearch} className={classes.buttonIcon}>
                                                <SearchIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={handleCancelSearch} className={classes.buttonIcon}>
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BDebtTextField
                            fullWidth
                            size="small"
                            id="start-date"
                            variant="outlined"
                            type="date"
                            value={startDate}
                            placeholder="Start Date"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BDebtTextField
                            fullWidth
                            size="small"
                            id="end-date"
                            variant="outlined"
                            type="date"
                            value={endDate}
                            placeholder="End Date"
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            fullWidth
                            id="demo-autocomplete-small"
                            options={["Success", "Failed", "In Progress"]}
                            value={actionFilter}
                            size="small"
                            onChange={(e, value) => setActionFilter(value as string)}
                            renderInput={(params) => (
                                <BDebtTextField {...params} label="Select Action" variant="outlined" />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: '12px' }}>
                                    {option}
                                </li>
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <BDebtButton variant="contained" color="primary" onClick={handleSearch}>
                            Filter
                        </BDebtButton>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
export default SearchEgressDetails;

