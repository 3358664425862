/* eslint-disable @typescript-eslint/no-redeclare */
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AppBar, Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import AgentUser from "../Components/Users/AgentUser/AgentUser";
import AllUser from "../Components/Users/AllUser/AllUser";
import ClientAdmin from "../Components/Users/ClientAdmin/ClientAdmin";
import ClientUser from "../Components/Users/ClientUser/ClientUser";
import PlatformAdmin from "../Components/Users/PlatformAdmin/PlatformAdmin";
import ProspectUser from "../Components/Users/ProspectUser/ProspectUser";
import useStyles from "../Components/Users/UserCss";
import api from "../ApiConfiguration/Configuration";
import { IGetUserTypeList } from "../Model/IUser";

const UserList = () => {
  const classes = useStyles();
  const [value, setValue] = useState<string>("1");
  const [userTypeList, setUserTypeList] = useState<IGetUserTypeList[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(()=>{
    GetUserTypeDetail();
  },[]);


  const GetUserTypeDetail = () => {
    api.get<IGetUserTypeList[]>('User/GetUserTypeDetail').then((response) => {
      setUserTypeList(response.data);
    })
  };

  return (
    <Box m={1} p={1} >
      <TabContext value={value}>
        <AppBar position="static" className={classes.appbardesign} >
          <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="fullWidth" sx={{ minHeight: 35, padding: 0 }}                  >
            <Tab label="All USERS" value="2" className={classes.usertab} />
            {userTypeList.map((user, index) => (
                  <Tab key={index} label={user?.userRoleName} value={index.toString()} className={classes.usertab} />
                ))}
          </TabList>
        </AppBar>

        <TabPanel value="2" sx={{ padding: 0 }}>
          <AllUser />
        </TabPanel>
        <TabPanel value="4" sx={{ padding: 0 }}>
          < PlatformAdmin Value={value}/>
        </TabPanel>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <ClientAdmin Value={value} />
        </TabPanel>
        <TabPanel value="0" sx={{ padding: 0 }}>
          <ClientUser Value={value}/>
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0 }}>
          <AgentUser />
        </TabPanel>
        <TabPanel value="5" sx={{ padding: 0 }}>
          <ProspectUser />
        </TabPanel>
      </TabContext>
    </Box>

  );
};

export default UserList;


