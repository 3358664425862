import { Box } from '@mui/material'

const Notification = () => {
  return (
    <Box p={1} m={1}>
      <div>Notification</div>
    </Box>
  )
}

export default Notification