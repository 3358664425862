/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TablePagination, TextField } from "@mui/material";
import BDebtTableRow from "../../GlobalControl/Table/BDebtTableRow";
import BDebtTablehead from "../../GlobalControl/Table/BDebtTablehead";
import AddDataRules from "../../Components/Setting/DataRules/AddDataRules";
import EditDataRules from "../../Components/Setting/DataRules/EditDataRules";
import DeleteDataRules from "../../Components/Setting/DataRules/DeleteDataRules";
import { IGetRules } from "../../Components/Setting/DataRules/IDataRules";
import useStyles from "../../Components/Setting/DataRules/DataRulesCss";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import api from "../../ApiConfiguration/Configuration";
import { searchTermRegex } from "../../Model/RegexValidation/DataRuleValidation";

const DataRules = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IGetRules[]>([]);
  const [StartingRecordNumber, setStartingRecordNumber] = useState<number>(1);
  const [searchText, setSearchParameters] = useState<string>(null);
  const [orderBy, setOrderBy] = useState<string>("Name");
  const [totalCount, setTotalCount] = useState<number>(null);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleClear = () => {
    setSearchParameters(null);
    ClearfetchSearchRuleDetails();
  };

  const ClearfetchSearchRuleDetails = async () => {
    setLoading(true);
    let clearRequest = {
      SearchParameters: null,
      StartingRecordNumber: StartingRecordNumber,
      RowsPerPage: rowsPerPage,
      orderBy: orderBy,
    };
    await api
      .post<{ searchRuleDetail: IGetRules[]; totalCount: number }>(
        "Rule/SearchRule",
        clearRequest
      )
      .then((response) => {
        setData(response.data.searchRuleDetail);
        setTotalCount(response.data.totalCount);
      });
    setStartingRecordNumber(page * rowsPerPage);
    setLoading(false);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const fetchSearchRuleDetails = async () => {
    setLoading(true);
    let searchRequest = {
      SearchParameters: searchText,
      startingRecordNumber: page * rowsPerPage,
      RowsPerPage: rowsPerPage,
      orderBy: orderBy,
    };
    await api
      .post<{ searchRuleDetail: IGetRules[]; totalCount: number }>("Rule/SearchRule", searchRequest)
      .then((result) => {
        setData(result?.data.searchRuleDetail);
        setTotalCount(result?.data.totalCount);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSearchRuleDetails();
  }, [rowsPerPage, orderBy, page]);

  // Helper function to highlight the first three words
  const highlightSearchTerm = (text: string, searchText: string | null) => {
    if (!searchText) return text;

    const regex = searchTermRegex(searchText);
    const highlightedText = text.split(regex).map((part, index) => {
      return regex.test(part) ? (
        <mark key={index} style={{ backgroundColor: "#FFF44F", padding: "2px" }}>
          {part}
        </mark>
      ) : (
        part
      );
    });

    return <>{highlightedText}</>;
  };

  function EnhancedTableHead() {
    const headCells = [
      { id: "ruleName", numeric: false, disablePadding: false, label: "Rule Name", sortable: false },
      { id: "ruleDes", numeric: false, disablePadding: false, label: "Rule Description", sortable: false },
      { id: "ruleType", numeric: false, disablePadding: false, label: "Rule Type", sortable: false },
      { id: "ruleOptions", numeric: false, disablePadding: false, label: "Rule Options", sortable: false },
      { id: "maxlength", numeric: false, disablePadding: false, label: "Max Length", sortable: false },
      { id: "formula", numeric: false, disablePadding: false, label: "Formula", sortable: false },
      { id: "Action", numeric: false, disablePadding: false, label: "Action", sortable: false },
    ];

    return (
      <BDebtTablehead>
        <BDebtTableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.dataruletableCellHeader}
              key={headCell.id}
              size="small"
            >
              {headCell?.label.toUpperCase() || headCell.sortable}
            </TableCell>
          ))}
        </BDebtTableRow>
      </BDebtTablehead>
    );
  }

  return (
    <Grid boxShadow={2} container spacing={0}>
      <Grid
        container
        spacing={0}
        item
        xs={12}
        md={12}
        justifyContent="space-between"
        alignItems="center"
        className={classes.dataruletableGrid}
      >
        <Grid item xs={2} md={2}>
          <BDebtTypography variant="h6">Data Rules</BDebtTypography>
        </Grid>
        <Grid item xs={2} md={8}>
          <TextField
            id="input-with-icon-textfield"
            fullWidth
            placeholder="Search Rule Name or Rule Type..."
            size="small"
            value={searchText || ''}
            variant="outlined"
            className="search-icon"
            InputProps={{
              startAdornment: (
                <IconButton onClick={fetchSearchRuleDetails}>
                  <SearchIcon color="disabled" />
                </IconButton>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => setSearchParameters(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && searchText != null) {
                fetchSearchRuleDetails();
              } else if (event.key === "Enter" && searchText === null) {
                fetchSearchRuleDetails();
              }
            }}
          />
        </Grid>
        <Grid item xs={3} md={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <AddDataRules onSave={fetchSearchRuleDetails} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} borderBottom={"1px solid lightgray"}>
        <TableContainer className={`${classes.dateRuleContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead />
            <TableBody>
              {data.map((row, index) => (
                <BDebtTableRow key={index}>
                  <TableCell sx={{ padding: 1 }}>
                    {highlightSearchTerm(row?.name || '', searchText)}
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    {highlightSearchTerm(row?.description || '', searchText)}
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    {highlightSearchTerm(row?.ruleType || '', searchText)}
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    {highlightSearchTerm(row?.ruleOption || '', searchText)}
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>{row?.maxLength}</TableCell>
                  <TableCell sx={{ padding: 1 }}>{row?.formula}</TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    <EditDataRules row={row} onUpdate={fetchSearchRuleDetails} />
                    <DeleteDataRules rowId={row?.ruleId} onDelete={fetchSearchRuleDetails} />
                  </TableCell>
                </BDebtTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={12}>
        <TablePagination
          component="div"
          count={totalCount || 0}
          size="small"
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default DataRules;
