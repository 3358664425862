import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  // DataRules.tsx
    dataruletableCellHeader: {
        padding: "8px !important",
        fontSize: "12px !important",
        color: "white !important",
        background: "#3b4a54 !important",
        fontWeight: 'bold'
    },
    dataruletableGrid: {
        padding: "5px 5px 5px 5px",
    },
    dateRuleContainer: {
        maxHeight: "660px !important",
        minHeight: "660px !important",
        [theme.breakpoints.up("lg")]: {
          maxHeight: "calc( 100vh - 290px) !important",
          minHeight: "calc( 100vh - 290px) !important",
        },
    },
    //Add Datarule
    dropdownPaper: {
      border: "1px solid lightgray",
      fontSize: "13px !important",
      marginTop: "0px",
    },
    autoCompleteFont: {
      fontSize: "13px !important",
      padding: "3px 12px !important",
    },
  })
);
export default useStyles;