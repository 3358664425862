import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeicon: {
      position: "absolute",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    properties: { padding: "4px" },
    fixedHeader: {
      backgroundColor: "#f4f6f8",
      color: "#637381",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    tableBody: {
      maxHeight: "302px",
      overflowY: "auto",
    },
    subscribe: {
      backgroundColor: "rgb(234 249 224)",
      color: "rgb(86 202 0)",
      fontSize: "12px",
      fontWeight: 700,
      width: "150px",
    },
    Unsubscribe: {
      backgroundColor: "rgb(241 241 242)",
      color: "rgb(138 141 147)",
      fontSize: "12px",
      width: "150px",
      fontWeight: 700,
    },
    clientID: {
      backgroundColor: "rgba(118, 176, 241, 0.2)",
      color: "blue",
      fontSize: "12px",
      width: "100px",
      fontWeight: 700,
    },
    flatfee: {
      color: "rgb(253 169 45)",
      backgroundColor: "rgb(255 242 221)",
      fontSize: "12px",
      fontWeight: "bold",
    },
    contingency: {
      color: "rgb(118 53 220)",
      backgroundColor: "rgb(236 224 251)",
      fontSize: "12px",
      fontWeight: "bold",
    },
    edit: {
      fontFamily: "Inter, sans-serif",
      borderRadius: "6px",
      color: "green",
      backgroundColor: "#5be49b33",
      boxShadow:
        "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
      fontWeight: 750,
      fontSize: "13px",
    },
    delete: {
      fontFamily: "Inter, sans-serif",
      borderRadius: "6px",
      color: "rgb(255 76 81)",
      backgroundColor: "rgb(255 233 234)",
      boxShadow:
        "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
      fontWeight: 750,
      fontSize: "13px",
    },
    template: {
      height: "auto",
      padding: "0.45em 0.775em",
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: 1,
      borderRadius: "0.6rem",
      position: "relative",
    },
    ControlBox: {
      border: "1px solid #ccc",
      borderRadius: "8px",
      marginTop: '14px',
      boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
      display: "flex",
      marginLeft: "0px",
      justifyContent: "space-between",
    },
    icon: {
      marginRight: "8px",
      cursor: "pointer",
    },
    color1: {
      backgroundColor: "rgb(230, 247, 255)",
      color: "rgb(24, 144, 255)",
    },

    color2: {
      backgroundColor: " rgb(237, 231, 246)",
      color: "rgb(94, 53, 177)",
    },

    tablecell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    dynamicControl: {
      width: "600px",
      border: "2px solid black",
      alignItems: "center",
    },
    formbuild: {
      lexDirection: "column",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 60vh - 150px)",
        minHeight: "calc( 60vh - 100px)",
      },
      alignItems: "left !important",
      backgroundColor: "#fff",
      alignContent: "left",
      overflowY: "auto",
      overflowX: "hidden",
    },
    heading: {
      padding: "0px 0px 0px 70px",
      color: "white",
      backgroundColor: "rgb(59 74 84)",
      fontFamily: "initial",
    },

    tablebodyConainer: {
      borderRadius: 0,
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 260px)",
        minHeight: "calc( 100vh - 260px)",
      },
    },
    titleheader: {
      fontWeight: "bold",
      marginTop: "5px",
      display: "flex",
      justifyContent: "center",
    },
    CheckBox: {
      backgroundColor: "rgb(219 246 229)",
      color: "rgb(23 144 91)",
      borderRadius: "10px",
      marginLeft: "21px",
      fontWeight: "bold",
    },
    draganddrop: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "5px",
    },
    gridStyle: {
      backgroundColor: "smokewhite",
      borderBottom: "2px groove rgb(34 197 94)",
    },
    gridStyle1: {
      borderRight: "2px dotted rgb(34 197 94)",
      padding: "10px 5px",
    },
    radioContainer: {
      display: "flex",
      alignItems: "center",
    },
    horizontalLine: {
      display: "flex",
      alignItems: "center",
      maxHeight: "15px",
      minHeight: "15px",
    },
    yesbutton: {
      backgroundColor: "green",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "green",
        color: "white",
      },
    },
    nobutton: {
      background: "red",
      borderRadius: "20px",
      color: "white",
      fontSize: 12,
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    },
    textAlginEnd: {
      textAlign: "left",
      fontSize: 12,
      padding: "2px 0px",
    },
    gridDisplay: {
      display: "flex",
      justifyContent: "flex-end",
    },
    box: { padding: "0px 0px 0px 15px !important ", color: "white !important " },
    tableCellStyle: {
      padding: "3px 15px !important",
      "& .MuiTableCell-root": {
        padding: "3px 15px!important",
      },
      fontSize: "13px !important",
    },
    inputField: {
      //   width: '80%',
      padding: "0px 50px 0px 20px",
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px', // Reduce font size on smaller screens
      },
    },
    deleteIcon: {
      color: "red !important",
      marginLeft: "8px !important",
    },
    editIcon:{
      color:"green !important",
    },
    pageHeader: {
      marginTop: '10px',
      marginBottom: '8px',
      textTransform: 'uppercase',
      fontSize: 13
    },
    textStyle1: {
      fontSize: 16,
      fontWeight: "bold",
      color: "blue",
    },
    tableHead: {
      position: "sticky",
      top: "0",
      zIndex: 1020
    },
    tableCellHeader: {
      padding: "12px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: 'bold'
    },
    textStyle: {
      fontSize: 16,
      fontWeight: "bold",
      margin: "5px !important",
    },
    permittedChecked: {
      backgroundColor: "#dbf6e5",
      color: "green",
      borderRadius: "5px",
      marginTop: "5px",
      marginLeft: "5px",
      width: "350px",
      fontWeight: "bold",
    },
    toobar: {
      padding: "0px 0px 0px 20px",
      color: "white",
      backgroundColor: "skyblue",
      fontFamily: "initial",
    },
    permittedFiled: {
      width: "350px",
      marginTop: "5px",
      marginLeft: "5px",
      backgroundColor: "white",
      color: "black",
      borderRadius: "5px",
    },
    headtool: {
      backgroundColor: "#3b4a54",
      height: "560px",
      color: "whitesmoke",
    },
    permittedChecked1: {
      backgroundColor: "#dbf6e5",
      color: "green",
      borderRadius: "5px",
      marginTop: "5px",
      marginLeft: "0px",
      width: "100px",
      fontWeight: "bold",
    },
    avatar: {
      "&.MuiAvatar-root": {
        width: 30,
        height: 30,
      },
    },
    ellipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    ContainerStyle: {
      backgroundColor: "rgb(57 71 81)",
      color: "#fff",
      padding: "10px",
      textAlign: "center",
      borderTopLeftRadius: "7px",
      borderTopRightRadius: "7px",
    },
    commonControl: {
      "&.css-1p823my-MuiListItem-root": {
        backgroundColor: "#e1ecf4",
        padding: "10px 10px 10px 10px",
        borderRadius: "10px",
        marginTop: "5px",
      },
    },
    button: {
      background: "#3b4a54",
      color: "white",
      "&.css-cz2cky-MuiButtonBase-root-MuiButton-root": {
        marginLeft: "10px !important",
        marginTop: "4px",
      },
    },
    templateStyle: {
      width: "200px",
      marginTop: "1px",
      marginLeft: "2px",
    },
    boostrapdialog: {
      "& .MuiDialog-paper": {
        width: "150%",
      },
    },
    dialogStyles: {
      backgroundColor: "#3b4a54",
      color: "white",
      textAlign: "center",
      padding: "12px !important",
    },

    confirmstyle: {
      backgroundColor: "green !important",
      color: "white !important",
      borderRadius: '8px'
    },
    closeStyle: {
      backgroundColor: "red !important",
      color: "white !important",
      borderRadius: '8px'
    },
    buttonStyle: {
      margin: "5px",
      color: "rgb(35 129 216)",
      backgroundColor: "rgb(190 233 255)",
    },
    dashboardConainer: {
      padding: 5,
      overflow: "auto",
      maxHeight: "600px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 70px)",
        minHeight: "calc( 100vh - 70px)",
      },
    },
    WidgetGridStyle: {
      width: "800px",
      height: "550px",
    },
    AutoComplete: {
      width: 300,
    },
    checkBoxStyle: {
      color: "#008216",
    },
    TextBox: {
      width: "100%",
    },
    PieDivStyle: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    configurationConainer: {
      borderBottom: '2px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 230px)',
        minHeight: 'calc( 100vh - 320px)',
      },
    },
    tablecardConainer: {
      borderBottom: '2px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 370px)',
        minHeight: 'calc( 100vh - 370px)',
      },
    },
    divStyles: {
      display: "flex",
    },
    Eventually: {
      marginLeft: "100px",
      display: "flex",
      justifyContent: "center",
    },
    EventuallySpanStyle: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      backgroundColor: "#357cd2",
      marginRight: "5px",
    },
    InitiatorSpanStyle: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      backgroundColor: "#00bdae",
      marginRight: "5px",
    },
    SetFeeSpanStyle: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      backgroundColor: "#e36593",
      marginRight: "5px",
    },
    divChart: {
      marginLeft: '10px'
    },
    ChartContainer: {
      height: "90%",
      width: "100%"
    },
    ChartComponent: {
      textAlign: "center"
    },
    VisitorsChart: {
      height: "100%",
      width: "100%"
    },
    shadowStyle: {
      boxShadow:
        "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px"
    }
  })
);

export default useStyles;
