import { TableCell, Theme, styled } from '@mui/material';

const BDebtTableCell = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'customStyle',
})(
    ({ theme, customStyle }: { theme: Theme; customStyle?: React.CSSProperties }) => ({
        '& .MuiTableCell-root': {
            padding: "5px !important"
        }
    })
);

export default BDebtTableCell;
