/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import * as React from 'react';
import { GridRowModes, GridRowEditStopReasons} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import useStyles from '../PlacementCss';
import Scrollbars from 'react-custom-scrollbars';
import StyledDataGrid from './StyledDataGrid';
import CustomPagination from './CustomPagination';
interface DeletedFieldRecordProps {
  deletedRecords: any[];
  fieldMappings: any[];
}

const DeletedColumnField: React.FC<DeletedFieldRecordProps> = (props) => {
  const classes = useStyles();
  const { deletedRecords, fieldMappings } = props;
  const [rows, setRows] = useState([]);
  const [rowModel, setRowModel] = useState({});
  

  useEffect(() => {
    // Update rowModesModel when deletedRecords changes
    if (deletedRecords && deletedRecords?.length > 0) {
      const newRowModesModel = {};
      deletedRecords?.forEach((data) => {
        newRowModesModel[data?.id?.toString()] = { mode: GridRowModes.View };
      });
      setRowModel(newRowModesModel);
      setRows(deletedRecords);
    } else {
      // Handle the case where deletedRecords is null or empty
      setRowModel({});
      setRows([]);
    }
  }, [deletedRecords]);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows((oldRows) => oldRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const dynamicColumns = fieldMappings?.filter((mapping) => mapping?.mappedField?.trim())?.map((mapping) => ({
    field: mapping?.mappedField,
    headerName: mapping?.title,
    width: 150,
    editable: true,
  }));

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={10}
      className={classes.ErrorRecordContainer}
    >
      <StyledDataGrid
        rows={rows}
        columns={dynamicColumns}
        editMode="row"
        rowModesModel={rowModel}
        onRowEditStop={(params, event) => {
          if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
          }
        }}
        processRowUpdate={processRowUpdate}
        rowHeight={30}
        isCellEditable={(params) => params.field !== 'actions' && !rowModel[params.row.id]?.isNew}
        getRowId={(row) => row.id}
        slotProps={{
          toolbar: { setRows, setRowModel },
        }}
        sx={{
          backgroundColor: 'white'
        }}
        className={classes.GridFontStyle}
        components={{
          Pagination: (paginationProps) => ( // Pass paginationProps here
            <CustomPagination {...paginationProps} rows={rows} />
          ),
        }}
      />
    </Scrollbars>
  );
};

export default DeletedColumnField;