/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Box, Checkbox, Paper, TableContainer } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Scrollbars from "react-custom-scrollbars";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { Field, IGetTableSchemaResponse, IGetTargetMapping } from "../../../Model/Configuration";
import { dataTypes } from "../../../MockData/Placement/DataRuleMock";
import useStyles from "../PlacementCss";
import { Grid } from "@mui/material";
import { usePost } from "../../../utils/apiHelper";
import { IconButton } from "@mui/material";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import SaveIcon from '@mui/icons-material/Save';
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ReorderIcon from '../../../asset/image/ReorderIcon.svg';


const GeneralMapping = (props) => {
    const { fieldMappings, tableDataSchema, datasetFieldMappings } = props;
    const classes = useStyles();
    // source
    const [importFields, setImportFields] = useState<Field[]>(fieldMappings);
    // destination
    const [destinationUploadedFile, setDestinationUploadedFile] = useState<File>(null);
    const [destinationFields, setDestinationFields] = useState<Field[]>(tableDataSchema);
    const [connection, setConnection] = useState();
    const [targetData, setTargetData] = useState([]);
    const [fields, setFields] = useState([]);
    const [unMatchedfields, setUnmatchedFields] = useState([]);

    useEffect(() => {
        const matched = [];
        for (let i = 0; i < fieldMappings?.length; i++) {
            const fieldName = fieldMappings[i]?.title?.replace(/\s+/g, ''); // Removes all whitespace characters
            for (let j = 0; j < tableDataSchema?.length; j++) {
                const columnName = tableDataSchema[j]?.columnName;
                if (fieldName === columnName) {
                    matched?.push({ columnName: columnName }); // Store the matched column name

                    break;
                }
            }
        }
        const matchedColumnNames = matched?.map(field => field.columnName);
        const matchedColumns = matchedColumnNames?.map(columnName => {
            return tableDataSchema?.find(header => header?.columnName === columnName);
        });
        setFields(matchedColumns);
        const unmappedHeaders = tableDataSchema?.filter(header => !matchedColumnNames?.includes(header?.columnName));
        setUnmatchedFields(unmappedHeaders);

    }, [tableDataSchema, fieldMappings]);

    const handleTableChange = (newValue) => {
        const request = {
            "connectionString": connection,
            "tableName": newValue.tableList,
        };
        // Assuming usePost is a custom hook for making HTTP POST requests
        usePost<IGetTableSchemaResponse[]>('GetSqlTable/GetTableSchemaAsync', request)
    };

    const handleTargetmapping = (connectionString: string, tableName: string) => {
        if (!connectionString || !tableName) {
            console.error('Connection string or table name is missing!');
            return;

        }

        const request = {
            "connectionString": connectionString,
            "tableName": tableName,
            "columns": [],
        };
        // Assuming usePost is a custom hook for making HTTP POST requests
        usePost<IGetTargetMapping[]>('GetSqlAll/InsertData', request).then((response) => {
            setTargetData(response.data);
        });
    };

    const handleDestinationFileUpload = (event: any) => {
        const files: FileList = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setDestinationUploadedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target) {
                    const contents: string = e.target.result as string;
                    const lines = contents.split("\n");
                    if (lines.length > 0) {
                        const headers = lines[0].split(",");
                        const initialFields = headers.map((header) => ({
                            columnName: header.trim(),
                            dataType: "Text",
                            isRequired: false,
                        }));
                        setDestinationFields(initialFields);
                    }
                }
            };
            reader.readAsText(file);
        }
    };

    const handleDeleteField = (index) => {
        const updatedFields = [...fields];
        updatedFields.splice(index, 1);
        setFields(updatedFields);
    };

    const addNewRecord = () => {
        const newRecord = {
            columnName: "",
            dataType: "",
            isRequired: false
        };

        setFields([...fields, newRecord]);
    };

    const CsvFieldHeader = () => {
        return (
            <Grid container spacing={0} className={classes.stickyHeader}>
                <Grid item xs={12} sm={12} md={1} >
                    <Checkbox size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={2} alignItems={"center"} display={"flex"} justifyContent={"start"}>
                    <BDebtTypography variant="subtitle2" >Reorder</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} alignItems={"center"} display={"flex"} justifyContent={"start"}>
                    <BDebtTypography variant="subtitle2" >Field Name</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} alignItems={"center"} display={"flex"} justifyContent={"start"}>
                    <BDebtTypography variant="subtitle2">Type</BDebtTypography>
                </Grid>
            </Grid>
        );
    };


    const DestinationHeader = () => {
        return (
            <Grid container spacing={0} className={classes.stickyHeaderStyle}>
                <Grid item xs={12} sm={12} md={4} alignItems={"center"} display={"flex"} justifyContent={"end"} >
                    <BDebtTypography variant="subtitle2" >Field Name</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} alignItems={"center"} display={"flex"} justifyContent={"end"} >
                    <BDebtTypography variant="subtitle2">Type</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={2} alignItems={"center"} display={"flex"} justifyContent={"end"}>
                    <BDebtTypography variant="subtitle2">Add</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                    <BDebtTypography variant="subtitle2">Skip</BDebtTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={1} alignItems={"center"} display={"flex"} justifyContent={"flex-end"}>
                    <BDebtTypography variant="subtitle2">Reorder</BDebtTypography>
                </Grid>
            </Grid>
        );
    };
    const CsvField = ({ field, index }: any) => {
        return (
            <div className={classes.draggableField}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={1}>
                        <Checkbox checked={field?.required} size="small" />
                    </Grid>
                    <Grid xs={12} sm={6} md={1} style={{ marginTop: "7px" }}>
                        <img src={ReorderIcon} alt="Database" width="25px" height="25px" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container spacing={0}>
                            <Grid item xs={11}>
                                <Autocomplete
                                    size="small"
                                    classes={{
                                        paper: classes.dropdownPaper,
                                        option: classes.autoCompleteFont,
                                    }}
                                    options={datasetFieldMappings?.map((field) => field?.fieldName)}
                                    value={field?.mappedField}
                                    renderInput={(params) => (
                                        <BDebtTextField variant="outlined" {...params} size="small" />
                                    )}
                                    className={field?.required ? classes.isRequiredFiledStyle : ''}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <Autocomplete
                            size="small"
                            options={datasetFieldMappings?.map(field => field?.dataType)} // Map dataType from each object
                            value={datasetFieldMappings?.length > index ? datasetFieldMappings[index]?.dataType : ''}
                            classes={{
                                paper: classes.dropdownPaper,
                                option: classes.autoCompleteFont,
                            }}
                            renderInput={(params) => (
                                <BDebtTextField variant="outlined" {...params} size="small" />
                            )}
                            className={field?.required ? classes.isRequiredFiledStyle : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} alignItems={"center"} display={"flex"} justifyContent={"center"} className={classes.borderGridStyle}>
                        <ArrowRightAltIcon />
                    </Grid>
                </Grid>
            </div>
        );
    };

    const DestinationCsvField = ({ field, index, onDelete, unMatchedColumns }: any) => {
        const handleDelete = () => {
            onDelete(index);
        };

        return (
            <div className={classes.draggableField}>
                <Grid container spacing={0} pt={0.3} >
                    <Grid item xs={12} sm={6} md={1}></Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <Grid container spacing={0}>
                            <Grid item xs={11}>
                                <Autocomplete
                                    size="small"
                                    classes={{
                                        paper: classes.dropdownPaper,
                                        option: classes.autoCompleteFont,
                                    }}
                                    onChange={handleTableChange}
                                    options={unMatchedColumns?.map(field => field?.columnName)}
                                    value={field?.columnName}
                                    renderInput={(params) => (
                                        <BDebtTextField variant="outlined" {...params} size="small" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            size="small"
                            options={dataTypes}
                            value={field?.dataType}
                            classes={{
                                paper: classes.dropdownPaper,
                                option: classes.autoCompleteFont,
                            }}
                            onChange={handleTableChange}
                            renderInput={(params) => (
                                <BDebtTextField variant="outlined" {...params} size="small" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                        <AddIcon
                            fontSize="small"
                            className={classes.icon}
                            onClick={addNewRecord}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                        <DeleteOutlineOutlinedIcon
                            fontSize="small"
                            className={classes.icon}
                            onClick={handleDelete}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={1} alignItems={"center"} display={"flex"} justifyContent={"center"} >
                        <img src={ReorderIcon} alt="Database" width="25px" height="25px" style={{ transform: "rotate(540deg)" }} />
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <Box p={1} sx={{ padding: 0 }}>
            <Grid component={Paper} container spacing={0} className={classes.pageContainer}>
                <React.Fragment>
                    <Grid container spacing={0} className={classes.gridSearchbar}>
                        <Grid item xs={5} alignItems={"center"} display={"flex"} justifyContent={"center"} >
                            <BDebtTypography variant="subtitle2">Processed Source</BDebtTypography>
                        </Grid>
                        <Grid item xs={6} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                            <BDebtTypography variant="subtitle2">Target Mapping</BDebtTypography>
                        </Grid>
                        <Grid item xs={1} alignItems={"center"} display={"flex"} justifyContent={"flex-end"}>
                            <input
                                type="file"
                                onChange={handleDestinationFileUpload}
                                style={{ display: 'none' }}
                                id="destinationFileInput"
                            />
                            <label htmlFor="destinationFileInput">
                                <IconButton component="span" className={classes.JoinIconButton}>
                                    <BDebtTooltip title="Destination Schema" arrow placement="top-end">
                                        <UploadFileIcon
                                            fontSize="small"></UploadFileIcon>
                                    </BDebtTooltip>
                                </IconButton>
                            </label>
                            &nbsp;
                            <IconButton className={classes.JoinIconButton}>
                                <BDebtTooltip title="Save Target Mapping" arrow placement="top-end" onClick={() => handleTargetmapping(props?.connectionString, props?.tableName)}>
                                    <SaveIcon fontSize="small" />
                                </BDebtTooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} style={{ marginBottom: "2px" }}>
                        <Grid item xs={12} md={6}>
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TableContainer className={classes.TabPanelStyle}>
                                        <Scrollbars
                                            autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={200}
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={510}
                                            thumbMinSize={30}
                                            universal={true}>
                                            <CsvFieldHeader />
                                            {Object?.values(fieldMappings)?.map((field, index) => (
                                                <div
                                                    key={index}
                                                    draggable>
                                                    <CsvField
                                                        key={index}
                                                        field={field}
                                                        index={index} />
                                                </div>
                                            ))}
                                        </Scrollbars>
                                    </TableContainer>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Scrollbars
                                            autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={200}
                                            autoHeight
                                            autoHeightMin={0}
                                            autoHeightMax={510}
                                            thumbMinSize={30}
                                            universal={true}>
                                            <DestinationHeader />
                                            {(destinationFields && destinationFields.length > 0) ?
                                                fields?.map((field, index) => (
                                                    <div
                                                        key={index}>
                                                        <DestinationCsvField
                                                            key={index}
                                                            field={field}
                                                            index={index}
                                                            unMatchedColumns={unMatchedfields}
                                                            schemaType="destination"
                                                            style={{ marginTop: '10px' }}
                                                            onDelete={handleDeleteField} />
                                                    </div>
                                                ))
                                                :
                                                (tableDataSchema && tableDataSchema?.length > 0) &&
                                                tableDataSchema?.map((field, index) => (
                                                    <div
                                                        key={index}>
                                                        <DestinationCsvField
                                                            key={index}
                                                            field={field}
                                                            index={index}
                                                            unMatchedColumns={unMatchedfields}
                                                            schemaType="destination"
                                                            style={{ marginTop: '10px' }}
                                                            onDelete={handleDeleteField}/>
                                                    </div>
                                                ))
                                            }
                                        </Scrollbars>
                                    </TableContainer>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </React.Fragment >
            </Grid >
        </Box>
    );
};

export default GeneralMapping;