import { DialogContentText, styled } from "@mui/material";

interface BDebtDialogContentTextProps {
    textAlign?: string; // Add textAlign property to BDebtCardProps
    color?: string;
  }

  const BDebtDialogContentText = styled(DialogContentText, {
    shouldForwardProp: (prop) => prop !== "textAlign" && prop !== "color", // Exclude 'borderRadius' and 'textAlign' from being passed to the underlying DOM element
  })<BDebtDialogContentTextProps>(({ textAlign, color }) => ({
  "&.MuiDialogContentText-root": {
    // padding: theme.spacing(2), // Add your custom styles
    fontSize: '16px', // Example: Change font size
    textAlign: textAlign || "left", // Use the provided textAlign or default to "left"
    color: color || "black", // Additional styles if needed

  },
}));

export default BDebtDialogContentText;
