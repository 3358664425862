import { Table, Theme, styled } from "@mui/material";

const BDebtTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== "customStyle",
})(
  ({
    theme,
    customStyle,
  }: {
    theme: Theme;
    customStyle?: React.CSSProperties;
  }) => ({

    "&.MuiTableContainer-root": {
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      width:"20px"
    },
   
    "& .MuiTableCell-root": {
    // backgroundColor:"#9022c7",
      borderBottom: `1px solid ${theme.palette.divider}`, // Add border between table cells
      width:"20px",
    },
    "& .MuiTable-root":{
        width:"100%",
        height:"100%",
    }
  })
);

export default BDebtTable;
