/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
//Controls
import Papa from "papaparse";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { DialogContent, DialogActions, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, FormControlLabel, Grid, Paper, Step, StepLabel, Stepper, Hidden } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
//MockData
import { FormData, Phase, VisuallyHiddenInput, clientcode, GetDataSets } from "../../../MockData/Placement/placement";
//Icons
import ColorlibStepIcon from "./style";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//SVG
import logo from "../../../asset/image/Login/Cog_Logo.png";
import cloud from "../../../asset/image/Placement/cloud.svg";
import submit from "../../../asset/image/Placement/submit.svg";
import upload from "../../../asset/image/Placement/upload.svg";
import preview from "../../../asset/image/Placement/preview.svg";
import dropbox from "../../../asset/image/Placement/dropbox.svg";
import onedrive from "../../../asset/image/Placement/onedrive.svg";
import transform from "../../../asset/image/Placement/transform.svg";
import maptarget from "../../../asset/image/Placement/maptarget.svg";
import localdrive from "../../../asset/image/Placement/localdrive.svg";
import googledrive from "../../../asset/image/Placement/googledrive.svg";
//Css
import useStyles from "../PlacementCss";
// Modules
import { Android12Switch } from "../../../GlobalControl/Android12Switch";
import * as constant from "../../../Constant/Constant";
import { usePost } from "../../../utils/apiHelper";

const steps = ["Load", "Transform", "Preview", "Map Target", "Submit"];

let data: FormData = {
  clientcode: "",
  Phase: "",
  Dataset: "",
};

const Upload = () => {
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState(data);
  const [dataSetId, setDataSetId] = useState(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [datasetOptions, setDatasetOptions] = useState<GetDataSets[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!showConfirmationDialog) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const unblock = history.block((location, action) => {
      if (!showConfirmationDialog && action === 'POP') {
        const isDataUploaded = formData.clientcode && formData.Phase && formData.Dataset && isFileUploaded;
        if (!isDataUploaded) {
          setShowConfirmationDialog(true);
          return false;
        }
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [showConfirmationDialog, history, isBackButtonClicked]);

  const handleCancelNavigation = () => {
    setShowConfirmationDialog(false);
  };

  const handleStepClick = (step: number) => () => {
    setActiveStep(step === activeStep ? 0 : step);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }

  const handleSwitchClick = () => {
    const newFormData: FormData = isSwitchOn
      ? { clientcode: '', Phase: '', Dataset: '' }
      : {
        clientcode: constant.clientcode,
        Phase: constant.Phase,
        Dataset: '',
      };

    setFormData(newFormData);
    setIsSwitchOn((prev) => !prev);

    if (!isSwitchOn) {
      GetDataSetDetail(constant.clientcode);
    } else {
      if (formData.clientcode) {
        GetDataSetDetail(formData.clientcode);
      }
    }
  };

  const fileBrowserList = [
    { icon: upload, title: "UPLOAD", selected: "Local", alt: "cloud-icon", buttonName: "Upload File" },
    { icon: transform, title: "TRANSFORM", selected: "Cloud", buttonName: "UPLOAD FROM CLOUD" },
    { icon: preview, title: "PREVIEW", selected: "GoogleDrive", buttonName: "UPLOAD FROM Google Drive" },
    { icon: maptarget, title: "MAP TARGET", selected: "OneDrive", buttonName: "UPLOAD FROM OneDrive" },
    { icon: submit, title: "SUBMIT", selected: "DropBox", buttonName: "UPLOAD FROM DropBox" },
  ];

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result as string;
        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            if (result.errors.length > 0) {
              return setIsDialogOpen(true);
            } else {
              let currentData: any[] = result.data;
              const newArray = currentData.map((item, index) => ({ ...item, id: index + 1, }));

              history.push("/columnfieldmapping", {
                uploadedData: newArray,
                dataSetId: dataSetId,
                ingestDetails: formData,
                clientCode: formData.clientcode,
                phaseCode: formData.Phase,
                dataset: formData.Dataset,
                fileName: file.name,
                fileDataLength: currentData.length,
              });
            }
          },
        });
      };
      setIsFileUploaded(true);
      reader.readAsText(file);
    }
  };

  const handleFormChange = (field: keyof FormData, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleGetDataSet = (event, selected) => {
    setDataSetId(selected?.dataSetId);
    let value = selected?.dataSetName;
    setFormData({
      ...formData,
      ["Dataset"]: value,
    });
  };

  useEffect(() => {
    if (formData.clientcode && formData.Phase && formData.Dataset) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [formData]);

  const GetDataSetDetail = async (selectedClientCode: string) => {
    let request = {
      clientCode: selectedClientCode
    }
    await usePost<GetDataSets[]>('Dataset/GetDataSetPlacement', request).then((result) => {
      setDatasetOptions(result.data);
    });
  }

  const handleClientCodeChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    handleFormChange("clientcode", value || "");

    if (value) {
      GetDataSetDetail(value);
    }
  };
  function Icon() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.socialIcons}>
              <span className={`${classes.socialIconStyle} ${classes.googleStyle}`}>
                <img src={localdrive} alt="google" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.facebookStyle}`}>
                <img src={cloud} alt="facebook" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.microsoftStyle}`}>
                <img src={dropbox} alt="microsoft" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.twitterStyle}`}>
                <img src={onedrive} alt="twitter" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.linkedinStyle}`}>
                <img src={googledrive} alt="linkedIn" className={classes.image} />
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={0} component={Paper} className={classes.pageContainer}>
        <Grid xs={activeStep > 0 ? 10 : 11} className={activeStep > 0 ? classes.stepperActive : classes.stepperInActive} sx={{ borderBottom: "1px solid lightgray" }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} onClick={index === 0 ? handleStepClick(index) : null}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid xs={1} mt={'4px'}  >
          <FormControlLabel className={classes.formControl}
            control={<Android12Switch defaultChecked checked={isSwitchOn} onChange={handleSwitchClick} />}
            label="Demo"
            labelPlacement="start"
            style={{ height: '32px' }}
          />
        </Grid>
      </Grid>
      {activeStep === 0 && (
        <div className={classes.div}>
          <Grid container spacing={1} className={classes.root}>
            <Hidden smDown>
              <Grid item xs={12} sm={5} className={classes.imageDisplay}>
                <Carousel autoPlay interval={3000} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop >
                  {fileBrowserList?.map((item) => (
                    <div>
                      <Typography variant="caption" className={classes.imageStyle}>
                        {item?.title}
                      </Typography>
                      <img src={item?.icon} alt={item?.alt} height="380px" width="380px" />
                    </div>
                  ))}
                </Carousel>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={7}>
              <div>
                <Grid container spacing={1} sx={{ padding: "8px 0px" }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      <img src={logo} height="40px" alt="companylogo" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="combo-box-demo"
                      options={clientcode}
                      value={formData.clientcode}
                      onChange={handleClientCodeChange}
                      classes={{
                        paper: classes.dropdownPaper,
                        option: classes.autoCompleteFont,
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Company Code"
                          {...params}
                          className={classes.dropDownStyle}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: {
                              root: classes.dropDownInput,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      size="small"
                      id="combo-box-demo"
                      options={Phase}
                      value={formData.Phase}
                      onChange={(event, value) => handleFormChange("Phase", value || "")}
                      classes={{
                        paper: classes.dropdownPaper,
                        option: classes.autoCompleteFont,
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Project"
                          {...params}
                          className={classes.dropDownStyle}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            classes: {
                              root: classes.dropDownInput,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={datasetOptions}
                      classes={{
                        paper: classes.dropdownPaper,
                        option: classes.autoCompleteFont,
                      }}
                      getOptionLabel={(option) => option.dataSetName || ""}
                      onChange={(event, selected) => handleGetDataSet(event, selected)}
                      PaperComponent={({ children }) => (<Paper className={classes.autoCompletePaperStyle}>{children}</Paper>)}
                      renderInput={(params) => (
                        <TextField {...params} name="Dataset" placeholder="Dataset"
                          className={isSwitchOn ? !formData?.Dataset ? 'button' : classes.dropDownStyle : classes.dropDownStyle}
                          InputProps={{
                            ...params.InputProps,
                            name: 'Dataset',
                            disableUnderline: true,
                            classes: {
                              root: classes.dropDownInput,
                            },
                          }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.button}>
                    <Grid xs={12} className={classes.uploadbuttonStyle}>
                      <Button component="label" variant="contained" color="primary" size="small" fullWidth startIcon={<CloudUploadIcon />} disabled={!isButtonEnabled}>
                        Browse file
                        <VisuallyHiddenInput type="file" onChange={changeHandler} />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="line-container">
                        <div className="hr-left"></div>
                        <p className="line-text">
                          <b>Or Browse From</b>
                        </p>
                        <div className="hr-right"></div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justifyContent={"center"}>
                    <Grid item xs={8}>
                      <Icon />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
          <BDebtDialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            maxWidth={"sm"}
            PaperProps={{style:{ height:150, borderRadius: "5px"}}}
          >
            <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleDialogClose}>
              <Typography variant="body1">
                <b>Warning Message</b>
              </Typography>
            </BDebtDialogTitleHeader>
            <DialogContent>
              <BDebtTypography variant="body1"  className={classes.errorMessageStyle1}>
                Please select a valid CSV File to upload!
              </BDebtTypography>
            </DialogContent>
          </BDebtDialog>
        </div>
      )}
      <BDebtDialog open={showConfirmationDialog} maxWidth={"sm"} onClose={handleCancelNavigation} fullWidth>
        <BDebtDialogTitleHeader onClose={handleCancelNavigation} id="customized-dialog-title">
          Incomplete Data
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body2" textAlign="left">
            Kindly fill in all the required information!
          </BDebtTypography>
        </DialogContent>
        <DialogActions>
          <BDebtButton onClick={handleCancelNavigation} color="error">
            Cancel
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
};
export default Upload;
