import { RadioGroup, styled } from '@mui/material';
const BDebtRadioGroup = styled(RadioGroup)(
  () => ({
    '& .MuiRadioGroup-root': {
      margin: '2px',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default BDebtRadioGroup;