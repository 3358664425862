import { useState } from "react";
//MUI COMPONENTS
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
//ICONS
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@material-ui/icons/Search";
import DownloadIcon from '@mui/icons-material/Download';
//CUSTOM CSS
import useStyles from '../Ingress/IngressCss';
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtButton from "../../../GlobalControl/BDebtButton";

const SearchIngressDetails = () => {
  const classes = useStyles();
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [ingressEntity, setIngressEntity] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [actionFilter, setActionFilter] = useState<string>("");

  const handleSearch = () => {
    if (
      ingressEntity.trim() !== "" ||
      (startDate.trim() !== "" && endDate.trim() !== "") ||
      actionFilter.trim() !== ""
    ) {
      setShowSearchIcon(false);
    } else {
      setShowSearchIcon(true);
    }
  };

  const handleCancelSearch = () => {
    setIngressEntity("");
    setStartDate("");
    setEndDate("");
    setActionFilter("");
    setShowSearchIcon(true);
  };

  return (
    <div>
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <BDebtTypography variant="body2" textPadding="10px 0 0 0" color="primary"><b>INGRESS</b></BDebtTypography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              size="small"
              id="standard-bare"
              variant="outlined"
              className={classes.searchBox}
              type="text"
              value={ingressEntity}
              placeholder="Search All"
              onChange={(e) => setIngressEntity(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
              inputProps={{ maxLength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon ? (
                      <IconButton onClick={handleSearch} className={classes.buttonIcon}>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleCancelSearch} className={classes.buttonIcon}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={2} >
            <BDebtTextField
              fullWidth
              size="small"
              id="start-date"
              variant="outlined"
              type="date"
              value={startDate}
              placeholder="Start Date"
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <BDebtTextField
              fullWidth
              size="small"
              id="end-date"
              variant="outlined"
              type="date"
              value={endDate}
              placeholder="End Date"
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="space-between">
            <BDebtButton variant="contained" color="primary" onClick={handleSearch}>
              Filter
            </BDebtButton>
            <IconButton size="small" className={classes.iconButton}>
              <DownloadIcon color="inherit" />
            </IconButton>
          </Grid>    
        </Grid>
      </Box>
    </div>
  );
};

export default SearchIngressDetails;
