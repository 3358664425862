import { AccordionSummary, styled } from '@mui/material';

const BDebtAccordionSummary = styled(AccordionSummary, {
    shouldForwardProp: (prop) => prop !== 'customStyle',
})(
    () => ({
        '&.MuiAccordionSummary-root': {
            height: '0',
            minHeight: '40px',
            borderRadius: '5px',
            marginTop: '10px',
        },
        '&.MuiAccordionSummary-content ': {
            margin: "0",
            display: "flex",
            padding: "0px 1px 0px 2px",
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        '&.Mui-expanded': {
            color: 'white',
            background: '#3b4a54',
        },
        '& .css-yw020d-MuiAccordionSummary-expandIconWrapper': {
            color: '#000000!important',
            backgroundColor: 'white',
            borderRadius: '50%',
        },
        '& .css-ad83dk-MuiAccordionDetails-root.MuiAccordionDetails-root': {
            padding: '8px 16px!important',
        }
    })
);

export default BDebtAccordionSummary;
