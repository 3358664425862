import React,{useState} from "react";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TableContainer } from "@material-ui/core";
import BDebtTextField from "../../../../../GlobalControl/BDebtTextField";
import BDebtTypography from "../../../../../GlobalControl/BDebtTypography";
import useStyles from "../../WidgetCss";
import { Grid } from "@mui/material";


const connection = {
  connectionData:
    ["Sql Query", "Table", "Procedure"]
};

export const DataSourceContent = ({ selectedDatabase }) => {
  const classes = useStyles();
  const renderContent = () => {
    switch (selectedDatabase) {
      case "SQL Database":
        return <SQLDatabaseConfiguration />;
      case "MongoDB":
        return <BDebtTypography variant="body2">Selected MongoDB!</BDebtTypography>;
      default:
        return (
          <BDebtTypography variant="body2">
            Select a database from the sidebar.
          </BDebtTypography>
        );
    }
  };

  return (
    <Box>
      <Grid container spacing={0} mt={"0px"} >
        <Grid xs={12}>
          <Grid className={classes.gridTypography}>
            <BDebtTypography variant="h6"  >
              <b>Data source configuration</b>
            </BDebtTypography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid className={`${classes.SqltablebodyConainer} `}>
            {renderContent()}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

///Sql configuration sample page component
export const SQLDatabaseConfiguration = () => {
  const classes = useStyles();
  const [value, setValue] = useState("table");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <Grid container spacing={1}>
      <TableContainer
        className={`${classes.SqltablebodyConainer
          } ${"scrollbox"} ${"on-scrollbar"}`}
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}> Database Name</BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="Name"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}> Host Name </BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="Host Name"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}>Port Number</BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="Port Number"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}>User Name </BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="User Name"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}> Password</BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="Password"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}> Connection String</BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <BDebtTextField
              id="outlined-basic"
              placeholder="Connection string"
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <BDebtTypography variant={"button"}> Connection Mode</BDebtTypography>
          </Grid>
          <Grid item xs={9}>
            <FormControl size="small">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                className={classes.radioButtonDirection}
                onChange={handleChange}
              >
                {connection.connectionData.map((connectionData) => (
                  <FormControlLabel
                    control={<Radio size="small" />}
                    label={connectionData}
                    value={connectionData}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </TableContainer>
    </Grid>
  );
};