import { ListItem, ListItemText } from "@material-ui/core";
import BDebtList from "../../../../../GlobalControl/BDebtList";
import BDebtTypography from "../../../../../GlobalControl/BDebtTypography";
import { Data } from "../../../../../MockData/Widget";
import { Grid } from "@mui/material";
import { useState } from "react";
import useStyles from "../../WidgetCss";

export const DataSourceSidebar = ({ handleDatabaseChange }) => {
  const classes=useStyles();
  const [selectedDatabase, setSelectedDatabase] = useState(null);

  const handleListItemClick = (database) => {
    setSelectedDatabase(database);
    handleDatabaseChange(database);
  };

  return (
    <Grid container spacing={0} className={classes.borderRight}>
      <Grid xs={12}  className={`${classes.borderBottom} ${classes.gridTypography}`}>
        <BDebtTypography component="div" variant="h6">
          <b>Data source list</b>
        </BDebtTypography>
      </Grid>
      <Grid item xs={12}>
        <BDebtList>
          {Data.Database.map((database) => (
            <ListItem
              key={database}
              button
              selected={selectedDatabase === database}
              onClick={() => handleListItemClick(database)}
              className={classes.listItem}
            >
              <ListItemText>
                <b><BDebtTypography variant="body2">{database}</BDebtTypography></b>
              </ListItemText>
            </ListItem>
          ))}
        </BDebtList>
      </Grid>
    </Grid>
  );
};
