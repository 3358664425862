/* eslint-disable @typescript-eslint/no-redeclare */
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { ReactNode, useState } from "react";
import useStyles from './SettingsCss';
//Icons
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from '@mui/icons-material/Category';
import ChatIcon from "@mui/icons-material/Chat";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import GroupIcon from "@mui/icons-material/Group";
import HubIcon from "@mui/icons-material/Hub";
import LockIcon from "@mui/icons-material/Lock";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
//Custom Styles
import Scrollbars from "react-custom-scrollbars";
import MenuLevelSetting from "../../Components/Setting/MenuSubscription/MenuLevelSetting";
import Template from "../../Components/Setting/Template";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import Datasource from "../Datasource";
import Chatbot from "./Chatbot";
import DynamicDashboard from "./DynamicDashboard";
import IngestRules from "./IngestRules";
import Notification from "./Notification";
import Permission from "./Permission";
import Themes from "./Themes";
import UserLevel from "./UserLevel";

interface MenuItem {
    icon: ReactNode;
    text: string;
}

interface MenuItemProps extends MenuItem {
    onItemClick: () => void;
    isActive: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, text, onItemClick, isActive, }) => {
    const classes = useStyles();

    return (
        <ListItem
            button
            onClick={onItemClick}
            className={isActive ? classes.listItemActive : classes.listItemInactive}
        >
            <ListItemIcon className={isActive ? classes.listItemIconActive : classes.listItemIconInActive}            >
                {icon}
            </ListItemIcon>
            <ListItemText>
                <BDebtTypography variant="body2" className={isActive ? classes.listItemTextActive : classes.listItemTextInActive}>
                    {isActive ?
                        <span><b>{text}</b></span>
                        :
                        <span>{text}</span>
                    }
                </BDebtTypography>
            </ListItemText>
        </ListItem>
    );
};

interface DynamicComponentProps {
    activeItem: string | null;
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({ activeItem }) => {
    const componentMapping: Record<string, React.ReactNode> = {
        Menu: <MenuLevelSetting />,
        Users: <UserLevel />,
        Permission: <Permission />,
        Notifications: <Notification />,
        Themes: <Themes />,
        Configuration: <Datasource />,
        IngestRules: <IngestRules />,
        DashBoard: <DynamicDashboard />,
        DynamicForm: <Template />,
        ChatBot: <Chatbot />,

    };
    return <div>{activeItem && componentMapping[activeItem]}</div>;
};

const SettingsLayout: React.FC = () => {
    const classes = useStyles();
    const [activeItem, setActiveItem] = useState<string | null>("Menu");

    const handleItemClick = (itemText: string) => {
        setActiveItem(itemText === activeItem ? null : itemText);
    };

    const menuItems = [
        { icon: <MenuIcon fontSize="small" />, text: "Menu" },
        { icon: <GroupIcon fontSize="small" />, text: "Users" },
        { icon: <LockIcon fontSize="small" />, text: "Permission" },
        { icon: <NotificationsActiveIcon fontSize="small" />, text: "Notifications" },
        { icon: <DashboardCustomizeIcon fontSize="small" />, text: "DashBoard" },
        { icon: <DarkModeIcon fontSize="small" />, text: "Themes" },
        { icon: <HubIcon fontSize="small" />, text: "Configuration" },
        { icon: <BusinessCenterIcon fontSize="small" />, text: "IngestRules" },
        { icon: <CategoryIcon fontSize="small" />, text: "DynamicForm" },
        { icon: <ChatIcon fontSize="small" />, text: "ChatBot" },
    ];

    return (
        <Box m={1} p={0.8} >
            <Grid container spacing={0} boxShadow={2} borderRadius={1}>
                <Grid xs={12} md={12} sx={{ borderBottom: '1px solid lightgray' }} padding={0.5}>
                    <BDebtTypography component="div" variant="h6" marginTop="3px">
                        <b>SETTINGS</b>
                    </BDebtTypography>
                </Grid>
                <Grid item xs={2} >
                    {/* <Box m={0} p={1} sx={{ borderBottom: '1px dashed lightgray' }} >
                        <BDebtTypography component="div" variant="h6">
                            <b>SETTINGS</b>
                        </BDebtTypography>
                    </Box> */}
                    <Box m={0} p={0.8}>
                        <BDebtTextField variant='outlined' size='small' placeholder="Search..." fullWidth color='primary' />
                    </Box>
                    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.settingsSidebar} >
                        <List className={classes.listMui}>
                            {menuItems.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    icon={item.icon}
                                    text={item.text}
                                    onItemClick={() => handleItemClick(item.text)}
                                    isActive={item.text === activeItem}
                                />
                            ))}
                        </List>
                    </Scrollbars>
                </Grid>
                <Grid item xs={10} sx={{ borderLeft: '1px solid lightgray' }}>
                    <DynamicComponent activeItem={activeItem} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingsLayout;