/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//Controls
import React from "react";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { useTheme } from '@mui/material/styles';
import TextField from "@material-ui/core/TextField";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import { DialogContent, IconButton, InputAdornment } from "@material-ui/core";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { Grid, Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Snackbar, Typography, DialogActions } from "@mui/material";
//Css
import useStyles from "../PlacementCss";
//Mock Data
import { Field, GetDataSet } from "../../../MockData/Placement/DataRuleMock";
//Icons
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import TransformIcon from '@mui/icons-material/Transform';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import JoinFullTwoToneIcon from "@mui/icons-material/JoinFullTwoTone";
import BDebtButton from "../../../GlobalControl/BDebtButton";

interface IRecordCombineViewProps {
  datasetDetail: GetDataSet[];
  addNewRow: any[];
  onRowUpdate: (updatedRow: any) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onAddNewRecord: () => void;
  selectedRowData: any; 
  onDeletedRecordsChange: (records:any) => void;
}

const RecordsCombineView: React.FC<IRecordCombineViewProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { addNewRow, datasetDetail, isOpen, onOpen, onClose, onAddNewRecord, onDeletedRecordsChange } = props;
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(props?.selectedRowData ? props?.selectedRowData : 0);
  const [editedRows, setEditedRows] = useState<any[]>(addNewRow);
  const [originalValues, setOriginalValues] = useState<any[]>([]);
  const [deletedRecords, setDeletedRecords] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const calculateDefaultFieldMappings = () => {
    return datasetDetail?.flatMap((dataset) =>
      dataset?.fieldsDetail?.map((field: Field) => ({
        fieldName: field?.fieldName,
        dataSetId: field?.dataSetId,
        dataType: field?.dataType,
        ruleId: field?.ruleId,
        isRequired: field?.isRequired,
        dateFormatId: field?.dateFormatId,
      }))
    );
  };

  const defaultFieldMappings = calculateDefaultFieldMappings();

  const [fieldValues, setFieldValues] = useState(defaultFieldMappings);
  const [history, setHistory] = useState<Array<Array<Field>>>([defaultFieldMappings]);
  const [pointer, setPointer] = useState<number>(0);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && currentIndex > 0) {
        handlePrevious();
      } else if (event.key === "ArrowRight" && currentIndex < addNewRow.length - 1) {
        handleNext();
      } else if (event.key === "ArrowUp") {
        handleFirstRecord();
      } else if (event.key === "ArrowDown") {
        handleLastRecord();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, addNewRow]);

  useEffect(() => {
    setEditedRows([...addNewRow]);
    setOriginalValues(JSON.parse(JSON.stringify(addNewRow)));
  }, [addNewRow]);

  const handleTextFieldChange = (fieldName: string, newValue: string) => {
    setEditedRows(prevRows =>
      prevRows.map(row => ({
        ...row,
        [fieldName]: row === editedRows[currentIndex] ? newValue : row[fieldName]
      }))
    );
  };

  useEffect(() => {
    // Update the record data when selectedRowData changes
    setCurrentIndex(props?.selectedRowData - 1);
  }, [props?.selectedRowData]);

  const handleSave = () => {
    props.onRowUpdate(editedRows);
    setOriginalValues([...editedRows]);
    setSnackbarOpen(true);
    setSnackbarMessage("Updated record saved successfully!");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUndo = () => {
    if (pointer > 0) {
      setPointer(pointer - 1);
      setFieldValues(history[pointer - 1]);
    }
  };

  const handleRedo = () => {
    if (pointer < history.length - 1) {
      setPointer(pointer + 1);
      setFieldValues(history[pointer + 1]);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? addNewRow.length - 1 : prevIndex - 1
    );
  };

  const handleFirstRecord = () => {
    setCurrentIndex(0);
  };

  const handleLastRecord = () => {
    setCurrentIndex(addNewRow.length - 1);
  };

  const handleDelete = (indexToDelete: number) => {
    const newRows = [...editedRows];
    const deletedRow = newRows?.splice(indexToDelete, 1); // Remove the row at the specified index
    setEditedRows(newRows);
    setOriginalValues(newRows);
    // Update deletedRecords state using functional update to include both old and new deleted records
    setDeletedRecords([...deletedRecords, ...deletedRow]);
    onDeletedRecordsChange([...deletedRecords, ...deletedRow]);
    setIsConfirmationOpen(false);
  };

  const handleAddNewRecord = () => {
    onClose();
    onAddNewRecord();
  };

  const openDialog = () => {
    onOpen();
    setCurrentIndex(0);
  };

  const closeDialog = () => {
    onClose();
  };

  const confirmDelete = (index: number) => {
    handleDelete(index);
    setIsConfirmationOpen(false);
  };

  const handleOpenDelete = () => {
    setIsConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  return (
    <div>
      <BDebtTooltip title="Combined View">
        <IconButton
          onClick={openDialog}
          className={classes.IconButton}
          size="small"
        >
          <JoinFullTwoToneIcon />
        </IconButton>
      </BDebtTooltip>
      <BDebtDialog
        open={isOpen}
        maxWidth={"md"}
        onClose={closeDialog}
        fullWidth
      >
        <BDebtDialogTitleHeader
          onClose={closeDialog}
          id="customized-dialog-title"
        >
          Manage Dataset - Records
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid component={Paper} container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                size="small"
                id="standard-bare"
                variant="outlined"
                value={searchDebtor}
                className={classes.searchBox}
                type="text"
                placeholder="Search All"
                onChange={(e) => setSearchDebtor(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && searchDebtor !== "") {
                    setShowSearchIcon(false);
                  } else if (
                    event.key === "Enter" &&
                    searchDebtor === ""
                  ) {
                    setShowSearchIcon(true);
                  }
                }}
                inputProps={{ maxLength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showSearchIcon === true ? (
                        <IconButton
                          onClick={(e) => {
                            setShowSearchIcon(false);
                          }}
                          style={{ padding: "3px" }}
                        >
                          <SearchIcon />
                        </IconButton>
                      ) : (
                        <IconButton style={{ padding: "3px" }}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2} display={'block'} justifyContent={'space-evenly'}>
              <IconButton className={classes.IconButtonDialog} size="small" onClick={handleUndo}>
                <BDebtTooltip title="Undo">
                  <UndoIcon fontSize="small" />
                </BDebtTooltip>
              </IconButton>
              <IconButton className={classes.IconButtonDialog} size="small" onClick={handleRedo}>
                <BDebtTooltip title="Redo">
                  <RedoIcon fontSize="small" />
                </BDebtTooltip>
              </IconButton>
              <IconButton className={classes.IconButtonDialog} size="small" onClick={handleSave}>
                <BDebtTooltip title="Save">
                  <SaveIcon fontSize="small" />
                </BDebtTooltip>
              </IconButton>
              <IconButton className={classes.IconButtonDialog} size="small" onClick={handleOpenDelete}>
                <BDebtTooltip title="Delete">
                  <DeleteIcon fontSize="small" />
                </BDebtTooltip>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" size="small" startIcon={<AddIcon fontSize="small" />} fullWidth sx={{ mt: 0.5 }} onClick={handleAddNewRecord}>New Record</Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                className={`${classes.tablebodyConainer
                  } ${"scrollbox"} ${"on-scrollbar"}`}
                sx={{ maxHeight: '400px', overflow: 'auto' }}
              >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.TableCellStyle1}>Fields</TableCell>
                      <TableCell align="left" className={classes.TableCellStyle}>Original Value</TableCell>
                      <TableCell align="left" className={classes.TableCellStyle}>Updated Value</TableCell>
                      <TableCell align="left" className={classes.TableCellStyle}>Save</TableCell>
                      <TableCell align="left" className={classes.TableCellStyle2}>Transform</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {defaultFieldMappings?.map((field, index) => {
                      const fieldName = field?.fieldName;
                      const isRequired = field?.isRequired;
                      const originalValue = (originalValues[currentIndex] && originalValues[currentIndex][fieldName]) || '';
                      const updatedValue = (editedRows[currentIndex] && editedRows[currentIndex][fieldName]) || '';
                      return (
                        <TableRow key={fieldName}>
                          <TableCell align="left" className={classes.TableCellStyle1}>
                            {fieldName}
                            {isRequired && <span className={classes.isRequired}> * </span>}
                          </TableCell>
                          <TableCell align="left" className={classes.TableCellStyle}>
                            <BDebtTypography variant="body2" color="gray" bgcolor={"lightgray"} border={'1px solid gray'} p={"6px"} borderRadius={"5px"} height={"34px"}>
                              {originalValue}
                            </BDebtTypography>
                          </TableCell>
                          <TableCell align="left" className={classes.TableCellStyle}>
                            <BDebtTextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={updatedValue}
                              onChange={(e) => handleTextFieldChange(fieldName, e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center" className={classes.TableCellStyle2}>
                            <IconButton className={classes.IconButtonDialog} size="small" onClick={handleSave}>
                              <BDebtTooltip title="Save" >
                                <SaveIcon fontSize="small" />
                              </BDebtTooltip>
                            </IconButton>
                          </TableCell>
                          <TableCell align="center" className={classes.TableCellStyle2}>
                            <IconButton className={classes.IconButtonDialog} size="small" disabled={true}>
                              <BDebtTooltip title="Transform">
                                <TransformIcon fontSize="small" color="disabled" />
                              </BDebtTooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" >
              <IconButton aria-label="previous" size="small" onClick={handleFirstRecord}>
                <BDebtTooltip title="First Record">
                  {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                </BDebtTooltip>
              </IconButton>
              <IconButton aria-label="play/pause" size="small" onClick={handlePrevious} disabled={currentIndex === 0}>
                <BDebtTooltip title="Previous">
                  <PlayArrowIcon className={classes.iconRotateStyle} />
                </BDebtTooltip>
              </IconButton>
              <Box className={classes.outOfBoxStyle}>
                <BDebtTypography variant="body2">{`${currentIndex + 1} of ${addNewRow.length}`}</BDebtTypography>
              </Box>
              <IconButton aria-label="play/pause" size="small" onClick={handleNext} disabled={currentIndex === addNewRow?.length - 1}>
                <BDebtTooltip title="Next">
                  <PlayArrowIcon />
                </BDebtTooltip>
              </IconButton>
              <IconButton aria-label="next" size="small" onClick={handleLastRecord}>
                <BDebtTooltip title="Last Record">
                  {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                </BDebtTooltip>
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
          <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success" >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </BDebtDialog>
      <BDebtDialog
        open={isConfirmationOpen}
        onClose={handleCancelDelete}
        maxWidth={"sm"}
        PaperProps={{style:{ height:150, borderRadius: "5px"}}}
      >
        <BDebtDialogTitleHeader id="alert-dialog-title" onClose={handleCancelDelete}>
          <Typography variant="body1">
            <b>DELETE ROW</b>
          </Typography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body1">
            Are you sure you want to Delete this Row?
          </BDebtTypography>
        </DialogContent>
        <DialogActions  sx={{ borderTop: '1px dashed gray' }}>
          <BDebtButton onClick={() => confirmDelete(currentIndex)} color="success" variant="contained" size="small">
            Yes
          </BDebtButton>
          <BDebtButton onClick={handleCancelDelete} color="error" variant="contained" size="small">
            Cancel
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default RecordsCombineView;