/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import { DialogContent, Grid} from "@material-ui/core";
import { useState } from "react";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { Autocomplete, DialogActions, Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
// Interface
import {IRules, Rule} from "../DataRules/IDataRules";
// Icon
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import useStyles from "./DataRulesCss";
import { useFetch, usePost } from "../../../utils/apiHelper";


const AddDataRules : React.FC<{ onSave: () => void }> = (props) => {
  const classes = useStyles();
  const {onSave} = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rules, setRules] = useState<Rule[]>([]);
  const [selectedRuleType, setSelectedRuleType] = useState<string>("");
  const [selectedRuleOption, setSelectedRuleOption] = useState<string>("");

  let AddRule: IRules = {
    name: "",
    description: "",
    ruleType: "",
    ruleOption: "",
    maxLength: "",
    formula: ""
  };

  const [localState, setLocalState] = useState(AddRule);

  const fetchRules = async () => {
   await useFetch<any>('Rule/GetRuleTypeWithOptions').then((result) => {
      setRules(result?.data);
    });  
  };
  
  const openDialog = () => {
    fetchRules();
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleNameChange = (e) => {
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
  };

  const handleSave = async () => {
    let ruleDetailList: IRules[] = [];
    ruleDetailList?.push(localState);

    let request = {
      ruleId: 0,
      ruleDetailList:[
        {
          name: localState.name,
          description: localState.description,
          ruleType: selectedRuleType,
          ruleOption: selectedRuleOption,
          maxLength: localState.maxLength,
          formula: localState.formula,
        },
      ]
    };

    await usePost("Rule/ManageRule", request).then((response) => {
    onSave();
    closeDialog();

    setSelectedRuleType(null);
    setSelectedRuleOption(null);
    });
  }


  const handleRuleTypeChange = (event: React.SyntheticEvent, value: string | null) => {
    setSelectedRuleType(value);
    setSelectedRuleOption(null);
  };

  const handleRuleOptionChange = (event: React.SyntheticEvent, value: string | null) => {
    setSelectedRuleOption(value);
  };

  const ruleTypeOptions = rules?.map((rule) => rule?.ruleType);
  const ruleOptions = rules?.find((rule) => rule?.ruleType === selectedRuleType)?.ruleOptionDetails?.map((option) => option.ruleOption) || [];
  

  return (
    <div>
      <BDebtButton
        variant="contained"
        color="primary"
        size="small"
        startIcon={<GroupAddIcon />}
        onClick={openDialog}
      >
        Add Rules
      </BDebtButton>
      <BDebtDialog
        open={isDialogOpen}
        maxWidth={"xs"}
        onClose={closeDialog}
        fullWidth
      >
        <BDebtDialogTitleHeader
          onClose={closeDialog}
          id="customized-dialog-title"
        >
        <Typography variant="body1">
        <b>ADD RULES</b>
        </Typography>          
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                size="small"
                variant="outlined"
                label="Rule Name"
                name="name"
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                size="small"
                variant="outlined"
                label="Rule Description"
                name="description"
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                id="rule-type"
                size="small"
                placeholder="Rule Type"
                options={ruleTypeOptions}
                value={selectedRuleType}
                classes={{
                  paper: classes.dropdownPaper,
                  option: classes.autoCompleteFont,
                }}
                onChange={handleRuleTypeChange}
                renderInput={(params) => (
                  <BDebtTextField {...params} variant="outlined" label="Rule Type" name="ruleType" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                id="rule-options"
                size="small"
                options={ruleOptions}
                value={selectedRuleOption}
                classes={{
                  paper: classes.dropdownPaper,
                  option: classes.autoCompleteFont,
                }}
                onChange={handleRuleOptionChange}
                renderInput={(params) => (
                  <BDebtTextField {...params} label="Rule Options" variant="outlined" name="ruleOption" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <BDebtTextField
                fullWidth
                label="Max Length"
                type="text"
                size="small"
                variant="outlined"
                name="maxLength"
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                label="Formula"
                type="text"
                size="small"
                variant="outlined"
                name="formula"
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
        <BDebtButton
            color="success" 
            variant="contained"
            onClick={handleSave}
            size = "small"
          >
            Save
          </BDebtButton>
          <BDebtButton
            color="error" 
            variant="contained"
            onClick={closeDialog}
            size="small"
          >
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default AddDataRules;
