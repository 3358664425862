import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { IconButton, TableBody, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import AddWidget from "./CustomizeWidgets/AddWidgets";
import useStyles from "./WidgetCss";
import mockData from "../../../MockData/Widget";
import BDebtTable from "../../../GlobalControl/Table/BDebtTable";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtTableCell from "../../../GlobalControl/Table/BDebtTableCell";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditWidgets from "./CustomizeWidgets/EditWidgets";


export default function CustomizeWidgets() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container spacing={0} component={Paper} boxShadow={2}>
      <Grid xs={10} className={classes.gridContainer}  padding="5px">
        <BDebtTypography variant="h6" padding="6px">
          <b>CUSTOM WIDGETS</b>
          <WidgetsOutlinedIcon />
        </BDebtTypography>
      </Grid>
      <Grid xs={2} className={classes.gridContainer}>
        <AddWidget />
      </Grid>
      <Grid xs={12} className={classes.borderTop}>
        <TableContainer>
          <BDebtTable stickyHeader aria-label="sticky table">
            <TableHead
              className={classes.tableHead}>
              <TableRow> <BDebtTableCell className={classes.tableCellHeader}>WIDGETS</BDebtTableCell>
                <BDebtTableCell className={classes.tableCellHeader}  >
                  CREATED USER
                </BDebtTableCell >
                <BDebtTableCell className={classes.tableCellHeader} >
                  MODIFIED DATE
                </BDebtTableCell>
                <BDebtTableCell align="center" className={classes.tableCellHeader}>
                  ACTIONS
                </BDebtTableCell></TableRow>
            </TableHead>
            <TableBody>
              {mockData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow>
                    <BDebtTableCell className={classes.tableCellStyle}>
                      {row.name}
                    </BDebtTableCell>
                    <BDebtTableCell className={classes.tableCellStyle} >{row.userId}</BDebtTableCell>
                    <BDebtTableCell className={classes.tableCellStyle} >{row.modifiedDate}</BDebtTableCell>
                    <BDebtTableCell className={classes.tableCellStyle} align="center" >
                      < EditWidgets />
                      <IconButton size="small" >
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    </BDebtTableCell>
                  </TableRow>))}
            </TableBody>
          </BDebtTable>
        </TableContainer>
      </Grid>
      <Grid xs={12} borderRadius={0} className={classes.borderTop}>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}
