import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContainer: {
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 280px)',
                minHeight: 'calc( 100vh - 280px)',
            },
        }
    })
);

export default useStyles;