/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useState } from "react";
import { Button, Checkbox, Paper, Grid, TableContainer} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import Scrollbars from "react-custom-scrollbars";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useStyles from "../DataPipeline/DataPipelineCss";
import { dataTypes } from "../../MockData/Placement/DataRuleMock";
import { Field } from "../../Model/Configuration";
import AddIcon from '@material-ui/icons/Add';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
interface DataMappingProps {
  sourceSchema: Field[];
  destinationSchema: Field[];
}

const DataMapping: React.FC<DataMappingProps> = (props) => {
  const { sourceSchema, destinationSchema } = props;
  const classes = useStyles();
  // source
  const [importUploadedFile, setImportUploadedFile] = useState<File>(null);
  const [importFields, setImportFields] = useState<Field[]>([]);
  const [importDraggedIndex, setImportDraggedIndex] = useState<number>(null);
  // destination
  const [destinationUploadedFile, setDestinationUploadedFile] = useState<File>(null);
  const [destinationFields, setDestinationFields] = useState<Field[]>([]);
  const [destinationDraggedIndex, setDestinationDraggedIndex] = useState<number>(null);

  useEffect(() => {
    if (sourceSchema) {
      setImportFields(sourceSchema);
    }
    if (destinationSchema) {
      setDestinationFields(destinationSchema);
    }
  }, [sourceSchema, destinationSchema]);

  const handleImportFileUpload = (event:any) => {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setImportUploadedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const contents: string = e.target.result as string;
          const lines = contents.split("\n");
          if (lines.length > 0) {
            const headers = lines[0].split(",");
            const initialFields = headers.map((header) => ({
              columnName: header.trim(),
              dataType: "Text",
              isRequired: false,
            }));

            setImportFields(initialFields);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const handleDestinationFileUpload = (event:any) => {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setDestinationUploadedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const contents: string = e.target.result as string;
          const lines = contents.split("\n");
          if (lines.length > 0) {
            const headers = lines[0].split(",");
            const initialFields = headers.map((header) => ({
              columnName: header.trim(),
              dataType: "Text",
              isRequired: false,
            }));
            setDestinationFields(initialFields);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const addImportField = () => {
    setImportFields([...importFields, { columnName: "", dataType: ""}]);
  };

  const addDestinationField = () => {
    setDestinationFields([...destinationFields, { columnName: "", dataType: ""}]);
  };

  const deleteImportField = (index: number) => {
    const updatedFields = [...importFields];
    updatedFields.splice(index, 1);
    setImportFields(updatedFields);
  };

  const deleteDestinationField = (index: number) => {
    const updatedFields = [...destinationFields];
    updatedFields.splice(index, 1);
    setDestinationFields(updatedFields);
  };

  const handleImportDragStart = (e: React.DragEvent, index: number) => {
    e.dataTransfer.setData("index", index.toString());
    setImportDraggedIndex(index);
  };

  const handleDestinationDragStart = (e: React.DragEvent, index: number) => {
    e.dataTransfer.setData("index", index.toString());
    setDestinationDraggedIndex(index);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleImportDrop = (e: React.DragEvent, targetIndex: number) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("index"));
    if (sourceIndex !== targetIndex) {
      const updatedFields = [...importFields];
      const [draggedField] = updatedFields.splice(sourceIndex, 1);
      updatedFields.splice(targetIndex, 0, draggedField);
      setImportFields(updatedFields);
    }
    setImportDraggedIndex(null);
  };

  const handleDestinationDrop = (e: React.DragEvent, targetIndex: number) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData("index"));
    if (sourceIndex !== targetIndex) {
      const updatedFields = [...destinationFields];
      const [draggedField] = updatedFields.splice(sourceIndex, 1);
      updatedFields.splice(targetIndex, 0, draggedField);
      setDestinationFields(updatedFields);
    }
    setDestinationDraggedIndex(null);
  };

  const CsvField = ({ field, index, schemaType }: any) => {
    const isImport = schemaType === 'import';
    
    return (
      <div
        draggable={true}
        onDragStart={(e) => isImport ? handleImportDragStart(e, index) : handleDestinationDragStart(e, index)}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => isImport ? handleImportDrop(e, index) : handleDestinationDrop(e, index)}
        className={classes.draggableField}
      >
        <Grid container spacing={0} >
          <Grid item xs={12} sm={12} md={1}>
            <Checkbox
              checked={field.isRequired}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Grid container spacing={0}>
              <Grid item xs={11}>
                <Autocomplete
                  size="small"
                  options={importFields.map((field) => field.columnName)}
                  value={field.columnName}
                  renderInput={(params) => (
                    <BDebtTextField variant="outlined" {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              size="small"
              options={dataTypes}
              value={field.dataType}
              renderInput={(params) => (
                <BDebtTextField variant="outlined" {...params} size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <AddIcon
              fontSize="small"
              className={classes.icon}
              onClick={() => isImport ? addImportField() : addDestinationField()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              className={classes.icon}
              onClick={() => isImport ? deleteImportField(index) : deleteDestinationField(index)}
            />
          </Grid>
          {isImport && (
            <Grid item xs={12} sm={12} md={1}>
              <ArrowRightAltIcon />
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  const CsvFieldHeader = ({ schemaType }: any) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={1} >
          <Checkbox
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} >
          <BDebtTypography variant="subtitle2" >{schemaType === 'import' ? 'Source' : 'Destination'}</BDebtTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} >
          <BDebtTypography variant="subtitle2">Type</BDebtTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={1} >
          <BDebtTypography variant="subtitle2">Add</BDebtTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <BDebtTypography variant="subtitle2">Action</BDebtTypography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.divStyle}>
      <Grid container spacing={0}>
        <React.Fragment>
          <Grid item xs={12} md={6}>
            <input
              type="file"
              onChange={handleImportFileUpload}
              style={{ display: 'none' }}
              id="importFileInput"
            />
            <label htmlFor="importFileInput">
              <Button
                color="primary"
                variant="text"
                component="span"
                size="small"
                style={{color:'blue'}}
              >
                 <AddIcon
              fontSize="small"></AddIcon>Import Schema
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              type="file"
              onChange={handleDestinationFileUpload}
              style={{ display: 'none' }}
              id="destinationFileInput"
            />
            <label htmlFor="destinationFileInput">
              <Button
                color="primary"
                variant="text"
                component="span"
                size="small"
                style={{color:'blue'}}
              >
                  <AddIcon
              fontSize="small"></AddIcon>Destination Schema
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Scrollbars
                className={` ${!importUploadedFile ? classes.fieldUploadedTable : classes.fieldTable1}`}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                thumbMinSize={20}
                universal={true}
              >
                <CsvFieldHeader schemaType="import" />
                {importFields.map((field, index) => (
                  <CsvField
                    key={index} 
                    field={field}
                    index={index}
                    schemaType="import"
                  />
                ))}
              </Scrollbars>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Scrollbars
                className={` ${!destinationUploadedFile ? classes.fieldUploadedTable : classes.fieldTable1}`}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                thumbMinSize={20}
                universal={true}
              >
                <CsvFieldHeader schemaType="destination" />
                {destinationFields.map((field, index) => (
                  <CsvField
                    key={index}
                    field={field}
                    index={index}
                    schemaType="destination"
                    style={{marginTop:'10px'}}
                  />
                ))}
              </Scrollbars>
            </TableContainer>
          </Grid>
        </React.Fragment>
      </Grid>
    </div>
  );
};

export default DataMapping;

