export const dataTypes: string[] = [
  "Text",
  "Int",
  "DateTime",
  "bool",
  "currency$",
  "Date",
  "UUID",
];

export const rules = [
  "Account Number Rule",
  "Email Rule",
  "Alphanumeric Validation",
  "Phonenumber Rule",
  "Amount Validation Rule",
  "State validation Rule",
  "Zipcode Validation Rule",
  "Date Validation Rule",
];


export interface Rule {
  ruleName: string;
}

export interface RuleGroup {
  ruleType: string;
  fields: Rule[];
}


export const mockrule: RuleGroup[] = [
  {
      ruleType: "Numeric",
      fields: [
          { ruleName: "Account Number Rule" },
          { ruleName: "Absolute Value" },
          { ruleName: "Round Up to the Nearest Integer" },
          { ruleName: "Round Down to the Nearest Integer" },
          { ruleName: "Check within the range" },
          { ruleName: "Round to the Nearest Integer" },
          { ruleName: "Amount Validation Rule" }
      ]
  },
  {
      ruleType: "String",
      fields: [
          { ruleName: "Email Validation Rule" },
          { ruleName: "Alphanumeric Validation" },
          { ruleName: "Phonenumber Rule" },
          { ruleName: "State validation Rule" },
          { ruleName: "Name Validation Rule" },
          { ruleName: "Zipcode Validation Rule" }
      ]
  },
  {
      ruleType: "Date & Date Time",
      fields: [
          { ruleName: "Date Format - Convert to String " },
          { ruleName: "Date Extraction - Extract month, date" },
          { ruleName: "Date Parsing - String to date object" },
          { ruleName: "Date Validation/ correction" },
          { ruleName: "Date Rounding" },
          { ruleName: "Date Filtering" },
          { ruleName: "Date Localization" },
          { ruleName: "Relative Dates" },
          { ruleName: "Timezone Conversions" }
      ]
  },
  {
      ruleType: "Transformation",
      fields: [
          { ruleName: "Concatenation" },
          { ruleName: "Age Calculation" },
          { ruleName: "Timezone Conversion" }
      ]
  }
];

export interface GetDatsetField {
  dataSetId: number; 
  dataSetName: string; 
  clientCode: string; 
  createdBy: number; 
  fields: Field[];
}
export interface Field {
  dataSetId: number;
  fieldName: string;
  dataType: string;
  ruleId: number;
  isRequired: boolean;
  dateFormatId:number;
}

// Define the interface for the dataset
export interface GetDataSet {
  dataSetId: number;
  dataSetName: string;
  clientCode: string;
  createdBy: number;
  createdDate: string;
  modifiedDate: string;
  fieldsDetail: Field[];
}
