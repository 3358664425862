/* eslint-disable @typescript-eslint/no-unused-vars */
import { DialogTitle, IconButton, TablePagination, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import Data from '../../../MockData/UserHistory.json';
import useStyles from '../UserCss';


const AgentUserHistory = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    function EnhancedTableHead() {
        const headCells = [
            { id: "pagename", numeric: false, disablePadding: false, label: "  Page Name", sortable: false },
            { id: "debtorid", numeric: false, disablePadding: false, label: "  Debtor Id", sortable: false },
            { id: "action", numeric: false, disablePadding: false, label: "Action", sortable: false },
            { id: "dateofaction", numeric: false, disablePadding: false, label: " Date Of Action", sortable: false },
            { id: "time", numeric: false, disablePadding: false, label: " Time", sortable: false },

        ];

        return (
            <TableHead>
                <TableRow >
                    {headCells.map(headCell => (
                        <TableCell className={classes.tableCellHeader}
                            key={`${headCell?.id}`}>
                            {headCell.label || headCell.sortable}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead >
        );
    }

    return (
        <div>
            <Tooltip title="HISTORY" arrow onClick={handleOpen} >
                <IconButton size="small">
                    <HistoryIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullScreen
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle
                    className={classes.dashedLine}
                >
                    <Typography variant="h6" textAlign={"center"}><b>USER HISTORY</b> </Typography>
                    <IconButton className={classes.icon}
                        onClick={handleClose} >
                        <CloseIcon fontSize="small" />
                    </IconButton>

                </DialogTitle>

                <DialogContent>

                    <TableContainer component={Paper} className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
                            <EnhancedTableHead />
                            <TableBody>
                                {Data.map((item) => (
                                    <TableRow key={item?.id}>
                                        <TableCell align="left">{item.pageName}</TableCell>
                                        <TableCell align="left">{item.debtorId}</TableCell>
                                        <TableCell align="left">{item.action}</TableCell>
                                        <TableCell align="left">{item.dateofAction}</TableCell>
                                        <TableCell align="left">{item.time}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={100}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AgentUserHistory