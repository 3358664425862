import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogContent, IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import api from '../../../ApiConfiguration/Configuration';
import { Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import useStyles from "../UserCss";

const DeleteUser: React.FC<{UserId : number; onDelete:()=>void}>=(props)=>{
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { UserId } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async()=>{
    await api.delete("User/DeleteUser?UserId="+UserId).finally(()=>{
      props?.onDelete();
      setOpen(false); 
      handleClose();
    }).finally(()=>{
    }) 
  }

  return (
    <div>
        <IconButton className={classes.EditIcon} onClick={handleClickOpen}>
        <DeleteOutlineOutlinedIcon className={classes.deleteIconcolor} />
      </IconButton>
      <BDebtDialog open={open} onClose={handleClose}aria-labelledby="alert-dialog-title"aria-describedby="alert-dialog-description"
        fullWidth maxWidth={'xs'} >
        <BDebtDialogTitleHeader onClose={handleClose} id="customized-dialog-title">Delete User</BDebtDialogTitleHeader>
        <DialogContent> 
           <Typography variant="h6"> Are You Sure want to delete this User?</Typography>
            </DialogContent>
        <DialogActions>
          <BDebtButton onClick={handleDelete} variant="contained" color="primary" size="small">
            Delete
          </BDebtButton>
          <BDebtButton onClick={handleClose} variant="outlined"color="primary" size="small">
            Cancel
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
}
export default DeleteUser
