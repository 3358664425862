interface MenuItem {
    menuId: number;
    menuName: string;
    subMenu?: SubMenuItem[];
}
interface SubMenuItem {
    subMenuId: number;
    subMenuName: string;
}


export const Menulist: MenuItem[] = [

    {
        "menuId": 1,
       "menuName": "Dashboard"
    },
    {
        "menuId": 2,
        "menuName": "Accounts",
        "subMenu": [
            {
                "subMenuId": 1,
                "subMenuName": "Ingest Account",
            },
            {
                "subMenuId": 2,
                "subMenuName": "Debtors",  
            },
            {
                "subMenuId": 3,
                "subMenuName": "Collect", 
            },
        ] 
    },
    {
        "menuId": 3,
        "menuName": "Notification", 
    },
    
    {
        "menuId": 5,
        "menuName": "Operations",  
    },
    {
        "menuId": 7,
        "menuName": "Reports",
    },
    {
        "menuId": 9,
        "menuName": "Email",
    },
    {
        "menuId": 10,
        "menuName": "Website",
    },
    {
        "menuId": 11,
        "menuName": "Settings",
    }

];

 export const Userlevel = [
    { id: 0, title: "Client User" },
    { id: 1, title: "Client Admin" },
    { id: 2, title: "Agent User" },
    { id: 3, title: "Prospect User" },
    { id: 4, title: "Platform Admin" },
  ];  
 
  export const Client = [
    { title: "CONTSO1" },
    { title: "CONTSO3" },
    { title: "CONTSO2" },
    { title: "CONTSO4" },
    { title: "CONTSO5" },
  ];