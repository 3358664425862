// RichTextEditor.tsx
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface RichTextEditorProps {
  initialContent: string;
  onChange: (content: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ initialContent, onChange }) => {
  const [editorState, setEditorState] = useState(() => {
    if (initialContent) {
      try {
        const contentState = convertFromRaw(JSON.parse(initialContent));
        return EditorState.createWithContent(contentState);
      } catch (error) {
        console.error('Error parsing initial content:', error);
      }
    }
    return EditorState.createEmpty();
  });

  useEffect(() => {
    const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    onChange(content);
  }, [editorState, onChange]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={(newEditorState) => setEditorState(newEditorState)}
    />
  );
};

export default RichTextEditor;
