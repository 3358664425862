/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import * as React from 'react';
import { GridColDef, GridActionsCellItem, GridRowModes, GridRowEditStopReasons} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import useStyles from '../PlacementCss';
import Scrollbars from 'react-custom-scrollbars';
// Icons
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import StyledDataGrid from './StyledDataGrid';
import CustomPagination from './CustomPagination';

interface ErrorFieldRecordProps {
  uploadedData: any[];
}

const ErrorColumnField: React.FC<ErrorFieldRecordProps> = (props) => {
  const classes = useStyles();
  const { uploadedData } = props;
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({}); // Declare rowModesModel here

  useEffect(() => {
    // Update rowModesModel when uploadedData changes
    if (uploadedData && uploadedData.length > 0) {
      const newRowModesModel = {};
      uploadedData.forEach((data) => {
        newRowModesModel[data.id?.toString()] = { mode: GridRowModes.View };
      });
      setRowModesModel(newRowModesModel);
      setRows(uploadedData);
    } else {
      // Handle the case where uploadedData is null or empty
      setRowModesModel({});
      setRows([]);
    }
  }, [uploadedData]);

  const handleEditClick = (id) => () => {
    if (rows.some((row) => row.id === id)) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows((oldRows) => {
      const updatedRows = oldRows.filter((row) => row.id !== id);
      return updatedRows;
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows((oldRows) => oldRows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows((oldRows) => oldRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const columns: GridColDef[] = [
    {
      field: "Account Number",
      headerName: "Account Number",
      width: 150,
      editable: true,
    },
    {
      field: "First Name",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    {
      field: "Last Name",
      headerName: "Last Name",
      width: 150,
      editable: true,
    },
    {
      field: "Billing Address",
      headerName: "Billing Address",
      width: 200,
      editable: true,
    },
    {
      field: "Billing City",
      headerName: "Billing City",
      width: 150,
      editable: true,
    },
    {
      field: "Billing State",
      headerName: "Billing State",
      width: 120,
      editable: true,
    },
    {
      field: "Billing Zip Code",
      headerName: "Billing Zip Code",
      width: 150,
      editable: true,
    },
    {
      field: "Billing Phone Number",
      headerName: "Billing Phone Number",
      width: 150,
      editable: true,
    },
    {
      field: "Email Address",
      headerName: "Email Address",
      width: 200,
      editable: true,
    },
    {
      field: "Last Charge Date",
      headerName: "Last Charge Date",
      width: 150,
      editable: true,
    },
    {
      field: "Date of Last Payment",
      headerName: "Date of Last Payment",
      width: 150,
      editable: true,
    },
    {
      field: "Itemization Date",
      headerName: "Itemization Date",
      width: 150,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={10}
      className={classes.ErrorRecordContainer}
    >
      <StyledDataGrid
         rows={rows} 
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        // className={classes.ErrorRecordGridStyle}
        onRowEditStop={(params, event) => {
          if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
          }
        }}
        processRowUpdate={processRowUpdate}
        rowHeight={30}
        isCellEditable={(params) => params.field !== 'actions' && !rowModesModel[params.row.id]?.isNew}
        getRowId={(row) => row.id}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        sx={{
          backgroundColor: 'white'
        }}
        className={classes.GridFontStyle}
        components={{
          Pagination: (paginationProps) => ( // Pass paginationProps here
            <CustomPagination {...paginationProps} rows={rows} />
          ),
        }}
      />
    </Scrollbars>
  );
};

export default ErrorColumnField;