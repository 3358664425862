import { Button, styled, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Primary,Secondary,Success,Warning,Error,colorMapping,containedColorMapping } from './GlobalColorPalette';

interface BDebtButtonProps {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
  size?: 'small' | 'medium' | 'large';
  iconStyle?: SxProps<Theme>;
  variant?: 'contained' | 'outlined' | 'text'; // Existing variants
  dashed?: boolean; // New dashed variant
  disabled?: boolean; // Handle disabled state
  borderRadius?: string; // Border radius as a string, e.g., '4px', '50%', etc.
  boxShadow?:string;
}

const BDebtButton = styled(Button)<BDebtButtonProps>(
  ({ color, size, iconStyle, variant, dashed, disabled, borderRadius,boxShadow}) => {
    const getTextStyles = (colorKey: keyof typeof colorMapping) => {
      const colorPalette = colorMapping[colorKey];
      return {
        ...(color && {
          color: color ? colorPalette.dark : 'inherit', // Adjust this based on your color mapping
        }),
      };
    };


  return {
  '&.MuiButton-root': {
    ...(color === 'primary' && {
      backgroundColor: Primary.dark,
      color: '#fff',
    }),
    ...(color === 'secondary' && {
      backgroundColor: Secondary.dark,
      color: '#fff',
    }),
    ...(color === 'success' && {
      backgroundColor: Success.dark,
      color: '#fff',
    }),
    ...(color === 'error' && {
      backgroundColor: Error.dark,
      color: '#fff',
    }),
    ...(color === 'warning' && {
      backgroundColor: Warning.dark,
      color: '#fff',
    }),

    // Size styles
    ...(size === 'small' && {
      padding: '3px 9px;',
      fontSize: '13px',
    }),
    ...(size === 'medium' && {
      padding: '5px 15px',
      fontSize: '14px',
    }),
    ...(size === 'large' && {
      padding: '7px 21px',
      fontSize: '15px',
    }),

      // Variant-specific styles for 'contained'
      ...(variant === 'outlined' && color && {
        border: `1px solid ${containedColorMapping[color]}`, // Adjust this based on your color mapping
        backgroundColor: 'transparent',
        color: containedColorMapping[color],
        borderRadius: borderRadius || '4px', // Default border radius if not provided
      }),
  
    ...(variant === 'outlined' && {
      border: `1px solid ${color === 'primary' ? Primary.dark : Secondary.dark}`,
      backgroundColor: 'transparent',
      borderRadius: borderRadius || '4px', // Default border radius if not provided
    }),
    ...(variant === 'text' && {
      backgroundColor: 'transparent',
      borderRadius: borderRadius || '4px', // Default border radius if not provided
    }),
    ...(dashed && {
      border: `1px dashed ${colorMapping[color].dark}`, // Add dashed border
      backgroundColor: 'transparent',
      color: colorMapping[color].dark,
      borderRadius: borderRadius || '4px', // Default border radius if not provided
    }),
    // Additional variants for success, error, and warning
    ...(color === 'primary' && variant === 'outlined' && {
      border: `1px solid ${Primary.dark}`,
      backgroundColor: 'transparent',
      color: color === 'primary' ? Primary.dark : color === 'primary' ? Primary.dark : color === 'primary' ? Primary.dark : 'main',
    }),
    ...(color === 'primary' && variant === 'outlined' && {
      border: `1px solid ${Primary.dark}`,
      backgroundColor: 'transparent',
      color: color === 'primary' ? Primary.dark : color === 'primary' ? Primary.dark : color === 'primary' ? Primary.dark : 'inherit',
    }),
    ...(color === 'success' && variant === 'outlined' && {
      border: `1px solid ${Success.dark}`,
      backgroundColor: 'transparent',
      color: color === 'success' ? Success.dark : color === 'error' ? Error.dark : color === 'warning' ? Warning.dark : 'inherit',
    }),
    ...(color === 'error' && variant === 'outlined' && {
      border: `1px solid ${Error.dark}`,
      backgroundColor: 'transparent',
      color: color === 'error' ? Error.dark : color === 'error' ? Error.dark : color === 'warning' ? Warning.dark : 'inherit',
    }),
    ...(color === 'warning' && variant === 'outlined' && {
      border: `1px solid ${Warning.dark}`,
      backgroundColor: 'transparent',
      color: color === 'warning' ? Warning.dark : color === 'error' ? Error.dark : color === 'warning' ? Warning.dark : 'inherit',
    }),

    // Disabled state
    ...(disabled && {
      backgroundColor: '#f0f0f0',
      color: '#808080',
    }),
    ...(variant === 'text' && color && getTextStyles(color)),

    ...iconStyle,
  },
};
});

export default BDebtButton;
