/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from "@material-ui/core";
import { Box, Grid, Paper } from "@mui/material";
import { useState } from "react";
import AccountTabList from "../../Components/Inquiry/AccountTabList";
import AgentActivity from "../../Components/Inquiry/AgentActivity/AgentActivity";
import useStyles from "../../Components/Inquiry/DebtorsCss";
import DebtorDetails from "../../Components/Inquiry/DebtorsDetails/DebtorDetails";
import SearchDebtorDetails from "../../Components/Inquiry/DebtorsDetails/SearchDebtorDetails";
import { IDebtTableRow } from "../../Model/Debtors/IDebtTableRow";
import BlankImage from "../../asset/image/document.svg";

const Inquiry = () => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState<IDebtTableRow>();
    const [activityEnable, setActivityEnable] = useState(false);

    const handleRowClick = (rowData: IDebtTableRow) => {
        setSelectedRow(rowData);
    };

    const handleClickActivity = (activityEnable) => {
        // Handle the flag passed from the child component
        setActivityEnable(activityEnable);
    };

    return (
        <Box p={0.9} m={1}>
            <Grid component={Paper} container spacing={0} className={classes.pageContainer}>
                <Grid item xs={12} className={classes.gridSearchbar}>
                    <SearchDebtorDetails />
                </Grid>
                <Grid item xs={6} className={classes.gridPayment}>
                    <DebtorDetails onLoadData={handleRowClick} activityEnable={handleClickActivity} />
                </Grid>
                <Grid item xs={6} className={!selectedRow ? classes.selectedColumn : null}>
                    {!selectedRow ?
                        <div style={{ textAlign: 'center' }}>
                            <img src={BlankImage} alt="blank-img" width="100px" />
                            <br />
                            <br />
                            <Typography variant="body2" color="inherit" style={{ marginTop: 5 }}>
                                <b>Please select a client</b>
                            </Typography>
                        </div>
                        : activityEnable ?
                            <AgentActivity />
                            :
                            <AccountTabList dataRow={selectedRow} />
                    }
                </Grid>
            </Grid >
        </Box>
    );
};
export default Inquiry;
