import { Box } from '@mui/material'

const Themes = () => {
  return (
    <Box p={1} m={1}>
      <div>Themes</div>
    </Box>
  )
}

export default Themes