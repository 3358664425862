import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    settingsContainer: {
      maxHeight: "600px !important",
      minHeight: "600px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 181px) !important",
        minHeight: "calc( 100vh - 181px) !important",
      },
    },
    settingsSidebar: {
      maxHeight: "600px !important",
      minHeight: "600px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 167px) !important",
        minHeight: "calc( 100vh - 167px) !important",
      },
    },
    settingHeader: {
      borderBottom: "1px solid lightgray !important",
      paddingBottom: "8px !important",
    },
    cardLayout: {
      display: "flex !important",
      borderBottom: "5px solid #283593",
      "&:hover": {
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        background: "#f2f8f9",
        transition: "box-shadow 0.3s ease, transform 0.3s ease",
        border: "1px solid #283593",
        borderBottom: "5px solid #283593",
        borderRadius: "10px",
      },
    },
    Submenuimg: {
      height: "100px !important",
      width: "100px !important",
    },
    imageWidth: {
      backgroundColor: "#9ba3dd",
      borderRadius: "5px",
      transition: ".3s",
      "&:hover": {
        webkitClipPath: "polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%)",
        clipPath: "polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%)",
        transition: "clip-path 0.3s ease",
      },
    },

    linkStyle: {
      textDecoration: "none !important",
      color: "#283593 !important",
      "&:hover": {
        color: "#283593 !important",
      },
    },

    dashedLine: {
      display: "block",
      borderBottom: "1px dashed #283593", // Adjust the color as needed
      paddingBottom: "3px", // Adjust the spacing as needed
    },

    /* Settings page sidebar */
    sidebarContainer: {
      width: "230px !important",
      // borderRight: '1px solid lightgray',
      margin: "8px !important",
    },
    listMui: {
      padding: "0px !important",
      // width: drawerWidth,
      // borderRight: '1px solid lightgray',
      // background: '#f1f5f8',
    },
    listItemMui: {
      padding: "2px !important",
      borderRadius: 5,
    },
    listItemActive: {
      padding: "6px 10px !important",
      color: "rgb(0, 127, 255) !important",
      fontWeight: "bolder",
      borderLeft: "4px solid #283593 !important",
      // backgroundColor: '#f1f5f8 !important',
      backgroundColor: "#FFFFFF !important",
      // boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
    },
    listItemInactive: {
      padding: "8px 10px !important",
    },
    listItemIconActive: {
      fontSize: 20,
      color: "#283593 !important",
      minWidth: "40px !important",
    },
    listItemIconInActive: {
      fontSize: 20,
      color: "#000000 !important",
      minWidth: "40px !important",
    },
    listItemTextActive: {
      fontSize: "12px !important",
      color: "#283593 !important",
      fontWeight: "bold",
    },
    listItemTextInActive: {
      fontSize: "12px !important",
    },
    contentContainer: {
      flexGrow: 1,
      maxWidth: "100% !important",
      margin: "0 auto",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      background: "white !important",
      [theme.breakpoints.up("sm")]: {
        boxShadow:
          "0 0 2px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.14) !important",
        marginLeft: "-2px !important",
        zIndex: 1,
      },
    },

    /* Dashboard Css */
    appbarPosition: {
      backgroundColor: "white !important",
      minHeight: "45px !important",
      padding: "0px !important",
    },
    tabRecords: {
      minHeight: "45px !important",
      fontSize: "12px !important",
      fontWeight: "bold",
    },
    tabList: {
      minHeight: "45px !important",
      padding: "0px !important",
    },

    typography: {
      padding: "10px !important",
    },
  })
);

export default useStyles;
