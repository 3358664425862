import { useState } from "react";
import { DialogContent, Grid } from "@material-ui/core";
import { Autocomplete, DialogActions, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { CreateDataSet } from "../../../MockData/Placement/placement";
import {clientcodes } from "../../../MockData/Placement/placement";
import { usePost } from "../../../utils/apiHelper";


const AddDataset = ({ onDatasetAdd }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [localState, setLocalState] = useState<CreateDataSet>({
    dataSetId: 0,
    dataSetName: "",
    createdBy: 1, // Assuming a default value or you prompt the user for this
    clientCodeList: "", 
  });

  const CreateDatasetDetail = async () => {
    let request = {
      dataSetId: 0,
      dataSetName: localState.dataSetName,
      createdBy: localState.createdBy,
      clientList: [
        {clientCode: localState.clientCodeList}],
    };
 
    const result = await usePost<any>("Dataset/CreateDataSetDetail", request);
    setLocalState(result?.data || localState);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
    setLocalState({
      dataSetId: 0,
      dataSetName: "",
      createdBy: 1,
      clientCodeList: "", 
    });
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDatasetNameChange = (event) => {
    setLocalState((prevDataset) => ({
      ...prevDataset,
      dataSetName: event.target.value,
    }));
  };

  const handleClientCodeChange = (event, value) => {
    setLocalState((prevDataset) => ({
      ...prevDataset,
      clientCodeList: value || "",
    }));
  };
const handleSave = async () => {
    await CreateDatasetDetail();
    onDatasetAdd(localState);
    closeDialog();
  };

  return (
    <div>
      <BDebtButton
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={openDialog}
        size="small"
      >
        New Dataset
      </BDebtButton>
      <BDebtDialog open={isDialogOpen} maxWidth={"sm"} onClose={closeDialog} fullWidth>
        <BDebtDialogTitleHeader onClose={closeDialog} id="customized-dialog-title">
          <Typography variant="body1">
            <b>ADD DATASET</b>
          </Typography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                name="dataSetName"
                type="text"
                label="Dataset Name"
                size="small"
                variant="outlined"
                value={localState.dataSetName}
                onChange={handleDatasetNameChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={clientcodes}
                value={localState.clientCodeList}
                onChange={handleClientCodeChange}
                renderInput={(params) => (
                  <BDebtTextField variant="outlined" {...params} label="Client Code" />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
          <BDebtButton
            color="success" 
            variant="contained"
            onClick={handleSave}
            size="small"
          >
            Save
          </BDebtButton>
          <BDebtButton
            variant="contained"
            color="error"
            onClick={closeDialog}
            size="small"
          >
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default AddDataset;
