/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState} from "react";
import { DialogContent, DialogActions, Typography, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import { usePost } from "../../../utils/apiHelper";

const DeleteDataSet: React.FC<{
  rowId: number,
  onDelete: (rowId: number) => void,
}> = (props) => {
  const {rowId} = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    let request = rowId
    // Make the API call to delete the dataset
    await usePost(`Dataset/DeleteDatasetDetail?DataSetId=` + rowId, request).finally(() =>{
        props.onDelete(props.rowId);
        closeDialog();
      })
  };

  return (
    <div>
      <IconButton size="small" onClick={openDialog}>
        <DeleteOutlineOutlinedIcon color="error" sx={{ fontSize: 17 }} />
      </IconButton>
      <BDebtDialog open={isDialogOpen} maxWidth={"sm"} onClose={closeDialog} fullWidth>
        <BDebtDialogTitleHeader onClose={closeDialog} id="customized-dialog-title">
          <Typography variant="body1">
            <b>DELETE DATASET</b>
          </Typography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body1">
            Are you sure you want to delete this dataset?
          </BDebtTypography>
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px dashed gray' }}>
          <BDebtButton onClick={handleConfirmDelete} color="success" variant="contained" size="small">
            Yes
          </BDebtButton>
          <BDebtButton onClick={closeDialog} color="error" variant="contained" size="small">
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default DeleteDataSet;
