import React from "react";
import { Grid, Box } from "@mui/material";
import PowerIcon from '@mui/icons-material/Power';
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtButton from "../../../GlobalControl/BDebtButton";
import CancelIcon from '@mui/icons-material/Cancel';

interface ConnectionFormProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTestConnection: () => void;
    snackbarMessage: string;
    snackbarSeverity: "success" | "error";
    }

const ConnectionForm: React.FC<ConnectionFormProps> = ({
    handleChange,
    handleTestConnection,
    snackbarMessage,
    snackbarSeverity,

}) => {
    const buttonText = snackbarMessage ? (snackbarSeverity === "success" ? "Connection Successful" : "Connection Failed") : "Test Connection";
    const buttonColor = buttonText === "Test Connection" ? "primary" : (snackbarSeverity === "success" ? "success" : "error");
    return (
        <Box p={1} m={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BDebtTextField
                        variant="outlined"
                        fullWidth
                        label="Connector Type"
                        name="connectorType"
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BDebtTextField
                        variant="outlined"
                        fullWidth
                        label="Connector Name"
                        name="connectorName"
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BDebtTextField
                        variant="outlined"
                        fullWidth
                        label="Connection String"
                        name="connectionString"
                        size="small"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BDebtTextField
                        variant="outlined"
                        fullWidth
                        label="user Name"
                        name="userName"
                        size="small"
                    // onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BDebtTextField
                        variant="outlined"
                        fullWidth
                        label="Password"
                        name="password"
                        size="small"
                    // onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
                    <BDebtButton
                        variant="contained"
                        size="small"
                        color={buttonColor}
                        onClick={handleTestConnection}
                    >
                        {snackbarSeverity === "success" ? (
                            <PowerIcon />
                        ) : (
                            <CancelIcon />
                        )}
                        {buttonText}
                    </BDebtButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ConnectionForm;
