/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import { Grid} from "@material-ui/core";
import useStyles from '../ProspectUserCss';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography'; 
const mockLabels = ['Company Name', 'Company Phone Number', 'Company Fax', 'Company Physical Address - Street','Company Physical Address - City','Company Physical Address - state','Company Physical Address - Zip Code','Company Mailing Address - Street','Company Mailing Address - City','Company Mailing Address - State','Company Mailing AddressZip Code','Company Website','Company Contact Person','Company Contact Title','Company Contact Phone','Company Contact Email']; // Mock data for labels

const CompanyInformation = () => {
   const classes = useStyles();
    const [numTextboxes, setNumTextboxes] = useState(16);
    return (
        <Grid container spacing={2} className={classes.Tabhead}>
        {Array.from({ length: numTextboxes }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
             <BDebtTypography variant="body2" className={classes.verfiyfont}>{mockLabels[index] || `Label ${index + 1}`}</BDebtTypography>
                {['Company Phone Number', 'Company Contact Phone'].includes(mockLabels[index]) ? (
                <>
                <Grid container spacing = {1} >
                  <Grid item xs={10} >
                    <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index]}`}/>
                  </Grid>
                  <Grid item xs ={2}>
                    <BDebtTextField variant="outlined" size='small' fullWidth  placeholder='#EXT'/>
                  </Grid>
                </Grid>
                </>
                ) : (
                <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index] || `Label ${index + 1}`}`} />
                )}
          </Grid>
        ))}
      </Grid>
  );
};

 
export default CompanyInformation;