import { Box, Paper, TableContainer, TablePagination } from '@material-ui/core';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BarChartIcon from '@mui/icons-material/BarChart';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import DensitySmallRoundedIcon from '@mui/icons-material/DensitySmallRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import SouthWestRoundedIcon from '@mui/icons-material/SouthWestRounded';
import StackedBarChartRoundedIcon from '@mui/icons-material/StackedBarChartRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import useStyles from "../../../Components/Setting/Dashboard/WidgetCss";
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import BDebtCard from '../../../GlobalControl/Card/BDebtCard';
import BDebtCardContent from '../../../GlobalControl/Card/BDebtCardContent';
import BDebtCardHead from '../../../GlobalControl/Card/BDebtCardHead';
import Predefine from '../../../MockData/PredefineWidget.json';
import AddWidgets from './PredefineWidgets/AddWidgets';
import DeletePredefineWidgets from './PredefineWidgets/DeletePredefineWidgets';
import EditPredefineWidgets from './PredefineWidgets/EditPredefineWidgets';

const PredefineWidgets = () => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getIconComponent = (iconName: string) => {
    const icons: { [key: string]: JSX.Element } = {
      'AssignmentTurnedInIcon': <AssignmentTurnedInIcon />,
      'PieChartIcon': <PieChartIcon />,
      'TimelineRoundedIcon': <TimelineRoundedIcon />,
      'AutoGraphIcon': <AutoGraphIcon />,
      'LeaderboardIcon': <LeaderboardIcon />,
      'CodeOffIcon': <CodeOffIcon />,
      'SouthWestRoundedIcon': <SouthWestRoundedIcon />,
      'SignalCellularAltRoundedIcon': <SignalCellularAltRoundedIcon />,
      'BarChartIcon': <BarChartIcon />,
      'GridViewRoundedIcon': <GridViewRoundedIcon />,
      'DensitySmallRoundedIcon': <DensitySmallRoundedIcon />,
      'StackedBarChartRoundedIcon': <StackedBarChartRoundedIcon />
    };
    return icons[iconName] || null;
  };
  return (
    <Grid container spacing={0} component={Paper} boxShadow={2}>
      <Grid xs={10} display="flex" justifyContent={'flex-start'} alignItems={'center'} padding="5px" bgcolor={'#2a3944'} >
        <BDebtTypography variant="h6" padding="6px" color='white'>
          <b>PREDEFINE WIDGETS</b>
        </BDebtTypography>
      </Grid>
      <Grid xs={2} display="flex" justifyContent={'flex-end'} alignItems={'center'} padding="5px" bgcolor={'#2a3944'} >
        <AddWidgets />
      </Grid>
      <Grid xs={12} borderTop={'1px solid lightgray'}>
        <TableContainer className={`${classes.widgetsContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Grid container spacing={1} bgcolor={'#f1f5f8'} padding={1}>
            {Predefine.map((item) => (
              <Grid item xs={3} key={item.id}>
                <BDebtCard className={classes.cardLayout}>
                  <BDebtCardHead className={classes.cardHeadLine} style={{padding:"6px 10px   "}} 
                    action={
                      <Grid display="flex" alignItems="center">
                        <EditPredefineWidgets />
                        <DeletePredefineWidgets />
                      </Grid>}
                    title={
                      <Grid overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                        <BDebtTypography variant='body2'>
                          <b>{item?.title}</b>
                        </BDebtTypography>
                      </Grid>
                    }
                  />
                  <Box m={1} p={1} display='flex' justifyContent='center' alignItems='center'>
                    <BDebtCardContent>
                      {getIconComponent(item.icon)}
                    </BDebtCardContent>
                  </Box>
                </BDebtCard>
              </Grid>
            ))}
          </Grid>
        </TableContainer>
      </Grid >
      <Grid xs={12} borderRadius={0} borderTop={'1px solid lightgray'}>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid >
  );
};
export default PredefineWidgets;