import { Button, Grid } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import BDebtButton from "../../GlobalControl/BDebtButton";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import BDebtDialog from "../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import FormBuilder from "./FormBuilder";
import FormControl from "./FormControl";
import useStyles from "./TemplateCss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function AddCustomForm() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [allControlProps, setAllControlProps] = React.useState([]);

  const handleSaveAllControlProps = (updatedAllControlProps: any) => {
    setAllControlProps(updatedAllControlProps);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = [
    { label: "TextField", value: "textfield" },
    { label: "Radio BDebtButton", value: "radio" }
  ];

  const displayAllControlProperties = () => {
    const propertiesAsString = JSON.stringify(allControlProps, null, 2);
    alert(`All Control Properties:\n${propertiesAsString}`);
    handleClose();
  };

  return (
    <React.Fragment>
      <BDebtButton
        variant="contained"
        size="small"
        color="primary"
        style={{ fontSize: 12 }} onClick={handleClickOpen}
      >
        <AddCircleOutlineIcon/>
        CREATE NEW FORM
      </BDebtButton>
      <BDebtDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"lg"}
        className={classes.boostrapdialog}
      >
        <BDebtDialogTitleHeader id="add-form-name" onClose={handleClose}>
           FORMS
        </BDebtDialogTitleHeader>
        <Grid container spacing={0}> 
          <Grid item xs={4}  className={classes.properties}  >
            <BDebtTextField
            size="small"
              variant="outlined"
              label="Activity Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}  className={classes.properties} >
            <BDebtTextField
            size="small"
              label="Activity Description"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}  className={classes.properties} >
            <Autocomplete
            size="small"
              disablePortal
              id="combo-box-demo"
              options={data}
              renderInput={(params) => (
                <BDebtTextField {...params} label="Activity GroupName" />
              )}
            />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container className={classes.shadowStyle}>
            <Grid item xs={2} className={classes.shadowStyle}>
              <FormControl />
            </Grid>
            <Grid item xs={10} className={classes.shadowStyle}>
              <FormBuilder onSaveAllControlProps={handleSaveAllControlProps} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={displayAllControlProperties}
            className={classes.confirmstyle}
          >
            Confirm
          </Button>
          <Button
            onClick={handleClose}
            className={classes.closeStyle}
          >
            Close
          </Button>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
}
