/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTheme,
  AccumulationTooltip,
  IAccLoadedEventArgs,
  Inject,
  PieSeries,
} from "@syncfusion/ej2-react-charts";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import PieChart from "../../../../MockData/PieChart.json";
import {
  DialogContent,
  Grid,
} from "@material-ui/core";
import BDebtDialogTitleHeader from "../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { useState } from "react";
import useStyles from "../../../CustomForm/TemplateCss";
import BDebtButton from "../../../../GlobalControl/BDebtButton";
import BDebtDialog from "../../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTypography from "../../../../GlobalControl/BDebtTypography";
// import PieChart from "";




const SampleDashboardLayout = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const load = (args: IAccLoadedEventArgs): void => {
    let selectedTheme: string = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark") as AccumulationTheme;
  };

  const cellSpacing: number[] = [5, 5];

  const pie = () => {
    return (
      <div className={classes.PieDivStyle}>
        <div style={{ flex: 1 }}>
          <AccumulationChartComponent
            id="pie"
            legendSettings={{ visible: false }}
            enableSmartLabels={true}
            height="100%"
            width="100%"
            enableAnimation={false}
            selectionMode={"Point"}
            center={{ x: "50%", y: "50%" }}
            tooltip={{
              enable: false,
              header: "<b>${point.x}</b>",
              format: "Composition : <b>${point.y}%</b>",
            }}
            load={load.bind(this)}
          >
            <Inject
              services={[
                AccumulationLegend,
                PieSeries,
                AccumulationTooltip,
                AccumulationDataLabel,
              ]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={PieChart}
                name="Revenue"
                xName="Device"
                yName="Amount"
                explode={false}
                dataLabel={{
                  visible: true,
                  position: "Inside",
                  name: "text",
                  font: { fontWeight: "600" },
                }}
                radius="100%"
                innerRadius="35%"
                palettes={["#357cd2", "#00bdae", "#e36593"]}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
        <div className={classes.divStyles}>
          <div className={classes.Eventually}>
            <span className={classes.EventuallySpanStyle}></span>
            Eventually
          </div>
          <div className={classes.divChart}>
            <span className={classes.InitiatorSpanStyle}></span>
            Initiator
          </div>
          <div className={classes.divChart}>
            <span className={classes.SetFeeSpanStyle}></span>
            Set Fee
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <BDebtButton variant="contained" color="primary" size="small" onClick={handleOpen}>
        Preview
      </BDebtButton>
      <BDebtDialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={handleClose}
        >
          <BDebtTypography variant="h6" gutterBottom>
            Preview
          </BDebtTypography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <div>
            <Grid item xs={12} className={classes.WidgetGridStyle}>
              <DashboardLayoutComponent
                id="analytic_dashboard"
                cellAspectRatio={100 / 100}
                cellSpacing={cellSpacing}
                columns={10}
              >
                <PanelsDirective>
                  <PanelDirective sizeX={15} sizeY={10} row={4} col={4} content={pie.bind(this)} header="<div>Usage Statistics</div>"></PanelDirective>
                </PanelsDirective>
              </DashboardLayoutComponent>
            </Grid>
          </div>
        </DialogContent>
      </BDebtDialog>
    </div>
  );
};
export default SampleDashboardLayout;
