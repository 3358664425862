import React from 'react';
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination';
import { makeStyles } from '@mui/styles';

// Create custom styles using makeStyles
const useStyles = makeStyles({
  root: {
    backgroundColor: '#f0f0f0',
  },
  toolbar: {
    '&.MuiToolbar-root': {
      // Override styles for MuiToolbar-root within MuiTablePagination-toolbar
      // For example, change the color
      color: 'black',
    },
  },
  selectRoot: {
    fontSize: '14px',
    color: '#333',
  },
  select: {
    border: '1px solid #ccc',
    backgroundColor: '#fff',
  },
  selectIcon: {
    color: '#555',
  },
  actions: {
    margin: '0 20px',
  },
});

const BDebtTablePagination: React.FC<TablePaginationProps> = (props) => {
  // Apply the custom styles
  const classes = useStyles();

  return (
    <TablePagination
      // Apply custom classes to override styles
      classes={{
        root: classes.root,
        toolbar: classes.toolbar,
        selectRoot: classes.selectRoot,
        select: classes.select,
        selectIcon: classes.selectIcon,
        actions: classes.actions,
      }}
      {...props}
    />
  );
};

export default BDebtTablePagination;
