/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppBar, Box, Button, Checkbox, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, Select, Toolbar, } from "@mui/material";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
//LODASH 
import _ from "lodash";
//React ScrollBars
import Scrollbars from "react-custom-scrollbars";
//Global Custom Styles
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { BDebtTransition } from "../../../GlobalControl/BDebtTransition";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
//MockData
import { IClientCodeDetail, ICreateUser, IValidationErrors, MenuIndexw } from "../../../Model/IUser";
import useStyles from "../UserCss";
import api from "../../../ApiConfiguration/Configuration";
import { Menulist, Userlevel } from "../../../MockData/UserLevel/Menulist";
import { useState } from "react";
import EditClientCode from "./ClientCode";

let userDetail: ICreateUser = {
  userId: 0,
  firstName: "",
  lastName: "",
  userEmailAddress: "",
  userType: 0
};

const AddUser: React.FC<{ onCreate: () => void }> = (props: { onCreate: () => void; }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [Validation, setValidation] = useState<IValidationErrors[]>([]);
  const [selectedClientList, setSelectedClientList] = useState<any>();
  const [MenuIndex, setMenuIndex] = useState<MenuIndexw>({});
  const [localState, setLocalState] = useState(userDetail);

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, menuId: number, subMenuId?: number) => {
    const { name, checked } = e.target;

    setMenuIndex((prevMenuIndex) => {
      const updatedMenuIndex = { ...prevMenuIndex };

      if (subMenuId !== undefined) {
        // Update submenu checkbox
        updatedMenuIndex[menuId] = {
          ...updatedMenuIndex[menuId],
          subMenu: {
            ...updatedMenuIndex[menuId]?.subMenu,
            [subMenuId]: {
              ...updatedMenuIndex[menuId]?.subMenu?.[subMenuId],
              [name]: checked,
            },
          },
        };
      } else {
        // Update main menu checkbox
        updatedMenuIndex[menuId] = {
          ...updatedMenuIndex[menuId],
          [name]: checked,
        };
      }
      return updatedMenuIndex;
    });
  };

  const handleSelectedClientList = (newClientList: any) => {
    setSelectedClientList(newClientList);
  }

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleUserChange = (e: any) => {
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: parseInt(event.target.value),
    });
  };


  const formattedData = Menulist?.flatMap((menu) => {
    // Map through main menu items
    const mainMenuItem = {
      "menuId": menu.menuId,
      "subMenuId": 0, // Main menu item doesn't have a subMenuId
      "isCreate": MenuIndex[menu.menuId]?.isCreate || false,
      "isView": MenuIndex[menu.menuId]?.isView || false,
      "isDelete": MenuIndex[menu.menuId]?.isDelete || false,
    };

    // Map through sub-menu items if they exist
    const subMenuItems = menu.subMenu?.map((subMenu) => ({
      "menuId": menu.menuId,
      "subMenuId": subMenu.subMenuId,
      "isCreate": MenuIndex[subMenu.subMenuId]?.isCreate || false,
      "isView": MenuIndex[subMenu.subMenuId]?.isView || false,
      "isDelete": MenuIndex[subMenu.subMenuId]?.isDelete || false,
    })) || [];
    // Combine main menu item and sub-menu items
    return [mainMenuItem, ...subMenuItems];
  }) || [];

  const handleCreateUser = async () => {
    let newClientCodeList = [];
    let newUserDetails = [];

    selectedClientList.forEach((result) => {
      let newClient: IClientCodeDetail = {
        clientCode: result?.title
      }
      newClientCodeList?.push(newClient);
    });

    newUserDetails?.push(localState);
    (async () => {
      let request = {
        "userId": 0,
        "userDetail": newUserDetails,
        "clientCodeList": newClientCodeList,
        "userMenuPermission": formattedData
      }
      await api.post('User/SaveUserDetail', request);
    })();
  };

  return (
    <Grid>
      <BDebtButton variant="contained" onClick={openDialog} size="small" color="primary" startIcon={<GroupAddIcon />} >
        Add User
      </BDebtButton>
      <Dialog fullScreen open={isDialogOpen} onClose={closeDialog} TransitionComponent={BDebtTransition} fullWidth={true} >
        <AppBar sx={{ position: "relative" }} className={classes.dialogAppbar}>
          <Toolbar className={classes.dialogAppbar}>
            <BDebtTypography sx={{ flex: 1 }} variant="h6" component="div">
              ADD USER
            </BDebtTypography>
            <Button autoFocus variant="outlined" size="small" color="inherit" onClick={handleCreateUser} >
              SUBMIT
            </Button>
            <IconButton edge="end" color="inherit" onClick={closeDialog} aria-label="close" >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box m={0} p={0} bgcolor={"#FBFCFE"} borderBottom={"1px solid #CDD7E1"} >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Grid container spacing={2} padding={2} borderRadius={2}>
                <Grid item xs={12}>
                  <BDebtTypography variant="h6" padding="3px" borderBottom={"1px dashed lightgray"} align="center" color="primary" >
                    <b>PROFILE INFORMATION</b>
                  </BDebtTypography>
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="First Name"
                    size="small"
                    name="firstName"
                    onChange={(e) => { handleUserChange(e); }}
                    error={!!Validation.find((err) => err.fieldName === "firstName")}
                    helperText={Validation.find((err) => err.fieldName === "firstName")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="Last Name"
                    size="small"
                    name="lastName"
                    onChange={(e) => { handleUserChange(e); }}
                    error={!!Validation.find((err) => err.fieldName === "lastName")}
                    helperText={Validation.find((err) => err.fieldName === "lastName")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BDebtTextField
                    variant="outlined"
                    fullWidth
                    id="demo-helper-text-aligned"
                    label="Email"
                    size="small"
                    name="userEmailAddress"
                    onChange={(e) => { handleUserChange(e); }}
                    error={!!Validation.find((err) => err.fieldName === "userEmailAddress")}
                    helperText={Validation.find((err) => err.fieldName === "userEmailAddress")?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <form className={classes.root} noValidate autoComplete="off" >
                    <FormControl size="small" variant="outlined" className={`${classes.formControlCtEt}`} >
                      <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                      <Select native
                        id="U_CU_adminLevel"
                        onChange={handleChange}
                        value={localState.userType}
                        label="Admin Level"
                        inputProps={{ name: 'userType', id: 'outlined-age-native-simple', }}
                      >
                        {Userlevel?.map((option) => (
                          <option key={option.id} value={option?.id}>{option.title}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </form>
                </Grid>
                <Grid item xs={12}>
                  <EditClientCode ClientCode={null} onSelectedClientListChange={handleSelectedClientList} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} borderLeft={"1px solid #CDD7E1"}>
              <Grid container spacing={1} padding={2} borderRadius={2} bgcolor={"#FBFCFE"}>
                <Grid item xs={12}>
                  <BDebtTypography variant="h6" padding="3px" borderBottom={"1px dashed lightgray"} align="center" color="primary">
                    <b>MENU INFORMATION</b>
                  </BDebtTypography>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} bgcolor={"#3b4a54"}>
                      <Grid item xs={4} p={1}>
                        <BDebtTypography variant="body2" color="white" className={classes.MenuHeader}>
                          <b>MENU</b>
                        </BDebtTypography>
                      </Grid>
                      <Grid item xs={1} p={1}>
                        <BDebtTypography variant="body2" color="white" className={classes.MenuHeader}>
                          <b>CREATE</b>
                        </BDebtTypography>
                      </Grid>
                      <Grid item xs={1} p={1}>
                        <BDebtTypography variant="body2" color="white" className={classes.MenuHeader}>
                          <b>VIEW</b>
                        </BDebtTypography>
                      </Grid>
                      <Grid item xs={1} p={1}>
                        <BDebtTypography variant="body2" color="white" className={classes.MenuHeader}>
                          <b>DELETE</b>
                        </BDebtTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      className={classes.menuListContainer}
                    >
                      {Menulist?.map((r, index) => {
                        return (
                          <Box m={0.5} mt={1} p={0} key={index}>
                            <Grid
                              container
                              spacing={0}
                              borderRadius={0}
                              boxShadow={2}
                            >
                              <Grid item xs={1} p={0}>
                                {r?.subMenu && r.subMenu.length > 0 && (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setExpand(!expand)}
                                  >
                                    {expand ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                )}
                              </Grid>
                              <Grid item xs={4} p={0}>
                                <BDebtTypography
                                  variant="body2"
                                  pt={1}
                                  pl={1}
                                >
                                  {r?.menuName}
                                </BDebtTypography>
                              </Grid>
                              <Grid item xs={1} p={0}>
                                <Checkbox
                                  name="isCreate"
                                  size="small"
                                  color="success"
                                  onChange={(e) => handleChangeChecked(e, r.menuId)}
                                />
                              </Grid>
                              <Grid item xs={1} p={0}>
                                <Checkbox
                                  name="isView"
                                  size="small"
                                  color="success"
                                  onChange={(e) => handleChangeChecked(e, r.menuId)}
                                />
                              </Grid>
                              <Grid item xs={1} p={0}>
                                <Checkbox
                                  name="isDelete"
                                  size="small"
                                  color="success"
                                  onChange={(e) => handleChangeChecked(e, r.menuId)}
                                />
                              </Grid>
                            </Grid>
                            <Collapse
                              in={expand}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                m={0}
                                border={"1px solid lightgray"}
                                bgcolor={"aliceblue"}
                              >
                                {r?.subMenu?.map(
                                  (subMenu, subMenuIndex) => (
                                    <Grid
                                      container
                                      spacing={0}
                                      key={subMenuIndex}
                                    >

                                      <Grid item xs={2} p={0}>
                                        <BDebtTypography
                                          variant="body2"
                                          className={classes.submenuName}
                                          pt={1}
                                          pl={1}
                                        >
                                          {subMenu?.subMenuName}
                                        </BDebtTypography>
                                      </Grid>
                                      <Grid item xs={1} p={0}>
                                        <Checkbox
                                          name="isCreate"
                                          size="small"
                                          color="primary"
                                          className={classes.submenuCheckbox}
                                          onChange={(e) => handleChangeChecked(e, r.menuId, subMenu.subMenuId)}
                                        />
                                      </Grid>
                                      <Grid item xs={1} p={0}>
                                        <Checkbox
                                          name="isView"
                                          size="small"
                                          color="primary"
                                          className={classes.submenuCheckbox}
                                          onChange={(e) => handleChangeChecked(e, r.menuId, subMenu.subMenuId)}
                                        />
                                      </Grid>
                                      <Grid item xs={2} p={0}>
                                        <Checkbox
                                          name="isDelete"
                                          size="small"
                                          color="primary"
                                          className={classes.submenuCheckbox}
                                          onChange={(e) => handleChangeChecked(e, r.menuId, subMenu.subMenuId)}
                                        />
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                              </Box>
                            </Collapse>
                          </Box>
                        );
                      })}
                    </Scrollbars>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Grid>
  );
}
export default AddUser;