/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//Controls
import Scrollbars from "react-custom-scrollbars";
import { Grid, Paper, Typography, Button, Stepper, Step, StepLabel, Box } from "@mui/material";
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
//Icons
import ColorlibStepIcon from "./style";
import notSubmitted from "../../../asset/image/notSubmitted.svg"; //FutureEnhancement
import submissionIcon from "../../../asset/image/Placement/submissionIcon.svg";
import DownloadingIcon from '@mui/icons-material/Downloading';
import Confirmation from "../../../asset/image/Placement/Confirmation.svg";
import csv from "../../../asset/image/Placement/csv.svg";
//CSS
import useStyles from "../PlacementCss";
//Modules
import { IUploadedFile } from "../../../Model/placement/PlacementGridItem";
import { useFetch } from "../../../utils/apiHelper";
import DownloadCsvFile from "./DownloadCsvFile";
import { usePost } from "../../../utils/apiHelper";
import { PlacementItem } from "../../../Model/placement/PlacementGridItem";

const steps = ["Load", "Transform", "Preview", "Map Target", "Submit"];

const Finish = (props) => {
  const [activeStep, setActiveStep] = useState(4);
  const classes = useStyles();
  const history = useHistory();
  const clientCode = props?.location?.state?.clientCode;
  const phaseCode = props?.location?.state?.phaseCode;
  const dataset = props?.location?.state?.dataset;
  const uploadedData: any[] = props?.location?.state?.uploadedData;
  const [addNewRow, setAddNewRow] = useState(props?.location?.state?.addNewRow);
  const errorRecords = props?.location?.state?.errorRecords;
  const acceptedRecords = props?.location?.state?.acceptedRecords;
  const fileName = props?.location?.state?.fileName;
  const fileDataLength = props?.location?.state?.fileDataLength;
  const successRecord = props?.location?.state?.successRecord;
  const deletedRecords = props?.location?.state?.deletedRecords;
  const [fileData, setFileData] = useState<IUploadedFile[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const handleAdditionalAccountsSubmit = () => {
    history.push("/accounts/Ingest");
  };

  const GetUploadFileDetails = () => {
    useFetch<IUploadedFile[]>('Placement/GetFileDetail').then((response) => {
      setFileData(response.data);
    })
  };

  useEffect(() => {
    GetUploadFileDetails();
  }, []);

  const handleExport = () => {
    if (errorRecords && errorRecords.length > 0) {
      const csvData = convertToCSV(errorRecords);
      downloadCSV(csvData, "ErrorRecords.csv");
    }
  };

  const handleExportAccepted = () => {
    if (acceptedRecords && acceptedRecords.length > 0) {
      const csvData = convertToCSV(acceptedRecords);
      downloadCSV(csvData, "AcceptedRecords.csv");
    }
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row: any) => Object.values(row).join(",")).join("\n");
    return header + rows;
  };

  const downloadCSV = (data: string, filename: string) => {
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${data}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmitClick = () => {
    if (uploadedData && uploadedData.length > 0) {
      const headers = Object?.keys(uploadedData[0]);

      const headerMapping: { [key: string]: keyof PlacementItem } = headers?.filter(header => header?.trim() !== '')?.reduce((acc, header) => {
        acc[header] = header.charAt(0).toLowerCase() + header.slice(1).replace(/\s/g, '') as keyof PlacementItem;
        return acc;
      }, {});

      const csvData = convertToCSV(acceptedRecords);

      (async () => {
        let request = {
          id: 0,
          clientCode: clientCode,
          phaseCode: phaseCode,
          dataset: dataset,
          placementAccountDetailList: addNewRow?.map((field) => {
            // Filterout empty headers if needed
            const mappedField: Partial<Record<keyof PlacementItem, string>> = headers?.filter(header => header?.trim() !== '')?.map(header => {
              const mappedKey = headerMapping[header] || header; return [mappedKey, field[header] !== null ? field[header] : "DefaultValue"];
            })?.reduce((obj, [key, value]) => {
              obj[key] = value;
              return obj;
            }, {} as Partial<Record<keyof PlacementItem, string>>);
            return mappedField as Record<keyof PlacementItem, string>;
          })
        };
        await usePost("Placement/PlaceAccount", request);

        let userId = 1;
        let uploadedBy = 123;
        const formData = new FormData();
        formData.append("file", new Blob([csvData], { type: "text/csv" }), fileName);
        formData.append("UserId", userId?.toString());
        formData.append("UploadedBy", uploadedBy?.toString());
        await usePost("Placement/InsertPlacementFileDetail", formData).then((response) => {
          setSubmitted(true);
        });
      })()
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        component={Paper}
        className={classes.pageContainer}>
        <Grid xs={activeStep === 4 ? 12 : 10} className={activeStep === 3 ? classes.stepperInActive : classes.stepperActive} sx={{ borderBottom: "1px solid lightgray" }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <div>
        {submitted ? (
          <div className={classes.finishDiv}>
            <Grid container spacing={1} padding={'10px 150px'}>
              <Grid item xs={6} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                <img src={submissionIcon} alt="submit Icon" width={'450px'} /* width={800} height={500} */ />
                <Typography variant="h6" align="center" fontWeight="bold" className={classes.FontStyle}>
                  Thank You for Your Submission!
                </Typography>
              </Grid>
              <Grid item xs={6} pt={2}>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.processedData} p={1}>
                      <b>Processed Data Overview</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <Typography variant="subtitle2" >
                      File Name:  {fileName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" >
                      Total Records: {fileDataLength}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" >
                      Success Records:  {successRecord}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" >
                      Error Records:  0
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">
                      Deleted Records:  {deletedRecords?.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"} alignContent={"space-between"} spacing={2}>
                    <Grid item xs={6}>
                      <Button className={classes.finishButtonStyle} size="small" fullWidth onClick={handleExport}>
                        <span className={classes.colorStyle}>Error Records</span>
                        <DownloadingIcon className={classes.ThumbUpIconStyle} />
                      </Button >
                    </Grid>
                    <Grid item xs={6}>
                      <Button className={classes.finishButtonStyle} size="small" fullWidth style={{ marginLeft: "3px" }} onClick={handleExportAccepted}>
                        <span className={classes.colorStyle}>Accepted Records</span>
                        <DownloadingIcon className={classes.ThumbUpIconStyle} />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="small"
                      onClick={handleAdditionalAccountsSubmit}
                      style={{ backgroundColor: "#009AD5" }}
                    >
                      Ingest Accounts Again
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mt={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.processedData} p={1}>
                      <b>Previous Submission</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      className={classes.fieldContainerHistory}
                    >
                      {fileData?.map((field: any) => (
                        <Box p={0} m={0.5}>
                          <BDebtCard borderRadius="5px">
                            <Grid container spacing={1} sx={{ padding: 0.5 }} >
                              <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <img src={csv} alt="csv" width={"20px"} height={"20px"} />
                              </Grid>
                              <Grid item xs={10} className={classes.fontStyle}>
                                <BDebtTypography variant="body2" >
                                  {field.fileName}
                                </BDebtTypography>
                              </Grid>
                              <Grid item xs={1}>
                                <DownloadCsvFile blobUri={field.blobUri} />
                              </Grid>
                            </Grid>
                          </BDebtCard>
                        </Box>
                      ))}
                    </Scrollbars>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
            {/* Future enhancement */}
            {/* <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} className={classes.submitedData}>
                  <Typography align="center" fontWeight="bold" className={classes.FontStyle}>
                    Thank You for Your Submission!
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"} marginTop={1} >
                  <img src={submissionIcon} alt="submit Icon" width={200} height={200} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} sm={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.processedData} p={1}>
                        <b>Processed Data Overview</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography variant="subtitle2" >
                        File Name:  {fileName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" >
                        Total Records: {fileDataLength}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" >
                        Success Records:  {successRecord}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" >
                        Error Records:  0
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">
                        Deleted Records:  {deletedRecords?.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} alignContent={"space-between"} spacing={2}>
                      <Grid item xs={6}>
                        <Button className={classes.finishButtonStyle} size="small" fullWidth onClick={handleExport}>
                          <span className={classes.colorStyle}>Error Records</span>
                          <DownloadingIcon className={classes.ThumbUpIconStyle} />
                        </Button >
                      </Grid>
                      <Grid item xs={6}>
                        <Button className={classes.finishButtonStyle} size="small" fullWidth style={{ marginLeft: "3px" }} onClick={handleExportAccepted}>
                          <span className={classes.colorStyle}>Accepted Records</span>
                          <DownloadingIcon className={classes.ThumbUpIconStyle} />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="small"
                        onClick={handleAdditionalAccountsSubmit}
                        style={{ backgroundColor: "#009AD5" }}
                      >
                        Ingest Accounts Again
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.processedData} p={1}>
                        <b>Previous Submission</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        className={classes.fieldContainerHistory}
                      >
                        {fileData?.map((field: any) => (
                          <Box p={0} m={1}>
                            <BDebtCard borderRadius="5px">
                              <Grid container spacing={1} sx={{ padding: 1 }}>
                                <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                  <img src={csv} alt="csv" width={"20px"} height={"20px"} />
                                </Grid>
                                <Grid item xs={10} className={classes.fontStyle}>
                                  <BDebtTypography variant="body2" >
                                    {field.fileName}
                                  </BDebtTypography>
                                </Grid>
                                <Grid item xs={1}>
                                  <DownloadCsvFile blobUri={field.blobUri} />
                                </Grid>
                              </Grid>
                            </BDebtCard>
                          </Box>
                        ))}
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Box> */}
          </div>
        ) : (
          <div className={classes.finishDiv}>
            <Box p={2}>
              <Grid container spacing={1} padding={4} border={"2px solid orange"} borderRadius={"20px"} maxWidth={"500px"}>
                <Grid item xs={12} sm={12} className={classes.submitedData}>
                  <Typography align="center" fontWeight="bold" className={classes.FontStyle}>
                    Would you like to Submit?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"} marginTop={1} >
                  <img src={Confirmation} alt="submit Icon" width={250} height={250} />
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"}>
                  <Button variant="contained" color="success" onClick={handleSubmitClick}> Submit</Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </div>
      {/* Future Enhancement
      <div className={classes.finishDiv}>
        <Grid container spacing={2} className={classes.finishRoot} sx={{ maxWidth: 500 }}>
          <Grid item xs={12} sm={12} className={classes.submitedData}>
            <Typography align="center" fontWeight="bold" className={classes.FontStyle}>
            Your records were not submitted!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"}>
            <img src={notSubmitted} alt="submit Icon" width={130} height={130} />
          </Grid>
        </Grid>
      </div>  */}
    </div >
  );
};

export default Finish;