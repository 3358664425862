
export const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/3ba10f38-0314-4ab2-b6b4-516f9ca8dbe5',
      clientId: 'ddf15b6b-89c4-42c1-99fa-3406552dbb27',
      redirectUri: window.document.location.origin,
      postLogoutRedirectUri: window.document.location.origin,
      scopes: ['https://cognultsoftb2c.onmicrosoft.com/a64ecc2e-3822-4ee0-94d5-87050f6271c3/Debtor365.API.Read'],
    },
    system: {
      iframeHashTimeout: 10000,
    },
  }
  export const loginRequest = {
    scopes: msalConfig.auth.scopes,
  }

  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };