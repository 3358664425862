/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
//Mui Components
import { Paper, Table, TablePagination, TableRow,TableCell,TableContainer,TableBody,InputAdornment, Grid,TableHead,Checkbox} from "@mui/material";
import { Box } from "@mui/system";
//CSS
import useStyles from "../../src/Components/Setting/Connection_configuration/ConfigurationCss";
//JSON
import ConfigMock from "../MockData/Configuration/ConfigMock.json";
//HTTP request
import axios from "axios";
//Interface
import { IGetDataDetails } from "../Model/Configuration";
//Global Custom CSS
import BDebtTextField from "../GlobalControl/BDebtTextField";
//Component
import AddDataSource from "../Components/Setting/Connection_configuration/AddDataSource";
//Icons
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";


import BDebtTypography from "../GlobalControl/BDebtTypography";

const Datasource = () => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value, setValue] = useState("");
  const [DataDetails, SetDataDetails] = useState<IGetDataDetails[]>([]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GetdataSourceDetails = async () => {
    await axios
      .get("https://localhost:7095/api/Student/GetAllDatasourceDetails")
      .then((response) => {
        SetDataDetails(response.data);
      });
  };

  function EnhancedTableHead() {
    const headCells = [
      {
        id: "ConnectorType",
        numeric: false,
        disablePadding: false,
        label: "CONNECTOR TYPE",
        sortable: false,
      },
      {
        id: "ConnectorName",
        numeric: false,
        disablePadding: false,
        label: "CONNECTOR NAME",
        sortable: false,
      },
      {
        id: "createdby",
        numeric: false,
        disablePadding: false,
        label: "CREATED BY",
        sortable: false,
      },
      {
        id: "ConnectionString",
        numeric: false,
        disablePadding: false,
        label: "CONNECTION STRING",
        sortable: false,
      },

      {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "ACTIONS",
        sortable: false,
      },
    ];
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellHeader}>
            <Checkbox
              size="small"
              color="secondary"
              className={classes.checkBox}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell className={classes.tableCellHeader} key={headCell.id}>
              {headCell.label || headCell.sortable}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Box p={1} m={1}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
        <BDebtTypography variant="body2">
            <b>CONFIGURATION</b>
          </BDebtTypography>
        </Grid>
        <Grid item xs={8}>
          <BDebtTextField
            id="input-with-icon-textfield"
            placeholder="Search User name or Email Id..."
            size="small"
            variant="outlined"
            fullWidth
            onChange={onSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
        <AddDataSource onCreate={GetdataSourceDetails} />  
        </Grid>
        <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Paper>
            <TableContainer
              className={`${
                classes.tablebodyConainer
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableHead />
                <TableBody>
                  {ConfigMock.map((row: any, index: number) => (
                    <TableRow key={row?.activityName}>
                      <TableCell className={classes.tableCellStyle}>
                        <Checkbox
                          size="small"
                          color="primary"
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      {row?.connectorType}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      {row?.connectorName}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      {row?.createdBy}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      {row?.connectionString}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      <EditOutlinedIcon className={classes.editIcon}  />
                      <DeleteOutlineOutlinedIcon className={classes.deleteIcon}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={100}
              page={page}
              size="small"
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      </Grid>
    </Box>
  );
};

export default Datasource;
