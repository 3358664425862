/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Button, Grid, Typography, TextField, Box, Paper, FormLabel, ClickAwayListener, Checkbox,
  Chip, InputAdornment, IconButton, TableContainer, FormControlLabel,
} from "@material-ui/core";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "../AgentUser/AgentUserCss";
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { Scrollbars } from "react-custom-scrollbars";
import UserPermissions from "../../../MockData/AgentUserPermission.json";
import FormControl from "@mui/material/FormControl";
import CustomizeActivity from "./CustomizeActivity";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailTwoToneIcon from "@mui/icons-material/AlternateEmailTwoTone";

const CreateAgentUser = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [clientCodeOpen, setClientCodeOpen] = useState(false);
  const [selectedClients, setSelectedClients] = useState<any>([]);
  const [showCustomizeActivity, setShowCustomizeActivity] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowCustomizeActivity(false);
  };

  const handleClientSelect = () => { };

  const handleClickAway = () => { };

  const DeselectAllChange = () => { };
  const handleClickOpen = () => { };
  const handleSearchClient = () => { };

  const handleSubmit = () => {
    setShowCustomizeActivity(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        startIcon={<GroupAddIcon />}
        style={{ backgroundColor: "rgb(59 74 84)", color: "white" }}
        onClick={handleOpen}
      >
        Create Agent User
      </Button>
      <BDebtDialog
        open={open}
        fullScreen
        maxWidth={"lg"}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={handleClose}
        >
          <b>CREATE NEW AGENT USER</b>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.BoxStyles} display={"flex"}>
                <Grid item xs={4}>
                  <TextField
                    placeholder="First Name"
                    variant="outlined"
                    size="small"
                    className={classes.testBox}
                    InputProps={{
                      startAdornment: <AccountCircle />,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Last Name"
                    variant="outlined"
                    size="small"
                    className={classes.testBox}
                    InputProps={{
                      startAdornment: <AccountCircle />,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    className={classes.testBox}
                    InputProps={{
                      startAdornment: <AlternateEmailTwoToneIcon />,
                    }}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.userboxviewStyle}>
                <div>
                  <Box className={classes.LabelBoxStyle}>
                    <FormLabel className={classes.formLabel}>
                      {" "}
                      Selected Client Code : {selectedClients.length}
                    </FormLabel>
                  </Box>
                </div>
                <div>
                  {selectedClients?.length > 0 ? (
                    <Paper className={classes.boxBorder}>
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                        autoHeightMin="auto"
                        autoHeightMax={80}
                        style={{ width: "100%" }}
                      >
                        <div className={`${classes.valueContainer}`}>
                          {selectedClients.map((v: any) => (
                            <Chip
                              key={v.client_Code}
                              size="small"
                              color="secondary"
                              label={v.client_Code}
                            />
                          ))}
                        </div>
                      </Scrollbars>
                    </Paper>
                  ) : null}
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                      <Autocomplete
                        multiple
                        size="small"
                        filterOptions={(options, state) => options}
                        className={classes.clientCodeBox}
                        autoComplete={false}
                        id="checkboxes-tags-demo"
                        classes={{ paper: classes.paper2 }}
                        disableClearable
                        renderTags={() => null}
                        onChange={(e, selected) => {
                          handleClientSelect();
                        }}
                        onOpen={() => { }}
                        onClose={(e: any, reason: AutocompleteCloseReason) => {
                          if (reason === "toggleInput") {
                            setClientCodeOpen(false);
                          }
                        }}
                        ListboxProps={{
                          style: {
                            maxHeight: "160px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            placeholder="Search Clients"
                            onChange={(e) => { }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleSearchClient();
                                handleClickOpen();
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(_) => {
                                        handleClickOpen();
                                      }}
                                    >
                                      <SearchIcon
                                        className={classes.IconSizeStyle}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                  <Box
                                    display="flex"
                                    width="100%"
                                    className={classes.CheckBoxBorder}
                                  >
                                    <Box
                                      className={classes.ClientcheckBoxStyle}
                                      width="60%"
                                    >
                                      <Checkbox size="small" id="check-all" />
                                      <span style={{ fontSize: 12 }}>
                                        Select All
                                      </span>
                                    </Box>
                                    <Box
                                      className={classes.ClientcheckBoxStyle}
                                      width="40%"
                                    >
                                      <Checkbox
                                        size="small"
                                        onChange={(e) => {
                                          DeselectAllChange();
                                        }}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 12 }}>
                                        Deselect All
                                      </span>
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              ),
                            }}
                            className={classes.textBoxStyle}
                          />
                        )}
                        options={[]}
                      />
                    </Box>
                  </ClickAwayListener>
                </div>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className={classes.BoxStyles}>
                <Typography className={classes.typography}>
                  <b>PERMISSION</b>
                </Typography>
                <TableContainer
                  style={{ padding: "5px" }}
                  className={`${"scrollbox"} ${"on-scrollbar"}`}
                >
                  {UserPermissions?.map((r: any) => {
                    return (
                      <FormControl
                        component="fieldset"
                        className={classes.FormControl}
                      >
                        <FormControlLabel
                          value={`${r?.Id}`}
                          control={
                            <Checkbox size="small" name={`${r?.permission}`} />
                          }
                          label={`${r?.permission}`}
                          labelPlacement="end"
                        />
                      </FormControl>
                    );
                  })}
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.BoxStyles}>
                <Typography className={classes.typography}>
                  <b>WIDGETS</b>
                </Typography>
                <TableContainer
                  style={{ padding: "5px" }}
                  className={`${"scrollbox"} ${"on-scrollbar"}`}
                >
                  {UserPermissions?.map((r: any) => {
                    return (
                      <FormControl
                        component="fieldset"
                        className={classes.FormControl}
                      >
                        <FormControlLabel
                          value={`${r?.Id}`}
                          control={
                            <Checkbox size="small" name={`${r?.widgets}`} />
                          }
                          label={`${r?.widgets}`}
                          labelPlacement="end"
                        />
                      </FormControl>
                    );
                  })}
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
          <div style={{ display: showCustomizeActivity ? "block" : "none" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomizeActivity />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={handleSubmit}
            className={classes.yesbutton}
          >
            SAVE
          </Button>
          <Button
            size="small"
            onClick={handleClose}
            className={classes.nobutton}
          >
            CANCEL
          </Button>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default CreateAgentUser;
