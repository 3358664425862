/* eslint-disable @typescript-eslint/no-unused-vars */
import HistoryIcon from '@material-ui/icons/History';
import { FormControl, Grid, IconButton, MenuItem, TablePagination, Tooltip, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React, { useState } from 'react';
import BDebtButton from '../../../GlobalControl/BDebtButton';
import BDebtTextField from '../../../GlobalControl/BDebtTextField';
import BDebtTable from '../../../GlobalControl/Table/BDebtTable';
import BDebtTableCell from '../../../GlobalControl/Table/BDebtTableCell';
import BDebtTableRow from '../../../GlobalControl/Table/BDebtTableRow';
import BDebtTablehead from '../../../GlobalControl/Table/BDebtTablehead';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import Data from '../../../MockData/UserHistory.json';
import useStyles from '../UserCss';
import { Select } from '@material-ui/core';

interface IData {
    id: string,
    value: string
}

const AllUserHistory = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [action, setAction] = useState('');
    const [startDate, setStartDate] = useState(getDate());
    const [endDate, setEndDate] = useState(getDate());
    const [filteredData, setFilteredData] = useState([] as any[]);
    const [debtorId, setDebtorId] = useState('');

    function getDate(): string {
        const today: Date = new Date();
        const year: number = today.getFullYear();
        let month: number | string = today.getMonth() + 1;
        let day: number | string = today.getDate();

        // Formatting to 'YYYY-MM-DD'
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    }

    const options: IData[] = [
        { id: 'CONSTO1', value: 'CONSTO1' },
        { id: 'CONSTO2', value: 'CONSTO2' },
        { id: 'CONSTO2', value: 'CONSTO2' },
    ];


    const handleFilter = () => {
        const filtered = Data.filter((item: any) => {
            let matches = true;

            // Convert debtorId to a number (if applicable)
            const debtorIdToCompare = typeof debtorId === 'string' ? Number(debtorId) : debtorId;
            if (debtorIdToCompare !== null && item.debtorId !== debtorIdToCompare) {
                matches = false;
            }

            if (startDate && endDate) {
                const startDateObject = new Date(startDate);
                const endDateObject = new Date(endDate);

                const itemDate = new Date(item.dateOfAction); // Make sure to use the correct property name

                if (itemDate > startDateObject && itemDate < endDateObject) {
                    matches = false;

                }
            }

            if (action && item.action !== action) {
                matches = false;
            }

            return matches;
        });

        setFilteredData(filtered);
    };



    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event: any) => {
        setAction(event.target.value as string);
    };

    const handleDateChange = (event: any) => {
        setStartDate(event.target.value);
    };
    const handleDate = (event: any) => {
        setEndDate(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    function EnhancedTableHead() {
        const headCells = [
            { id: "pagename", numeric: false, disablePadding: false, label: "  Page Name", sortable: false },
            { id: "debtorid", numeric: false, disablePadding: false, label: "  Debtor Id", sortable: false },
            { id: "action", numeric: false, disablePadding: false, label: "Action", sortable: false },
            { id: "dateofaction", numeric: false, disablePadding: false, label: " Date Of Action", sortable: false },
            { id: "time", numeric: false, disablePadding: false, label: " Time", sortable: false },

        ];

        return (
            <BDebtTablehead>
                <BDebtTableRow>
                    {headCells.map(headCell => (
                        <BDebtTableCell className={classes.tableCellHeader}
                            key={`${headCell?.id}`}>
                            {headCell.label || headCell.sortable}
                        </BDebtTableCell>
                    ))}
                </BDebtTableRow>

            </BDebtTablehead >
        );
    }

    return (
        <div>
            <Tooltip title="HISTORY" arrow onClick={handleOpen} >
                <IconButton size='small' sx={{ padding: 0 }}>
                    <HistoryIcon />
                </IconButton>
            </Tooltip>

            <BDebtDialog
                open={open}
                fullScreen
                onClose={handleClose}
                fullWidth={true}
            >
                <BDebtDialogTitleHeader
                    id="responsive-dialog-title"
                    onClose={handleClose}

                >
                    <Typography variant="h6" textAlign={"center"}><b>USER HISTORY</b> </Typography>


                </BDebtDialogTitleHeader>

                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid container spacing={1} style={{ padding: "10px" }}>
                            <Grid item xs={2}>
                                <BDebtTextField
                                    size='small'
                                    label="DebtorId"
                                    onChange={(e) => setDebtorId(e.target.value)}
                                    fullWidth
                                    sx={{ padding: '4px' }}
                                >

                                </BDebtTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <BDebtTextField
                                    type='date'
                                    size='small'
                                    fullWidth
                                    label="Startdate"
                                    value={startDate}
                                    sx={{ padding: '4px' }}
                                    onChange={handleDateChange}
                                    inputProps={{ max: new Date().toISOString().split('T')[0] }}
                                >
                                    Start Date
                                </BDebtTextField>
                            </Grid>
                            <Grid item xs={3}>
                                <BDebtTextField
                                    type='date'
                                    size='small'
                                    value={endDate}
                                    onChange={handleDate}
                                    fullWidth
                                    label="Enddate"
                                    sx={{ padding: '4px' }}
                                    inputProps={{
                                        min: startDate,
                                        max: new Date().toISOString().split('T')[0]
                                    }}
                                >
                                    End Date
                                </BDebtTextField>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth size='small'>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        variant='outlined'
                                        value={action}
                                        style={{marginTop:"6px"}}
                                        className={classes.selectform}
                                        onChange={handleChange}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null, // Prevents default positioning
                                        }}

                                    >
                                        {options.map((option: IData) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <BDebtButton
                                    size='small'
                                    variant='contained'
                                    style={{ top: "5px" }}
                                    onClick={handleFilter}
                                >
                                    Filter
                                </BDebtButton>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} >
                            <TableContainer component={Paper} className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <BDebtTable aria-label="customized table" size="medium" stickyHeader>
                                    <EnhancedTableHead />
                                    <TableBody>
                                        {(filteredData.length > 0 ? filteredData : Data).map((item, index) => (
                                            <BDebtTableRow key={item?.id === undefined ? item.id : index}>
                                                <BDebtTableCell align="left" className={classes.Tablecell}>{item.pageName}</BDebtTableCell>
                                                <BDebtTableCell align="left" className={classes.Tablecell}>{item.debtorId}</BDebtTableCell>
                                                <BDebtTableCell align="left" className={classes.Tablecell}>{item.action}</BDebtTableCell>
                                                <BDebtTableCell align="left" className={classes.Tablecell}>{item.dateofAction}</BDebtTableCell>
                                                <BDebtTableCell align="left" className={classes.Tablecell}>{item.time}</BDebtTableCell>
                                            </BDebtTableRow>
                                        ))}

                                    </TableBody>
                                </BDebtTable>
                            </TableContainer>

                            <TablePagination
                                component="div"
                                count={100}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                style={{display:"flex",justifyContent:"flex-end",padding:"16px"}}
                            />

                        </Grid>
                    </Grid>
                </DialogContent>
            </BDebtDialog>
        </div>
    )
}

export default AllUserHistory;


