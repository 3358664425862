import { CardHeader, styled } from "@mui/material";

const BDebtCardHead = styled(CardHeader)({
  "&.MuiCardHeader-root": {
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(18, 25, 38)',
    padding: '24px',
  }
});

export default BDebtCardHead;
