/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useState } from "react";
import DataMapping from "../../Components/DataPipeline/DataMapping";
import useStyles from "../../Components/DataPipeline/DataPipelineCss";
import Destination from "../../Components/DataPipeline/Destination";
import Schedule from "../../Components/DataPipeline/Schedule";
import Source from "../../Components/DataPipeline/Source";
import { Field } from "../../Model/Configuration";

const steps = ["Source", "Destination", "Column Mapping", "Schedule", "Debug"];

const Pipeline: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [data, setData] = useState<any>(null);
  const [sourceSchema, setSourceSchema] = useState<any>([]);
  const [destinationSchema, setDestinationSchema] = useState<any>([]);
  const [selectedConnection, setSelectedConnection] = useState<string>(null);
  const [selectedTableList, setSelectedTableList] = useState<string>(null);
  const [selectedDesConnection, setSelectedDesConnection] = useState<string>(null);
  const [selectedDesTableList, setSelectedDesTableList] = useState<string>(null);

  const handleNext = (formData: any, sourceSchemaData: any, destinationSchemaData: any) => {
    setData(formData);
    setSourceSchema(sourceSchemaData);
    setDestinationSchema(destinationSchemaData);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDataSchema = (newData: Field[], connection: string, tableList: string) => {
    setSourceSchema(newData);
    setSelectedConnection(connection);
    setSelectedTableList(tableList);
  };

  const handleDestinationSchema = (newData: Field[], connection: string, tableList: string) => {
    setDestinationSchema(newData);
    setSelectedDesConnection(connection);
    setSelectedDesTableList(tableList);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Source sourceSchema={handleDataSchema} />;
      case 1:
        return <Destination  destinationSchema={handleDestinationSchema} />;
      case 2:
        return <DataMapping sourceSchema={sourceSchema} destinationSchema={destinationSchema} />;
      case 3:
        return <Schedule sourceSchema={sourceSchema} destinationSchema={destinationSchema} SourceConnection={selectedConnection} Destinationconnection={selectedDesConnection} SourceTable={selectedTableList} DestinationTable={selectedDesTableList} />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={0} component={Paper} className={classes.pageContainer}>
      <Grid xs={9} className={classes.stepperInActive}>
        <Stepper activeStep={activeStep} >
          {steps.map((label, index) => (
            <Step key={label} >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid xs={3} className={classes.buttonStyle}>
        {activeStep > 0 && (
          <Button variant="outlined" color="inherit" onClick={handleBack} >
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button variant="contained" onClick={() => handleNext(data, sourceSchema, destinationSchema)} >
            {activeStep === steps.length - 2 ? "Finish" : "Next"}
          </Button>
        )}
      </Grid>
      <Grid xs={12}>
        {getStepContent(activeStep)}
      </Grid>
    </Grid>
  );
};

export default Pipeline;

