/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import * as React from 'react';
import { IDebtTableRow } from '../../Model/Debtors/IDebtTableRow';
import Demographic from "./AccountDetails/Demographic";
import PaymentAmount from "./AccountDetails/PaymentAmount";

const AccountDetails: React.FC<{ dataRowSelected?: IDebtTableRow }> = (props) => {
  const { dataRowSelected } = props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Demographic dataRowSelected={dataRowSelected} />
      <PaymentAmount dataRowSelected={dataRowSelected} />
    </Box >
  );
}

export default AccountDetails;
