import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        div: {
            flexGrow: 1,
        },
        GreenColor: {
            color: "green",
        },
        container: {
            paddingTop: '15px',
        },
        outlinedInput: {
            fontSize: "12px !important",
            color: "black !important",
            marginTop: '-2px !important',
            '&$focused $notchedOutline': {
                color: 'white !important',
                border: '0px !important'
            },
        },
        notchedOutline: {
            color: 'white !important',
            border: 'none !important',
            fontSize: "12px !important",
        },
        searchBox: {
            marginTop: '2px !important',
            borderRadius: "5px !important",
            border: '2px solid #215176 !important',
            height: '35px !important',
            maxWidth: '100%',
            textIndent: '10px !important',
            fontSize: "12px !important",
            '& .MuiInputBase-root': {
                fontSize: "12px !important"
            }
            // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        },
        tableCellHeader: {
            padding: '3px 15px !important',
            fontSize: '12px !important',
            color: 'white !important',
            background: "#3b4a54 !important"
        },
        tableCellStyle: {
            padding: '3px 15px !important',
            '& .MuiTableCell-root': {
                padding: '3px 15px!important',
            },
            fontSize: '13px !important',
        },
        boxHeader: {
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            background: 'white'
        },
        paddingDiv: {
            padding: 0
        },
        tabContainer: {
            '& .MuiTabs - flexContainer': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: ''
            }
        },
        avatar: {
            '&.MuiAvatar-root': {
                width: 30,
                height: 30
            }
        },
        tablebodyConainer: {
            borderRadius: "0px !important",
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 220px)',
                minHeight: 'calc( 100vh - 220px)',
            },
        },
        tabFont: {
            fontSize: '12px'
        },
        checkBox: {
            color: "white",
            padding: '5px'
        },
        gridDisplay: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        tableCell: {
            paddingBottom: 0,
            paddingTop: 0
        },
        typostartdate: {
            marginTop: '15px',
            padding: '10px',
            textAlign: 'left',
            marginLeft: '11px'
        },
        DialogHead: {
            backgroundColor: '#76b2fe',
            fontWeight: 'bold',
            marginTop: '5px',
            textAlign: 'center',
        },
        pivotroot: {
            marginTop: '110px',
        },
        addbutton: {
            textAlign: 'center',

        },
        formcontrol: {
            minWidth: '600',
            alignItems: "left",
            padding: "10px",
        },
        textstart: {
            width: '300px',
            marginLeft: "18px",
            backgroundColor: 'white',
            border: '2px',
            borderRadius: 5,
            float: 'left',
            textAlign: 'left',

        },
        typoenddate: {
            marginTop: '10px',
            padding: '10px',
            textAlign: 'left',
            marginLeft: '22px'
        },
        textend: {
            width: '300px',
            backgroundColor: 'white',
            marginLeft: "18px",
            border: '2px',
            bordeRadius: '5px',
            float: 'left',
        },
        typocode: {
            marginTop: '10px',
            padding: '10px',
            textAlign: 'left',

        },
        autocompletecode: {
            width: '300px',
            height: '10',
            marginLeft: '25px',
        },
        autocompletephase: {
            width: '300px',
            height: '10',
            marginLeft: '35px',
        },
        typophase: {
            textAlign: 'left',
        },
        dropdownPaper: {
            border: "1px solid lightgray",
            fontSize: "12px !important",
            marginTop: "0px",
        },
        autoCompleteFont: {
            fontSize: "12px !important",
            padding: "3px 12px !important",
        },
        dropDownStyle: {
            borderRadius: 5,
            backgroundColor: "white !important",
            fontSize: "12px !important",
            "& .MuiInputBase-input": {
                fontSize: "12px !important",
            },
        },
        LabelBoxStyle: {
            margin: '6px 2px',
        },
        formLabel: {
            fontWeight: 'bold',
            color: '#2a3944 !important',
            fontSize: 12,
            marginTop: '5px'
        },
        dropDownInput: {
            fontSize: "13px !important",
            color: "black",
            padding: "0px 30px 0px 10px !important",
            "&$focused $notchedOutline": {
                color: "white",
                border: "none",
            },
            "& .MuiInputBase-input": {
                fontSize: "12px !important",
            },
        },
        menuListContainer: {
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 215px)',
                minHeight: 'calc( 100vh - 215px)',
            },
        },
        clientCodeBox: {
            width: '100%',
            marginTop: '5px',
            borderRadius: '4px 4px 8px 8px',
            '& .MuiOutlinedInput-root': {
                borderBottom: '3px solid blue',
                borderRadius: '4px 4px 8px 8px'
            }
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        ClientcheckBoxStyle: {
            padding: '0px'
        },
        CheckBoxBorder: {
            borderTop: '1px solid gray'
        },
        textBoxStyle: {
            marginTop: '12px',
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                paddingLeft: '5px',
                paddingLight: '5px',
                fontSize: '10px'
            }
        },
        IconSizeStyle: {
            height: '20px',
            width: '20px'
        },
    })
);

export default useStyles;