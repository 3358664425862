import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Box: {
      padding: "10px",
    },
    WidgetsConfigurationCard: {
      backgroundColor: "lightseagreen",
      padding: "3px !important",
    },
    ActiveUsersCard: {
      backgroundColor: "rgb(30 136 229)",
      padding: "3px !important",
    },
    ActiveAccountsCard: {
      backgroundColor: "#A6Bc09",
      padding: "3px !important",
    },
    RevenueCard: {
      padding: "0px !important",
    },
    LoggedInUsersCard: {
      padding: "0px !important",
    },
    Visitors: {
      padding: "0px !important",
    },
    Grid: {
      padding: "25px 40px !important",
    },
    Typography: {
      color: "white !important",
    },
    Typography1: {
      color: "black !important",
    },
    Grid2: {
      padding: "30px",
    },
    RevenueGrid: {
      padding: "33px 20px !important",
      backgroundColor: "#e36593",
    },
    LoggedInUsersGrid: {
      padding: "33px 20px !important",
      backgroundColor: "#00bdae",
    },
    VisitorsGrid: {
      padding: "33px 20px !important",
      backgroundColor: "#3fb8f1",
    },
    Icon: {
      width: "100px !important",
      height: "80px !important",
      opacity: "0.5",
      color: "whitesmoke",
    },
    InvoiceGrid: {
      padding: "40px 20px !important",
    },
    GoalGrid: {
      padding: "40px 20px !important",
    },
    CardIconStyle: {
      width: "40px !important",
    },
    GoalIcon: {
      height: "30px !important",
      opacity: "1.5",
      color: "rgb(33 150 243)",
    },
    InvoiceIcon: {
      height: "30px !important",
      opacity: "1.5",
      color: "rgb(0 200 83)",
    },
    PaidIcon: {
      height: "30px !important",
      opacity: "1.5",
      color: "#e36593",
    },
    PaidIconStyle: {
      backgroundColor: "#f5d5e1", 
      width:'50px'
    },
    InvoiceIconStyle: {
      backgroundColor: "rgb(185 246 202)", 
      width:'50px'
    },
    GoalIconStyle: {
      backgroundColor: "rgb(227 242 253)", 
      width:'50px'
    },
    Jobs: {
      padding: "0px !important",
      backgroundColor:'rgb(41 49 79)'
    },
    JobIcon: {
      height: "30px !important",
      opacity: "1.5",
      color: "white",
      minWidth:'50px'
    },
    JobTypography: {
      color: "white !important",
    },
    AzureFunction: {
      padding: "0px !important",
      backgroundColor:'#1e88e5'
    },
    Notification: {
      padding: "0px !important",
      backgroundColor:'#673ab7'
    },
    line: {
      width: '5%', 
      height: '50px', 
      margin: '0px 50px', 
      backgroundColor:'rgb(255 255 255) !important'
    },
    LetterIconStyle: {
      color: 'rgb(46 125 50)',
      borderRadius:'10px',
      height:'50px',
      width:'50px'
    },
    LetterIconButtonStyle: {
      backgroundColor: "rgb(175 205 177)", 
      width:'50px'
    },
    EmailIconStyle: {
      color: 'rgb(156 39 176)',
      borderRadius:'10px',
      height:'50px',
      width:'50px'
    },
    EmailIconButtonStyle: {
      backgroundColor: "rgb(197 180 227)", 
      width:'50px'
    },
    CallIconButtonStyle: {
      backgroundColor: "rgb(170 215 250)", 
      width:'50px'
    },
    CallconStyle: {
      color: 'rgb(33 150 243)',
      borderRadius:'10px',
      height:'50px',
      width:'50px'
    },
    LinearProgress3: {
      backgroundColor:'rgb(175 205 177)'
    },
    LinearProgress2: {
      backgroundColor:'rgb(197 180 227)'
    },
    AgentUserDivStyle: {
      margin: "0px 10px"
    },
    div: {
      display: "flex"
    }
  })
);

export default useStyles;
