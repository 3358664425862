import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  AppBar, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tab, TextField
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";
import Scrollbars from 'react-custom-scrollbars';
import BDebtButton from "../../../GlobalControl/BDebtButton";
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import logo from '../../../asset/image/Login/Cog_Logo.png';
import useStyles from '../ProspectUser/ProspectUserCss';
import BillingInformation from "../ProspectUser/VerifyClient/BillingInformation";
import CollectionInformation from '../ProspectUser/VerifyClient/CollectionInformation';
import CompanyInformation from '../ProspectUser/VerifyClient/CompanyInformation';
import ContactInformation from '../ProspectUser/VerifyClient/ContactInformation';


const VerifyClientUser = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [value, setValue] = useState<string>('0');
  const [companyBranches, setCompanyBranches] = useState([]);
  const [newBranchName, setNewBranchName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const companyName = 'Company A';


  const handleAddBranchName = (branchName) => {
    setCompanyBranches([...companyBranches, branchName]);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewBranchName('');
  };
  const handleChange = (e: any) => {
    setNewBranchName(e.target.value);
  }
  const handleAddBranch = () => {
    if (newBranchName.trim() !== '') {
      handleAddBranchName(newBranchName);
      handleDialogClose();
    }

  };
  const handleRemoveBranch = (index) => {
    // Implement logic to remove a branch
    const newBranches = [...companyBranches];
    newBranches.splice(index, 1);
    setCompanyBranches(newBranches);
  };


  const handleTabChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  return (
    <div>
      <BDebtButton onClick={openDialog}>
        Client Information
      </BDebtButton>

      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth={true} >
        <DialogTitle className={`${classes.dialogdes} ${classes.usertitle}`} >
          <Grid container spacing={0} >
            <Grid item xs={2}>
              <BDebtTypography variant="h6" align="left" >
                <img src={logo} className={classes.logo} alt="companylogo" />
              </BDebtTypography>
            </Grid>
            <Grid item xs={8} className={classes.dialogHeader}>
              <BDebtTypography variant="h6">
                CLIENT INFORMATION
              </BDebtTypography>
            </Grid>
            <Grid item xs={2} className={classes.DialogTitlebutton} >
              < BDebtButton variant="contained" size="small" color="secondary" className={classes.dialogsend}>
                Send
              </BDebtButton>
              <BDebtButton variant="contained" size="small" color="secondary" className={classes.dialogsubmit}>
                Submit
              </BDebtButton>
              <Tooltip title="close the dialog">
                <IconButton
                  onClick={closeDialog}  >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>

          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} className={classes.usergrid} >
            <Grid item xs={2} className={classes.companygrid} >
              <Paper elevation={3} className={`${classes.paperWithTabs} ${classes.companypaper}`}>
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.auditHistoryContainer}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <BDebtTypography variant="h6" color="white" >{companyName}</BDebtTypography>
                    </Grid>
                    <Grid item xs={6} className={classes.Addbranch}>
                      <Tooltip title="Add Branch">
                        <IconButton onClick={handleDialogOpen} color="primary" aria-label="add branch">
                          <AddIcon style={{ color: "white" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div className={classes.dashedborder} />
                  <List>
                    {companyBranches.map((branch, index) => (
                      <ListItem key={index}
                        secondaryAction={
                          <Tooltip title="Remove the branch">
                            <IconButton edge="end" onClick={() => handleRemoveBranch(index)}
                              aria-label={`remove branch ${branch}`}>
                              <RemoveIcon className={classes.iconcolor} />
                            </IconButton>
                          </Tooltip>
                        }>

                        <ListItemText
                          className={`${classes.iconcolor} ${classes.listitem}`}
                          primary={branch}
                        />
                      </ListItem>
                    ))}
                  </List>

                </Scrollbars>
              </Paper>
            </Grid>
            <Grid item xs={10} component={Paper}>
              <TabContext value={value} >
                <AppBar position="static" className={classes.appbardesign}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="fullWidth"
                    sx={{ minHeight: 30, padding: 0 }}
                  >
                    <Tab label="COMPANY INFORMATION" value="0" className={classes.usertab} />
                    <Tab label="CONTACT INFORMATION" value="1" className={classes.usertab} />
                    <Tab label="BILLING INFORMATION" value="2" className={classes.usertab} />
                    <Tab label="COLLECTION INFORMATION" value="3" className={classes.usertab} />
                  </TabList>
                </AppBar>
                <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.auditHistoryContainer}>
                  <TabPanel value="0" sx={{ padding: 1 }}>
                    <CompanyInformation />
                  </TabPanel>
                  <TabPanel value="1" sx={{ padding: 1 }}>
                    < ContactInformation />
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: 0 }}>
                    <BillingInformation />
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: 1 }}>
                    <CollectionInformation />
                  </TabPanel>
                </Scrollbars>
              </TabContext>
            </Grid>
          </Grid>

          <Dialog open={dialogOpen} maxWidth='md'>
            <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleDialogClose}>
              <BDebtTypography variant="body1">
                <b>ADD BRANCH</b>
              </BDebtTypography>
            </BDebtDialogTitleHeader>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BDebtTypography variant="subtitle1">
                    <b>Add Branch</b>
                  </BDebtTypography>
                </Grid>
                <Grid item xs={12} className={classes.branchnamegrid}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Add Branch"
                    value={newBranchName}
                    onChange={handleChange}
                    id="branchName"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: '1px dashed gray' }}>
              <BDebtButton autoFocus onClick={handleAddBranch} variant='contained' size="small" color="secondary" >
                Add Branch
              </BDebtButton>
              <BDebtButton autoFocus onClick={handleDialogClose} variant='contained' size="small" color="secondary" >
                Close
              </BDebtButton>
            </DialogActions>
          </Dialog>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VerifyClientUser;