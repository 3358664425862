/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, IconButton } from "@material-ui/core";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import BDebtCard from "../../GlobalControl/Card/BDebtCard";
import BDebtCardContent from "../../GlobalControl/Card/BDebtCardContent";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import EmojiEmotionsTwoToneIcon from "@mui/icons-material/EmojiEmotionsTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import useStyles from "./WidgetsCss";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import AdjustIcon from "@mui/icons-material/Adjust";
import BoltIcon from "@mui/icons-material/Bolt";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import SettingsSuggestTwoToneIcon from "@mui/icons-material/SettingsSuggestTwoTone";
import LinearProgress from '@mui/material/LinearProgress';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';

export const WidgetsConfiguration = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <Card>
          <CardContent className={classes.WidgetsConfigurationCard}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Total Accounts Placed</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>$659</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Last 6 Months</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.Grid2}>
                <EmojiEventsTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export const ActiveUsers = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <Card>
          <CardContent className={classes.ActiveUsersCard}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Total Active Users</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>3,02,659</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Last 6 Months</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.Grid2}>
                <AccountCircleTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export const ActiveAccounts = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <Card>
          <CardContent className={classes.ActiveAccountsCard}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Total Active Accounts</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>$659</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography}
                >
                  <b>Last 6 Months</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.Grid2}>
                <AccountBalanceTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export const Revenue = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.RevenueCard}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Total Revenue</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>$5,25,659</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.RevenueGrid}>
                <MonetizationOnTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const LoggedInUsers = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.LoggedInUsersCard}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Total Logged-In Users</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>2,659</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.LoggedInUsersGrid}>
                <PeopleAltTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Visitors = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Today's Visitors</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>1,569</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.VisitorsGrid}>
                <EmojiEmotionsTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Impact = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Impact</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>1,569</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.VisitorsGrid}>
                <EmojiEmotionsTwoToneIcon className={classes.Icon} />
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Goal = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Goal</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>30,564</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.GoalGrid}>
                <IconButton className={classes.GoalIconStyle}>
                  <RemoveRedEyeTwoToneIcon
                    className={`${classes.GoalIcon} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Invoices = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Total Invoice</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>1,569</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.InvoiceGrid}>
                <IconButton size="medium" className={classes.InvoiceIconStyle}>
                  <DescriptionTwoToneIcon
                    className={`${classes.InvoiceIcon} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const TotalPaidUsers = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Total Paid Users</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>1,569</b>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.InvoiceGrid}>
                <IconButton size="medium" className={classes.PaidIconStyle}>
                  <AdjustIcon
                    className={`${classes.PaidIcon} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Jobs = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Jobs}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <div className={classes.div}>
                  <div>
                    <Typography
                      variant="body2"
                      // color="white"
                      className={classes.JobTypography}
                    >
                      <b>Success</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>12</b>
                    </Typography>
                  </div>
                  <div>
                    <hr className={classes.line} />
                  </div>
                  <div className={classes.AgentUserDivStyle}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>Failure</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>2</b>
                    </Typography>
                  </div>
                </div>

                <Typography
                  variant="body1"
                  color="text.secondary"
                  className={classes.JobTypography}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.InvoiceGrid}>
                {/* <IconButton size="medium" className={classes.PaidIconStyle}> */}
                <SettingsSuggestTwoToneIcon className={classes.JobIcon} />
                {/* </IconButton> */}
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const AzureFunctions = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.AzureFunction}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <div className={classes.div}>
                  <div>
                    <Typography
                      variant="body2"
                      // color="white"
                      className={classes.JobTypography}
                    >
                      <b>Success</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>25</b>
                    </Typography>
                  </div>
                  <div>
                    <hr className={classes.line} />
                  </div>
                  <div className={classes.AgentUserDivStyle}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>Failure</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>5</b>
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  className={classes.JobTypography}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.InvoiceGrid}>
                {/* <IconButton size="medium" className={classes.PaidIconStyle}> */}
                <BoltIcon className={classes.JobIcon} />
                {/* </IconButton> */}
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const Notifications = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Notification}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <div className={classes.div}>
                  <div>
                    <Typography
                      variant="body2"
                      className={classes.JobTypography}
                    >
                      <b>Success</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>15</b>
                    </Typography>
                  </div>
                  <div>
                    <hr className={classes.line} />
                  </div>
                  <div className={classes.AgentUserDivStyle}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>Failure</b>
                    </Typography>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className={classes.JobTypography}
                    >
                      <b>6</b>
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  className={classes.JobTypography}
                >
                  <b>November 17 2023</b>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.InvoiceGrid}>
                <NotificationsActiveTwoToneIcon className={classes.JobIcon} />
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const AgentUserCall = () => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [counter, setCounter] = useState(60);


  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer); // Stop the progress when it reaches 100
          return 0;
        }

        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });

      // Use the callback form of setCounter to ensure you get the latest state
      setCounter((prevCounter) => {
        if (prevCounter >= 60) {
          clearInterval(timer); // Stop the progress after 10 iterations
        }
        return prevCounter + 1;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []); // Removed `counter` from the dependencies array

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Calls</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>30,564</b>
                </Typography>
                <Box >
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.GoalGrid}>
                <IconButton className={classes.CallIconButtonStyle}>
                  <AddIcCallTwoToneIcon
                    className={`${classes.CallconStyle} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const AgentUserEmail = () => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [counter, setCounter] = useState(60);


  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer); // Stop the progress when it reaches 100
          return 0;
        }

        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });

      // Use the callback form of setCounter to ensure you get the latest state
      setCounter((prevCounter) => {
        if (prevCounter >= 50) {
          clearInterval(timer); // Stop the progress after 10 iterations
        }
        return prevCounter + 1;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []); // Removed `counter` from the dependencies array

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Sent Emails</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>5,623</b>
                </Typography>
                <Box>
                  <LinearProgress color="secondary" className={classes.LinearProgress2} variant="determinate" value={progress} />
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.GoalGrid}>
                <IconButton className={classes.EmailIconButtonStyle}>
                  <SendTwoToneIcon
                    className={`${classes.EmailIconStyle} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};

export const AgentUserLetters = () => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [counter, setCounter] = useState(60);


  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer); // Stop the progress when it reaches 100
          return 0;
        }

        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });

      // Use the callback form of setCounter to ensure you get the latest state
      setCounter((prevCounter) => {
        if (prevCounter >= 70) {
          clearInterval(timer); // Stop the progress after 10 iterations
        }
        return prevCounter + 1;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []); // Removed `counter` from the dependencies array

  return (
    <div>
      <Box className={classes.Box}>
        <BDebtCard>
          <BDebtCardContent className={classes.Visitors}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.Grid}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>Sent Letters</b>
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  className={classes.Typography1}
                >
                  <b>20,564</b>
                </Typography>
                <Box>
                  <LinearProgress color="success" className={classes.LinearProgress3} variant="determinate" value={progress} />
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.GoalGrid}>
                <IconButton className={classes.LetterIconButtonStyle}>
                  <MarkEmailReadTwoToneIcon
                    className={`${classes.LetterIconStyle} ${classes.CardIconStyle}`}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </BDebtCardContent>
        </BDebtCard>
      </Box>
    </div>
  );
};
