import * as React from 'react';
//MUI COMPONENTS
import { Box, Grid, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableCell, IconButton, Paper, Typography } from '@material-ui/core';
//ICONS
import { Download as DownloadIcon } from '@mui/icons-material';
//CUSTOM CSS
import useStyles from '../Ingress/IngressCss';
//INTERFACE
import { IIngressTableRow } from "../../../Model/Diagnostics/IIngressTableRow";
//JSON
import IngressEntityData from "../../../MockData/IngressEntityData.json";
//COMPONENTS
import SearchIngressDetails from "./SearchIngressDetails";
import PreviewPage from './PreviewPage';
import BlankImage from "../../../asset/image/document.svg";

function convertToCSV(data) {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map(row => Object.values(row).join(','));
  return `${header}\n${rows.join('\n')}`;
}

const handleDownloadClick = (rowData) => {
  // Convert data to CSV format
  const csvContent = convertToCSV([rowData]);

  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a download link for the CSV file
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = 'row_data.csv';
  downloadLink.style.display = 'none';

  // Trigger a click event on the link to initiate the download
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up the download link element
  document.body.removeChild(downloadLink);
};

function EnhancedTableHead() {
  const classes = useStyles();
  const headCells = [
    { id: "date", numeric: false, disablePadding: false, label: "DATE", sortable: false },
    { id: "runId", numeric: false, disablePadding: false, label: "RUN ID", sortable: false },
    { id: "startTime", numeric: false, disablePadding: false, label: "START TIME", sortable: false },
    { id: "endTime", numeric: false, disablePadding: false, label: "END TIME", sortable: false },
    { id: "record", numeric: false, disablePadding: false, label: "RECORD", sortable: false },
    { id: "download", numeric: false, disablePadding: false, label: "DOWNLOAD", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell className={classes.tableCellHeader} key={headCell.id} align="left">
            <b> {headCell.label || headCell.sortable}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const IngressEntity: React.FC<{ dataRow?: IIngressTableRow, activityEnable }> = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isPreviewOpen, setPreviewOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePreviewClick = (row) => {
    setSelectedRow(row);
    setPreviewOpen(true);
  }

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  }

  return (
    <Box p={0.9} m={1}>
      <Grid component={Paper} container spacing={0} className={classes.pageContainer}>
        <Grid item xs={12} className={classes.gridSearchbar}>
          <SearchIngressDetails />
        </Grid>
        <Grid item xs={6}>
          <TableContainer className={`${classes.tablebodyContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead />
              <TableBody>
                {IngressEntityData.map((row, index: number) => (
                  <TableRow key={index} onClick={() => handlePreviewClick(row)} className={classes.clickableTableRow}>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.date}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.runId}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.startTime}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.endTime}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {row?.record}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      <IconButton onClick={() => handleDownloadClick(row)} size="small">
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination className={classes.tableCellStyle}
            component="div"
            count={100}
            page={page}
            size="small"
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={6} className={!selectedRow ? classes.selectedColumn : null}>
          {isPreviewOpen ? (
            <PreviewPage
              open={isPreviewOpen}
              onClose={handlePreviewClose}
              rowData={selectedRow}
            />
          ) : (
            <div style={{ textAlign: 'center' }}>
              <img src={BlankImage} alt="empty-file-img" width="100px" />
              <br />
              <br />
              <Typography variant="body2" color="inherit" style={{ marginTop: 5 }}>
                <b>No preview available</b>
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </Box >
  );
}

export default IngressEntity;