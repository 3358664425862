/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { usePost } from "../../../utils/apiHelper";

const DownloadCsvFile = (props) => {
  const [downloading, setDownloading] = useState(false);

  // For future enhancement
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map(row => Object.values(row).join(",")).join("\n");
    return header + rows;
  };

  const handleDownload = () => {
    (async() => {
      setDownloading(true);

      let request = {
        blobURI: props?.blobUri
      };

      const result = await usePost("Placement/FileDownload",request);

      const blobData:any = result.data;
      // for future enhancement
      //   const decodedData = decodeURIComponent(blobData);
      //   const jsonData = JSON.parse(decodedData);
      //   const csvString = convertToCSV(jsonData);

      const blob = new Blob([blobData], { type: "text/csv" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloadedFile.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloading(false);
    })()
  };


  return (
    <IconButton size="small" onClick={handleDownload} disabled={downloading}>
      <FileDownloadIcon fontSize="small" color="error" />
    </IconButton>
  );
};

export default DownloadCsvFile;
