/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Paper, Button, DialogActions, DialogContent } from "@material-ui/core";
import * as React from "react";
import BDebtDialog from "../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "./TemplateCss";
import Sidebar from "./FormControl";
import FormBuilder from "./FormBuilder";
import EditIcon from '@mui/icons-material/Edit';

interface EditCustomFormProps {
  open: boolean; // Ensure that 'open' prop is defined
  onClose: () => void;
  selectedRow: any;
  // ... other props
}

const EditCustomForm: React.FC<EditCustomFormProps> = (props) => {
  const {open,onClose,selectedRow} =props;
  const classes = useStyles();
  const [allControlProps, setAllControlProps] = React.useState([]);

  const handleSaveAllControlProps = (updatedAllControlProps: any) => {
    setAllControlProps(updatedAllControlProps);
  };

  const data = [
    { label: "TextField", value: "textfield" },
    { label: "Radio BDebtButton", value: "radio" }
  ];

  const displayAllControlProperties = () => {
    const propertiesAsString = JSON.stringify(allControlProps, null, 2);
    alert(`All Control Properties:\n${propertiesAsString}`);
    onClose();
  };
  return (
    <React.Fragment>
      <EditIcon color="success" onClick={() => open} />
      <BDebtDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"xl"}
        className={classes.boostrapdialog}
      >
        <BDebtDialogTitleHeader id="add-form-name" onClose={onClose}>
          Edit Form
        </BDebtDialogTitleHeader>
        <DialogContent dividers>
          <Grid container component={Paper} spacing={1}>
            <Grid item xs={2} >
              <Sidebar />
            </Grid>
            <Grid item xs={10}>
              <FormBuilder onSaveAllControlProps={handleSaveAllControlProps} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={displayAllControlProperties}
            className={classes.confirmstyle}
          >
            Confirm
          </Button>
          <Button
            autoFocus
            onClick={onClose}
            className={classes.closeStyle}
          >
            Close
          </Button>
        </DialogActions>
      </BDebtDialog>
    </React.Fragment>
  );
}

export default EditCustomForm;