import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/system';

const BDebtTableCellHead = styled(TableCell)({
    padding: '11px !important',
    color: 'white !important',
    background: '#3b4a54',
});

export default BDebtTableCellHead;
