import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
        },
        fileManager: {
            flex: 1,
            maxHeight: "calc(100vh - 70px) !important",
            minHeight: "calc(100vh - 70px) !important",
        },
    })
);

export default useStyles;