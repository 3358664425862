/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControl, InputAdornment, OutlinedInput, Typography,
} from "@material-ui/core";
import { Box, Button, CardActions, CardContent, Grid } from "@mui/material";
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import accountImage from "../../../asset/image/payment-due.png";
import useStyles from "../DebtorsCss";
// Icons
import ChecklistIcon from "@mui/icons-material/Checklist";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseAccount from "../PaymentDetails/CloseAccount";
import CloseAndRemove from "../PaymentDetails/CloseAndRemove";
import DocumentUpload from "../PaymentDetails/DocumentUpload";
import React, { useEffect, useState } from "react";
import AgentActivity from "../AgentActivity/AgentActivity";
import { IDebtTableRow } from "../../../Model/Debtors/IDebtTableRow";
import Scrollbars from "react-custom-scrollbars";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import dueImage from '../../../asset/image/due-date.png';

interface PaymentAmountProps {
  principalAmount: number;
  lateFeeAmount: number;
  total: number;
  onPaymentUpdated: (event: number, action: number) => void;
}

const PaymentAmount: React.FC<{ dataRowSelected?: IDebtTableRow }> = (props) => {
  const classes = useStyles();
  // const [principalAmount, setPrincipalAmount] = useState<number>();
  // const [lateFeeAmount, setLateFeeAmount] = useState<number>();
  // const [total, setTotal] = useState(principalAmount + lateFeeAmount);
  // const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>("");
  // const [principalAdjustmentAmount, setprincipalAdjustmentAmount] = useState<any>("");
  // const [lateFeePaymentAmount, setLateFeePaymentAmount] = useState<any>("");
  // const [lateFeeAdjustmentAmt, setLateFeeAdjustmentAmt] = useState<any>("");


  // useEffect(() => {
  //   setTotal(Number((principalAmount + lateFeeAmount).toFixed(2)));
  // }, [principalAmount, lateFeeAmount]);

  // const handleCallBack = (event, action: number) => {
  //   const value = parseFloat(event.target.value) || 0;
  //   console.log("Callback in child:", value, action);
  //   // props.onPaymentUpdated(value, action);
  // }

  // const preventMinus = (e: any) => {
  //   if (e.code === "Minus" || e.code === "NumpadSubtract") {
  //     e.preventDefault();
  //   }
  // };
  const [currentPrincipalAmount, setcurrentPrincipalAmount] = useState<number>(94.00);
  const [currentLateFeeAmount, setcurrentLateFeeAmount] = useState<number>(94.00);
  const [currentTotalValue, setCurrentTotalValue] = useState(currentPrincipalAmount + currentLateFeeAmount);
  const [principalAmount, setPrincipalAmount] = useState<number>(94.00);
  const [lateFeeAmount, setLateFeeAmount] = useState<number>(94.00);
  const [total, setTotal] = useState(principalAmount + lateFeeAmount);
  const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>("");
  const [principalAdjustmentAmount, setprincipalAdjustmentAmount] = useState<any>("");
  const [lateFeePaymentAmount, setLateFeePaymentAmount] = useState<any>("");
  const [lateFeeAdjustmentAmt, setLateFeeAdjustmentAmt] = useState<any>("");

  function recursiveRemove(value: any, principalAmount: any): any {
    if (value === undefined) return "";
    setPrincipalAmount(principalAmount - value);
  }

  useEffect(() => {
    setTotal(Number((principalAmount + lateFeeAmount).toFixed(2)));
  }, [principalAmount, lateFeeAmount])

  const handleOnValueChange = (e: any): void => {
    const value = parseFloat(e.target.value) || 0;
    setPrincipalPaymentAmount(value);
    setPrincipalAmount(principalAmount - value);
  };

  const handleOnAdjustmentAmtValueChange = (e: any): void => {
    const value = parseFloat(e.target.value) || 0;
    setprincipalAdjustmentAmount(value);

    // Use the callback function to work with the most up-to-date state
    setPrincipalAmount((prevPrincipalAmount) => prevPrincipalAmount + value);
  };

  useEffect(() => {
    console.log(principalAmount);
  }, [principalAmount]);

  const handleOnLateFeePaymentAmount = (e: any) => {
    const value = parseFloat(e.target.value) || 0;
    setLateFeePaymentAmount(value);
    setLateFeeAmount(lateFeeAmount - value);
  };

  const handleOnLateFeeAdjustment = (e: any) => {
    const value = parseFloat(e.target.value) || 0;
    setLateFeeAdjustmentAmt(value);
    // Use the callback function to work with the most up-to-date state
    setLateFeeAmount((prevlateFeeAmount) => prevlateFeeAmount - value);
  };

  useEffect(() => {
    console.log(lateFeeAmount);
  }, [lateFeeAmount]);

  const preventMinus = (e: any) => {
    if (e.code === "Minus" || e.code === "NumpadSubtract") {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, marginTop: "8px" }}>
        <BDebtCard>
          <Grid container spacing={0}>
            <Grid xs={12} className={classes.infoGrid}>
              <Typography
                variant="subtitle1"
                style={{ margin: "5px 0px 5px 10px" }}
              >
                <img
                  src={accountImage}
                  alt="Account Details"
                  width="20px"
                  height="20px"
                />
              </Typography>
              <Typography variant="body2" style={{ margin: "10px 10px" }}>
                <b>Payment Details</b>
              </Typography>
            </Grid>
          </Grid>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={classes.infoFontSize}>
                      <b>Principle Payment Amount</b>
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.inputField}
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder="Principle Payment Amount"
                        startAdornment={
                          <InputAdornment position="start"><span style={{ fontSize: 12 }}>$</span></InputAdornment>
                        }
                        inputProps={{ style: { textAlign: "right" } }}
                        // onClick={(e) => handleCallBack(e, 1)}
                        onChange={handleOnValueChange}
                        onKeyDown={preventMinus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={classes.infoFontSize}>
                      <b>Latefee Payment Amount</b>
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.inputField}
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder="Latefee Payment Amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        inputProps={{ style: { textAlign: "right" } }}
                        // onClick={(e) => handleCallBack(e, 3)}
                        onChange={handleOnLateFeePaymentAmount}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={classes.infoFontSize}>
                      <b>Principle Adjustment Amount</b>
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.inputField}
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder="Principle Adjustment Amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        inputProps={{ style: { textAlign: "right" } }}
                        // onClick={(e) => handleCallBack(e, 2)}
                        onChange={handleOnAdjustmentAmtValueChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" className={classes.infoFontSize}>
                      <b>LateFee Adjustment Amount</b>
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      className={classes.inputField}
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        placeholder="LateFee Adjustment Amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        inputProps={{ style: { textAlign: "right" } }}
                        // onClick={(e) => handleCallBack(e, 4)}
                        onChange={handleOnLateFeeAdjustment}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ borderTop: "1px dashed lightgray" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <Button variant="contained" size="small" sx={{ fontSize: 10 }} fullWidth startIcon={<DoneAllIcon />}              >
                  submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <CloseAccount />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CloseAndRemove />
              </Grid>
              <Grid item xs={12} sm={2}>
                <DocumentUpload />
              </Grid>
              <Grid item xs={12} sm={2}>
                <AgentActivity />
              </Grid>
            </Grid>
          </CardActions>
        </BDebtCard>
      </Box>

      <Box sx={{ flexGrow: 1, marginTop: '8px' }}>
        <BDebtCard>
          <Grid container spacing={0}>
            <Grid xs={12} className={classes.infoGrid}>
              <Typography variant="subtitle1" style={{ margin: '5px 0px 5px 10px' }}>
                <img src={dueImage} alt="Account Details" width="20px" height="20px" />
              </Typography>
              <Typography variant="body2" style={{ margin: '10px 10px' }}>
                <b>Due Details</b>
              </Typography>
            </Grid>
          </Grid>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={0} sx={{ padding: 0.5 }}>
                  <Grid item xs={12} sm={4} sx={{ backgroundColor: '#f1f5f8' }}>
                    <Typography variant='body2'></Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ backgroundColor: '#f1f5f8' }}>
                    <Typography variant='body2' className={classes.infoFontSize} align="right"><b>Current Due</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ backgroundColor: '#f1f5f8' }}>
                    <Typography variant='body2' className={classes.infoFontSize} align="right"><b>Updated Due</b></Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' className={classes.infoFontSize1}><b>Principle</b></Typography>
                    <Typography variant='body2' className={classes.infoFontSize1}><b>Late Fee</b></Typography>
                    <Typography variant='body2' className={classes.infoFontSize1}><b>Total Due</b></Typography>
                  </Grid>
                  {/* Current Value */}
                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${currentPrincipalAmount.toFixed(2)}</Typography>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${currentLateFeeAmount.toFixed(2)}</Typography>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${(currentPrincipalAmount + currentLateFeeAmount).toFixed(2)}</Typography>
                  </Grid>
                  {/* Updated Value */}
                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${principalAmount.toFixed(2)}</Typography>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${lateFeeAmount.toFixed(2)}</Typography>
                    <Typography variant='body2' className={classes.infoFontSize1} align="right">${(principalAmount + lateFeeAmount).toFixed(2)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={0} sx={{ padding: 0.5 }}>
                  <Grid item xs={12} sm={12} sx={{ backgroundColor: '#f1f5f8' }}>
                    <Typography variant='body2' className={classes.infoFontSize}><b>Pending</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Scrollbars autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      autoHeight
                      autoHeightMin={70}
                      autoHeightMax={70}
                    >
                      <Box m={0} sx={{ display: 'flex' }}>
                        <Box m={0} p={0} width="70%" >
                          <Typography variant='body2' className={classes.infoFontSize1}>Principal Payment :</Typography>
                          <Typography variant='body2' className={classes.infoFontSize1}>CloseAccount Payment :</Typography>
                          <Typography variant='body2' className={classes.infoFontSize1}>Close & Remove Payment :</Typography>
                        </Box>
                        <Box m={0} p={0} width="20%" >
                          <Typography variant='body2' className={classes.infoFontSize1}> 5.00</Typography>
                          <Typography variant='body2' className={classes.infoFontSize1}> 15.00</Typography>
                          <Typography variant='body2' className={classes.infoFontSize1}> 25.00</Typography>
                        </Box>
                        <Box m={0} p={0} width="5%" >
                          <SettingsBackupRestoreIcon fontSize="small" className={classes.settingIcon} />
                          <SettingsBackupRestoreIcon fontSize="small" className={classes.settingIcon} />
                          <SettingsBackupRestoreIcon fontSize="small" className={classes.settingIcon} />
                        </Box>
                      </Box>
                    </Scrollbars>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </CardContent>
        </BDebtCard>
      </Box>
    </React.Fragment>
  );
};

export default PaymentAmount;
