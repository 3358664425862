export const Primary = {
    lighter: '#7a8b95',
    light: '#4c5d67',
    main: '#3b4a54',
    dark: '#2a3944',
    darker: '#192533',
  };
export const Secondary = {
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A',
  };
  export const Success = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
  };
  export const Warning = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
  };
  export  const Error = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
  };
  export  const containedColorMapping = {
    secondary: Secondary.dark,
    success: Success.dark,
    warning: Warning.dark,
    error: Error.dark,
  };
  
  export const colorMapping = {
    primary: Primary,
    secondary: Secondary,
    success: Success,
    warning: Warning,
    error: Error,
  };