import { MenuItem } from "../../Model/Settings/IMenuDetails";

type Action =
  | { type: 'REMOVE_MENU_ITEM'; payload: number }
  | { type: 'ADD_MENU_ITEM'; payload: MenuItem }
  | { type: 'EDIT_MENU_ITEM'; payload: MenuItem };

const AppReducer = (state: { menuItems: MenuItem[] }, action: Action): { menuItems: MenuItem[] } => {
  switch (action.type) {
    case 'REMOVE_MENU_ITEM':
      return {
        ...state,
        menuItems: state.menuItems.filter(item => item.menuId !== action.payload)
      };

    case 'ADD_MENU_ITEM':
      return {
        ...state,
        menuItems: [...state.menuItems, action.payload]
      };

    case 'EDIT_MENU_ITEM':
      return {
        ...state,
        menuItems: state.menuItems.map(item =>
          item.menuId === action.payload.menuId ? action.payload : item
        )
      };

    default:
      return state;
  }
};

export default AppReducer;
