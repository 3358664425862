import { useState } from 'react';
import { DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import BDebtDialogTitleHeader from '../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import useStyles from '../WidgetCss';
import BDebtDialog from '../../../../GlobalControl/DialogBox/BDebtDialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography';
import BDebtButton from '../../../../GlobalControl/BDebtButton';

const DeletePredefineWidgets = () => {
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dialogOpen = () => {
        setIsDialogOpen(true);
    };
    const dialogClose = () => {
        setIsDialogOpen(false);
    };
    return (
        <div>
            <IconButton size='small' onClick={dialogOpen}>
                <DeleteOutlinedIcon className={classes.deleteIcon}/></IconButton>
            <BDebtDialog
                open={isDialogOpen}
                maxWidth={"sm"}
                onClose={dialogClose}
                fullWidth
            >
                <BDebtDialogTitleHeader
                    id="customized-dialog-title"
                    onClose={dialogClose}
                >
                    <BDebtTypography variant="h6"> DELETE PREDEFINE WIDGETS</BDebtTypography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this Predefine Widgets?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <BDebtButton onClick={dialogClose} variant='contained' color="success" size='small'>No</BDebtButton>
                    <BDebtButton onClick={dialogClose} variant="contained" color="error" size='small'>Yes</BDebtButton>
                </DialogActions>
            </BDebtDialog>
        </div>
    );
};
export default DeletePredefineWidgets;