/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Tab } from '@mui/material';
import React, { useState } from 'react';
import useStyles from '../PlacementCss';
import GeneralMapping from './GeneralMapping';
import DestinationMapping from './DestinationMapping';
import { usePost } from '../../../utils/apiHelper';
import { IGetTableSchemaResponse } from '../../../Model/Configuration';

const ConfigureTargetFile = (props) => {
    const classes = useStyles();
    const { uploadedData, datasetFieldMappings } = props;
    const [value, setValue] = useState('1');
    const [tableDataSchema, setTableDataSchema] = useState([]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const handleNextClick = (connectionString, tableName) => {
        const request = {
            "connectionString": connectionString,
            "tableName": tableName
        };
      // Assuming usePost is a custom hook for making HTTP POST requests
        usePost<IGetTableSchemaResponse[]>('GetSqlTable/GetTableSchemaAsync', request).then((response) => {
            setTableDataSchema(response.data);
        }).finally(() => {
            setValue('2');
        });
        // Navigate to the "GeneralMapping" tab
    };

    const handleNextPageClick = () => {
        props?.onClickNextPage();
    }

    return (
        <React.Fragment>
            <TabContext value={value} >
                <AppBar position="static" className={classes.appbarPosition}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" className={classes.tabList}>
                        <Tab label="Destination" value="1" className={classes.tabRecords} />
                        <Tab label="Mapping" value="2" className={classes.tabRecords} />
                    </TabList>
                </AppBar>
                <TabPanel value="1" sx={{ padding: 0 }}>
                    <DestinationMapping onNextClick={handleNextClick} onClickSubmitPage={handleNextPageClick}/>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 1 }}>
                    <GeneralMapping tableDataSchema={tableDataSchema} uploadedData={uploadedData} fieldMappings={props?.fieldMappings} yourFieldOptions={props?.yourFieldOptions} onNextClick={handleNextClick} datasetFieldMappings={datasetFieldMappings}/>
                </TabPanel>
            </TabContext>
        </React.Fragment >
    )
}
export default ConfigureTargetFile;