import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AppBar, Tab } from '@mui/material';
import React, { useState } from 'react';
import { IDebtTableRow } from '../../Model/Debtors/IDebtTableRow';
import AccountHistory from '../Collect/AccountHistory';
import AuditHistory from '../Collect/AuditHistory';
import InvoiceDetails from '../Collect/InvoiceDetails';
import useStyles from '../Placement/PlacementCss';
import AccountDetails from './AccountDetails';

const AccountTabList: React.FC<{ dataRow?: IDebtTableRow }> = (props) => {
    const classes = useStyles();
    const { dataRow } = props;
    const [value, setValue] = useState('0');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <TabContext value={value} >
                <AppBar position="static" className={classes.appbarPosition}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" className={classes.tabList}
                    >
                        <Tab label="Account Details" value="0" className={classes.tabRecords} />
                        <Tab label="Account History" value="1" className={classes.tabRecords} />
                        <Tab label="Audit History" value="2" className={classes.tabRecords} />
                        <Tab label="Invoice Details" value="3" className={classes.tabRecords} />
                    </TabList>
                </AppBar>

                <TabPanel value="0" sx={{ padding: 1 }}>
                    <AccountDetails dataRowSelected={dataRow} />
                </TabPanel>
                <TabPanel value="1" sx={{ padding: 1 }}>
                    <AccountHistory />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 0 }}>
                    <AuditHistory />
                </TabPanel>
                <TabPanel value="3" sx={{ padding: 1 }}>
                    <InvoiceDetails />
                </TabPanel>
            </TabContext>
        </React.Fragment >
    )
}
export default AccountTabList;
