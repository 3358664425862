import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepperInActive: {
            padding: "16px !important",
            width: '50%!important',
            borderBottom: "1px solid lightgray !important",
        },
        stepperActive: {
            display: "flex",
            justifyContent: "center",
        },
        stepper: {
            padding: "8px",
            width: "50%",
        },
        buttonStyle: {
            padding: "8px",
            display: "flex",
            justifyContent: "space-around",
            borderBottom: "1px solid lightgray",
        },
        div: {
            maxWidth: "100vw",
            minHeight: 'calc(100vh - 124px)',
            alignItems: "center",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
        },
        root: {
            maxWidth: "700px",
            height: "auto !important",
            alignItems: "center",
            padding: "10px 15px",
            transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
        finishRoot: {
            maxWidth: "500px",
            height: "auto !important",
            alignItems: "center",
            padding: "10px 15px",
            transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
        container: {
            padding: theme.spacing(3),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        textSpace: {
            marginTop: "5px",
        },
        textFiled: {
            marginTop: "5px",
            "& .MuiFormLabel-root": {
                fontSize: 13,
            },
        },
        iconstyle: {
            width: "34px",
            height: "34px",
        },
        Storageicon: {
            display: "flex !important",
            alignItems: "left !important",
            justifyContent: "left !important",
            padding: "21px !important",
        },
        button: {
            display: "flex !important",
            justifyContent: "flex-end !important",
            alignItem: "flex-end !important",
        },
        paperstyle: {
            padding: "36px !important",
            boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 18px !important",
        },
        UploadTextStyle: {
            textAlign: "center",
            marginTop: "5px",
            padding: "8px 0px",
            background: "#3b4a54",
            color: "white",
            fontSize: 14,
            borderRadius: "5px",
        },
        outlinedInput: {
            fontSize: "12px !important",
            color: "black !important",
            marginTop: "-2px !important",
            "&$focused $notchedOutline": {
                color: "white !important",
                border: "0px !important",
            },
        },
        notchedOutline: {
            color: "white !important",
            border: "none !important",
            fontSize: "12px !important",
        },
        dropdownPaper: {
            border: "1px solid lightgray",
            fontSize: "12px !important",
            marginTop: "0px",
        },
        autoCompleteFont: {
            fontSize: "12px !important",
            padding: "3px 12px !important",
        },
        dropDownStyle: {
            marginTop: "2px !important",
            // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            borderRadius: 5,
            border: "2px solid #215176 !important",
            backgroundColor: "white !important",
            height: "35px !important",
            fontSize: "12px !important",
            "& .MuiInputBase-input": {
                fontSize: "12px !important",
            },
        },
        dropDownInput: {
            fontSize: "13px !important",
            color: "black",
            padding: "5px 30px 5px 10px !important",
            "&$focused $notchedOutline": {
                color: "white",
                border: "none",
            },
            "& .MuiInputBase-input": {
                fontSize: "12px !important",
            },
        },
        pageContainer: {
            boxShadow:
                " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
                
        },
        autoCompleteTop: {
            marginTop: "5px",
        },
        listActive: {
            padding: "6px 10px !important",
            color: "rgb(0, 127, 255) !important",
            borderTopLeftRadius: "5px !important",
            borderBottomLeftRadius: "5px !important",
            fontWeight: "bolder",
            borderRight: "4px solid rgb(0, 127, 255) !important",
            boxShadow:
                " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
        },
        listNonActive: {
            padding: "6px 10px !important",
            borderRight: "1px solid lightgray",
        },
        // Column Mapping Page
        fieldContainer: {
            maxHeight: "660px !important",
            minHeight: "660px !important",
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 155px) !important",
                minHeight: "calc( 100vh - 155px) !important",
            },
        },
        fieldHeader: {
            padding: "9px !important",
            fontSize: "12px !important",
            color: "white !important",
            fontWeight: "bold",
            backgroundColor: "#3b4a54 !important",
            boxShadow:
                "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
        },
        fontStyle: {
            marginTop: "7px !important",
        },
        ruleIconStyle: {
            fontSize: "17px !important",
            display: "flex",
            justifyContent: "center",
            color: "black",
            fontWeight: "bold",
        },
        cardOnhover: {
            "&:hover": {
                backgroundColor: "rgb(243, 246, 249) !important",
                border: "1px solid #3b4a54 !important",
            },
        },
        functionIcon: {
            marginTop: "10px !important",
        },
        dataRowContainer: {
            maxHeight: "660px !important",
            minHeight: "660px !important",
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 225px) !important",
                minHeight: "calc( 100vh - 225px) !important",
            },
        },
        perviewContainer: {
            maxHeight: "660px !important",
            minHeight: "660px !important",
            WebkitScrollSnapPointsX: "5px",
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 180px) !important",
                minHeight: "calc( 100vh - 180px) !important",
            },
        },
        resultContainer: {
            backgroundColor: "#ffffff",
            padding: "0px 10px",
            borderRadius: "5px",
            overflowY: "auto",
            overflowX: "hidden",
            filter: "drop-shadow(0 -6mm 4mm gray))",
        },
        finishTextStyle: {
            textAlign: "center",
            padding: "5px 0px",
            backgroundColor: "rgba(16, 185, 129, 0.12)",
            color: "rgb(11, 129, 90)",
            fontSize: 12,
        },
        inputSelectField: {
            fontSize: "12px !important",
            fontWeight: "bold",
            color: "black",
        },
        appbarPosition: {
            backgroundColor: "white !important",
            minHeight: "30px !important",
            padding: "0px !important",
        },
        tabRecords: {
            minHeight: "30px !important",
            fontSize: "12px !important",
            fontWeight: "bold",
        },
        tabList: {
            minHeight: "30px !important",
            padding: "0px !important",
        },
        displayGrid: {
            width: "100% !important",
            display: "flex !important",
            justifyContent: "space-around !important",
            padding: "4px !important",
        },
        uploadbuttonStyle: {
            padding: "2px !important",
        },
        manualdataRowContainer: {
            maxHeight: "660px !important",
            minHeight: "660px !important",
            [theme.breakpoints.up("lg")]: {
                maxHeight: "calc( 100vh - 227px) !important",
                minHeight: "calc( 100vh - 227px) !important",
            },
        },
        gridStyle: {
            padding: "3px",
            border: "1px solid lightgrey",
        },
        headButton: {
            padding: "4px",
            color: "white",
            backgroundColor: "#3b4a54",
            textTransform: "none",
            display: "flex",
            justifyContent: "center",
            borderRadius: "5px",
        },
        ruleGroupHeader: {
            background: "lightskyblue",
            padding: "3px",
            marginBottom: "8px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
        },
        selectedRulesContainer: {
            flexWrap: "wrap",
            padding: "8px",
            marginBottom: "8px",
            cursor: "pointer",
        },
        selectedRuleItem: {
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "5px",
            margin: "4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "honeydew",
            justifyContent: "space-between",
        },
        rulehead: {
            marginRight: "8px",
        },
        ruleDisplay: {
            marginLeft: "10px",
        },
        ruleNameStyle: {
            display: "flex",
            alignItems: "center",
        },
        cancelIcon: {
            marginLeft: "8px",
            cursor: "pointer",
        },
        MuiDialogContentroot: {
            flex: "1 1 auto",
            padding: "0",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
        },

        selectedRule: {
            backgroundColor: "lightskyblue",
        },
        checkbox: {
            "& .MuiSvgIcon-root": {
                fontSize: 15, // Adjust the size of the checkbox icon
            },
        },
        dataruletableCellHeader: {
            padding: "12px 8px !important",
            fontSize: "13px !important",
            color: "white !important",
            background: "#3b4a54 !important",
            fontWeight: 'bold'
        },
        dataruletableGrid: {
            padding: "5px 5px 5px 5px",
        },
        datarulegridDisplay: {
            display: "flex",
            justifyContent: "flex-end",
        },
        editIcon: {
            color: "green"
        },
        deleteIcon: {
            color: "red"
        },
        RadioButton: {
            paddingLeft: '30px'
        },
        padding: {
            padding: "20px 25px"
        },
        draggableField: {
            cursor: "grab",
            display: "flex",
            alignItems: "center",
          },
          icon: {
            marginTop: "10px",
            cursor: "pointer",
            marginLeft: "10px",
          },
          typographyStyle: {
            marginLeft: "5px",
            fontWeight: "bold",
          },
          typographyStyle1: {
            marginLeft: "5px",
          },
          divStyle: {
            marginTop: "5px",
          },
          dragIcon: {
            marginTop: '2px'
          },
          fieldTable: {
            maxHeight: '600px !important',
            minHeight: '600px !important',
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'calc( 100vh - 343px) !important',
              minHeight: 'calc( 100vh - 343px) !important',
            }
          },

          fieldTable1: {
            maxHeight: '600px !important',
            minHeight: '600px !important',
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'calc( 100vh - 155px) !important',
              minHeight: 'calc( 100vh - 155px) !important',
            }
          },
          fieldUploadedTable: {
            maxHeight: '600px !important',
            minHeight: '600px !important',
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'calc( 100vh - 155px) !important',
              minHeight: 'calc( 100vh - 155px) !important',
            }
          },
          DropzoneArea: {
            minHeight: '150px !important',
            fontSize: '12px !important',
            border: '1px dashed blue !important',
            backgroundColor: '#f1f5ff !important',
            boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px !important',
            borderRadius: '5px'
          },
          DropzoneAreaText: {
            fontSize: '14px !important',
            fontWeight: 'bold',
            color: 'blue'
          },
          
          outputStyle: {
            marginTop: "10px"
          }

    })
);

export default useStyles;
