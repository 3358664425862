/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Grid, Typography, TextField, Box, Checkbox, Chip, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, TablePagination,
  Button, Paper,
} from "@material-ui/core";
import useStyles from "../AgentUser/AgentUserCss";
import CustomActivity from "../../../MockData/CustomActivityPErmission.json";
import ActivityList from "../../../MockData/ActivityList.json";
import Stack from "@mui/material/Stack";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import StarBorderPurple500TwoToneIcon from "@mui/icons-material/StarBorderPurple500TwoTone";
import StarHalfTwoToneIcon from "@mui/icons-material/StarHalfTwoTone";
import StarRateTwoToneIcon from "@mui/icons-material/StarRateTwoTone";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

const CustomizeActivity = () => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(15);

  const headCells = [
    { id: "client_code", label: "CLIENT CODE", fontSize: "12px" },
    { id: "client_name", label: "CLIENT NAME", fontSize: "12px" },
    { id: "readOnly", label: "READ ONLY", fontSize: "12px" },
    { id: "phase", label: "PHASE", fontSize: "12px" },
  ];

  function EnhancedTableHead(props: { classes: any }) {
    const { classes } = props;
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} className={classes.rowhead}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handlePageChange = (page: any) => {
    setPageNumber(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRecordsPerPage(+event.target.value);
    setPageNumber(0);
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.header}>
          <b>MANAGE CUSTOM FORM PERMISSION</b>
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Box display="flex">
          <Grid item xs={4}>
            <Button startIcon={<StarBorderPurple500TwoToneIcon />}
              variant="outlined"
              className={classes.phaseButton}
            >
              1ST PARTY
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              startIcon={<StarRateTwoToneIcon />}
              variant="outlined"
              className={classes.phaseButton}
            >
              FLAT FEE
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              startIcon={<StarHalfTwoToneIcon />}
              variant="outlined"
              className={classes.phaseButton}
            >
              CONTINGENCY
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="outlined-basic"
          placeholder="Search"
          variant="outlined"
          size="small"
          className={classes.search}
          InputProps={{
            startAdornment: <SearchTwoToneIcon />,
          }}
        />
      </Grid>
      <Grid item xs={8} className={classes.gridBox}>
        <TableContainer
          component={Paper}
          className={`${classes.tablebodyConainer} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Table aria-label="customized table" size="small" stickyHeader>
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {CustomActivity?.map((r) => {
                return (
                  <TableRow hover>
                    <TableCell className={classes.tableCell1}>
                      {r?.ClientCode}
                    </TableCell>
                    <TableCell className={classes.tableCell1}>
                      {r?.ClientName}
                    </TableCell>
                    <TableCell className={classes.tableCell1}>
                      <Checkbox
                        size="small"
                        // checked={checked}
                        // onChange={handleChange}
                        defaultChecked
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell1}>
                      {r?.Phase}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={recordsPerPage}
          page={pageNumber}
          onChangePage={(e) => {
            handlePageChange(page);
          }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Grid>
      <Grid item xs={4} className={classes.GridStyle}>
        <Grid item xs={12}>
          <Typography
            className={classes.typography1}
          >
            <b>Manage Permissions</b>
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ padding: "15px" }}>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Custom Form Name"
                onClick={handleClick}
                className={classes.chipStyle}
                icon={
                  <CardMembershipIcon style={{ color: "rgb(22, 177, 255)" }} />
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6} style={{ padding: "15px" }}>
            <FormGroup>
              <FormControlLabel
                control={<Android12Switch defaultChecked />}
                label="Show All Activity"
                className={classes.SwitchButton}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            className={`${classes.BoxStyles} ${classes.box1}`}
            display={"flex"}
          >
            <TableContainer
              className={`${classes.permissionCheckbox
                } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <React.Fragment>
                <div>
                  {ActivityList?.map((r) => {
                    return (
                      <FormControlLabel
                        id="U_CU_remainingAccountCount"
                        control={
                          <Checkbox
                            size="small"
                            name={r?.activity}
                            style={{ color: "rgb(22 177 255)" }}
                          />
                        }
                        className={classes.checkboxStyle1}
                        label={
                          <span className={classes.inputFontSize1}>
                            {r.activity}
                          </span>
                        }
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomizeActivity;
