import { ListItem, ListItemText } from "@material-ui/core";
import { useState } from "react";
import BDebtCheckbox from "../../../../../GlobalControl/BDebtCheckBox";
import BDebtList from "../../../../../GlobalControl/BDebtList";
import BDebtTypography from "../../../../../GlobalControl/BDebtTypography";
import useStyles from "../../WidgetCss";
import { Grid } from "@mui/material";

const databaseNames = {
  table: [
    "table1Data",
    "table2Data",
    "table3Data",
    "table4Data",
    "table5Data",
    "table6Data",
    "table7Data",

  ],
};

export const TableSidebar = ({ handleDatabaseChange }) => {
  const classes = useStyles();
  const [checkedItems, setCheckedItems] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
    handleDatabaseChange(newChecked);
  };

  return (
    <Grid container spacing={0} className={classes.borderRight}>
      <Grid xs={12} md={12} className={classes.borderBottom}>
        <BDebtTypography component="div" variant="h6" p={1}>
          <b>Table List</b>
        </BDebtTypography>
      </Grid>
      <Grid item xs={2}>
        <BDebtList >
          {databaseNames.table.map((table) => (
            <ListItem
              key={table}
              button
              onClick={handleToggle(table)}
              className={classes.listItem}
            >
              <BDebtCheckbox
                size="small"
                edge="start"
                checked={checkedItems.indexOf(table) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${table}` }}
              />
              <ListItemText id={`checkbox-list-label-${table}`} primary={table} />
            </ListItem>
          ))}
        </BDebtList>
      </Grid>
    </Grid>

  );
};