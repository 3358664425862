import * as React from "react";
import { PivotViewComponent, IDataOptions, ChartSeriesType, IDataSet, FieldList, Inject, PivotChart } from '@syncfusion/ej2-react-pivotview';
import pivotData from '../../MockData/Pivotdata.json';
import { ChartTheme } from '@syncfusion/ej2-react-charts';
import { DropDownListComponent, ChangeEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import '../../../src/asset/css/Report.css';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from "@mui/material";



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let Pivot_Data: IDataSet[] = (pivotData as any).data;
let dataSourceSettings: IDataOptions = {
    enableSorting: true,
    rows: [{ name: 'Year' }, { name: 'Order_Source', caption: 'Order Source' }],
    columns: [{ name: 'Country' }, { name: 'Products' }],
    valueSortSettings: { headerDelimiter: ' - ' },
    dataSource: Pivot_Data,
    // dataSource: remoteData,
    expandAll: false,
    drilledMembers: [{ name: 'Year', items: ['FY 2015'] }],
    formatSettings: [{ name: "Amount", format: "C" }],
    values: [{ name: "Amount", caption: "Sales Amount" }],
    filters: [{}]


};

const clientcode = [
    { title: "CONTSO1" },
    { title: "CONTSO2" },
    { title: "CONTSO3" },
    { title: "CONTSO4" },
    { title: "CONTSO5" },
];


const Phase = [
    { title: "PHASE1" },
    { title: "PHASE2" },
    { title: "PHASE3" },
    { title: "PHASE4" },
    { title: "PHASE5" },

];

function ClientAcknowledgement(this: any) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let pivotObj: PivotViewComponent;
    let fields: object = { text: 'text', value: 'value' };
    let chartTypes: { [key: string]: Object }[] = [
        { 'value': 'Column', 'text': 'Column' },
        { 'value': 'Bar', 'text': 'Bar' },
        { 'value': 'Line', 'text': 'Line' },
        { 'value': 'Spline', 'text': 'Spline' },
        { 'value': 'Area', 'text': 'Area' },
        { 'value': 'SplineArea', 'text': 'SplineArea' },
        { 'value': 'StepLine', 'text': 'StepLine' },
        { 'value': 'StepArea', 'text': 'StepArea' },
        { 'value': 'StackingColumn', 'text': 'StackingColumn' },
        { 'value': 'StackingBar', 'text': 'StackingBar' },
        { 'value': 'StackingArea', 'text': 'StackingArea' },
        { 'value': 'StackingColumn100', 'text': 'StackingColumn100' },
        { 'value': 'StackingBar100', 'text': 'StackingBar100' },
        { 'value': 'StackingArea100', 'text': 'StackingArea100' },
        { 'value': 'Scatter', 'text': 'Scatter' },
        { 'value': 'Bubble', 'text': 'Bubble' },
        { 'value': 'Polar', 'text': 'Polar' },
        { 'value': 'Radar', 'text': 'Radar' },
        { 'value': 'Pareto', 'text': 'Pareto' },
        { 'value': 'Pie', 'text': 'Pie' },
        { 'value': 'Doughnut', 'text': 'Doughnut' },
        { 'value': 'Funnel', 'text': 'Funnel' },
        { 'value': 'Pyramid', 'text': 'Pyramid' },
    ];

    function ddlOnChange(args: ChangeEventArgs): void {
        if (pivotObj.chartSettings && pivotObj.chartSettings.chartSeries) {
            pivotObj.chartSettings.chartSeries.type = args.value as ChartSeriesType;
        }
    }

    function chartOnLoad(args: { chart: { theme: string; }; }): void {
        let selectedTheme: string = window.location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';

        if (args.chart && args.chart.theme) {
            args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
                .replace(/-dark/i, "Dark") as ChartTheme;
        }
    }


    return (
        <Box p={1} m={1}>
            <div className="pivotroot">
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className="addbutton"
                        onClick={handleClickOpen}

                    >
                        Download
                    </Button>
                    <Dialog
                        fullWidth
                        open={open}
                        maxWidth='sm'
                        onClose={handleClose}
                    >
                        <Typography variant="h6" className="DialogHead">
                            <b>Download Document</b>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                className="custom-Button"

                            >
                                <CloseIcon />
                            </IconButton>
                        </Typography>

                        <DialogContent >
                            <Grid container spacing={0} >
                                <Grid item xs={6} className="gridstyle">
                                    <Typography variant="h6" >
                                        <b> StartDate</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <TextField
                                        variant='outlined' id="date"
                                        type="date"
                                        fullWidth
                                        size="medium"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <Typography variant="h6"
                                    >
                                        <b> EndDate </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <TextField
                                        variant='outlined' id="date"
                                        type="date"
                                        fullWidth
                                        size="medium"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <Typography variant="h6"

                                    >
                                        <b> ClientCode </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <Autocomplete
                                        multiple
                                        id="combo-box-demo"
                                        size="small"
                                        options={clientcode}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.title}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title}
                                            </React.Fragment>
                                        )}
                                        fullWidth
                                        renderInput={(params) => (<TextField {...params}
                                            label="Clientcode"
                                            variant="outlined"

                                        />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <Typography variant="h6"
                                    >
                                        <b> ClientPhase </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className="gridstyle">
                                    <Autocomplete
                                        multiple
                                        id="combo-box-demo"
                                        size='small'
                                        options={Phase}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.title}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title}
                                            </React.Fragment>
                                        )}
                                        fullWidth
                                        renderInput={(params) => (<TextField {...params}
                                            label="ClientPhase"
                                            variant="outlined"

                                        />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className="addbutton"

                            >
                                ok
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className="addbutton"
                                onClick={handleClose}
                            >
                                cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className='col-lg-9 control-section'>
                    <PivotViewComponent
                        id='PivotView'
                        ref={d => pivotObj = d!} // Use the non-null assertion operator here
                        dataSourceSettings={dataSourceSettings}
                        width={'100%'}
                        height={'450'}
                        showFieldList={true}
                        displayOption={{ view: 'Chart' }}
                        chartSettings={{
                            title: 'Sales Analysis',
                            chartSeries: { type: "Column" },
                            load: chartOnLoad.bind(this)
                        }}
                    >
                        <Inject services={[PivotChart, FieldList]} />
                    </PivotViewComponent>

                </div>
                <div className='col-lg-3 property-section'>
                    <table id='property' title='Properties' className='pivot-property-panel-table property-panel-table' style={{ width: '100%' }}>
                        <tbody>
                            <tr >
                                <td>
                                    <DropDownListComponent placeholder={'Chart Types'} floatLabelType={'Auto'} fields={fields} change={ddlOnChange.bind(this)} id="charttypes" index={0} enabled={true} dataSource={chartTypes} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        </Box>
    );

};
export default ClientAcknowledgement;