/* eslint-disable @typescript-eslint/no-unused-vars */
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import Login from './Pages/login/Login';
import './index.css';
import theme from './theme';

// Msal Authentication 
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authConfig';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from './MockData/UserLevel/GlobalContext';


registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX9ZcXBQQmFeVkZ3XQ==');

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: any) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event?.payload?.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalProvider>
            <Login />
          </GlobalProvider>
        </MuiThemeProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)