/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { IconButton, Button,Typography,TableContainer, Paper,} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useStyles from "./ProspectUserCss";
import Grid from "@mui/material/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import BackupIcon from "@mui/icons-material/Backup";
import Badge from "@mui/material/Badge";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { CloudDownload, Delete, Visibility } from "@material-ui/icons";
import { Document, Page, pdfjs } from "react-pdf";
import api from "../../../ApiConfiguration/Configuration";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const UploadContract = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [file, setFile] = useState<File | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      const selectedFile = files[0];
      setFile(selectedFile);
     
    }
   
  };

  const handleView = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDownload = () => {
    if (file) {
      // Create a blob URL for the selected file
      const blobUrl = URL.createObjectURL(file);
      // Create an anchor element and trigger a click event to start the download
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = file.name;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // Revoke the blob URL to release resources
      URL.revokeObjectURL(blobUrl);
    }
  };

  const handleDelete = () => {
    setFile(null);
  };

  const handleSave = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("userId", "2"); 
      formData.append("file", file);
        const response = await api.post("File/InsertContractFile", formData);
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Tooltip title="UPLOAD CONTRACT" arrow>
          <IconButton className={`${classes.UploadIcon} ${classes.IconButtonStyle}`} onClick={handleOpen}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <LooksTwoIcon
                  className={`${classes.horizontalLine} ${classes.BadgeIcon2}`} /> } >
              <BackupIcon className={classes.icon1} />
            </Badge>
          </IconButton>
        </Tooltip>
        <div className={classes.divStyle}>
          <Tooltip title="Notification Sent" arrow>
            <div>
              <NotificationsActiveIcon className={classes.divIcon2} />
            </div>
          </Tooltip>
          <div className={`${classes.IconDivstyle} ${classes.Icon2}`} />
        </div>
      </div>

      <Dialog
        open={open}
        maxWidth={"md"}
             keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <BDebtDialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          Document Upload
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="justify"
                className={classes.Note} >
                <span>
                  <b>Note : </b>
                </span>
                The uploaded document will be saved automatically.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DropzoneArea
                onChange={handleFileChange}
                acceptedFiles={["application/pdf"]}
                showPreviews={false}
                showPreviewsInDropzone={false}
                filesLimit={1}
                dropzoneText="Drag and Drop an PDF file here or Browse"
                dropzoneClass={classes.DropzoneArea}
                dropzoneParagraphClass={classes.DropzoneAreaText}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                className={`${classes.container
                  } ${"scrollbox"} ${"on-scrollbar"}`} >
                <Typography variant="h6" className={classes.UploadStyle}>
                  <b>UPLOADED DOCUMENTS</b>
                </Typography>
                <Grid component={Paper} style={{ minHeight: '200px' }}>
                  {file && (
                    <div>
                      <span>File Name: {file.name}</span>
                      <IconButton
                        aria-label="view"
                        color="primary"
                        onClick={handleView}>
                        <Visibility />
                      </IconButton>
                      <IconButton
                        aria-label="download"
                        color="primary"
                        onClick={handleDownload} >
                        <CloudDownload />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={handleDelete} >
                        <Delete />
                      </IconButton>
                    </div>
                  )}
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.yesbutton} onClick={handleSave}>SAVE</Button>
          <Button className={classes.nobutton} onClick={handleClose} autoFocus>CLOSE</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDialogOpen} maxWidth="xl" fullWidth>
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={handleCloseDialog}
        >Preview</BDebtDialogTitleHeader>
        <DialogContent>
          {file ? (
            <Document file={file}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <div>No file selected</div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadContract;
