import { makeStyles } from "@material-ui/core";
import bgbanner from "../../asset/image/Login/gradient-bg.svg";

const useStyles = makeStyles((theme) => ({
    div: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        maxWidth: "100vw",
        height: "100vh",
        alignItems: "center",
        backgroundColor: "rgb(255, 255, 255)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundImage: `url(${bgbanner})`,
        display: "flex",
        justifyContent: "center",
        padding: 10
    },
    root: {
        maxWidth: "800px",
        height: "auto",
        alignItems: "center",
        backgroundColor: "white",
        transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    },
    container: {
        padding: theme.spacing(3),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    logoImg: {
        borderRadius: "10px",
        width: "100%",
        height: "100%",
    },
    textSpace: {
        marginTop: "5px",
    },
    textFiled: {
        marginTop: "5px",
        "& .MuiFormLabel-root": {
            fontSize: 13,
        },
        '& .MuiFormHelperText-root': {
            fontSize: 10,
        }
    },
    socialIcons: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        paddingBottom: "5px",
        paddingTop: "5px",
    },
    facebookStyle: {
        backgroundColor: "#385998",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    microsoftStyle: {
        backgroundColor: "#e9eaec",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    twitterStyle: {
        backgroundColor: "#00ACEE",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    googleStyle: {
        backgroundColor: "#CA3224",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    linkedinStyle: {
        backgroundColor: "#0E76AB",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    appleStyle: {
        backgroundColor: "#161617cc",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    socialIconStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly !important",
        fontSize: "1.4rem",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        cursor: "pointer",
        marginBottom: "10px",
        boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    image: {
        width: "18px",
        height: "18px"
    },
    disabledStyle: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray",
            color: "gray",
            border: '2px solid gray',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray",
            color: "gray",
        },
        "& .MuiInputLabel-outlined": {
            color: "gray",
        },
        "& .MuiOutlinedInput-input": {
            color: "gray"
        },
    },
    rootError: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red",
            border: '2px solid red',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            color: "red",
        },
        "& .MuiInputLabel-outlined": {
            color: "red",
        },
        "& .MuiOutlinedInput-input": {
            color: "red"
        },
    },
    ButtonStyle: {
        marginTop: "5px",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
            color: "green",
            border: '2px solid green'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
            color: "green"
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "& .MuiOutlinedInput-input": {
            color: "green"
        }
    },
    continuebutton: {
        fontWeight: 'bold',
        backgroundColor: '#283593',
        color: 'white',
        width: '100%',
        padding: '8px',
        borderRadius: '5px',
        border: 'none',
        // marginTop: '10px',
        cursor: 'pointer',
        '&:hover': {
            border: 'none',
            padding: '8px',
            borderRadius: '5px',
            backgroundColor: '#3bb78f',
            boxShadow: '0px 15px 20px rgba(46, 229, 157, 0.4)',
        }
    },
    noteInfo: {
        background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)',
        borderRadius: '5px',
        color: 'rgb(0, 75, 80)',
        padding: '10px 15px',
        fontSize: '13px'
    },
    gridLogin: {
        padding: "14px 25px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'column'
    },
    imageDisplay: {
        width: '100%',
        maxWidth: '100%'
    },
    gridCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default useStyles;