import React, { useEffect, useState } from "react";
import {
  useAutocomplete,
} from "@mui/base/useAutocomplete";
import { Root } from "./PlacementStyle";
import { InputWrapper } from "./PlacementStyle";
import { StyledTag } from "./PlacementStyle";
import { Listbox } from "./PlacementStyle";
import CheckIcon from "@mui/icons-material/Check";
import useStyles from "../PlacementCss";
interface CustomizedHookProps {
  onSelect: (selectedFields: string[]) => void;
  onFieldSelectionChange: (selectedFields: string[]) => void;
  options: string[];
  
}

const CustomizedHook: React.FC<CustomizedHookProps> = ({ onSelect, onFieldSelectionChange, options, }) => {
  const classes = useStyles();
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const handleMultiSelectChange = (e: any, selectedOptions: any) => {
    let selectedValues = selectedOptions?.map((option: any) => option);
  
    if (selectedValues?.includes("Select All")) {
      selectedValues = options
        .filter((option) => option !== "Select All" && option !== "id")
        .map((option) => option);
      if (!selectedValues?.includes("Account Number")) {
        selectedValues?.unshift("Account Number");
      }
    }
    setSelectedFields(selectedValues);
    onSelect(selectedValues);
    onFieldSelectionChange(selectedValues);
  };

  useEffect(() => {
    setInputValue(selectedFields?.length === 0 || selectedFields?.length === 1 ? " + Select more fields" : "");
  }, [selectedFields]);


  const optionsWithSelectAll = ["Select All", ...options.filter((option) => option !== "Account Number" && option !=="id")];

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: optionsWithSelectAll,
    getOptionLabel: (option) => option,
    onChange: handleMultiSelectChange,
  });


  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {value.includes("Select All") ? (
            <StyledTag label="Select All" {...getTagProps({ index: 0 })} />
          ) : (
            value.map((option, index) => (
              <StyledTag label={option} {...getTagProps({ index })} />
            ))
          )}
          <input {...getInputProps()} placeholder={inputValue} className={classes.inputSelectField} />
        </InputWrapper>
      </div>
      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()} >
          {(groupedOptions as typeof options)?.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span>{option}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
};
export default CustomizedHook;