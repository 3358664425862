/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, DialogActions, DialogContent, Grid, Typography, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import BDebtTextField from '../../../../GlobalControl/BDebtTextField';
import BDebtDialog from '../../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import Looks3Icon from '@mui/icons-material/Looks3';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import useStyles from "../../UserCss";
import RichTextEditor from './RichTextEditor';

const SetupClient = () => {
    const [open, setOpen] = useState(false);
    const [editorState, setEditorState] = useState(null);
    const [content, setContent] = useState<string>('{}');
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleEditorChange = (newContent: string) => {
        setContent(newContent);
      };

    const handleClickClose = () => {
        setOpen(false);
    };
    
    return (
        <div>

            <Tooltip title="CLIENT SETUP" arrow onClick={handleClickOpen}>
                <IconButton className={`${classes.SetUpIcon} ${classes.IconButtonStyle}`}>
                    <Badge overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={<Looks3Icon className={`${classes.horizontalLine} ${classes.BadgeIcon3}`} />}>
                        <PersonAddAlt1Icon className={classes.icon2} />
                    </Badge>
                </IconButton>
            </Tooltip>

            <BDebtDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'md'}
            >
                <BDebtDialogTitleHeader id="customized-dialog-title" onClose={handleClickClose}>
                    <Typography variant="body1">
                        <b>CLIENT SETUP</b>
                    </Typography>
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={0} padding="2px">
                        <Grid item xs={12} >
                            <Typography variant="body2" padding="2px"><b>To Email</b></Typography>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="TO Email"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="body2" padding="2px"><b>CC Email</b></Typography>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="CC Email"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="body2" padding="2px"><b>Subject</b></Typography>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                placeholder="Subject"
                                size='small'
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" padding="1px"><b>Details</b></Typography>
                            <Box sx={{ border: '2px solid grey',textIndent: "10px" }} borderRadius={1.5} height={270}>
                            <RichTextEditor initialContent={content} onChange={handleEditorChange} />
                            </Box>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions sx={{ borderTop: '1px dashed gray' }}>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='primary' sx={{ fontSize: 12 }}>
                        Confirm
                    </Button>
                    <Button autoFocus onClick={handleClickClose} variant='contained' size="small" color='error' sx={{ fontSize: 12 }}>
                        Close
                    </Button>
                </DialogActions>
            </BDebtDialog>
        </div>
    )
}

export default SetupClient;