import { styled } from "@material-ui/styles";
import { DateTime } from "@syncfusion/ej2-charts";

export const clientcode: string[] = [
    "COGNULT1",
    "COGNULT2",
    "COGNULT3",
    "COGNULT4",
    "COGNULT5",
 ];

 export const Phase: string[] = [
    "NEW ACCOUNTS",
    "UPDATE ACCOUNTS",
];

 export const DateFormat: string[] = [
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "YYYY/MM/DD",
    "YYYY/DD/MM",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
    "YYYY-MM-DD",
    "YYYY/DD/MM"
];


export const Dataset: string[] = [
    "DATASET1",
    "DATASET2",
    "DATASET3",
    "DATASET4",
    "SINGLE CODE - MULTI PHASE",
    "MULTI CODE - SINGLE PHASE",
    "SINGLE CODE - SINGLE PHASE",
    "MULTI CODE - MULTI PHASE",
];

  export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export interface FormData  {
    clientcode: string;
    Phase: string;
    Dataset: string;
}
export interface IFormData  {
  clientcode: string;
  Phase: string;
  DateFormat: string;
  Dataset: string;
}


export interface IFormData  {
  clientcode: string;
  Phase: string;
  DateFormat: string;
  Dataset: string;
}

export interface CreateDataSet {
    dataSetId : number;
    dataSetName: string;
    createdBy: number;
    clientCodeList: string;
}

 export const clientcodes: string[] = [
  "COGNULT1",
  "COGNULT2",
  "COGNULT3",
  "COGNULT4",
  "COGNULT5",
  ];

  export interface RuleInformation {
    ruleId: number;
    name: string;
    description: string;
    ruleType: string;
    ruleOption: string;
    maxLength: string;
    formula: string;
  }
  
  export interface RuleGroup {
    ruleTypeId: number;
    ruleType: string;
    ruleInformation: RuleInformation[];
  }

  export interface Field {
    id: number;
    ruleId: number;
    name: string;
  }


export interface GetDataSets{
    dataSetId : number;
    dataSetName : string;
    clientCode:string;
    createdBy:number;
    createdDate:DateTime;
    modifiedDate:DateTime;
}

export const fetchedDateFormats = [
  'YYYY-MM-DD',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'YYYY/MM/DD',
  'DD-MM-YYYY',
];

export const DatasetMockData = [
  { id:1, title: "Account Number",  record1:"CU201001", record2:"CU201001"},
  { id:2, title: "First Name" ,record1:"Kesavapriya", record2:"Indhumathi"},
  { id:3, title: "Last Name",record1:"Samraj", record2:"Samraj"},
  { id:4, title: "Billing Address",record1:"No.10", record2:"No.22"},
  { id:5, title: "Billing City" ,record1:"Bangalore", record2:"Bangalore"},
  { id:6, title: "Billing State" ,record1:"KA", record2:"KA"},
  { id:7, title: "Billing Zip Code" ,record1:"63250", record2:"36520"},
  { id:8, title: "Phone Number" ,record1:"9874563210", record2:"9874563210"},
  { id:9, title: "Email Address" ,record1:"keshu@gamil.com", record2:"indhu@gamil.com"},
  { id:10, title: "Last Charge Date" ,record1:"22/01/2024", record2:"22/01/2024"},
  { id:11, title: "Date of Last Payment", record1:"02/01/2024", record2:"02/01/2024"},
  { id:12, title: "Itemization Date" ,record1:"17/01/2024", record2:"17/01/2024"},
];



