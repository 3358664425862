import { Autocomplete, styled } from "@mui/material";

const BDebtAutoComplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    backgroundColor: "#f0f0f0", // Set the background color of the input
  },
  "& .MuiInputLabel-root": {
    color: "black", // Set the color of the input label
  },
  "& .MuiListItem-root": {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "lightblue", // Set the background color of the selected item
    },
  },
  "& .MuiAutocomplete-inputRoot": {
    // Additional styles for the input root container
    border: "1px solid #ccc", // Set a border for the input root
    borderRadius: "5px", // Set border radius for the input root
  },
  "& .MuiAutocomplete-input": {
    // Additional styles for the input element
    padding: "10px", // Set padding for the input element
  },
  "& .MuiAutocomplete-clearIndicator": {
    // Styles for the clear indicator (the "X" button to clear the input)
    color: "grey", // Set the color of the clear indicator
  },
});

export default BDebtAutoComplete;
