/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
// Css
import useStyles from "./DatasetCss";
// Icon
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
// Modules
import AddDataset from "./AddDataset";
import UploadSchema from "./UploadSchema";
import DeleteDataSet from "./DeleteDataSet";
// Controls
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useState } from "react";
import { Box, Divider, Grid, InputAdornment } from "@mui/material";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { IconButton, Typography } from "@material-ui/core";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import EditDataset from "./EditDataset";
import { GetDataSet, GetDatsetField } from "../../../MockData/Placement/DataRuleMock";
import { searchTermRegex } from "../../../Model/RegexValidation/DataRuleValidation";
import { usePost } from "../../../utils/apiHelper";


const Dataset = () => {
  const classes = useStyles();
  const [selectedDataset, setSelectedDataset] = useState<GetDataSet>();
  const [getData, setGetData] = useState<GetDataSet[]>([]);
  const [selectedCardData, setSelectedCardData] = useState<GetDatsetField>();
  const [searchText, setSearchParameters] = useState<string>("");

  useEffect(() => {
    handleGetRequest();
  }, []);

  const handleDatasetAdd = (newDataset: GetDataSet) => {
    setSelectedDataset(newDataset);
    handleGetRequest();
  };
  const handleCallBack = () => {
    handleGetRequest();
  };

  const isDatasetSelected = (dataSetId: number) => {
    return selectedDataset?.dataSetId === dataSetId;
  };

  const handleCardClick = (dataSetId: number) => {
    const selectedData = getData.find((dataset) => dataset.dataSetId === dataSetId);
    setSelectedDataset(selectedData || null);
  };

  useEffect(() => {
    const selectedId = selectedDataset?.dataSetId;
    const selectedFields = selectedDataset?.fieldsDetail || [];
    const selectedCardData = {
      dataSetId: selectedId || 0,
      dataSetName: selectedDataset?.dataSetName,
      clientCode: selectedDataset?.clientCode,
      createdBy: selectedDataset?.createdBy,
      fields: selectedFields || [],
    };
    setSelectedCardData(selectedCardData);
  }, [selectedDataset]);

  const BDebtCardContentWithDivider = ({ children, }: { children: React.ReactNode; }) => (
    <>
      {children}
      <Divider className={classes.dividerStyle} />
    </>
  );

  const handleGetRequest = async () => {
    let request = {
      SearchParameters: searchText,
    };
    const response = await usePost<GetDataSet[]>("Dataset/GetDataSetDetail", request);
    setGetData(response.data);
  };

  const ClearfetchDetails = async () => {
    let clearRequest = {
      SearchParameters: null,
    };
    const response = await usePost<GetDataSet[]>("Dataset/GetDataSetDetail", clearRequest);
    setGetData(response.data);
  };

  const handleClear = () => {
    setSearchParameters(null);
    ClearfetchDetails();
  };

  const highlightSearchTerm = (text: string, searchText: string | null) => {
    if (!searchText) return text;

    const regex = searchTermRegex(searchText);
    const highlightedText = text.split(regex).map((part, index) => {
      return regex.test(part) ? (
        <mark key={index} style={{ backgroundColor: "#FFF44F", padding: "2px" }}>
          {part}
        </mark>
      ) : (
        part
      );
    });

    return <>{highlightedText}</>;
  };

  return (
    <Box p={0} m={0}>
      <Grid container spacing={0}>
        <Grid xs={12} sm={8} md={10}>
          <BDebtTextField
            id="input-with-icon-textfield"
            fullWidth
            placeholder="Search the all Records..."
            size="small"
            value={searchText || ''}
            variant="outlined"
            className="search-icon"
            InputProps={{
              startAdornment: (
                <IconButton onClick={handleGetRequest} size="small">
                  <SearchIcon color="disabled" />
                </IconButton>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <IconButton onClick={handleClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => setSearchParameters(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && searchText != null) {
                handleGetRequest();
              } else if (event.key === "Enter" && searchText === null) {
                handleGetRequest();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <AddDataset onDatasetAdd={handleDatasetAdd} />
        </Grid>
      </Grid>
      <Grid container spacing={1} padding={1}>
        <Grid xs={3} borderTop="1px dashed lightgray">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={10}
            className={classes.dasetSidebar}
          >
            {getData?.map((dataset) => (
              <Box m={0} p="0px 15px 0px 3px" key={dataset.dataSetId}>
                <BDebtCard
                  className={`${classes.classCard} ${isDatasetSelected(dataset?.dataSetId) ? classes.gridStyle : "none"} ${isDatasetSelected(dataset.dataSetId) ? classes.cardStyle : "white"}`}
                  onClick={() => handleCardClick(dataset?.dataSetId)}
                >
                  <BDebtCardContentWithDivider>
                    <div className={`${classes.divHeader} ${isDatasetSelected(dataset?.dataSetId) ? classes.cardContentColor : "black"}`}>
                      <div className={classes.divElipsis}>
                        <BDebtTypography variant="h6">
                          {highlightSearchTerm(dataset?.dataSetName || '', searchText)}
                        </BDebtTypography>
                      </div>
                      <BDebtTypography variant="body2">
                        {dataset?.clientCode}
                      </BDebtTypography>
                    </div>
                    <div className={classes.divHeader}>
                      <div className={classes.divElipsis}>
                        <Typography variant="button" className={`${classes.propertiesStyle} ${isDatasetSelected(dataset?.dataSetId) ? classes.cardContentColor : "black"}`}>
                          Properties
                        </Typography>
                      </div>
                      <div className={classes.actionsContainer}>
                        <EditDataset
                          row={{
                            dataSetId: dataset?.dataSetId,
                            dataSetName: dataset?.dataSetName,
                            clientCode: dataset?.clientCode,
                            createdBy: dataset?.createdBy,
                          }}
                          onEdit={handleGetRequest}
                        />
                        <DeleteDataSet
                          onDelete={handleCallBack}
                          rowId={dataset?.dataSetId}
                        />
                      </div>
                    </div>
                  </BDebtCardContentWithDivider>
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={100}
                    thumbMinSize={20}
                    universal={true}
                  >
                    {(dataset?.fieldsDetail || []).map((field, index) => (
                      <Grid container spacing={0} key={index} className={`${isDatasetSelected(dataset?.dataSetId) ? classes.cardContentColor : "black"}`}>
                        <Grid item xs={9}>
                          <Typography className={classes.fieldStyle}>
                            {field.fieldName}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.fieldStyle}>
                            {field.dataType}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Scrollbars>
                </BDebtCard>
              </Box>
            ))}
          </Scrollbars>
        </Grid>
        <Grid xs={9} borderLeft="1px solid lightgray" borderTop="1px dashed lightgray">
          <UploadSchema selectedDataset={selectedCardData} onUploadSchema={handleGetRequest}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dataset;
