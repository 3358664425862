import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IRoute } from '../../Routes';
import Routes from '../../Routes';
import useStyles from './NavbarCss';
import { Container } from '@mui/material';

const Content = () => {
    const classes = useStyles();
    return (
        <Container component="main" className={classes.contentContainer} maxWidth="xl">
            <Switch>
                {
                    Routes.map((route: IRoute) => {
                        return (
                            <Route exact path={route.path} key={route.path} component={route.component}>
                            </Route>
                        )
                    })
                }
            </Switch>
        </Container >
    );
};

export default Content;
