/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Avatar, Checkbox, IconButton, InputAdornment,
    Table, TableBody,
    TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography
} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import useStyles from './ProspectUserCss';
// Icons
import MailLockIcon from '@mui/icons-material/MailLock';
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import SearchandHighlight from '../GlobalFunctions/SearchandHighlight';
import ProspectHistory from './ProspectHistory';
import UploadContract from './UploadContract';
import VerifyClient from './VerifyClient';
import SetClientPermission from '../SetclientPermission/SetClientPermission';
import SetupClient from '../ProspectUser/SetupClient/SetupClient';
import api from '../../../ApiConfiguration/Configuration';
import { IProspectUserDetail } from '../../../Model/IUser';
import DeletePropectUser from './DeletePropectUser';

const ProspectUser = () => {
    const classes = useStyles();
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState<IProspectUserDetail[]>([]);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);

    };

    useEffect(()=>{
        GetProspectUserDetail();
      },[]);
    
      const GetProspectUserDetail = () => {
        setLoading(true)
        api.get<IProspectUserDetail[]>('User/GetProspectUserDetail').then((response) => {
          setUserList(response.data);
          setLoading(false);
        })
      };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead() {
        const headCells = [
            { id: "", numeric: false, disablePadding: false, label: "", sortable: false },
            { id: "fName", numeric: false, disablePadding: false, label: "FIRST NAME", sortable: false },
            { id: "lName", numeric: false, disablePadding: false, label: "LAST NAME", sortable: false },
            { id: "emailId", numeric: false, disablePadding: false, label: "EMAIL ID", sortable: false },
            { id: "useRole", numeric: false, disablePadding: false, label: "VERIFY CLIENT", sortable: false },
            { id: "joinedDate", numeric: false, disablePadding: false, label: "UPLOAD CONTRACT", sortable: false },
            { id: "lActivty", numeric: false, disablePadding: false, label: "CLIENT SETUP", sortable: false },
            { id: "lActivty", numeric: false, disablePadding: false, label: "AUTHORIZE USER", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "SETTINGS", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "HISTORY", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
            { id: "", numeric: false, disablePadding: false, label: "", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow >
                    <TableCell className={classes.tableCellHeader}>
                        <Checkbox
                            color="secondary"
                            className={classes.checkBox}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map(headCell => (
                        <TableCell className={classes.tableCellHeader}
                            key={headCell.id}>
                            {headCell.label || headCell.sortable}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead >
        );
    }

    return (
        <Grid container spacing={0} boxShadow={3}>
            <Grid item xs={12} sm={8} md={12} padding="5px">
                <TextField
                    fullWidth
                    size="small"
                    id="standard-bare"
                    variant="outlined"
                    className={classes.searchBox}
                    onChange={onSearchChange}
                    placeholder="Search User name or Email Id..."
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        classes: {
                            root: classes.outlinedInput,
                            notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
                <TableContainer className={`${classes.tablebodyConainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Table aria-label="customized table" size='medium' stickyHeader>
                        <EnhancedTableHead />
                        <TableBody>
                            {userList.map((row, index) => (
                                <React.Fragment>
                                    <TableRow key={row?.prospectUserId}>
                                        <TableCell className={classes.tableCellStyle}>
                                            <Checkbox
                                                color="primary"
                                                style={{ padding: '5px' }}
                                                inputProps={{
                                                    'aria-label': 'select all desserts',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            <Avatar sizes='small' className={classes.avatar}>
                                                <Typography variant='body2'>{row?.prospectFirstName[0]}{row?.prospectLastName[0]}</Typography>
                                            </Avatar>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {SearchandHighlight(row?.prospectFirstName, value)}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {SearchandHighlight(row?.prospectLastName, value)}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {SearchandHighlight(row?.prospectEmailAddress, value)}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle1}>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <Tooltip title="VERIFY CLIENT" arrow >
                                                      
                                                        <VerifyClient />
                                                    </Tooltip>
                                                </div>
                                                <div className={classes.divStyle}>
                                                    <Tooltip title="Processed" arrow>
                                                        <div>
                                                            <MarkEmailReadIcon className={classes.divIcon1} /> {/* Place the icon above the line */}
                                                        </div>
                                                    </Tooltip>
                                                    <div className={`${classes.IconDivstyle} ${classes.Icon1}`} />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle1}>
                                            <Tooltip title="CONTRACT UPLOADED" arrow>
                                                <UploadContract />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle1}>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <SetupClient/>
                                                </div>
                                                <div className={classes.divStyle}>
                                                    <Tooltip title="Notification not Sent" arrow>
                                                        <div>
                                                            <NotificationsOffIcon className={classes.divIcon3} />
                                                        </div>
                                                    </Tooltip>
                                                    <div className={`${classes.IconDivstyle} ${classes.Icon3}`} />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle1}>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <SetClientPermission/>
                                                </div>
                                                <div className={classes.divStyle}>
                                                    <Tooltip title="Yet to Process" arrow>
                                                        <div>
                                                            <MailLockIcon className={classes.divIcon4} />
                                                        </div>
                                                    </Tooltip>
                                                    <div className={`${classes.IconDivstyle} ${classes.Icon4}`} />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            <Tooltip title="SETTINGS" arrow>
                                                <IconButton style={{ color: 'black' }}><MiscellaneousServicesIcon /></IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle} >
                                            <ProspectHistory />
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle} >    
                                 <DeletePropectUser prospectUserId={row.prospectUserId} onDelete={GetProspectUserDetail}/>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid >
            <Grid item xs={12} sm={8} md={12} borderTop={'1px solid lightgray'}>
                <TablePagination
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid >
    );
}
export default ProspectUser