import { Box } from '@mui/material'

const Permission = () => {
  return (
    <Box p={1} m={1}>
      <div>Permission</div>
    </Box>
  )
}

export default Permission