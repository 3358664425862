//mui component import
import React, { useState } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
//style import
import useStyles from "../../WidgetCss";
//components import
//import SampleDashboardLayout from "../SampleDashboardLayout";
import SampleChart from "../SampleDashboardLayout";
import DatabasePage from "./DataSourcePage";
import TableList from "./TableList";
import BDebtTypography from "../../../../../GlobalControl/BDebtTypography";



const steps = [
  "Select Data Source & Configure",
  "Choose Table Column",
  "Chart Preview",
];

const StepComponents = [
  () => (
    <Grid item xs={12} p={1}>
      <DatabasePage />
    </Grid>
  ),
  () => (
    <Grid item xs={12} p={1}>
      <TableList />
    </Grid>
  ),
  () => (
    <Grid item xs={12} p={1} >
      <SampleChart />
    </Grid>
  ),
];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean; }>({});
  const classes = useStyles();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
      <Box sx={{ marginTop: '10px' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography mt={2} mb={1}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box display={'flex'} flexDirection={'row'} pt={2} >
                <Box flex={"1 1 auto"} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container className={classes.stepperbodyConainer}>
                {StepComponents[activeStep]()}
              </Grid>
              <Box display={"flex"} flexDirection={"row"}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box flex={"1 1 auto"} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <BDebtTypography
                      variant="caption"
                      display={"inline-block"}
                    >
                      Step {activeStep + 1} already completed
                    </BDebtTypography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
  );
}
