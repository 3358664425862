import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //Custome widgets
    root: {
      padding: "5px 5px 5px 5px",
    },
    tableHeadCell: {
      color: "white",
      fontSize: "16px",
    },
    stickyHeader: {
      position: "sticky",
    },
    tableRow: {
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    },
    tableCell: {
      color: "white",
      backgroundColor: "#e5f2fa", // Example background color
    },
    chip: {
      backgroundColor: "#1976d2",
      color: '#fff',
      fontSize: "14px",
    },
    deleteButton: {
      color: "red",
      padding: "5px 5px 5px 20px",
    },
    widget1: {
      color: "#474747",
      backgroundColor: "#e5f2fa",
    },
    widget2: {
      color: "#474747",
      backgroundColor: "#d0f5df",
    },
    widget3: {
      color: "#474747",
      backgroundColor: "#fdecd2",
    },
    widget4: {
      color: "#474747",
      backgroundColor: "#e8d8ee",
    },
    btnradious: {
      borderRadius: '20px !important'
    },
    tableHead: {
      position: "sticky",
      top: "0",
      zIndex: 1020
    },
    testIcon: { borderRadius: "50%  !important", backgroundColor: "white  !important", color: "green  !important" },
    testButton: {
      marginRight: "10px  !important",
      borderRadius: '20px !important',
      backgroundColor: "green !important",
      color: " white !important",
      alignContent: "right  !important",
    },
    addBtn: {
      marginRight: "10px !important",
      borderRadius: '20px !important',
      backgroundColor: "green !important",
      color: "white !important"
    },
    AddIcon: {
      backgroundColor: "white !important",
      color: "green !important",
      borderRadius: '50px !important'
    },
    pageHeader: {
      marginTop: '10px',
      marginBottom: '8px',
      textTransform: 'uppercase',
      fontSize: 13
    },
    tableCellHeader: {
      padding: "5px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: 'bold'
    },
    tableCellStyle: {
      padding: "5px 8px !important",
      "& .MuiTableCell-root": {
        padding: "10px 15px!important",
      },
      fontSize: "12px !important",
    },
    tablebodyConainer: {
      borderBottom: '2px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 245px)',
        minHeight: 'calc( 100vh - 245px)',
      },
    },
    stepperbodyConainer: {
      borderBottom: '1px solid lightgray',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 230px)',
        minHeight: 'calc( 100vh - 300px)',
      },
    },
    configurationConainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 230px)',
        minHeight: 'calc( 100vh - 320px)',
      },
    },
    tablecardConainer: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 317px)',
        minHeight: 'calc( 100vh - 317px)',
      },
    },
    buttonStyle: {
      fontSize: '12px'
    },
    formStyle: {
      marginLeft: "5px"
    },
    SqltablebodyConainer: {
      padding: "10px",
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 30vh - 66px)',
        minHeight: 'calc( 56vh - 33px)',
      },
    },
    divStyle: {
      "&.MuiGrid-item": {
        padding: "0px",
        paddingBottom: "0px"
      }
    },
    tableNameStyle: {
      backgroundColor: "#10B9811F !important",
      color: "#0B815A !important",
      borderBottom: "1px  white !important",
      padding: "1px !important",
      alignContent: "center !important",
    },
    listItem: {
      borderBottom: "none !important",
      padding: "5px !important"
    },
    tableContainerStyle: {
      "&.css-xudz5b-MuiTableRow-root .MuiTableCell-root": {
        padding: "2px !important",
      },
    },
    gridTypography: {
      padding: "4px",
      backgroundColor: "rgba(16, 185, 129, 0.12)",
      borderBottom: '1px solid lightgray',
      color: "rgb(11, 129, 90)"

    },
    borderRight: {
      borderRight: "1px solid lightgray"
    },
    gridContainer: {
      display: "flex",
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    radioButtonDirection: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row"
    },
    borderTop:{
      borderTop:"1px solid lightgray"
    },

    // predefine widgets 

    cardLayout: {
      '&:hover': {
        transform: 'scale(1.02)',
      }
    },
    EditIcon: {
      marginRight: 1,
      borderRadius: '50px !important',
      backgroundColor: "white",
      color: "green",
      fontSize: "15px !important"
    },
    deleteIcon: {
      marginRight: 2,
      borderRadius: '50px !important',
      backgroundColor: "white",
      fontSize: "15px !important",
      color: 'red'
    },
    borderBottom: {
      borderBottom: `1px solid lightgray`
    },
    IconStyle: {
      marginRight: 1,
      borderRadius: '50px !important',
      backgroundColor: "white",
      color: 'red'
    },
    widgetsContainer: {
      maxHeight: '600px !important',
      minHeight: '600px !important',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 280px) !important',
        minHeight: 'calc( 100vh - 280px) !important',
      },
    },
    cardHeadLine: {
      borderBottom: "1px dashed #ccc"
    },
    padding: {
      padding: "0px 10px"
    },

  })
);
export default useStyles;
