/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import Radio from '@material-ui/core/Radio';
import { Grid,RadioGroup, FormControlLabel} from "@material-ui/core";
import useStyles from '../ProspectUserCss';
import BDebtTypography from '../../../../GlobalControl/BDebtTypography';
const mockLabels = ['If You Choose Corporate,Who Is Our Biling Contract?', 'Billing Contract Title', 'Billing Contract Phone Number', 'Billing Contract Email', 'Billing Address - Street', 'Billing Address - City', 'Billing Address - State', 'Billing Address - Zipcode'];

const BillingInformation = () => {
  const classes = useStyles();
  const [numTextboxes, setNumTextboxes] = useState(8);
  const [radioValue, setRadioValue] = useState('');
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };
  return (
    <Grid container spacing={2} className={classes.Tabhead}>
      <Grid item xs={12} >
        <BDebtTypography variant="body2" className={classes.verfiyfont}>
          Billing Should be Directed to?
        </BDebtTypography>
        <RadioGroup
          aria-label="Radio group for Yes or No"
          name="radioGroup"
          value={radioValue}
          onChange={handleRadioChange}
          row
        >
          <FormControlLabel value="Corporate" control={<Radio />} label={<span className={classes.commonfont}>Corporate</span>} />
          <FormControlLabel value="The Branch" control={<Radio />} label={<span className={classes.commonfont}>The Branch</span>} />
        </RadioGroup>

      </Grid>
      {Array.from({ length: numTextboxes }).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
          <BDebtTypography variant="body2" className={classes.verfiyfont}>{mockLabels[index] || `Label ${index + 1}`}</BDebtTypography>
          {['Billing Contract Phone Number'].includes(mockLabels[index]) ? (
            <>
            <Grid container spacing ={1} >
              <Grid item xs={10}>
              <BDebtTextField variant="outlined" size='small' fullWidth
              placeholder={`Enter ${mockLabels[index]}`}
              inputProps={{ style: { color: 'black' } }}
              />
              </Grid>
              <Grid item xs={2}>
              <BDebtTextField variant="outlined" size='small' fullWidth  placeholder='#EXT'  />
              </Grid>
             </Grid>
            </>
          ) : (
            
            <BDebtTextField variant="outlined" size='small' fullWidth placeholder={`Enter ${mockLabels[index] || `Label ${index + 1}`}`} 
            />
          
          )}
            
        </Grid>
      ))}
    </Grid>
  );
}

export default BillingInformation;