/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
//Controls
import { Box, Grid, Typography, FormControl, MenuItem, IconButton } from '@material-ui/core';
import Select from '@mui/material/Select';
//Icons
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import useStyles from '../PlacementCss';

interface CustomPaginationProps {
    totalPages: number;
    currentPage: number;
    pageSize: number;
    handleChangePage: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<{ value: unknown }>) => void;
    rowCount: number;
    rows: any[];
    acceptedRecords:any[];
    errorRecords:any[];
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ rows, acceptedRecords, errorRecords}) => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    // const [currentIndex, setCurrentIndex] = useState<number>();  //FutureEnhancement
    const classes = useStyles();

    // const totalPages = Math.ceil(rows.length / pageSize);
    const dataToPaginate = acceptedRecords && errorRecords ? acceptedRecords : rows;
    const totalPages = Math.ceil(dataToPaginate.length / pageSize);

    //future enhancement
    const handleChangeRowsPerPage = (event) => { 
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0); // Reset to the first page when changing rows per page
    };

    const handleChangePage = (event) => {
        const newPage = parseInt(event.target.value, 10) - 1;
        setCurrentPage(newPage);
    };

//Furute Enhancement
    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.key === "ArrowLeft" && currentIndex > 0) {
    //             handlePrevious();
    //         } else if (event.key === "ArrowRight") {
    //             handleNext();
    //         } else if (event.key === "ArrowUp") {
    //             handleFirstRecord();
    //         } else if (event.key === "ArrowDown") {
    //             handleLastRecord();
    //         }
    //     };

    //     document.addEventListener("keydown", handleKeyDown);

    //     return () => {
    //         document.removeEventListener("keydown", handleKeyDown);
    //     };
    // }, [currentIndex]);


    // const handleNext = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1));
    // };

    // const handlePrevious = () => {
    //     setCurrentIndex((prevIndex) =>
    //       prevIndex === 0 ? prevIndex - 1
    //     );
    // };

    // const handleFirstRecord = () => {
    //     setCurrentIndex(0);
    // };

    // const handleLastRecord = () => {
    //     setCurrentIndex();
    // };


    return (
        <Grid container spacing={0} className={classes.pgColor}>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="start" style={{ marginTop: '7px' }}>
                    <Box sx={{ margin: 2 }} display="flex" justifyContent="start" alignItems="center">
                        <IconButton aria-label="previous" size="small">
                            <FirstPageIcon className={classes.pageIconStyle} />
                        </IconButton>
                    </Box>
                    <Box sx={{ margin: 2 }} display="flex" justifyContent="start" alignItems="center">
                    <IconButton aria-label="play/pause" size="small">
                            <FastRewindIcon className={classes.pageIconStyle}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ margin: 2}} display="flex" justifyContent="center" alignItems="center">
                        <Typography style={{ fontSize: '15px', color: 'black'}}>Page</Typography>
                    </Box>
                    <Box sx={{ margin: 2,bgcolor:"#ffff !important"}} display="flex" justifyContent="center" alignItems="center">                      <FormControl>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={currentPage + 1}
                            onChange={handleChangePage}
                            classes={{ select: classes.customSelect }}
                        >
                            {Array.from({ length: totalPages }, (_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Box>
                    <Box sx={{ margin: 2 }} display="flex" justifyContent="center" alignItems="center">
                        <IconButton aria-label="play/pause" size="small">
                            <FastForwardIcon className={classes.pageIconStyle} />
                        </IconButton>
                    </Box>
                    <Box sx={{ margin: 2 }} display="flex" justifyContent="center" alignItems="center">
                        <IconButton aria-label="next" size="small">
                            <LastPageIcon className={classes.pageIconStyle} />
                        </IconButton>
                    </Box>
                    {/* <Box sx={{ margin: 2 }} display="flex" justifyContent="center" alignItems="center">
                        <FormControl>
                            <Select value={pageSize} onChange={handleChangeRowsPerPage}
                                classes={{ select: classes.customSelect }}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}
                </Box>
            </Grid>
            <Grid item xs={6} className={classes.viewStyle}>
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                {/* <span>
                        Rows per page:
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                    </span> */}
                    <span className={classes.pageStyle}>
                        {`View ${currentPage * pageSize + 1}-${Math.min(
                            (currentPage + 1) * pageSize,
                            rows.length
                        )} of ${rows.length}`}
                    </span>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CustomPagination;
