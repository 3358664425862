/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button, Grid, Hidden, TextField, Typography, makeStyles, InputAdornment, Snackbar
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import logo from "../../asset/image/Login/Cog_Logo.png";
import apple from "../../asset/image/Login/Logo/apple.svg";
import facebook from "../../asset/image/Login/Logo/facebook.svg";
import google from "../../asset/image/Login/Logo/google.svg";
import linkedin from "../../asset/image/Login/Logo/linkedin.svg";
import microsoft from "../../asset/image/Login/Logo/microsoft.svg";
import slide1 from "../../asset/image/Login/bg1.png";
import slide2 from "../../asset/image/Login/bg2.png";
import slide3 from "../../asset/image/Login/bg3.png";
import slide4 from "../../asset/image/Login/bg4.png";
import twitter from "../../asset/image/Login/Logo/twitter.svg";
import React, { useEffect, useState } from "react";
import PageLayout from "../PageLayout";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { RiseLoader, SyncLoader, BeatLoader } from 'react-spinners';

// Login
import { loginRequest } from '../../auth/authConfig';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { IProspectUsersDetails, IUserDetails } from "../../Model/ProspectUsersContract/ProspectUsersDetails";
import { emailRegex, NameRegex, PhoneRegex } from '../../Constant/Constant';
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import BDebtAlert from "../../GlobalControl/BDebtAlert";
import useStyles from "./loginCss";
import VerifyClientUser from "../../Components/Users/VerifyClientUserPage/VerifyClientUser";

const Login = () => {
  const classes = useStyles();

  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let history = useHistory();

  const [showSignin, setShowSigIn] = useState(false);
  const [showSignup, setShowSigup] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [userDetailsList, setUserDetailsList] = useState<IProspectUsersDetails>();
  const [organizationNameError, setorganizationNameError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [managerEmailError, setManagerEmailError] = useState("");
  const [ProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [isPasswordProvided, setIsPasswordProvided] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSignedupUser, setIsSignedupUser] = useState<boolean>(false);
  const [prospectEmailError, setProspectEmailError] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [emailerror, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [lastnameerror, setLastNameError] = useState("");
  const [orgEmailError, setOrgEmailError] = useState("");
  const [orgPhoneError, setOrgPhoneError] = useState("");
  const [firstnameerror, setFirstNameError] = useState("");
  const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let newUsersList: IUserDetails = {
    prospectEmailAddress: "",
    prospectFirstName: "",
    prospectLastName: "",
    prospectPhoneNumber: "",
    organizationName: "",
    organizationAdminEmailId: "",
    organizationPhoneNumber: "",
    password: "",
    confirmPassword: "",
    userName: ""
  }
  // localState to store the user information 
  const [localState, setLocalState] = useState<IUserDetails>(newUsersList);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  useEffect(() => {
    setIsDisable(inProgress === 'login' || inProgress === 'handleRedirect' || inProgress === 'startup')
  }, [inProgress]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
  }, []);


  // this functions is to validate 
  useEffect(() => {
    if (isAuthenticated) {
      let account = accounts[0];
      instance.ssoSilent({ ...loginRequest, loginHint: account.username }).then(a => {
      });
    }
  }, [isAuthenticated])


  const handleSignup = () => {
    setShowSigIn(false);
    setShowSigup(true)
  };

  const handleClickSignin = () => {
    setShowSigup(false)
  };

  const handleSignin = () => {
    if (!inProgress && accounts.length > 0) {
      const account = accounts[0];
      if (account) {
        try {
          instance.ssoSilent(loginRequest).then(a => {
            history.push('/dashboard');
          })
        }
        catch (exception) {
          instance.loginPopup(loginRequest)
        }
      }
    }
    else {
      instance.loginPopup(loginRequest).then(a => {
        setShowSigIn(true);
        setIsLoading(true);
      })
    }
  };

  const handleSignupClick = () => {
    if (!localState?.userName?.length || !localState?.prospectFirstName?.length || !localState?.prospectLastName?.length || !localState?.prospectPhoneNumber?.length
      || !!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length ||
      !localState?.password?.length || !localState?.confirmPassword?.length) {
      if (localState?.prospectFirstName === "") {
        setFirstNameError("First name is required");
      }
      if (localState?.prospectLastName === "") {
        setLastNameError("Last name is required");
      }
      if (localState?.prospectPhoneNumber === "") {
        setPhoneError("Phone number is Required");
      }
      if (localState?.confirmPassword === "") {
        setConfirmPasswordError("Confirm Password is required");
      }
      if (localState?.password === "") {
        setPasswordError("Password is required");
      }
      if (localState?.userName === "") {
        setProspectEmailError("User Email is Required");
      }
    } else if (localState?.password !== localState?.confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
      setLocalState({ ...localState, confirmPassword: "" });
    } else {
      handleSaveUserInformation();
    }
  };


  const handleSaveUserInformation = async () => {
    setIsLoading(true);
    const userEmail = !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName;
    setShowProgressBar(true);
    try {
      const response = await fetch("https://debtor365api.azurewebsites.net/api/MSEntra/CheckEmailExist?userEmail=" + userEmail);
      const data = await response.json();
      if (!isPasswordProvided) {
        setEmailError("Email Already Exist in Azure AD try with another email");
      } else if (isPasswordProvided) {
        setProspectEmailError("Email Already Exist in Azure AD try with another email");
      }
      setShowProgressBar(false);
      console.log(data); // log the data to the console
    } catch (error) {

      /**
      * Calling the azure function to insert the Prospect Users Information
      * Need to write a logic to validate the account is exist in the system(DB) or not 
      */


      /**
        * Call the graph API to send an invitation to Signup user
        * 
        * Calling the graph API to Create a new user in the Azure AD
        */
      try {
        let request = {
          "fullName": localState?.prospectFirstName + " " + localState?.prospectLastName,
          "userEmail": !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName,
          "givenName": localState?.prospectFirstName,
          "surname": localState?.prospectLastName,
          "password": localState?.password
        }
        const response = await fetch("https://debtor365api.azurewebsites.net/api/MSEntra/SendSignupInvitation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request),
        });
      } catch (error) {
        console.log(error); // log the data to the console
      }
      setEmailError("");
      // setOpen(false); // closing the dialog box
      handleMapUserDetails();
      setShowProgressBar(false);
      setIsAccountCreated(true);
      setShowSigup(false);
    }
  }

  const handleClose = () => {
    setIsAccountCreated(false);
  }

  const validatePassword = (password: any) => {
    // Password validation criteria
    const minLength = 8;
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check minimum length
    if (password.length < minLength) {
      return "Password must be at least 8 characters long";
    }

    // Check for uppercase letters
    if (!regexUpperCase.test(password)) {
      return "Password must contain at least one uppercase letter";
    }

    // Check for lowercase letters
    if (!regexLowerCase.test(password)) {
      return "Password must contain at least one lowercase letter";
    }

    // Check for special characters
    if (!regexSpecialChar.test(password)) {
      return "Password must contain at least one special character";
    }

    // Return empty string if password is valid
    return "";
  };

  const handleMapUserDetails = () => {
    let newUserDetails: IProspectUsersDetails = {
      prospectEmailAddress: localState?.prospectEmailAddress,
      prospectGivenName: "",
      prospectLastName: "",
      prospectDisplayName: "",
      prospectPhoneNumber: "",
      isNewOrginization: false,
      isWebviewUser: false,
      isClientOnboarded: false,
      isUserActivated: false,
      isPermissionUpdated: false,
      isDocumentUploaded: false,
      isAdminApproved: false,
      isClientInfoConfirmed: false,
      prospectUserId: 0,
      prospectCreatedDate: new Date(),
      organizationName: "",
      organizationAdminEmailId: "",
      organizationPhoneNumber: "",
      isClientSetup: false,
      isActive: false,
      isMultiBranch: false
    }
    setUserDetailsList(newUserDetails);
    setIsSignedupUser(true);
  }

  const HandleFirstNameVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal.match(NameRegex)) {
      setFirstNameError("");
    } else if (FirstNameVal === "") {
      setFirstNameError("First name is required");
    } else {
      setFirstNameError("Please enter characters only");
    }
  };

  const HandleLastNameVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const LastNameVal = e.target.value;
    if (LastNameVal.match(NameRegex)) {
      setLastNameError("");
    } else if (LastNameVal === "") {
      setLastNameError("Last name is required");
    } else {
      setLastNameError("Please enter characters only");
    }
  };

  const HandleUserEmailVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsPasswordProvided(true);
    setEmailError("");
    const EmailVal = e.target.value;
    if (EmailVal.match(emailRegex)) {
      setProspectEmailError(""); // log the data to the console
    } else if (EmailVal === "") {
      setProspectEmailError("Email address is required");
    } else {
      setProspectEmailError("Please enter valid email address");
    }
  };

  const HandlePhoneVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const PhoneVal = e.target.value;
    if (PhoneVal.match(PhoneRegex)) {
      setPhoneError("");
    } else if (PhoneVal === "") {
      setPhoneError("Phone number is Required");
    } else {
      setPhoneError("Please enter numbers only");
    }
  };

  const HandleManagerEmailVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const ManagerEmail = e.target.value;
    if (ManagerEmail.match(emailRegex)) {
      setManagerEmailError("");
    } else if (ManagerEmail === "") {
      setManagerEmailError("Manager/SPOC email is required");
    } else {
      setManagerEmailError("Please enter valid mail address");
    }
  };

  const HandleOrgNameVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const OrganizationEmail = e.target.value;
    if (OrganizationEmail.match(NameRegex)) {
      setorganizationNameError("");
    } else if (OrganizationEmail === "") {
      setorganizationNameError("Organization Name is required");
    } else {
      setorganizationNameError("Please enter valid mail address");
    }
  };

  const HandleOrgContactVal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const OrgPhone = e.target.value;
    if (OrgPhone.match(PhoneRegex)) {
      setOrgPhoneError("");
    } else if (OrgPhone === "") {
      setOrgPhoneError("Organization phone number is required");
    } else {
      setOrgPhoneError("Please enter numbers only");
    }
  };

  const handleValidatePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // Call password validation function
    const error = validatePassword(event.target.value);
    // Set error message if password is invalid, otherwise clear error message
    if (error) {
      setPasswordError(error);
    } else {
      setPasswordError("");
    }
  }

  // Check if password and confirm password are the same
  const handleConfirmPasswordChange = (event: any) => {
    // Check if password and confirm password are the same
    if (localState?.password !== event?.target?.value) {
      setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
    } else {
      setConfirmPasswordError("");
    }
  }

  // to get the user details
  const handleGetProspectUserDetails = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
  }

  function Icon() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.socialIcons}>
              <span className={`${classes.socialIconStyle} ${classes.googleStyle}`}>
                <img src={google} alt="google" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.facebookStyle}`}>
                <img src={facebook} alt="facebook" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.microsoftStyle}`}>
                <img src={microsoft} alt="microsoft" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.twitterStyle}`}>
                <img src={twitter} alt="twitter" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.linkedinStyle}`}>
                <img src={linkedin} alt="linkedIn" className={classes.image} />
              </span>
              <span className={`${classes.socialIconStyle} ${classes.appleStyle}`}>
                <img src={apple} alt="apple" className={classes.image} />
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!showSignin ?
        < div className={classes.div} >
          <div className={classes.root}>
            <Grid container spacing={0}        >
              <Hidden smDown>
                <Grid item xs={12} sm={5} className={classes.imageDisplay}>
                  <Carousel autoPlay showThumbs={false} showStatus={false} showArrows={false} infiniteLoop >
                    <img src={slide1} alt="company" className="slide-show" />
                    <img src={slide2} alt="consulting" className="slide-show" />
                    <img src={slide3} alt="product" className="slide-show" />
                    <img src={slide4} alt="training" className="slide-show" />
                  </Carousel>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={7} className={classes.gridLogin}>
                {showSignup ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center">
                        <img src={logo} height="40px" alt="companylogo" />
                      </Typography>
                      <Typography variant="h6" align="center" color="primary">
                        <b>Sign Up with Cognultsoft</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="userName"
                        label="Email (Login user ID)"
                        variant="outlined"
                        color="success"
                        size="small" fullWidth
                        disabled={!!localState?.prospectEmailAddress?.length}
                        className={!!localState?.prospectEmailAddress?.length ? classes.disabledStyle : prospectEmailError ? classes.rootError : classes.ButtonStyle}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { HandleUserEmailVal(e); handleGetProspectUserDetails(e); }}
                        helperText={prospectEmailError} error={!!prospectEmailError}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="prospectFirstName"
                        label="First Name"
                        variant="outlined"
                        color="success"
                        className={classes.textFiled}
                        value={localState?.prospectFirstName}
                        size="small" fullWidth
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { HandleFirstNameVal(e); handleGetProspectUserDetails(e); }}
                        helperText={firstnameerror} error={!!firstnameerror}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="prospectLastName"
                        label="Last Name"
                        variant="outlined"
                        color="success"
                        className={classes.textFiled}
                        size="small" fullWidth
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { HandleLastNameVal(e); handleGetProspectUserDetails(e); }}
                        helperText={lastnameerror} error={!!lastnameerror}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="password"
                        label="Password"
                        variant="outlined"
                        color="success"
                        disabled={!!localState?.prospectEmailAddress?.length}
                        type={showPassword ? 'text' : 'password'}
                        size="small" fullWidth
                        className={classes.textFiled}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { handleGetProspectUserDetails(e); handleValidatePassword(e) }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                tabIndex={-1}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        helperText={passwordError} error={!!passwordError}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="confirmPassword"
                        label="Confirm Password"
                        variant="outlined"
                        disabled={!!localState?.prospectEmailAddress?.length}
                        size="small" fullWidth
                        color="success"
                        type={showConfirmPassword ? 'text' : 'password'}
                        className={classes.textFiled}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { handleGetProspectUserDetails(e); handleConfirmPasswordChange(e); }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                tabIndex={-1}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        helperText={confirmPasswordError} error={!!confirmPasswordError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BDebtTextField id="outlined-basic"
                        required
                        name="prospectPhoneNumber"
                        label="Phone Number"
                        variant="outlined"
                        color="success"
                        className={classes.textFiled}
                        size="small" fullWidth
                        inputProps={{ maxLength: 16 }}
                        onChange={(e) => { HandlePhoneVal(e); handleGetProspectUserDetails(e); }}
                        helperText={phoneError} error={!!phoneError}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridCenter}>
                      {!isLoading ?
                        <Button variant="contained" color="secondary" fullWidth className={classes.continuebutton}
                          disabled={!!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length || !!managerEmailError?.length ||
                            !!passwordError?.length || !!confirmPasswordError?.length || !!organizationNameError?.length}
                          onClick={handleSignupClick}>
                          CREATE ACCOUNT
                        </Button>
                        :
                        <BeatLoader size={15} color="#36d7b7" speedMultiplier={1} />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        align="center"
                      >
                        Already have an account?
                        <Button variant="text" onClick={handleClickSignin}>
                          <span style={{ color: "blue" }}>
                            <b>Sign In!</b>
                          </span>
                        </Button>
                        <VerifyClientUser/>
                      </Typography>

                      <div className="line-container">
                        <div className="hr-left"></div>
                        <p className="line-text">
                          <b>Or Continue with</b>
                        </p>
                        <div className="hr-right"></div>
                      </div>
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center">
                        <img src={logo} height="40px" alt="companylogo" />
                      </Typography>
                      <Typography variant="h6" align="center" color="primary" style={{ marginTop: '10px' }}>
                        <b>Sign in with Cognultsoft</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center" color="primary" className={classes.noteInfo}>
                        <b>Your account is already registered and operational, signin again to continue</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.gridCenter}>
                      {!isLoading ?
                        <Button variant="contained" color="secondary" fullWidth className={classes.continuebutton} onClick={handleSignin}                        >
                          sign in
                        </Button>
                        :
                        <BeatLoader size={12} color="#36d7b7" speedMultiplier={1} />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center" style={{ padding: "5px" }}                        >
                        Don't have an account?
                        <Button variant="text" onClick={handleSignup}>
                          <span style={{ color: "blue" }}>
                            <b>Sign Up!</b>
                          </span>
                        </Button>
                      </Typography>
                      <div className="line-container">
                        <div className="hr-left"></div>
                        <p className="line-text">
                          <b>Or Continue with</b>
                        </p>
                        <div className="hr-right"></div>
                      </div>
                    </Grid>
                  </Grid>
                }
                <Icon />
              </Grid>
            </Grid>

            <Snackbar open={isAccountCreated} autoHideDuration={4000} onClose={handleClose} >
              <BDebtAlert onClose={handleClose} severity="success">
                Your account is created successfully!
              </BDebtAlert>
            </Snackbar>
          </div >
        </div >
        :
        <PageLayout />
      }
    </React.Fragment >
  );
};

export default Login;
