/* eslint-disable @typescript-eslint/no-unused-vars */
//MUI Components
import { Button, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import React  from "react";
//Custom CSS
import BDebtDialog from "../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import useStyles from "./ManageTemplateCss";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
//Interface
import { TemplateField } from "../../Model/AgentActivityTemplate/IAgentTableRow";

interface EditClientDetailProps {
    open: boolean;
    onClose: () => void;
    selectedRow: any;
  }

  const EditClientDetail: React.FC<EditClientDetailProps> = ({
    open,
    onClose, 
  }) =>{
  const classes = useStyles();

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div>
      <BDebtDialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={"md"}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <BDebtDialogTitleHeader
          id="responsive-dialog-title"
          onClose={onClose}
        >
          <BDebtTypography variant="h6" gutterBottom>
            <b>MANAGE ACTIVITY TEMPLATE</b>
          </BDebtTypography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={2}
              className={`${classes.gridStyle} ${classes.gridStyle1}`}
            >
              <Typography>
                <b>1ST PARTY</b>
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.gridStyle}>
              <Grid container spacing={1}>
                <Grid xs={3}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="1st party"
                      control={<Checkbox />}
                      label="READ ONLY"
                      className={classes.CheckBox}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={TemplateField}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Template Name" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              className={`${classes.gridStyle} ${classes.gridStyle1}`}
            >
              <Typography>
                <b>FLAT FEE</b>
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.gridStyle}>
              <Grid container spacing={0}>
                <Grid xs={3}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="1st party"
                      control={<Checkbox />}
                      label="READ ONLY"
                      className={classes.CheckBox}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={TemplateField}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Template Name" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              className={`${classes.gridStyle} ${classes.gridStyle1}`}
            >
              <Typography>
                <b>CONTINGENCY</b>
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.gridStyle}>
              <Grid container spacing={0}>
                <Grid xs={3}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="1st party"
                      control={<Checkbox />}
                      label="READ ONLY"
                      className={classes.CheckBox}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={TemplateField}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Template Name" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes.yesbutton}>
            UPDATE
          </Button>
          <Button onClick={onClose} className={classes.nobutton}>
            CANCEL
          </Button>
        </DialogActions>
      </BDebtDialog>
    </div>
  );
};

export default EditClientDetail;