import { Card, styled } from "@mui/material";

interface BDebtCardProps {
  borderRadius?: string;
  textAlign?: string; // Add textAlign property to BDebtCardProps
}

const BDebtCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "textAlign", // Exclude 'borderRadius' and 'textAlign' from being passed to the underlying DOM element
})<BDebtCardProps>(({ theme, borderRadius, textAlign }) => ({
  "&.MuiPaper-root": {
    backgroundColor: "white",
    boxShadow: theme.shadows[3],
    borderRadius: borderRadius || "0.8rem",
    width: "100%",
    textAlign: textAlign || "left", // Use the provided value or default to "left"
  },
}));

export default BDebtCard;
