/* eslint-disable @typescript-eslint/no-unused-vars */
import {IconButton, Tooltip, Grid} from '@material-ui/core';
import {DialogActions} from "@mui/material";
import {useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import PeopleIcon from '@mui/icons-material/People';
import BDebtButton from '../../../GlobalControl/BDebtButton';
import { IUser } from '../../../Model/IUser';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';

const Impersonate:React.FC<{data:IUser}> = (props:any) => {
    const {data} = props;
   const[open, setOpen] = useState(false); 

   const handleOpen = () => {
       setOpen(true);
   }

   const handleClose = () => {
       setOpen(false);
   }

       
   return (
    <div>
        <Tooltip title="IMPERSONATE" arrow onClick={handleOpen}>
            <IconButton size='small'><PeopleIcon style={{ color: "#3b4a54" }} /></IconButton>
        </Tooltip>
        <BDebtDialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
         <BDebtDialogTitleHeader onClose={handleClose} id="customized-dialog-title">
                Impersonate User
        </BDebtDialogTitleHeader>
         <DialogContent>
            <Grid container spacing={1}  style={{padding:"15px"}}>
                <Grid item xs={12}>
                "Are you certain that you want to impersonate {data.emailId} user?" 
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <BDebtButton variant='contained' color='primary' size="small">  yes </BDebtButton>
            <BDebtButton size="small" variant='outlined' color='primary' onClick={handleClose}>No</BDebtButton>
        </DialogActions>
        </BDebtDialog>
    </div>
)
}

export default Impersonate