import React from 'react';
//MUI COMPONENTS
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { BarChart } from '@mui/x-charts';
import { PieChart } from '@mui/x-charts/PieChart';
//CUSTOM CSS
import useStyles from './PreviewCss';
//INTERFACE
import { IPreviewTable } from '../../../Model/Diagnostics/IngressPreviewTable';
//JSON DATA
import IngressPreview from '../../../MockData/IngressPreview.json';
//COMPONENTS
import BDebtCard from '../../../GlobalControl/Card/BDebtCard';
import BDebtTypography from '../../../GlobalControl/BDebtTypography';
import { Pagination } from '@mui/material';
interface PreviewPageProps {
    open: boolean;
    onClose: () => void;
    rowData?: IPreviewTable;
}

function EnhancedTableHead() {
    const classes = useStyles();
    const headCells = [
        { id: "ClientCode", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: false },
        { id: "Phase", numeric: false, disablePadding: false, label: "PHASE", sortable: false },
        { id: "AccountId", numeric: false, disablePadding: false, label: "ACCOUNT ID", sortable: false },
        { id: "AgencyId", numeric: false, disablePadding: false, label: "AGENCY ID", sortable: false },
        { id: "Name", numeric: false, disablePadding: false, label: "NAME", sortable: false },
        { id: "AccountPlace", numeric: false, disablePadding: false, label: "ACCOUNT PLACE", sortable: false },
        { id: "TotalDue", numeric: false, disablePadding: false, label: "TOTAL DUE", sortable: false },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.tableCellHeader} key={headCell.id} align="left">
                        <b> {headCell.label || headCell.sortable}</b>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const PreviewPage: React.FC<PreviewPageProps> = ({ open, onClose, rowData }) => {
    const classes = useStyles();
    const pData = [10, 20, 90, 30, 60, 70, 40, 50, 80, 100, 20, 90];
    const xLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


    return (
        <Grid container spacing={1} padding={2}>
            <Grid item xs={4} display="flex" flexDirection="column">
                <BDebtCard borderRadius='5px'>
                    <Grid display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="row">
                        <Grid item xs={5} >
                            <BDebtTypography variant='body1'><b>Phase I</b></BDebtTypography>
                            <BDebtTypography variant='body2'>10K</BDebtTypography>
                        </Grid>
                        <Grid item xs={5}>
                            <SparkLineChart
                                data={[1, 4, 2, 5, 7, 2, 4, 6]}
                                xAxis={{
                                    scaleType: 'time',
                                    data: [
                                        new Date(2022, 5, 1),
                                        new Date(2022, 5, 2),
                                        new Date(2022, 5, 5),
                                        new Date(2022, 5, 6),
                                        new Date(2022, 5, 7),
                                        new Date(2022, 5, 8),
                                        new Date(2022, 5, 11),
                                        new Date(2022, 5, 12),
                                    ],
                                    valueFormatter: (value) => value.toISOString().slice(0, 10),
                                }}
                                height={50}
                                showTooltip
                                showHighlight
                            />
                        </Grid>
                    </Grid>
                </BDebtCard>

                <BDebtCard borderRadius='5px' style={{ marginTop: '10px' }}>
                    <Grid display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="row">
                        <Grid item xs={5} >
                            <BDebtTypography variant='body1' ><b>Phase II</b></BDebtTypography>
                            <BDebtTypography variant='body2'>8K</BDebtTypography>
                        </Grid>
                        <Grid item xs={5}>
                            <SparkLineChart
                                data={[1, 4, 2, 4, 6, 2, 5, 7,]}
                                xAxis={{
                                    scaleType: 'time',
                                    data: [
                                        new Date(2022, 5, 1),
                                        new Date(2022, 5, 2),
                                        new Date(2022, 5, 5),
                                        new Date(2022, 5, 6),
                                        new Date(2022, 5, 7),
                                        new Date(2022, 5, 8),
                                        new Date(2022, 5, 11),
                                        new Date(2022, 5, 12),
                                    ],
                                    valueFormatter: (value) => value.toISOString().slice(0, 10),
                                }}
                                height={50}
                                showTooltip
                                showHighlight
                            />
                        </Grid>
                    </Grid>
                </BDebtCard>

                <BDebtCard borderRadius='5px' style={{ marginTop: '10px' }}>
                    <Grid display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="row">
                        <Grid item xs={5}>
                            <BDebtTypography variant='body1' ><b>Phase III</b></BDebtTypography>
                            <BDebtTypography variant='body2'>15K</BDebtTypography>
                        </Grid>
                        <Grid item xs={5}>
                            <SparkLineChart
                                data={[7, 2, 4, 6, 1, 4, 2, 5]}
                                xAxis={{
                                    scaleType: 'time',
                                    data: [
                                        new Date(2022, 5, 1),
                                        new Date(2022, 5, 2),
                                        new Date(2022, 5, 5),
                                        new Date(2022, 5, 6),
                                        new Date(2022, 5, 7),
                                        new Date(2022, 5, 8),
                                        new Date(2022, 5, 11),
                                        new Date(2022, 5, 12)
                                    ],
                                    valueFormatter: (value) => value.toISOString().slice(0, 10),
                                }}
                                height={50}
                                showTooltip
                                showHighlight
                            />
                        </Grid>
                    </Grid>
                </BDebtCard>
            </Grid>

            <Grid item xs={4}>
                <BDebtCard borderRadius='5px'>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <BarChart
                            width={340}
                            height={170}
                            series={[{ data: pData, label: 'Records', id: 'pvId' }]}
                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                        />
                    </Grid>
                </BDebtCard>
            </Grid>

            <Grid item xs={4}>
                <BDebtCard borderRadius='5px'>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: 10, label: 'Phase I', color: '#357cd2' },
                                        { id: 1, value: 15, label: 'Phase II', color: '#e36593' },
                                        { id: 2, value: 20, label: 'Phase III', color: '#00bdae' }
                                    ],
                                },
                            ]}
                            width={340}
                            height={170}
                        />
                    </Grid>
                </BDebtCard>
            </Grid>

            <Grid item xs={12} >
                <BDebtCard borderRadius='5px'>
                    <TableContainer className={`${classes.tablebodyContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead />
                            <TableBody>
                                {IngressPreview.map((row) => (
                                    <TableRow >
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.ClientCode}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.Phase}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.AccountId}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.AgencyId}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.Name}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.AccountPlace}
                                        </TableCell>
                                        <TableCell className={classes.tableCellStyle}>
                                            {row?.TotalDue}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paper className={classes.pagination}>
                        <Pagination count={10} color="primary" size='small' />
                    </Paper>
                </BDebtCard>
            </Grid>
        </Grid>
    )
}

export default PreviewPage;