import { Grid } from "@material-ui/core";
import { useState } from "react";
import { DataSourceSidebar } from "./DataSourceSidebar";
import { DataSourceContent } from "./DataSourceConfiguration";
import useStyles from "../../WidgetCss";

const DataSourcePage = () => {
  const classes = useStyles();
  const [selectedDatabase, setSelectedDatabase] = useState("");

  const handleDatabaseChange = (database) => {
    setSelectedDatabase(database);
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2} className={classes.divStyle}>
        <DataSourceSidebar handleDatabaseChange={handleDatabaseChange} />
      </Grid>
      <Grid item xs={10} className={classes.divStyle}>
        <DataSourceContent selectedDatabase={selectedDatabase} />
      </Grid>
    </Grid>
  );
};

export default DataSourcePage;

