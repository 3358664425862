import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperInActive: {
      display: "flex",
      justifyContent: "center !important",
    },
    stepperActive: {
      display: "flex",
      justifyContent: "center",
    },
    stepper: {
      padding: "8px",
      width: "50%",
    },
    drawerPaper: {
      width: "400px", /* Set your preferred fixed width */
    },
    buttonStyle: {
      padding: "8px",
      display: "flex",
      justifyContent: "end",
      borderBottom: "1px solid lightgray",
    },
    padding: {
      padding: "20px 25px"
    },
    tableCellHeader: {
      padding: "8px 8px !important",
      fontSize: "12px !important",
      color: "white !important",
      background: "#3b4a54 !important",
      fontWeight: 'bold'
    },
    tableCellStyle: {
      padding: "12px 8px !important",
      backgroundColor: "#f9fafb inherit!important",
      "& .MuiTableCell-root": {
        padding: "10px 15px!important",
        backgroundColor: "#f9fafb inherit!important",
      },
      fontSize: "12px !important",
    },
    paperStyle: {
      overflow: "hidden",
      borderRadius: 0,
      borderBottomLeftRadius: 4,
    },

    RadioButton: {
      paddingLeft: '30px'
    },
    datagrid: {
      '& .MuiDataGrid-columnHeadersInner': {
        backgroundColor: 'rgb(59, 74, 84) !important',
        color: 'white !important',
      },
      '& .MuiDataGrid-footerContainer': {
        display: 'none', // Hide footer container
      },
      '& .MuiDataGrid-columnHeaders': {
        minHeight: "40px !important",
        maxHeight: "40px !important"
      },
    },
    draggableField: {
      cursor: "grab",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginTop: "10px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    div: {
      maxWidth: "100vw",
      height: "calc(92vh - 124px) !important",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      display: "flex",
      justifyContent: "center",
      padding: "15px",
    },
    finishDiv: {
      maxWidth: "170vw",
      height: "calc(100vh - 160px) !important",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      display: "flex",
      justifyContent: "center",
      padding: "20px",
    },
    root: {
      maxWidth: "750px",
      height: "auto !important",
      alignItems: "center",
      padding: "10px 15px",
      transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "#ffffff",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    },
    imageDisplay: {
      width: '100%',
      maxWidth: '100%',
      background: "linear-gradient(blue, 10%, pink) !important",
    },
    finishRoot: {
      maxWidth: "500px",
      height: "auto !important",
      alignItems: "center",
      padding: "10px 15px",
      transition: "boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "#ffffff",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "15px"
    },
    container: {
      padding: theme.spacing(3),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    textSpace: {
      marginTop: "5px",
    },
    textFiled: {
      marginTop: "5px",
      "& .MuiFormLabel-root": {
        fontSize: 13,
      },
    },
    iconstyle: {
      width: "34px",
      height: "34px",
    },
    Storageicon: {
      display: "flex !important",
      alignItems: "left !important",
      justifyContent: "left !important",
      padding: "21px !important",
    },
    button: {
      display: "flex !important",
      justifyContent: "flex-end !important",
      alignItem: "flex-end !important",
    },
    paperstyle: {
      padding: "36px !important",
      boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 18px !important",
    },
    UploadTextStyle: {
      textAlign: "center",
      marginTop: "5px",
      padding: "8px 0px",
      background: "#3b4a54",
      color: "white",
      fontSize: 14,
      borderRadius: "5px",
    },
    formControl: {
      marginTop: "4px !impotant",
      marginLeft: "0px !important",
      marginRight: "0px !important",
      innerHeight: "34px !important",
      borderRadius: "5px !important",
      border: '2px solid #2a3944 !important',
      padding: '0px 5px'
    },
    outlinedInput: {
      fontSize: "12px !important",
      color: "black !important",
      marginTop: "-2px !important",
      "&$focused $notchedOutline": {
        color: "white !important",
        border: "0px !important",
      },
    },
    notchedOutline: {
      color: "white !important",
      border: "none !important",
      fontSize: "12px !important",
    },
    dropdownPaper: {
      border: "1px solid lightgray",
      fontSize: "12px !important",
      marginTop: "0px",
    },
    autoCompleteFont: {
      fontSize: "12px !important",
      padding: "3px 12px !important",
    },
    dropDownStyle: {
      marginTop: "2px !important",
      borderRadius: 5,
      border: "2px solid #215176 !important",
      backgroundColor: "white !important",
      height: "35px !important",
      fontSize: "12px !important",
      "& .MuiInputBase-input": {
        fontSize: "12px !important",
      },
    },
    dropDownInput: {
      fontSize: "13px !important",
      color: "black",
      padding: "5px 30px 5px 10px !important",
      "&$focused $notchedOutline": {
        color: "white",
        border: "none",
      },
      "& .MuiInputBase-input": {
        fontSize: "12px !important",
      },
    },
    pageContainer: {
      boxShadow:
        " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px -1px !important",
    },
    autoCompleteTop: {
      marginTop: "5px",
    },
    listActive: {
      // padding: "6px 10px !important",
      color: "rgb(0, 127, 255) !important",
      borderTopLeftRadius: "5px !important",
      borderBottomLeftRadius: "5px !important",
      fontWeight: "bolder",
      borderRight: "4px solid rgb(0, 127, 255) !important",
      boxShadow:
        " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
    },
    listNonActive: {
      // padding: "6px 10px !important",
      borderRight: "1px solid lightgray",
    },
    // Column Mapping Page
    fieldContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 170px) !important",
        minHeight: "calc( 100vh - 170px) !important",
      },
    },
    fieldHeader: {
      padding: "9px !important",
      fontSize: "12px !important",
      color: "white !important",
      fontWeight: "bold",
      backgroundColor: "#3b4a54 !important",
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
    },
    fontStyle: {
      marginTop: "7px !important",
    },
    ruleIconStyle: {
      fontSize: "17px !important",
      display: "flex",
      justifyContent: "center",
      color: "black",
      fontWeight: "bold",
    },
    ruleIcon: {
      color: "white",
      fontWeight: "bold",
      padding: "0px 15px !important",
    },
    functionIcon: {
      marginTop: "10px !important",
    },
    dataRowContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 244px) !important",
        minHeight: "calc( 100vh - 244px) !important",
      },
    },
    perviewContainer: {
      WebkitScrollSnapPointsX: "5px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc(100vh - 180px) !important",
        minHeight: "calc(100vh - 180px) !important",
      },
      overflowY: "auto",
    },

    errorperviewContainer: {
      maxHeight: '660px !important',
      minHeight: '660px !important',
      WebkitScrollSnapPointsX: "5px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 170px) !important",
        minHeight: "calc( 100vh - 217px) !important",
      }
    },
    perviewrootContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      WebkitScrollSnapPointsX: "5px",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc(100vh - 280px) !important",
        minHeight: "calc(100vh - 280px) !important",
      },
      overflowY: "auto",
    },
    nextButton: {
      fontWeight: "bold",
      color: "#3b4a54 !important",
    },
    divStyle: {
      marginTop: "5px",
    },
    snackbarStyle: {
      color: "white !important",
      backgroundColor: "Green !important",
    },
    autoCompletePaperStyle: {
      maxHeight: "200px !important",
      overflowY: "auto",
    },
    dialogAction: {
      borderTop: "1px dashed lightgrey !important",
    },
    resultContainer: {
      backgroundColor: "#ffffff",
      padding: "0px 10px",
      borderRadius: "5px",
      overflowY: "auto",
      overflowX: "hidden",
      filter: "drop-shadow(0 -6mm 4mm gray))",
    },
    finishTextStyle: {
      textAlign: "center",
      padding: "5px 0px",
      backgroundColor: "rgba(16, 185, 129, 0.12)",
      color: "rgb(11, 129, 90)",
      fontSize: 12,
    },
    inputSelectField: {
      fontSize: "12px !important",
      fontWeight: "bold",
      color: "black",
    },
    appbarPosition: {
      backgroundColor: "white !important",
      maxHeight: "45px !important",
      padding: "0px !important",
      alignItem:"center !important",
      justifyContent:"center !important"
    },
    tabRecords: {
      minHeight: "30px !important",
      fontSize: "12px !important",
      fontWeight: "bold",
    },
    tabList: {
      minHeight: "30px !important",
      padding: "0px !important",
      backgroundColor: '#eef2f6 !important',
      "& .Mui-selected": {
        backgroundColor: "white !important",
        color: "#3b4a54 !important",
        font: 'bold'
      },
    },
    displayGrid: {
      width: "100% !important",
      display: "flex !important",
      justifyContent: "space-around !important",
      padding: "4px !important",
    },
    uploadbuttonStyle: {
      padding: "2px !important",
    },
    manualdataRowContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 245px) !important",
        minHeight: "calc( 100vh - 245px) !important",
      },
    },
    gridStyle: {
      padding: "3px",
      border: "1px solid lightgrey",
    },
    headButton: {
      padding: "4px",
      color: "white !important",
      backgroundColor: "#3b4a54",
      textTransform: "none",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
    },
    ruleGroupHeader: {
      background: "#1976D2",
      padding: "3px",
      marginBottom: "8px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      borderRadius: "8px",
      color: "white"
    },
    selectedRulesContainer: {
      flexWrap: "wrap",
      padding: "8px",
      marginBottom: "8px",
      cursor: "pointer",
      minWidth: 150,
      marginTop: "10px !important"
    },
    rulehead: {
      marginRight: "8px",
    },
    ruleDisplay: {
      marginLeft: "10px",
    },
    ruleNameStyle: {
      display: "flex",
      alignItems: "center",
    },
    cancelIcon: {
      marginLeft: "8px",
      cursor: "pointer",
    },
    dialogroot: {
      padding: "0",
      '&:first-child': {
        padding: 0,
      },
    },
    selectedRule: {
      backgroundColor: "white",
    },
    datarulegridDisplay: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editIcon: {
      color: "green"
    },
    deleteIcon: {
      color: "red"
    },
    datagridstyle: {
      backgroundColor: "white !important",
      height: "550px !important"
    },
    customHeader: {
      backgroundColor: "rgba(0, 0, 0, 0.87) !important",
      color: "white !important"
    },
    DividerStyle: {
      width: '1px !important',
      background: 'lightgray !important',
      marginTop: '12px !important'
    },
    ColumnFieldGrid: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc(100vh - 245px) !important',
        minHeight: 'calc(100vh - 245px) !important',
      },
      '& .MuiDataGrid-columnHeadersInner': {
        backgroundColor: 'rgb(59, 74, 84) !important',
        color: 'white !important',
      },
      '& .MuiDataGrid-columnHeaders': {
        minHeight: "40px !important",
        maxHeight: "40px !important"
      },
      "& .css-i4bv87-MuiSvgIcon-root": {
        fontSize: "medium !important",
      },
      "&.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel": {
        marginTop: "14px !important",
      },
    },
    ErrorRecordGridStyle: {
      fontSize: '15px !important',
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 150px) !important',
        minHeight: 'calc( 100vh - 150px) !important',
      }
    },
    outputStyle: {
      marginTop: "10px"
    },
    tablebodyConainer: {
      borderRadius: 0,
      maxHeight: 400,
      overflow: 'auto',
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 175px)",
        minHeight: "calc( 100vh - 255px)",
      },
    },


    ErrorRecordContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 185px) !important",
        minHeight: "calc( 100vh - 185px) !important",
      },
    },
    acceptedPreviewContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 185px) !important",
        minHeight: "calc( 100vh - 185px) !important",
      },
    },
    ErrorPreviewContainer: {
      maxHeight: "660px !important",
      minHeight: "660px !important",
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 185px) !important",
        minHeight: "calc( 100vh - 185px) !important",
      },
    },
    GridFontStyle: {
      "&.css-1tpca60-MuiDataGrid-root .MuiDataGrid-cellContent": {
        fontSize: "12px !important",
      },
      '& .MuiDataGrid-columnHeadersInner': {
        backgroundColor: 'rgb(59, 74, 84) !important',
        color: 'white !important',
      },
      '& .MuiDataGrid-columnHeaders': {
        minHeight: "40px !important",
        maxHeight: "40px !important"
      },
    },
    GridFontSize: {
      "&.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cellContent": {
        fontSize: "12px !important",
        // color: "white !important",
      },
      '& .MuiDataGrid-columnHeadersInner': {
        backgroundColor: 'rgb(59, 74, 84) !important',
        color: 'white !important',
      },
      '& .MuiDataGrid-columnHeaders': {
        minHeight: "40px !important",
        maxHeight: "40px !important"
      },
    },
    filtercolumnStyle: {
      fontSize: "12px !important",
      padding: "4px !important",
    },
    recordStyle: {
      color: 'black',
      fontSize: "14px"
    },
    highlightedrow: {
      backgroundColor: "#e6f7ff", /* or any other blue color */
    },
    DatabaseIcon: {
      cursor: 'pointer !important',
      filter: 'brightness(0) invert(1) !important',
      color: 'black !important'
    },
    infoStyle: {
      display: 'flex !important',
      alignItems: 'center !important',
      marginTop: "15px !important"
    },
    infoOutlinedIcon: {
      color: 'red !important',
      marginRight: '8px !important'
    },
    customAccordion: {
      marginTop: '8px',
      top: '0px !important',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      borderRadius: '0px !important',
    },
    accordionDetails: {
      padding: '1px !important',
      border: '2px solid green !important'
    },
    datasetActiveAccordion: {
      backgroundColor: "honeydew !important",
      border: "2px solid green !important",
      borderBottom: 'none !important',
      borderRadius: "0px !important",
      marginTop: "0px !important"
    },
    datasetInActiveAccordion: {
      backgroundColor: "inherit !important",
      border: "2px solid transparent !important",
      borderBottom: 'none !important',
      borderRadius: '0px !imporatnt',
      marginTop: '0px !important'
    },
    bordercolor: {
      border: "none!important",
    },
    cardActive: {
      cursor: "pointer !important",
      backgroundColor: "white !important",
      border: "white !important",
      borderRadius: '0px !important',
    },
    cardInActive: {
      cursor: "pointer !important",
      backgroundColor: "honeydew !important",
      border: "2px solid green !important",
      borderRadius: "4px !important",
    },
    cardRequired: {
      backgroundColor: " #ffe6e6 !important",
      border: "2px solid #ff6666 !important",
    },
    MessageActive: {
      "&.css-de9k3v-MuiDataGrid-selectedRowCount": {
        visibility: "hidden",
        width: "min-content !important",
        height: "min-content !important"
      },
      color: "red !important",
      fontSize: "12px !important",
      padding: "4px",
    },

    MessageInActive: {
      color: "inherit !important",
      fontSize: "12px !important",
      padding: "4px !important",
    },
    fixedSide: {
      width: '300px', // You can adjust the width as needed
    },
    GridStyle: {
      padding: "0px 0px 0px 20px !important",
    },
    AddIconStyle: {
      width: "35px !important",
      height: "35px !important"
    },
    placementIcon: {
      color: "#3b4a54 !important",
      fontWeight: "bold"
    },
    selectedCardColumn: {
      backgroundColor: "honeydew",
      border: "1px solid green",
      borderRadius: "2px",
      width: "200px"
    },
    //RecordCombineView.tsx
    TableCellStyle: {
      width: "33%"
    },
    TableCellStyle1: {
      width: "26%"
    },
    TableCellStyle2: {
      width: "8%"
    },
    IconButton: {
      color: '#3a4b54',
      borderRadius: "5px !important",

      border: '2px solid #2a3944 !important',
      "&:hover": {
        backgroundColor: '#2a3944 !important',
        color: 'white'
      }
    },
    IconButtonDialog: {
      color: '#3a4b54',
      borderRadius: "5px !important",
      marginTop: '5px',
      marginLeft: '3px',
      border: '2px solid #2a3944 !important',
      "&:hover": {
        backgroundColor: '#2a3944 !important',
        color: 'white'
      }
    },
    JoinIconButton: {
      color: 'black',
      borderRadius: "5px !important",
      backgroundColor: "white !important",
      border: '2px solid #3b4a54 !important',
      "&:hover": {
        backgroundColor: 'rgb(233,64,87) !important',
        color: 'white'
      }
    },
    searchBox: {
      marginTop: '2px !important',
      borderRadius: "5px !important",
      border: '2px solid #215176 !important',
      height: '35px !important',
      textIndent: '10px !important',
      fontSize: "12px !important",
      '& .MuiInputBase-root': {
        fontSize: "12px !important"
      }
    },
    combineGridContainer: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 270px) !important",
        minHeight: "calc( 100vh - 270px) !important",
      },
    },
    indicator: {
      backgroundColor: "#3b4a54 !important", // Set the desired color for the tab indicator
      height: '4px !important'
    },
    pinIconStyle: {
      width: "15px !important",
      height: "15px important",
      color: "white",
      marginTop: "9px"
    },
    UnpinIconStyle: {
      width: "14px !important",
      marginLeft: "3px !important",
      color: "white",
      marginTop: "9px"
    },
    contentStyle: {
      textAlign: "center",
      marginTop: "6rem !important",
      // marginBottom: "1rem !important",
    },
    placementDisableIcon: {
      color: "lightgray",
      fontWeight: "bold"
    },
    infoFontSize: {
      fontSize: 13,
      // fontWeight:'bold'
    },
    infoStatus: {
      backgroundColor: 'rgb(237, 247, 237)',
      color: 'green',
      borderRadius: 5,
      padding: '4px 6px',
      fontSize: 10
    },
    infoFontSize1: {
      fontSize: 13,
      marginTop: '5px',
      // fontWeight:'bold'
    },
    FontStyle: {
      fontSize: "20px !important",
      color: "darkgreen"
    },
    processedData: {
      display: "flex",
      justifyContent: "center",
      color: "green",
      fontSize: "16px !important",
      background: "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
      borderRadius: "4px",
      fontWeight: "bold"
    },
    paperContainer: {
      boxShadow: " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important",
      marginTop: "7px",
      width: "100%",
      borderRadius: "7px"
    },
    submitedData: {
      fontSize: "25px !important",
      color: "darkgreen"
    },
    ThumbUpIconStyle: {
      fontSize: "17px !important",
      color: "white"
    },
    pinnedstyle: {
      backgroundColor: "rgb(233,64,87) !important",
    },

    finishButtonStyle: {
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
      backgroundColor: "rgb(233,64,87) !important",
    },
    selectedRuleItem: {
      border: "2px solid green",
      borderRadius: "10px",
      padding: "5px",
      margin: "5px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "honeydew !important",
      justifyContent: "space-between",
      color: "black",
      marginLeft: "50px"
    },
    socialIcons: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: "5px",
      paddingTop: "5px",
    },
    facebookStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    microsoftStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    twitterStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    googleStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    linkedinStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    appleStyle: {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    socialIconStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly !important",
      fontSize: "1.4rem",
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      cursor: "pointer",
      marginBottom: "10px",
      boxShadow: "0 5px 4px rgba(0, 0, 0, 0.5)",
    },
    image: {
      width: "18px",
      height: "18px"
    },
    imageStyle: {
      fontWeight: "bold",
      color: "white !important",
    },
    iconRotateStyle: {
      transform: 'rotate(180deg) !important'
    },
    isRequired: {
      color: "red !important"
    },
    previewDataStyle: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important',
      flex: 1
    },
    outOfBoxStyle: {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'center !important',
      border: '1px solid #215176 !important',
      padding: '4px',
      borderRadius: "5px !important",
      width: "100px"
    },
    highlightedBorder: {
      borderColor: "green",/* Define your desired border color */
    },
    isRequiredFiledStyle: {
      borderColor: 'green !important',
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      borderRadius: "5px !important",
      backgroundColor: "honeydew !important"
    },
    gridSearchbar: {
      borderBottom: '1px solid lightgray !important',
      backgroundColor: "#3b4a54",
      color: "white"
    },
    stickyHeader: {
      position: "sticky",
      top: "0",
      zIndex: 1000,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      borderRadius: '2px !important',
      backgroundColor: "#D1FFBD !important",
    },
    stickyHeaderStyle: {
      position: "sticky",
      top: "0",
      zIndex: 1000,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      borderRadius: '2px !important',
      backgroundColor: "#D1FFBD !important",
      padding: "7px !important"
    },
    colorStyle: {
      color: 'white !important',
    },
    borderGridStyle: {
      borderRight: "2px solid gray !important"
    },
    TabPanelStyle: {
      "&.css-66wgl5-MuiTabPanel-root": {
        padding: "0px"
      }
    },
    pgColor: {
      backgroundColor: "#f0f0f0 !important",
    },
    paginationArow: {
      marginTop: "10px !important",
    },
    pageIconStyle: {
      fontSize: "20px !important",
    },
    customSelect: {
      padding: "5px 27px 5px 2px !important",
      left: "12px !important",
      border: "2px solid #3b4a54 !important",
      borderRadius: "5px !important",
    },
    viewStyle:{
      padding: '15px !important', 
      color: 'black !important'
    },
    pageStyle:{
      marginRight: '10px !important'
    },
    errorMessageStyle:{
      textAlign: 'center', 
      margin: 'auto', 
      paddingTop: '50px !important' 
    },
    errorMessageStyle1:{
      textAlign: 'center', 
      margin: 'auto', 
      paddingTop: '25px !important' 
    },
    fieldContainerHistory: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "calc( 100vh - 520px) !important",
        minHeight: "calc( 100vh - 520px) !important",
      },
    },
  })
);

export default useStyles;