import { IconButton, TextField } from "@material-ui/core";
import { Autocomplete, Box, Grid} from "@mui/material";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
// Icons
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
// Css
import useStyles from "../PlacementCss";
// Modules
import PlacementRules from "./PlacementRules";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";

const FieldCard: React.FC<{ title: string; mappedField: string; required: boolean; yourFieldOptions: string[]; isFetched: boolean; onAutocompleteChange: (newValue: string, title: string) => void;
onCardClick: (selectedCard: { title: string; mappedField: string }) => void; selected: boolean;
  }> = (props) => {
  const classes = useStyles();
  const { title, mappedField, required, yourFieldOptions, isFetched, selected } = props;
  const isUnmapped = mappedField === "" || mappedField === null;
  const handleCardClick = () => {
    // Toggle the selected state on click
    props.onCardClick({ title, mappedField });
  };

  let cardClassName = classes.cardActive;
  if (required && isUnmapped) {
    cardClassName = classes.cardRequired;
  } else if (selected ) {
    cardClassName = classes.cardInActive;
  }

  return (
    <Box p={0} m={1}>
      <BDebtCard borderRadius="5px" onClick={handleCardClick} className={cardClassName}>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={5}>
            <Grid container spacing={0}>
              <Grid item xs={10} className={classes.fontStyle}>
                <BDebtTypography variant="body2">
                  {title}
                </BDebtTypography>
              </Grid>
              <Grid item xs={2}>
                <ArrowRightAltIcon fontSize="small" color="inherit" className={classes.fontStyle} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              disablePortal
              size="small"
              id={`combo-box-demo-${title}`}
              options={yourFieldOptions}
              value={mappedField}
              getOptionLabel={(option) => option}
              fullWidth
              classes={{
                paper: classes.dropdownPaper,
                option: classes.autoCompleteFont,
              }}
              onChange={(event, newValue) => {
                props?.onAutocompleteChange(newValue || "", title);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Choose Field"
                  className={classes.dropDownStyle}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    classes: {
                      root: classes.dropDownInput,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={0}>
              <Grid item xs={8}>
             
                <BDebtTooltip title={title} arrow>
                  <PlacementRules />
                </BDebtTooltip>
              </Grid>
              <Grid item xs={4} className={classes.functionIcon}>
                {isFetched ? (
                  isUnmapped ? (
                    required ? (
                      <BDebtTooltip title="Required Field">
                      <ErrorOutlineRoundedIcon color="error" fontSize="small" />
                      </BDebtTooltip> 
                    ) : (
                      <ErrorOutlineRoundedIcon color="disabled" fontSize="small" /> 
                    )
                  ) : (
                    <CheckCircleOutlineRoundedIcon color="success" fontSize="small" /> 
                  )
                ) : (
                  <IconButton  size="small" disabled>
                  <ErrorOutlineRoundedIcon color="error" fontSize="small" /> 
                  </IconButton>
                )}
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </BDebtCard>
    </Box>
  );
};

export default FieldCard

