import { Button, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import { Box, Grid, Paper, IconButton } from '@mui/material';
import TableCell from "@mui/material/TableCell";
import * as React from 'react';
import DebtorData from "../../../MockData/DebtorData.json";
import { IDebtTableRow } from "../../../Model/Debtors/IDebtTableRow";
import useStyles from '../../Inquiry/DebtorsCss';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from "react";

function EnhancedTableHead() {
    const classes = useStyles();
    const headCells = [
        {
            id: "",
            numeric: false,
            disablePadding: false,
            label: "",
            sortable: false,
        },
        {
            id: "clientCode",
            numeric: false,
            disablePadding: false,
            label: "CLIENT CODE",
            sortable: false,
        },
        {
            id: "accountId",
            numeric: false,
            disablePadding: false,
            label: "ACCOUNT ID",
            sortable: false,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "STATUS",
            sortable: false,
        },
        {
            id: "deliquencyData",
            numeric: false,
            disablePadding: false,
            label: "DELIQUENCY DATE",
            sortable: false,
        },
        {
            id: "collect",
            numeric: false,
            disablePadding: false,
            label: "COLLECT",
            sortable: false,
        },
        {
            id: "remove",
            numeric: false,
            disablePadding: false,
            label: "REMOVE",
            sortable: false,
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className={classes.tableCellHeader} key={headCell.id} align="left">
                        <b> {headCell.label || headCell.sortable}</b>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const CollectDetails: React.FC<{ dataRow?: IDebtTableRow, activityEnable, onLoadData: (rowData: IDebtTableRow) => void }> = (props) => {
    const classes = useStyles();
    const { dataRow, onLoadData } = props;
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isCollectRemove, setIsCollectRemove] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [isCollectDisable, setIsCollectDisable] = useState(false);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickRemove = () => {
        setIsCollectRemove(true);
    }

    const handleClickCollect = () => {
        setIsCollectDisable(true);
    }

    const handleClickRedo = () => {
        setIsCollectDisable(false);
    }
    const handleRowClick = (index: number, row: IDebtTableRow) => {
        setSelectedRowIndex(index);
        onLoadData(row);
    };
    return (
        <Box p={0}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                <Paper className={`global-paper-style ${classes.paperStyle}`}>
                        <TableContainer className={`${classes.tablebodyConainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table>
                                <EnhancedTableHead />
                                <TableBody>
                                    {DebtorData.map((row: any, index: number) => (
                                           <TableRow
                                           key={index}
                                           onClick={() => handleRowClick(index, row)}
                                           className={selectedRowIndex === index ? classes.activeRow : ''}
                                       >
                                            <TableCell className={dataRow?.AccountId ? classes.selectedRow : classes.tableCellStyle} >
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Typography variant="caption" className={isCollectRemove ? classes.removeText : null}>{row?.ClientCode}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Typography variant="caption" className={isCollectRemove ? classes.removeText : null}>{row?.AccountId}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Typography variant="caption" className={isCollectRemove ? classes.removeText : null}>{row?.Status}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Typography variant="caption" className={isCollectRemove ? classes.removeText : null}>{row?.DelinquencyDate}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <Button variant="contained" size="small" color="primary" style={{ fontSize: 10 }} onClick={handleClickCollect} disabled={isCollectDisable}>collect</Button>
                                                {isCollectDisable ?
                                                    <IconButton size="small" sx={{ padding: 0 }} onClick={handleClickRedo}>
                                                        <ReplayIcon color="primary" />
                                                    </IconButton>
                                                    : null
                                                } 
                                            </TableCell>
                                            <TableCell className={classes.tableCellStyle}>
                                                <IconButton size="small" sx={{ padding: 0 }} onClick={handleClickRemove}>
                                                    <DeleteOutlineIcon color="error" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={100}
                            page={page}
                            size="small"
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    );
}

export default CollectDetails
