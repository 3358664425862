/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MapAjax } from "@syncfusion/ej2-maps";
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTheme,
  AccumulationTooltip,
  Category,
  ChartComponent,
  ChartTheme,
  ColumnSeries,
  DataLabel,
  DateTime,
  IAccLoadedEventArgs,
  ILoadedEventArgs,
  Inject,
  Legend,
  PieSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineAreaSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import {
  LayerDirective,
  LayersDirective,
  MapsComponent,
  Legend as MapsLegend,
  MapsTheme,
  MapsTooltip,
  Marker,
  MarkerDirective,
  MarkersDirective,
} from "@syncfusion/ej2-react-maps";
import {
  ActiveAccounts,
  ActiveUsers,
  AgentUserCall,
  AgentUserEmail,
  AgentUserLetters,
  AzureFunctions,
  Goal,
  Invoices,
  Jobs,
  LoggedInUsers,
  Notifications,
  Revenue,
  TotalPaidUsers,
  Visitors,
  WidgetsConfiguration,
} from "./Components/WidgetsConfiguration/WidgetsConfiguration";
import "./analytics.css";
import * as data from "./default-datasource.json";
import useStyles from "./Components/CustomForm/TemplateCss";
import Pie from "./MockData/Pie.json";
import PieChart from "./MockData/PieChart.json";
import BarChart from "./MockData/BarChart.json";
import { Box } from "@mui/material";

export let data1: any[] = [
  { x: new Date(2002, 0, 1), y: 2.2 },
  { x: new Date(2003, 0, 1), y: 3.4 },
  { x: new Date(2004, 0, 1), y: 2.8 },
  { x: new Date(2005, 0, 1), y: 1.6 },
  { x: new Date(2006, 0, 1), y: 2.3 },
  { x: new Date(2007, 0, 1), y: 2.5 },
  { x: new Date(2008, 0, 1), y: 2.9 },
  { x: new Date(2009, 0, 1), y: 3.8 },
  { x: new Date(2010, 0, 1), y: 1.4 },
  { x: new Date(2011, 0, 1), y: 3.1 },
];
export let data2: any[] = [
  { x: new Date(2002, 0, 1), y: 2 },
  { x: new Date(2003, 0, 1), y: 1.7 },
  { x: new Date(2004, 0, 1), y: 1.8 },
  { x: new Date(2005, 0, 1), y: 2.1 },
  { x: new Date(2006, 0, 1), y: 2.3 },
  { x: new Date(2007, 0, 1), y: 1.7 },
  { x: new Date(2008, 0, 1), y: 1.5 },
  { x: new Date(2009, 0, 1), y: 2.8 },
  { x: new Date(2010, 0, 1), y: 1.5 },
  { x: new Date(2011, 0, 1), y: 2.3 },
];
let datasource: any = data as any;
let markers: object[] = [
  { name: "Asia", latitude: 50.32087157990324, longitude: 90.015625 },
  { name: "Australia", latitude: -25.88583769986199, longitude: 134.296875 },
  { name: "Africa", latitude: 16.97274101999902, longitude: 16.390625 },
  {
    name: "Europe",
    latitude: 49.95121990866204,
    longitude: 18.468749999999998,
  },
  {
    name: "North America",
    latitude: 59.88893689676585,
    longitude: -109.3359375,
  },
  {
    name: "South America",
    latitude: -6.64607562172573,
    longitude: -55.54687499999999,
  },
];

const DashboardLayout = () => {
  const classes = useStyles();

  const load = (args: IAccLoadedEventArgs): void => {
    let selectedTheme: string = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark") as AccumulationTheme;
  };
  const Chartload = (args: ILoadedEventArgs): void => {
    let selectedTheme: string = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark") as ChartTheme;

    if (selectedTheme === "highcontrast") {
      if (args.chart.series[0]?.marker?.dataLabel) {
        args.chart.series[0].marker.dataLabel.fill = "#000000";
      }
      if (args.chart.series[1]?.marker?.dataLabel) {
        args.chart.series[1].marker.dataLabel.fill = "#000000";
      }
    }
  };

  const Mapload = (args: any): void => {
    let selectedTheme: string = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.maps.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/contrast/i, "Contrast")
      .replace(/-dark/i, "Dark") as MapsTheme;
  };

  const cellSpacing: number[] = [5, 5];

  const card1 = () => {
    return <WidgetsConfiguration />;
  };

  const card2 = () => {
    return <ActiveUsers />;
  };

  const card3 = () => {
    return <ActiveAccounts />;
  };

  const card4 = () => {
    return <Visitors />;
  };

  const card5 = () => {
    return <Revenue />;
  };

  const card6 = () => {
    return <LoggedInUsers />;
  };

  const card7 = () => {
    return <Goal />;
  };

  const card8 = () => {
    return <Invoices />;
  };

  const card9 = () => {
    return <TotalPaidUsers />;
  };

  const card10 = () => {
    return <Jobs />;
  };

  const card11 = () => {
    return <AzureFunctions />;
  };

  const card12 = () => {
    return <Notifications />;
  };

  const card13 = () => {
    return <AgentUserCall />;
  };

  const card14 = () => {
    return <AgentUserEmail />;
  };

  const card15 = () => {
    return <AgentUserLetters />;
  };

  const map = () => {
    const mapData: any[] = [
      { latitude: 37.6276571, longitude: -122.4276688, name: "San Bruno" },
      { latitude: 33.5302186, longitude: -117.7418381, name: "Laguna Niguel" },
      { latitude: 40.7424509, longitude: -74.0081468, name: "New York" },
      { latitude: -23.5268201, longitude: -46.6489927, name: "Bom Retiro" },
      { latitude: 43.6533855, longitude: -79.3729994, name: "Toronto" },
      { latitude: 48.8773406, longitude: 2.3299627, name: "Paris" },
      { latitude: 52.4643089, longitude: 13.4107368, name: "Berlin" },
      { latitude: 19.1555762, longitude: 72.8849595, name: "Mumbai" },
      { latitude: 35.6628744, longitude: 139.7345469, name: "Minato" },
      { latitude: 51.5326602, longitude: -0.1262422, name: "London" },
    ];
    return (
      <div className={classes.VisitorsChart}>
        <MapsComponent
          id="maps"
          load={Mapload.bind(this)}
          zoomSettings={{ enable: false }}
          legendSettings={{ visible: false }}
        >
          <Inject services={[Marker, MapsLegend, MapsTooltip]} />
          <LayersDirective>
            <LayerDirective
              shapeData={new MapAjax("./src/dashboard-layout/world-map.json")}
              shapePropertyPath="continent"
              shapeDataPath="continent"
              dataSource={datasource.default}
              shapeSettings={{ colorValuePath: "color" }}
            >
              <MarkersDirective>
                <MarkerDirective
                  visible={true}
                  template='<div style="font-size: 12px;color:white;text-shadow: 0px 1px 1px black;font-weight: 500;width:50px">{{:name}}</div>'
                  animationDuration={0}
                  dataSource={markers}
                ></MarkerDirective>
                <MarkerDirective
                  visible={true}
                  shape="Image"
                  imageUrl="./src/dashboard-layout/ballon.png"
                  height={20}
                  width={20}
                  offset={{ y: -10, x: 0 }}
                  animationDuration={0}
                  tooltipSettings={{ visible: true, valuePath: "name" }}
                  dataSource={mapData}
                ></MarkerDirective>
              </MarkersDirective>
            </LayerDirective>
          </LayersDirective>
        </MapsComponent>
      </div>
    );
  };

  const pie = () => {
    return (
      <div className={classes.PieDivStyle}>
        <div style={{ flex: 1 }}>
          <AccumulationChartComponent
            id="pie"
            legendSettings={{ visible: false }}
            enableSmartLabels={true}
            height="100%"
            width="100%"
            enableAnimation={false}
            selectionMode={"Point"}
            center={{ x: "50%", y: "50%" }}
            tooltip={{
              enable: false,
              header: "<b>${point.x}</b>",
              format: "Composition : <b>${point.y}%</b>",
            }}
            load={load.bind(this)}
          >
            <Inject
              services={[
                AccumulationLegend,
                PieSeries,
                AccumulationTooltip,
                AccumulationDataLabel,
              ]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={PieChart}
                name="Revenue"
                xName="Device"
                yName="Amount"
                explode={false}
                dataLabel={{
                  visible: true,
                  position: "Inside",
                  name: "text",
                  font: { fontWeight: "600" },
                }}
                radius="100%"
                innerRadius="35%"
                palettes={["#357cd2", "#00bdae", "#e36593"]}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
        <div className={classes.divStyles}>
          <div className={classes.Eventually}>
            <span className={classes.EventuallySpanStyle}></span>
            Eventually
          </div>
          <div className={classes.divChart}>
            <span className={classes.InitiatorSpanStyle}></span>
            Initiator
          </div>
          <div className={classes.divChart}>
            <span className={classes.SetFeeSpanStyle}></span>
            Set Fee
          </div>
        </div>
      </div>
    );
  };

  const visitorsChart = () => {
    return (
      <div className={classes.VisitorsChart}>
        <ChartComponent
          id="visitorsChart"
          style={{ textAlign: "center" }}
          load={Chartload.bind(this)}
          legendSettings={{ visible: false }}
          primaryXAxis={{
            valueType: "DateTime",
            labelFormat: "y",
            majorGridLines: { width: 0 },
            intervalType: "Years",
            edgeLabelPlacement: "Shift",
          }}
          primaryYAxis={{
            labelFormat: "{value}%",
            lineStyle: { width: 0 },
            maximum: 4,
            interval: 1,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          chartArea={{ border: { width: 0 } }}
        >
          <Inject services={[SplineAreaSeries, DateTime, Legend]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={data1}
              xName="x"
              yName="y"
              name="Jan"
              opacity={0.5}
              type="SplineArea"
              width={2}
              fill="rgb(239, 183, 202)"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={data2}
              xName="x"
              yName="y"
              name="Feb"
              opacity={0.5}
              type="SplineArea"
              width={2}
              fill="rgb(14, 64, 152, .6)"
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    );
  };

  const pieChart = () => {
    return (
      <div className={classes.ChartContainer}>
        <AccumulationChartComponent
          id="pieChart"
          legendSettings={{ visible: false }}
          enableSmartLabels={true}
          height="100%"
          width="100%"
          enableAnimation={false}
          selectionMode={"Point"}
          center={{ x: "50%", y: "50%" }}
          tooltip={{
            enable: false,
            header: "<b>${point.x}</b>",
            format: "Composition : <b>${point.y}%</b>",
          }}
          load={load.bind(this)}
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationTooltip,
              AccumulationDataLabel,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={Pie}
              xName="x"
              yName="y"
              explode={true}
              explodeIndex={2}
              explodeOffset={"10%"}
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: { fontWeight: "600" },
              }}
              radius="100%"
            ></AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>

        </AccumulationChartComponent>
        <div className={classes.divStyles}>
          <div className={classes.Eventually}>
            <span className={classes.EventuallySpanStyle}></span>
            Eventually
          </div>
          <div className={classes.divChart}>
            <span className={classes.InitiatorSpanStyle}></span>
            Initiator
          </div>
          <div className={classes.divChart}>
            <span className={classes.SetFeeSpanStyle}></span>
            Set Fee
          </div>
        </div>
      </div>
    );
  };

  const colChart = () => {
    return (
      <div className={classes.ChartContainer}>
        <ChartComponent
          id="colChart"
          className={classes.ChartComponent}
          load={Chartload.bind(this)}
          legendSettings={{ visible: false }}
          primaryXAxis={{
            valueType: "Category",
            interval: 1,
            majorGridLines: { width: 0 },
          }}
          primaryYAxis={{
            majorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            labelStyle: { color: "transparent" },
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: true }}
        >
          <Inject
            services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={
                BarChart?.dataSets.find((dataSets) => dataSets.name === "data1")
                  ?.data || []
              }
              xName="x"
              yName="y"
              name="Eventuality"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={
                BarChart?.dataSets.find((dataSets) => dataSets.name === "data2")
                  ?.data || []
              }
              xName="x"
              yName="y"
              name="Mobile"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
              fill="#e56691"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={
                BarChart?.dataSets.find((dataSets) => dataSets.name === "data3")
                  ?.data || []
              }
              xName="x"
              yName="y"
              name="Tablet"
              type="Column"
              marker={{
                dataLabel: {
                  visible: false,
                  position: "Top",
                  font: { fontWeight: "600", color: "#ffffff" },
                },
              }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
        <div className={classes.divStyles}>
          <div className={classes.Eventually}>
            <span className={classes.EventuallySpanStyle}></span>
            Eventually
          </div>
          <div className={classes.divChart}>
            <span className={classes.InitiatorSpanStyle}></span>
            Initiator
          </div>
          <div className={classes.divChart}>
            <span className={classes.SetFeeSpanStyle}></span>
            Set Fee
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box p={0} m={1}>
      <div className={`${classes.dashboardConainer} ${"scrollbox"} ${"on-scrollbar"}`}>
        <div className="control-section" id="target_dash">
          <div
            className="col-lg-12 col-sm-12 col-md-12"
            id="dashboard_sidebar_section"
          >
            <div id="analytic_target">
              <div className="dashboardParent">
                <DashboardLayoutComponent
                  id="analytic_dashboard"
                  cellAspectRatio={100 / 85}
                  cellSpacing={cellSpacing}
                  columns={6}
                >
                  <PanelsDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={0} content={card15 as any} header="<div>Agent User - Sent Letters</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={0} content={card14 as any} header="<div>Agent User - Sent Emails</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={0} content={card13 as any} header="<div>Agent User - Calls</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={0} content={card10 as any} header="<div>Job</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={2} content={card11 as any} header="<div>Azure Functions</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={4} content={card12 as any} header="<div>Notifications</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={0} content={card7 as any} header="<div>Goal</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={2} content={card8 as any} header="<div>Total Invoice</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={2} col={4} content={card9 as any} header="<div>Total Paid Users</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={0} col={0} content={card1 as any} header="<div>Placed Accounts</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={0} col={2} content={card2 as any} header="<div>Active Users</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={0} col={4} content={card3 as any} header="<div>Active Accounts</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={1} col={0} content={card4 as any} header="<div>Today Visitors</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={1} col={2} content={card5 as any} header="<div>Today's Revenue</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={1} row={1} col={4} content={card6 as any} header="<div>Today's Logged-In Users</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={2} row={3} col={0} content={pie.bind(this) as any} header="<div>Active Visitors</div>"></PanelDirective>
                    {/* <PanelDirective sizeX={2} sizeY={2} row={1} col={2} content={map.bind(this) as any} header="<div>Regional Map</div>"></PanelDirective> */}
                    <PanelDirective sizeX={2} sizeY={2} row={3} col={2} content={colChart.bind(this) as any} header="<div>Visitors by Type</div>"></PanelDirective>
                    <PanelDirective sizeX={2} sizeY={2} row={3} col={4} content={pieChart.bind(this) as any} header="<div>Usage Statistics</div>"></PanelDirective>
                    {/* <PanelDirective sizeX={4} sizeY={2} row={3} col={2} content={visitorsChart.bind(this) as any} header="<div>Traffic History</div>"></PanelDirective> */}
                  </PanelsDirective>
                </DashboardLayoutComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
export default DashboardLayout;
