import {  AccordionDetails, styled } from "@mui/material";

const BDebtAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    '&.MuiAccordionDetails-root ':{
        padding: '8px 16px !important',
        border:'1px,solid,darkgrey !important',
    borderRadius:'5px !important',
        boxShadow:'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset !important',
    }
}));

export default BDebtAccordionDetails;