import accountImage from '../../../asset/image/location2.svg';
import BDebtCard from "../../../GlobalControl/Card/BDebtCard";
import { Box, CardContent } from "@mui/material";
import { Grid, Typography } from "@material-ui/core";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import useStyles from '../DebtorsCss';
import { IDebtTableRow } from '../../../Model/Debtors/IDebtTableRow';

const Demographic: React.FC<{ dataRowSelected?: IDebtTableRow }> = (props) => {
  const classes = useStyles();
  const { dataRowSelected } = props;

  return (
    <BDebtCard>
      <Grid container spacing={0}>
        <Grid xs={12} className={classes.infoGrid}>
          <Typography variant="subtitle1" style={{ margin: '5px 0px 5px 10px' }}>
            <img src={accountImage} alt="Account Details" width="20px" height="20px" />
          </Typography>
          <Typography variant="body2" style={{ margin: '10px 10px' }}>
            <b>Demographic Info</b>
          </Typography>
        </Grid>
      </Grid>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid xs={12} md={3} >
            <Box m={0} sx={{ display: 'flex' }}>
              <Box m={0.3} p={1.2} width="25%" sx={{ bgcolor: 'rgb(237, 247, 237)', borderRadius: 1 }} >
                <HowToRegIcon fontSize='small' color='success' />
              </Box>
              <Box m={0.5} p={0} width="75%" >
                <Typography variant="caption" className={classes.infoFontSize}>
                  <b>DUE : ${dataRowSelected?.Due}</b>
                </Typography>
                <br />
                <Typography variant="caption" className={classes.infoStatus}>
                  <b>Paid in Full</b>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={4} >
            <Box m={0} sx={{ display: 'flex' }}>
              <Box m={0.5} p={0.2} width="100%">
                <Typography variant="body2" className={classes.infoFontSize}>
                  Phone Number : 7772-435-4534
                </Typography>
                <Typography variant="body2" className={classes.infoFontSize1}>
                  Referral Date : {dataRowSelected?.DelinquencyDate}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5} >
            <Box m={0} sx={{ display: 'flex' }}>
              <Box m={0.5} p={0.2} width="100%" >
                <Typography variant="body2" className={classes.infoFontSize}>
                  Referal Amount : ${dataRowSelected?.ReferalAmount}
                </Typography>
                <Typography variant="body2" className={classes.infoFontSize1}>
                  Email Address: {dataRowSelected?.EmailAddress}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </BDebtCard>
  );
};

export default Demographic;
