import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Box, Grid, Paper } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import DebtorData from "../../../MockData/DebtorData.json";
import { IDebtTableRow } from "../../../Model/Debtors/IDebtTableRow";
import useStyles from "../DebtorsCss";

function EnhancedTableHead() {
  const classes = useStyles();
  const headCells = [
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      sortable: false,
    },
    {
      id: "clientCode",
      numeric: false,
      disablePadding: false,
      label: "CLIENT CODE",
      sortable: false,
    },
    {
      id: "accountId",
      numeric: false,
      disablePadding: false,
      label: "ACCOUNT ID",
      sortable: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "STATUS",
      sortable: false,
    },
    {
      id: "deliquencyData",
      numeric: false,
      disablePadding: false,
      label: "DELIQUENCY DATE",
      sortable: false,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCellHeader}
            key={headCell.id}
            align="left"
          >
            <b> {headCell.label || headCell.sortable}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const DebtorDetails: React.FC<{
  dataRow?: IDebtTableRow;
  activityEnable;
  onLoadData: (rowData: IDebtTableRow) => void;
}> = (props) => {
  const classes = useStyles();
  const { dataRow, onLoadData } = props;
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | null>(
    null
  );
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (index: number, row: IDebtTableRow) => {
    setSelectedRowIndex(index);
    onLoadData(row);
  };

  return (
    <Box p={0}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
        <Paper className={`global-paper-style ${classes.paperStyle}`}>
            <TableContainer
              className={`${
                classes.tablebodyConainer
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table>
                <EnhancedTableHead />
                <TableBody>
                  {DebtorData.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(index, row)}
                      className={
                        selectedRowIndex === index ? classes.activeRow : ""
                      }
                    >
                      <TableCell
                        className={
                          dataRow?.AccountId
                            ? classes.selectedRow
                            : classes.tableCellStyle
                        }
                      ></TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.ClientCode}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.AccountId}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.Status}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellStyle}
                      >
                        {row?.DelinquencyDate}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={100}
              page={page}
              size="small"
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DebtorDetails;
