/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Controls
import { DialogContent, Grid, IconButton } from "@material-ui/core";
import { useState } from "react";
import BDebtDialog from "../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import { Autocomplete, DialogActions, Typography } from "@mui/material";
import BDebtButton from "../../../GlobalControl/BDebtButton";
// Icon
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// Interface
import { IGetRules, Rule } from "../DataRules/IDataRules";
import useStyles from "./DataRulesCss";
import { useFetch, usePost } from "../../../utils/apiHelper";

const EditDataRules: React.FC<{ row: IGetRules; onUpdate: () => void }> = (
  props
) => {
  const classes = useStyles();
  const { row, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rules, setRules] = useState<Rule[]>([]);
  const [selectedRuleType, setSelectedRuleType] = useState<string>("");
  const [selectedRuleOption, setSelectedRuleOption] = useState<string>("");
  const [localState, setLocalState] = useState<IGetRules>({
    ruleId: row?.ruleId,
    name: row?.name,
    description: row?.description,
    ruleType: row?.ruleType,
    ruleOption: row?.ruleOption,
    maxLength: row?.maxLength,
    formula: row?.formula,
  });

  const fetchRules = async () => {
    await useFetch<any>("Rule/GetRuleTypeWithOptions").then((result) => {
      setRules(result?.data);
    });
  };

  const openDialog = () => {
    fetchRules();
    setSelectedRuleType(row?.ruleType || null);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    let ruleDetailList: IGetRules[] = [localState];
    ruleDetailList?.push(localState);
    let request = {
      ruleId: row?.ruleId,
      ruleDetailList: [
        {
          name: localState.name,
          description: localState.description,
          ruleType: selectedRuleType,
          ruleOption: selectedRuleOption,
          maxLength: localState.maxLength,
          formula: localState.formula,
        },
      ],
    };
    await usePost<any>("Rule/ManageRule", request)
      .then((result) => {
        onUpdate();
      })
      .finally(() => {
        closeDialog();
      });
  };

  const handleNameChange = (e) => {
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value,
    });
  };

  const handleRuleTypeChange = (
    event: React.SyntheticEvent,
    value: string | null
  ) => {
    setSelectedRuleType(value);
  };

  const handleRuleOptionChange = (
    event: React.SyntheticEvent,
    value: string | null
  ) => {
    setSelectedRuleOption(value);
  };

  const ruleTypeOptions = rules?.map((rule) => rule.ruleType);
  const ruleOptions =
    rules?.find((rule) => rule?.ruleType === selectedRuleType)
      ?.ruleOptionDetails?.map((option) => option.ruleOption) || [];

  return (
    <>
      <IconButton onClick={openDialog} size="small">
        <EditOutlinedIcon color="success" fontSize="small" />
      </IconButton>
      <BDebtDialog
        open={isDialogOpen}
        maxWidth={"xs"}
        onClose={closeDialog}
        fullWidth
      >
        <BDebtDialogTitleHeader
          onClose={closeDialog}
          id="customized-dialog-title"
        >
          <Typography variant="body1">
            <b>EDIT RULES</b>
          </Typography>
        </BDebtDialogTitleHeader>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                name="name"
                size="small"
                variant="outlined"
                label="Rule Name"
                value={localState?.name}
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                type="text"
                name="description"
                size="small"
                variant="outlined"
                label="Rule Description"
                value={localState?.description}
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                id="rule-type"
                size="small"
                placeholder="Rule Type"
                options={ruleTypeOptions}
                value={selectedRuleType}
                classes={{
                  paper: classes.dropdownPaper,
                  option: classes.autoCompleteFont,
                }}
                onChange={handleRuleTypeChange}
                renderInput={(params) => (
                  <BDebtTextField
                    {...params}
                    variant="outlined"
                    label="Rule Type"
                    name="ruleType"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                id="rule-options"
                size="small"
                options={ruleOptions}
                value={localState?.ruleOption}
                classes={{
                  paper: classes.dropdownPaper,
                  option: classes.autoCompleteFont,
                }}
                onChange={handleRuleOptionChange}
                renderInput={(params) => (
                  <BDebtTextField
                    {...params}
                    label="Rule Options"
                    variant="outlined"
                    name="ruleOption"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <BDebtTextField
                fullWidth
                label="Max Length"
                type="text"
                size="small"
                name="maxLength"
                variant="outlined"
                value={localState?.maxLength}
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <BDebtTextField
                fullWidth
                label="Formula"
                type="text"
                name="formula"
                size="small"
                variant="outlined"
                value={localState?.formula}
                onChange={(e) => {
                  handleNameChange(e);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px dashed gray" }}>
          <BDebtButton
            color="success"
            variant="contained"
            onClick={handleSave}
            size="small"
          >
            Save
          </BDebtButton>
          <BDebtButton
            color="error"
            variant="contained"
            onClick={closeDialog}
            size="small"
          >
            Close
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </>
  );
};

export default EditDataRules;
