import { StepIconProps } from '@material-ui/core';
import { styled } from '@mui/system';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import TransformIcon from '@mui/icons-material/Transform';

const ColorlibStepIcon = (props: StepIconProps) => {
  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  const icons: { [index: string]: React.ReactElement } = {
    1: <CloudUploadOutlinedIcon />,
    2: <TransformIcon />,
    3: <PlagiarismOutlinedIcon />,
    4:<AdsClickIcon/>,
    5:<AssignmentTurnedInOutlinedIcon />,
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed: props.completed, active: props.active }} className={props.className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
export default  ColorlibStepIcon;