/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Button, Typography} from '@material-ui/core';
import { Grid } from '@mui/material';
import useStyles from './DataPipelineCss';
import BDebtTextField from '../../GlobalControl/BDebtTextField';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../ApiConfiguration/Configuration';
import { Time } from '../../MockData/Pipeline/Pipeline';
import { Field } from '../../Model/Configuration';

const Schedule: React.FC<{  sourceSchema:Field[]; destinationSchema:Field[];SourceConnection:string;Destinationconnection:string;SourceTable:string;DestinationTable:string }> = (props) => {
    const classes = useStyles();
    const { sourceSchema, destinationSchema,SourceConnection,Destinationconnection,SourceTable,DestinationTable } = props;
    const CopyData = async () => {
        let request={
            "sourceConnectionString": SourceConnection, "destinationConnectionString": Destinationconnection,
            "sourceTable":SourceTable,"destinationTable":DestinationTable ,
            "SourceSchema": sourceSchema.map(item => item.columnName),
            "DestinationSchema": destinationSchema.map(item => item.columnName)

        }
     await api.post('CopyActivity', request).then((response) => {
      });
    };
 

    console.log(sourceSchema)
    return (
        <React.Fragment>
            < div className={classes.div}  >
                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} className={classes.padding}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center" >
                                        <b>Schedule</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <BDebtTextField
                                        fullWidth
                                        variant="outlined"
                                        label="Name"
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BDebtTextField fullWidth
                                        multiline
                                        variant="outlined" label="Description"
                                        size='small' />
                                </Grid>
                                <Grid item xs={12}>
                                    <BDebtTextField
                                        fullWidth
                                        variant="outlined"
                                        label="Type"
                                        size='small' />
                                </Grid>
                                <Grid item xs={6}>
                                    <BDebtTextField
                                        type='date' fullWidth
                                        placeholder='Start Date'
                                        variant="outlined"
                                        size='small' />
                                </Grid>
                                <Grid item xs={6}>
                                    <BDebtTextField type='date'
                                        fullWidth placeholder='End Date'
                                        variant="outlined"
                                        size='small' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        placeholder="Time Zone"
                                        options={Time}
                                        renderInput={(params) => (
                                            <BDebtTextField {...params} variant="outlined" label="Time Zone" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    Recurrence
                                </Grid>
                                <Grid item xs={6}>
                                    <BDebtTextField fullWidth variant="outlined" label="Every"
                                        size='small' />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        placeholder="Time Zone"
                                        options={Time}
                                        renderInput={(params) => (
                                            <BDebtTextField {...params} variant="outlined" label="select" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant='contained' size='small' color='secondary' fullWidth onClick={CopyData} > Debug</Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant='outlined' size='small' >Cancel</Button>
                                </Grid>                               
                            </Grid>
                        </Grid>
                    </Grid>
                </div >
            </div >
        </React.Fragment >
    );
};

export default Schedule;
