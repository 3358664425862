/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Tooltip, Grid} from '@material-ui/core';
import { DialogActions} from "@mui/material";
import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import BDebtTextField from "../../../GlobalControl/BDebtTextField";
import { ICloneUser, IUser } from '../../../Model/IUser';
import api from '../../../ApiConfiguration/Configuration';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import BDebtButton from '../../../GlobalControl/BDebtButton';

const CloneUser: React.FC<{ data: IUser, OnClone: () => void }> = (props: any) => {
    const { data } = props;

    let AddData: ICloneUser = {
        userId: 0,
        firstName: "",
        lastName: "",
        userEmailAddress: "",
        userType: 0
    };

    const [localState, setLocalState] = useState(AddData);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value,
        });
    };

    const handleCloneuser = async () => {
        let request = {
            userId: data.userId,
            userDetail: [
                {
                    firstName: localState.firstName,
                    lastName: localState.lastName,
                    userEmailAddress: localState.userEmailAddress,
                    userType: localState.userType,
                },
            ],
        }
        await api.post("User/CloneUser", request);
        setLocalState(AddData);
        setOpen(false);
    };

    return (
        <div>
            <IconButton onClick={handleOpen} size="small">
                <Tooltip title="CLONEUSER" arrow >
                    <PeopleOutlineOutlinedIcon style={{ color: "#3b4a54" }} />
                </Tooltip>
            </IconButton>
            <BDebtDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <BDebtDialogTitleHeader onClose={handleClose} id="customized-dialog-title">
                    Clone User
                </BDebtDialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={3} style={{padding:'15px'}} >
                        <Grid item xs={6}>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                id="demo-helper-text-aligned"
                                label="First Name"
                                size="small"
                                name='firstName'
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                id="demo-helper-text-aligned"
                                label="Last Name"
                                size="small"
                                name='lastName'
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BDebtTextField
                                variant="outlined"
                                fullWidth
                                id="demo-helper-text-aligned"
                                label="Email"
                                size="small"
                                name='userEmailAddress'
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <BDebtButton
                        size="small"
                        variant='contained'
                        color='primary'
                        onClick={handleCloneuser}>
                        clone
                    </BDebtButton>
                    <BDebtButton
                        size="small"
                        variant='outlined'
                        color='primary'
                        onClick={handleClose}>
                        cancel
                    </BDebtButton>
                </DialogActions>
            </BDebtDialog>
        </div>
    )
}

export default CloneUser