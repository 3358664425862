/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//MUI components
import { TablePagination } from "@material-ui/core";
import { Avatar,Checkbox,Grid,InputAdornment,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,Typography,} from "@mui/material";
import React, { useEffect, useState } from "react";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Components
import AddUser from "../../../Components/Users/ManageUser/AddUser";
import AllUserHistory from "../AllUser/AllUserHistory";
import SearchandHighlight from "../GlobalFunctions/SearchandHighlight";
import DeleteUser from "../ManageUser/DeleteUser";
import EditUser from "../ManageUser/EditUser";
import Impersonate from "../ManageUser/Impersonate";
//Custom Css
import useStyles from "../UserCss";
import { IGetUser } from "../../../Model/IUser";
import api from "../../../ApiConfiguration/Configuration";

const ClientUser = ({ Value }) => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value, setValue] = useState("");
  const [usertype, setusertype] = useState(Value);
  const [userList, setUserList] = useState<IGetUser[]>([]);

  useEffect(()=>{
    GetClientUserDetail();
  },[]);

  const GetClientUserDetail = () => {
    api.post<IGetUser[]>("User/GetDetailByUser?UserType="+usertype).then((response) => {
      setUserList(response.data);
    })
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function EnhancedTableHead() {
    const headCells = [
      {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
        sortable: false,
      },
      {
        id: "fName",
        numeric: false,
        disablePadding: false,
        label: "FIRST NAME",
        sortable: false,
      },
      {
        id: "lName",
        numeric: false,
        disablePadding: false,
        label: "LAST NAME",
        sortable: false,
      },
      {
        id: "emailId",
        numeric: false,
        disablePadding: false,
        label: "EMAIL",
        sortable: false,
      },
      {
        id: "useRole",
        numeric: false,
        disablePadding: false,
        label: "USER ROLE",
        sortable: false,
      },
      {
        id: "joinedDate",
        numeric: false,
        disablePadding: false,
        label: "JOINED ",
        sortable: false,
      },
      {
        id: "lActivty",
        numeric: false,
        disablePadding: false,
        label: "LAST ACTIVITY",
        sortable: false,
      },
      {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "ACTIONS",
        sortable: false,
      },
      {
        id: "HISTORY",
        numeric: false,
        disablePadding: false,
        label: "HISTORY",
        sortable: false,
      },
    ];

    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellHeader}>
            <Checkbox
              size="small"
              color="secondary"
              sx={{ padding: "5px", color: "white" }}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell className={classes.tableCellHeader} key={headCell.id}>
              {headCell.label || headCell.sortable}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid container spacing={0} boxShadow={3}>
      <Grid item xs={12} sm={8} md={11} padding="5px">
        <TextField
          fullWidth
          size="small"
          id="standard-bare"
          variant="outlined"
          className={classes.searchBox}
          onChange={onSearchChange}
          placeholder="Search User name or Email Id..."
          inputProps={{ maxLength: 255 }}
          InputProps={{
            classes: {
              root: classes.outlinedInput,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={1} mt={1}>
        <AddUser onCreate={GetClientUserDetail}/>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          className={`${
            classes.tablebodyConainer
          } ${"scrollbox"} ${"on-scrollbar"}`}
        >
          <Table aria-label="customized table" size="medium" stickyHeader>
            <EnhancedTableHead />
            <TableBody>
              {userList.map((row: any, index: number) => (
                <React.Fragment>
                  <TableRow key={`${row?.userId}`}>
                    <TableCell className={classes.tableCellStyle}>
                      <Checkbox
                        size="small"
                        color="primary"
                        style={{ padding: "5px" }}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      <Avatar sizes="small" className={classes.avatar}>
                        <Typography variant="body2">
                          {row?.firstName[0]}
                          {row?.lastName[0]}
                        </Typography>
                      </Avatar>
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.firstName, value)}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.lastName, value)}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.userEmailAddress, value)}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.userType, value)}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.createdDate, value)}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {SearchandHighlight(row?.modifiedDate, value)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCellStyle}
                      sx={{ display: "flex" }}
                    >
                      <Impersonate data={row} />
                      <EditUser row={row} />
                      <DeleteUser UserId={row.userId} onDelete={GetClientUserDetail} />
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      <AllUserHistory />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} borderTop={"1px solid lightgray"}>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default ClientUser;
