import { Box, DialogActions, DialogContent, FormControlLabel, Grid } from "@material-ui/core";
import { useState } from "react";
import useStyles from "../WidgetCss";
import SettingsIcon from "@material-ui/icons/Settings";
import BDebtDialog from "../../../../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogTitleHeader from "../../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import BDebtCheckbox from "../../../../GlobalControl/BDebtCheckBox";
import SampleDashboardLayout from "./SampleDashboardLayout";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BDebtTypography from "../../../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../../../GlobalControl/BDebtTextField";
import BDebtButton from "../../../../GlobalControl/BDebtButton";
import { Autocomplete } from "@mui/material";

const PredefinedConfiguration = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const open = () => {
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
    };
    const selectOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const colorOptions = ["white", "blue", "mediumBlue", "black", "Yellow", "Red", "Orange"];
    const chartTypes = ["Pie", "Bar", "Column", "Stacked Bar", "Pivot table", "Stacked area", "Area", "Line"];
    const advancedfeatures = [
        { id: 1, label: 'Show burn down' },
        { id: 2, label: 'Show total scope' },
        { id: 3, label: 'Show Complete Work' },
        { id: 4, label: 'Plot remaining work using work item type color' },
        { id: 5, label: 'Show Resolve work items as complete' }
    ];
    const plot = ['Iteration', 'Date'];
    return (
        <div>
            <BDebtButton
                variant="contained"
                size="small"
                onClick={open}
                startIcon={<SettingsIcon fontSize="small" />}
                color="primary"
            >
                Configure
            </BDebtButton>
            <BDebtDialog
                open={isOpen}
                maxWidth={"md"}
                onClose={close}
                fullWidth
            >
                <BDebtDialogTitleHeader
                    id="customized-dialog-title"
                    onClose={close}
                >
                    <BDebtTypography variant="h6"> CONFIGURATION</BDebtTypography>
                </BDebtDialogTitleHeader>
                <DialogContent className={classes.borderBottom}>
                    <Box boxShadow={"0px 1px 4px 1px rgba(103, 128, 159, 1)"} borderRadius={5} p={2} mb={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgba(16, 185, 129, 0.12)"} color={"rgb(11, 129, 90)"}>Widgets Configure</BDebtTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={chartTypes}
                                    fullWidth
                                    renderInput={(params) => (
                                        <BDebtTextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label="Chart Type"
                                            margin="dense"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={selectOptions}
                                    fullWidth
                                    renderInput={(params) => (
                                        <BDebtTextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label="Width"
                                            margin="dense"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={selectOptions}
                                    fullWidth
                                    renderInput={(params) => (
                                        <BDebtTextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label="Height"
                                            margin="dense"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={selectOptions}
                                    fullWidth
                                    renderInput={(params) => (
                                        <BDebtTextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label="Grid Size"
                                            margin="dense"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={colorOptions}
                                    fullWidth
                                    renderInput={(params) => (
                                        <BDebtTextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            label="Color"
                                            margin="dense"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Box boxShadow={"0px 1px 4px 1px rgba(103, 128, 159, 1)"} borderRadius={5} p={3} mb={2}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgba(16, 185, 129, 0.12)"} color={"rgb(11, 129, 90)"}>Time Period</BDebtTypography>
                                    </Grid>
                                    <BDebtTypography variant="body2" marginBottom={"8px"}>Start Date</BDebtTypography>
                                    <Grid item xs={12}>
                                        <BDebtTextField
                                            fullWidth
                                            type="date"
                                            size="small"
                                            variant="outlined"

                                        />
                                    </Grid>
                                    <BDebtTypography variant="body2" marginBottom={"8px"}>End Date</BDebtTypography>
                                    <Grid item xs={12}>
                                        <BDebtTextField
                                            fullWidth
                                            type="date"
                                            size="small"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <BDebtTypography variant="body2" marginBottom={"8px"}>Plot Burndown By </BDebtTypography>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            options={plot}
                                            renderInput={(params) => (
                                                <BDebtTextField
                                                    {...params}
                                                    fullWidth
                                                    type="text"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box boxShadow={"0px 1px 4px 1px rgba(103, 128, 159, 1)"} borderRadius={5} p={3} mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BDebtTypography variant="subtitle2" align="center" bgcolor={"rgba(16, 185, 129, 0.12)"} color={"rgb(11, 129, 90)"} marginBottom={"25px"}>Advanced Features</BDebtTypography>
                                        {advancedfeatures.map((checkbox) => (
                                            <Grid item xs={12} key={checkbox.id}>
                                                <FormControlLabel
                                                    control={<BDebtCheckbox size='small' />}
                                                    label={
                                                        <BDebtTypography variant="body2">{checkbox.label}</BDebtTypography>
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={0}>
                        <Grid item xs={8} className={classes.padding}>
                            <SampleDashboardLayout />
                        </Grid>
                        <Grid item container xs={4} justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <BDebtButton
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    startIcon={<TurnedInNotIcon fontSize="small" />}
                                >
                                    Save
                                </BDebtButton>
                            </Grid>
                            <Grid item>
                                <BDebtButton
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    startIcon={<HighlightOffIcon fontSize="small" />}
                                    onClick={close}
                                >
                                    Close
                                </BDebtButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>

            </BDebtDialog>
        </div >
    );
}
export default PredefinedConfiguration;