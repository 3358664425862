/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Controls
import { v4 as uuidv4 } from 'uuid';
import { MoonLoader } from "react-spinners";
import { DialogActions, DialogContent, IconButton } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useHistory, withRouter } from "react-router-dom";
import BDebtTypography from "../../../GlobalControl/BDebtTypography";
import BDebtTooltip from "../../../GlobalControl/ToolTip/LightToolTip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AppBar, Box, Grid, Tab, Paper, Step, StepLabel, Stepper, Backdrop } from "@mui/material";
// Icons
import ColorlibStepIcon from "./style";
import DeleteIcon from '@mui/icons-material/Delete';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Css
import useStyles from "../PlacementCss";
// Modules
import FieldCard from "./FieldCard";
import DataSet from "./DataSetField";
import AddAccount from "./AddAccount";
import ColumnField from "./ColumnField";
import DisplayField from "./DisplayField";
import ErrorColumnField from "./ErrorCloumnField";
import { usePost } from "../../../utils/apiHelper";
import CustomizedHook from "./PlacementSelectField";
import DeletedColumnField from "./DeletedColumnField";
import RecordsCombineView from "./RecordsCombineView";
import { IFormData } from "../../../MockData/Placement/placement";
import { Mapping } from "../../../Model/placement/PlacementGridItem";
import { Field, GetDataSet } from "../../../MockData/Placement/DataRuleMock";
import BDebtDialogTitleHeader from '../../../GlobalControl/DialogBox/BDebtDialogTitleHeader';
import BDebtDialog from '../../../GlobalControl/DialogBox/BDebtDialog';
import BDebtButton from '../../../GlobalControl/BDebtButton';

const steps = ["Load", "Transform", "Preview", "Map Target", "Submit"];

function ColumnMapping(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(1);
  const history = useHistory();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const uploadedData: any[] = props?.location?.state?.uploadedData;
  const clientCode: string = props?.location?.state?.clientCode;
  const phaseCode: string = props?.location?.state?.phaseCode;
  const dataset: string = props?.location?.state?.dataset;
  const fileName: string = props?.location?.state?.fileName;
  const dataSetId: number = props?.location?.state?.dataSetId;
  const fileDataLength: number = props?.location?.state?.fileDataLength;
  const ingestDetails: IFormData = props?.location?.state?.ingestDetails ? props?.location?.state?.ingestDetails : null;
  const [addNewRow, setAddNewRow] = useState<any[]>(props?.location?.state?.addNewRow ? props?.location?.state?.addNewRow : uploadedData);
  const [fieldMappings, setFieldMappings] = useState<Mapping[]>(props.location?.state?.fieldMappings ? props.location?.state?.fieldMappings : []);
  const [yourFieldOptions, setYourFieldOptions] = useState<string[]>(props.location?.state?.yourFieldOptions ? props.location?.state?.yourFieldOptions : []);

  const [value, setValue] = useState("0");
  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [clientCodeId, setClientCodeId] = useState<string>();// for future enhancement
  const [deletedRecords, setDeletedRecords] = useState<any[]>([]);
  const [dataSetField, setDataSetField] = useState<GetDataSet[]>([]);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [isRecordsDialogOpen, setIsRecordsDialogOpen] = useState(false);
  const [isAddAccountDialogOpen, setIsAddAccountDialogOpen] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([]);
  const [selectedAutocompleteField, setSelectedAutocompleteOption] = useState<string>("");
  const [selectedCard, setSelectedCard] = useState<{ title: string; mappedField: string; }>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!showConfirmationDialog && !isBackButtonClicked) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const unblock = history.block((location, action) => {
      if (!showConfirmationDialog && !isBackButtonClicked && action === 'POP') {
        setShowConfirmationDialog(true);
        return false;
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [showConfirmationDialog, history, isBackButtonClicked]);

  const handleConfirmNavigation = () => {
    setShowConfirmationDialog(false);
    setIsBackButtonClicked(true);
    history.goBack();
  };
  const handleCancelNavigation = () => {
    setShowConfirmationDialog(false);
    setIsBackButtonClicked(false);
  };


  const handlePinToggle = () => {
    setIsPinned(true);
  };
  const handleUnPinToggle = () => {
    setIsPinned(false);
  };

  useEffect(() => {
    setLoading(true);
    handleGetDataSetField();
  }, [clientCodeId]);

  useEffect(() => {
    if (uploadedData?.length > 0) {
      const headers = Object.keys(uploadedData[0]);
      setAutocompleteOptions(headers);
      if (!fieldMappings?.length) {
        const defaultFieldMappings = dataSetField?.flatMap((dataset) =>
          dataset?.fieldsDetail?.map((field: Field) => {
            const matchingHeader = headers?.find((header) => header?.toLowerCase() === field?.fieldName.toLowerCase());
            const isRequired = field.isRequired || false;
            return { title: field?.fieldName, mappedField: matchingHeader || "", required: isRequired, fieldDetails: [] };
          })
        );
        // Sort the defaultFieldMappings to display required fields first, followed by non-required fields
        defaultFieldMappings.sort((a) => {
          // Required fields come before non-required fields
          if (a.required) {
            return -1; // a comes before b
          } else {
            // If both are required or non-required, maintain the original order
            return 0;
          }
        });
        setFieldMappings(defaultFieldMappings);

        const mappedFields = defaultFieldMappings?.map((mapping) =>
          mapping.mappedField.toLowerCase()
        );

        const unmappedHeaders = headers?.filter((header) => !mappedFields?.includes(header.toLowerCase()));
        setYourFieldOptions(unmappedHeaders);
      }
      setLoading(false);
    }
  }, [uploadedData, dataSetField]);

  const handleAutocompleteChange = (newValue: string, title: string) => {
    const updatedFieldMappings = [...fieldMappings];
    const index = updatedFieldMappings.findIndex((mapping) => mapping.title === title);
    const clearedField = updatedFieldMappings[index]?.mappedField;

    if (clearedField && !yourFieldOptions?.includes(clearedField)) {
      setYourFieldOptions((prevOptions) => [...prevOptions, clearedField]);
    }

    // Update mappedField based on the selected autocomplete option
    updatedFieldMappings[index].mappedField = newValue;

    // Pass the selected Autocomplete option to the ColumnField component
    setSelectedAutocompleteOption(newValue);

    setFieldMappings(updatedFieldMappings);

    if (newValue !== "") {
      const updatedYourFieldOptions = yourFieldOptions?.filter((field) => field !== newValue && field !== "");
      // Include both clearedField and updatedYourFieldOptions in yourFieldOptions
      setYourFieldOptions(clearedField ? [clearedField, ...(updatedYourFieldOptions)] : updatedYourFieldOptions);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedMappings = Array.from(fieldMappings);
    const [movedItem] = reorderedMappings.splice(result.source.index, 1);
    reorderedMappings.splice(result.destination.index, 0, movedItem);
    setFieldMappings(reorderedMappings);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  const handleFieldSelectionChange = (fields: string[]) => {
    setSelectedFields(fields);
  };

  const generateRandomRow = (currentRows) => {
    const newRow = { id: uuidv4() };
    return newRow;
  };

  const handleClick = (newRecord) => {
    const newRow = generateRandomRow(newRecord);
    const newRowCopy = { ...newRow, ...newRecord };
    setAddNewRow((oldRows) => [newRowCopy, ...oldRows]);
  };

  const handleRowUpdate = (updatedRow) => {
    setAddNewRow(updatedRow);
  };

  const handleCardSelection = (selectedCard: { title: string; mappedField: string }) => {
    // Reset the isSelected state for all cards except the selected one
    setFieldMappings((prevMappings) => {
      return prevMappings.map((mapping) => ({
        ...mapping,
        isSelected: mapping.title === selectedCard.title,
      }));
    });

    // Use the selected card if available, otherwise set to the first card value
    const updatedSelectedCard = selectedCard || fieldMappings[0] || null;
    setSelectedCard(updatedSelectedCard);
  };

  const datasetFieldMappings = dataSetField?.flatMap((dataset) =>
    dataset?.fieldsDetail?.map((field: Field) => {
      return { fieldName: field?.fieldName, dataType: field?.dataType, isRequired: field?.isRequired };
    })
  );

  const handleNextClick = () => {
    // Use the useHistory hook to get the history object
    history.push("/accountspreview", {
      uploadedData: uploadedData, clientCode: clientCode, phaseCode: phaseCode, dataset: dataset, fieldMappings: fieldMappings, addNewRow: addNewRow, fileName: fileName,
      fileDataLength: fileDataLength, ingestDetails: ingestDetails, dataSetId: dataSetId, yourFieldOptions: yourFieldOptions, datasetFieldMappings: datasetFieldMappings, dataSetField: dataSetField, deletedRecords: deletedRecords
    });
  }
  const handleBackClick = () => {
    setIsBackButtonClicked(true);
    setShowConfirmationDialog(true);
    // Future Needs
    // history.push("/accounts/Ingest", { clientCode: clientCode, phaseCode: phaseCode, dataset:dataset, fileName: fileName, fileDataLength: fileDataLength, uploadedData: uploadedData, dataSetId: dataSetId, ingestDetails: ingestDetails,});
  };

  useEffect(() => {
    if (dataSetId !== null && clientCodeId !== null) {
      handleGetDataSetField(); // Removed the argument
    }
  }, [dataSetId, clientCodeId]);

  const handleGetDataSetField = () => {
    (async () => {
      setLoading(true);
      let request = {
        clientCode: ingestDetails?.clientcode,
        dataSetId: dataSetId
      };
      await usePost<GetDataSet[]>("Dataset/GetDataSetField", request)
        .then((response) => {
          setDataSetField(response?.data);
          setLoading(false);
        })
        .finally(() => { });
    })();
  };

  const handleDatasetSave = (newDataset: GetDataSet) => {
    setDataSetField([newDataset]);
  };

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  const isRequiredFieldsMapped = () => {
    for (const mapping of fieldMappings) {
      if (mapping.required && !mapping.mappedField) {
        return false;
      }
    }
    return true;
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsRecordsDialogOpen(true);
  };

  const openRecordsDialog = () => {
    setIsRecordsDialogOpen(true);
  };

  const closeRecordsDialog = () => {
    setIsRecordsDialogOpen(false);
  };

  const openAddAccountDialog = () => {
    setIsAddAccountDialogOpen(true);
    closeRecordsDialog();
  };

  const openAddAccount = () => {
    setIsAddAccountDialogOpen(true);
  }

  const closeAddAccountDialog = () => {
    setIsAddAccountDialogOpen(false);
  };

  const handleDeletedRecordsChange = (records) => {
    setDeletedRecords((oldRecords) => {
      const newRecords = records?.filter((record) => !oldRecords?.some((oldRecord) => oldRecord?.id === record?.id));
      return [...oldRecords, ...newRecords];
    });
  };

  return (
    <div>
      <Grid container spacing={0} component={Paper} className={classes.pageContainer} >
        <Grid xs={activeStep > 0 ? 11 : 12} className={activeStep > 0 ? classes.stepperActive : classes.stepperInActive} sx={{ borderBottom: "1px solid lightgray" }}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid xs={activeStep > 0 ? 1 : null} className={activeStep > 0 ? classes.buttonStyle : null}>
          <IconButton size="small" onClick={handleToggleFullScreen}>
            <BDebtTooltip title={isFullScreen ? "Close Full Screen" : "Full Screen"}>
              {isFullScreen ? (
                <FullscreenExitIcon fontSize="medium" className={classes.placementIcon} />
              ) : (
                <FullscreenIcon fontSize="medium" className={classes.placementIcon} />
              )}
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleBackClick} >
            <BDebtTooltip title="Back">
              <ArrowBackIcon fontSize="medium" className={classes.placementIcon} />
            </BDebtTooltip>
          </IconButton>
          <IconButton size="small" onClick={handleNextClick} disabled={!isRequiredFieldsMapped()}>
            <BDebtTooltip title="Next">
              <ArrowForwardIcon fontSize="medium" className={isRequiredFieldsMapped() ? classes.placementIcon : classes.placementDisableIcon} />
            </BDebtTooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: 'rgb(198, 198, 198)', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
        <MoonLoader size={50} color={"#123abc"} loading={loading} />
      </Backdrop>
      <Grid container spacing={0}>
        <React.Fragment>
          {!isPinned ?
            < Grid item xs={4} sx={{ borderRight: "1px solid lightgray" }}>
              <Grid container spacing={0} className={classes.fieldHeader}>
                <Grid item xs={4} display="flex" justifyContent="center">
                  <BDebtTypography variant="body2" textAlign="left">
                    Expected Fields
                  </BDebtTypography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="end">
                  <BDebtTypography variant="body2" textAlign="left">
                    Your Fields
                  </BDebtTypography>
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="end" fontSize={"19px"}>
                  <BDebtTooltip title="Rules">
                    <span className={`${classes.ruleIcon} ${"e-icons e-hide-formula-bar"}`}></span>
                  </BDebtTooltip>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="end">
                  <DataSet selectedClientCode={ingestDetails?.clientcode} onSave={handleDatasetSave} dataSetId={dataSetId} />
                </Grid>
              </Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Scrollbars
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={10}
                  className={classes.fieldContainer}
                >
                  <Droppable droppableId="field-mappings">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div>
                          {fieldMappings?.map((r, index) => (
                            <Draggable key={r.title} draggableId={r.title} index={index} >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                  <FieldCard
                                    title={r.title}
                                    mappedField={r.mappedField}
                                    required={r.required}
                                    yourFieldOptions={yourFieldOptions}
                                    isFetched
                                    onAutocompleteChange={(newValue) => { handleAutocompleteChange(newValue, r.title); }}
                                    onCardClick={handleCardSelection}
                                    selected={selectedCard?.title === r.title}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Scrollbars>
              </DragDropContext>
            </Grid>
            : null}
        </React.Fragment>
        <Grid item xs={isPinned ? 12 : 8}>
          <TabContext value={value}>
            <AppBar position="static" className={classes.appbarPosition}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="fullWidth"
                className={classes.tabList}
                classes={{ indicator: classes.indicator }}
              >
                <Box className={classes.pinnedstyle}>
                  {isPinned ?
                    <BDebtTooltip title={isPinned ? "pin" : "Unpin"}>
                      <ArrowForwardIosIcon onClick={handleUnPinToggle} className={classes.pinIconStyle} />
                    </BDebtTooltip>
                    :
                    <BDebtTooltip title={isPinned ? "Pin" : "Unpin"}>
                      <ArrowBackIosIcon onClick={handlePinToggle} className={classes.UnpinIconStyle} />
                    </BDebtTooltip>
                  }
                </Box>
                <Tab
                  icon={<AllInboxIcon fontSize="small" />}
                  iconPosition="start"
                  label="ALL RECORDS"
                  value="0"
                  className={classes.tabRecords}
                />

                <Tab
                  icon={<ErrorOutlineIcon fontSize="small" />}
                  iconPosition="start"
                  label="ERROR RECORDS"
                  value="1"
                  className={classes.tabRecords}
                />

                <Tab
                  icon={<DeleteIcon fontSize="small" />}
                  iconPosition="start"
                  label="DELETED RECORDS"
                  value="2"
                  className={classes.tabRecords}
                />
              </TabList>
            </AppBar>

            <TabPanel value="0" sx={{ padding: 1 }}>
              <Box m={0} p={0}>
                <Grid container spacing={0} boxShadow={2} padding={0} bgcolor={"white"} >
                  <Grid xs={11} padding="5px">
                    <CustomizedHook
                      onSelect={(fields) => {
                        setSelectedFields(fields);
                      }}
                      onFieldSelectionChange={handleFieldSelectionChange}
                      options={autocompleteOptions}
                    />
                  </Grid>
                  <Grid xs={1} mt="11px">
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <RecordsCombineView datasetDetail={dataSetField} addNewRow={addNewRow} onRowUpdate={handleRowUpdate} isOpen={isRecordsDialogOpen}
                          onOpen={openRecordsDialog}
                          onClose={closeRecordsDialog}
                          onAddNewRecord={openAddAccountDialog}
                          selectedRowData={selectedRowData}
                          onDeletedRecordsChange={handleDeletedRecordsChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <AddAccount datasetDetail={dataSetField} onAdd={handleClick} isOpen={isAddAccountDialogOpen}
                          onClose={closeAddAccountDialog} onOpen={openAddAccount} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} borderTop={"1px solid lightgray"} pt={1}>
                    <Grid container spacing={0}>
                      <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        className={classes.dataRowContainer}
                      >
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={6} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                            <DisplayField
                              selectedFields={selectedFields}
                              uploadedData={uploadedData}
                              selectedCard={selectedCard}
                              fieldMappings={fieldMappings}
                              selectedAutocompleteField={selectedAutocompleteField}
                            />
                          </Grid>
                          <Grid item xs={6} display={"flex"} justifyContent={"start-end"} alignItems={"start-end"}>
                            <ColumnField
                              addNewRow={addNewRow}
                              selectedCard={selectedCard}
                              fieldMappings={fieldMappings}
                              onRowUpdate={handleRowUpdate}
                              onRowClick={handleRowClick}
                              selectedAutocompleteField={selectedAutocompleteField}
                            />
                          </Grid>
                        </Grid>
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="1" sx={{ padding: 1 }}>
              <ErrorColumnField uploadedData={uploadedData} />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 1 }}>
              <DeletedColumnField deletedRecords={deletedRecords} fieldMappings= {fieldMappings}/>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <BDebtDialog open={showConfirmationDialog} maxWidth={"sm"} onClose={handleCancelNavigation} fullWidth>
        <BDebtDialogTitleHeader onClose={handleCancelNavigation} id="customized-dialog-title">
        Confirm Moving Back
        </BDebtDialogTitleHeader>
        <DialogContent>
          <BDebtTypography variant="body2" textAlign="left">
            Are you sure you want to exit the current mapping?
          </BDebtTypography>
        </DialogContent>
        <DialogActions>
          <BDebtButton onClick={handleCancelNavigation} color="error">
            Cancel
          </BDebtButton>
          <BDebtButton onClick={handleConfirmNavigation} color="success" autoFocus>
            Confirm
          </BDebtButton>
        </DialogActions>
      </BDebtDialog>
    </div >
  );
};

export default withRouter(ColumnMapping);