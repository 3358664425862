import { TextField as MUITextField, Theme, styled, CSSObject } from "@mui/material";

interface BDebtTextFieldProps {
  theme?: Theme;
  customStyle?: CSSObject;
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error';
  variant?: 'standard' | 'outlined' | 'filled';
}

const BDebtTextField = styled(MUITextField)<BDebtTextFieldProps>(
  ({ theme, customStyle, color, variant }) => {
    const getColor = () => {
      switch (color) {
        case 'primary':
          return '#2a3944';
        case 'secondary':
          return '#1976d2';
        case 'success':
          return '#2e7d32';
        case 'warning':
          return '#ed6c02';
        case 'error':
          return '#d32f2f';
        default:
          return '#3b4a54';
      }
    };

    const getVariantStyles = () => {
        switch (variant) {
          case 'outlined':
            return {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: `${getColor()} !important`,
                },
                "&:hover fieldset": {
                  borderColor: `${getColor()} !important`,
                },
                "&.Mui-focused fieldset": {
                  borderColor: `${getColor()} !important`,
                },
                "&.Mui-error fieldset": {
                  borderColor: 'red !important',
                },
              },
            };
          case 'filled':
            return {
              "& .MuiFilledInput-root": {
                color: theme.palette.getContrastText(getColor()),
                backgroundColor: "White",
                "&:hover": {
                  backgroundColor: "White",
                  "&::placeholder": {
                    color: "#272e29 !important",
                  },
                },
              },
            };
          case 'standard':
            return {
              "& .MuiInputBase-input": {
                color: theme.palette.getContrastText(getColor()),
                "&::placeholder": {
                  color: "#272e29 !important",
                },
              },
            };
          default:
            return {}; // Standard variant, no additional styles
        }
      };
      

    return {
      "& .MuiFormControl-root": {
        "& .MuiTextField-root": {
          "& .MuiFormLabel-root": {
            fontSize: 12,
            color: getColor(),
          },
        },
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme?.palette.common.white,
        borderRadius: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        "&.Mui-disabled": {
          backgroundColor: "#dfe4e8 !important",
        },
      },
      "& .MuiInputBase-input": {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 13,
        "&::placeholder": {
          color: "#010f05 !important",
        },
      },
      "& .MuiFormLabel-root": {
        fontSize: 13,
        color: `${getColor()} !important`,
      },
      ...getVariantStyles(),
      // Apply custom styles if provided
      ...(customStyle || {}),
    };
  }
);

export default BDebtTextField;
