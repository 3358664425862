import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import BDebtTable from '../../../../../GlobalControl/Table/BDebtTable';
import BDebtTableCell from '../../../../../GlobalControl/Table/BDebtTableCell';
import BDebtTableRow from '../../../../../GlobalControl/Table/BDebtTableRow';
import useStyles from '../../WidgetCss';
import { TableSidebar } from './TableSidebar';
import { Box } from '@mui/material';
import tablesData from '../../../../../MockData/CustomizedWidgetsTable.json';

const TableContent = ({ selectedTables }) => {
  const classes = useStyles();

  if (selectedTables.length === 0) {
    return <p>No records to display.</p>;
  }

  return (
    <div>
      <Box>
        <Grid container spacing={0} className={`${classes.tablecardConainer} ${'scrollbox'} ${'on-scrollbar'}`} overflow={'auto'} flexWrap={'nowrap'} >
          {selectedTables.map((tableName) => {
            const table = tablesData[tableName];
            return (
              <Grid item xs={12} key={tableName}>
                <BDebtTable stickyHeader aria-label="sticky table"  className={`${classes.borderRight} ${classes.tableContainerStyle}`}>
                  <TableHead className={`${classes.tableHead} ${classes.stickyHeader}`}>
                    <BDebtTableRow  className={classes.tableContainerStyle}>
                      <BDebtTableCell size="small" colSpan={table.columns.length} className={classes.tableNameStyle}>
                        {table.tableName}
                      </BDebtTableCell>
                    </BDebtTableRow>
                    <BDebtTableRow className={classes.tableContainerStyle}>
                      {table.columns.map((column) => (
                        <BDebtTableCell size="small" key={column} className={`${classes.tableCellHeader} ${classes.tableContainerStyle}`}>
                          {column}
                        </BDebtTableCell>
                      ))}
                    </BDebtTableRow>
                  </TableHead>
                  <TableBody>
                    {table.data.map((row, rowIndex) => (
                      <BDebtTableRow key={rowIndex} className={classes.tableContainerStyle}>
                        {table.columns.map((column) => (
                          <BDebtTableCell size="small" key={column} className={classes.tableContainerStyle}>
                            {column.toLowerCase() === 'required' ? (
                              <Checkbox size="small"  checked={row[column.toLowerCase()] === 'Yes'} disabled />
                            ) : (
                              row[column.toLowerCase()]
                            )}
                          </BDebtTableCell>
                        ))}
                      </BDebtTableRow>
                    ))}
                  </TableBody>
                </BDebtTable>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

const TableList = () => {
  const [selectedTables, setSelectedTables] = useState([]);
  const handleDatabaseChange = (newChecked) => {
    setSelectedTables(newChecked);
  };

  return (
    <Grid container item xs={12} spacing={1} mt="0">
      <Grid item xs={2}>
        <TableSidebar handleDatabaseChange={handleDatabaseChange} />
      </Grid>
      <Grid item xs={10}>
        <TableContent selectedTables={selectedTables} />
      </Grid>
    </Grid>
  );
};

export default TableList;
