/* eslint-disable @typescript-eslint/no-unused-vars */
import { ListItemIcon, ListItemText, MenuList, Typography } from "@material-ui/core";
import { Avatar, Box, Button, Dialog, DialogContent, IconButton, Link, Menu, MenuItem, Paper } from "@mui/material";
import { useState } from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CodeIcon from "@mui/icons-material/Code";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import ForumIcon from '@mui/icons-material/Forum';
import PersonIcon from '@mui/icons-material/Person';
import avathor from "../profile.svg";
import "./Profile.css";
import { msalConfig } from '../auth/authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const ProfileCard = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAvatarClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signout = () => {
    instance.logout({
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    })
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleAvatarClick} size="small" sx={{ padding: 1 }}
        aria-controls={isDialogOpen ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={isDialogOpen ? "true" : undefined} >
        <img src={avathor} alt="" width="30px" />
      </IconButton>

      <Menu id="account-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} >
        <div className='pro-row'>
          <div className='profile'>
            <img src={avathor} alt="" width="30px" />
            <div style={{ marginLeft: '15px' }}>
              <Typography variant='body2' style={{ color: '#000000' }}>
                <b>John Doe</b>
              </Typography>
              <Typography variant='body2' style={{ color: '#919EAB' }}>
                Platform Admin
              </Typography>
            </div>
          </div>
        </div>
        <hr className='line-break' />
        <MenuList className='menu-List'>
          <MenuItem onClick={handleDialogOpen}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' >
                Profile Settings
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <HelpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' >
                Help
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <ForumIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' >
                Contact Us
              </Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
        <hr className='line-break' />
        <MenuList className='menu-List'>
          <MenuItem>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" onClick={signout} />
            </ListItemIcon>
            <ListItemText onClick={signout}>
              <Typography variant='body2' >
                Log Out
              </Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="sm" >
        <DialogContent>
          <Paper id="main-card" elevation={4}>
            <div className="cover-photo" style={{ height: "100px", maxHeight: "200px" }}></div>
            <Box display="flex" flexDirection="column" alignItems="center" p={2}>
              <Avatar src={avathor} alt="User Profile Picture" sx={{ width: 100, height: 100, position: "relative", top: "-50px", borderRadius: "50%" }} />
              <Typography variant="h5" className="name">
                Jono doe
              </Typography>

              <Typography variant="h6" className="fullstack">
                Full Stack Engineer <br />
                <Link href="" className="certified" target="_blank" sx={{ color: "#0ab581", textDecoration: "none" }} >
                  OCA® certified
                </Link>
              </Typography>

              <Typography variant="h6" className="email">
                <Link href="" sx={{ color: "#0ab581", textDecoration: "none" }} >
                  Jhondoe1791@gmail.com
                </Link>
              </Typography>

              <div className="contact" style={{ background: "#30354d", height: "50px" }}>
                <ul>
                  <Button component={Link} href="h" target="_blank" variant="text" color="inherit" >
                    <LinkedInIcon style={{ fontSize: 22, color: "#0ab581" }} />
                  </Button>
                  <Button component={Link} href="https://github.com" target="_blank" variant="text" color="inherit" >
                    <GitHubIcon style={{ fontSize: 22, color: "#0ab581" }} />
                  </Button>
                  <Button component={Link} href="https://codepen.io" target="_blank" variant="text" color="inherit" >
                    <CodeIcon style={{ fontSize: 22, color: "#0ab581" }} />
                  </Button>
                </ul>
              </div>
            </Box>
          </Paper>
        </DialogContent>

      </Dialog>
    </div >
  );
};

export default ProfileCard;
