import { Radio, styled } from "@mui/material";
import { Secondary, Success, Warning, Error } from '../theme';

const BDebtRadioButton = styled(Radio)<{
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
    size?: 'small' | 'medium';
}>(({ color, size }) => ({
    '&.Mui-checked': {
        color: color === undefined ? 'defaultColor' : '#2a3944',
    },
    ...(color === 'primary' && {
        '&.Mui-checked': {
            color: '#2a3944',
        },
    }),
    ...(color === 'secondary' && {
        '&.Mui-checked': {
            color: Secondary.dark,
        },
    }),
    ...(color === 'success' && {
        '&.Mui-checked': {
            color: Success.dark,
        },
    }),
    ...(color === 'error' && {
        '&.Mui-checked': {
            color: Error.dark,
        },
    }),
    ...(color === 'warning' && {
        '&.Mui-checked': {
            color: Warning.dark,
        },
    }),

    // Size styles
    ...(size === 'small' && {
        transform: 'scale(0.8)',
        fontSize: '16px !important',
        // padding: '4px'
    }),
    ...(size === 'medium' && {
        transform: 'scale(1)',
    }),

    // Font size
     fontSize: '16px !important', // Adjust the font size as needed
}));

export default BDebtRadioButton;