import React, { useState } from "react";
import useStyles from "./TemplateCss";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Table } from "@mui/material";
import CustomData from "../../MockData/CustomData.json";
import { TableBody } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SearchandHighlight from "../Users/GlobalFunctions/SearchandHighlight";
import { TablePagination } from "@mui/material";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCustomForm from "./AddCustomForm";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditCustomForm from "./EditCustomForm";

const CustomForm = () => {
  const classes = useStyles();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value, setValue] = useState("");
  const [isEditCustomOpen, setEditCustomOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any | null>(null);

  const onSearchChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (row: any) => {
    setSelectedRow(row);
    setEditCustomOpen(true);
  };
  const handleEditFormClose = () => {
    setEditCustomOpen(false);
  };
  function EnhancedTableHead() {
    const headCells = [
      {
        id: "activityName",
        numeric: false,
        disablePadding: false,
        label: "ACTIVITY NAME",
        sortable: false,
      },
      {
        id: "activityDescription",
        numeric: false,
        disablePadding: false,
        label: "ACTIVITY DESCRIPTION",
        sortable: false,
      },
      {
        id: "displayOrder",
        numeric: false,
        disablePadding: false,
        label: "DISPLAY ORDER",
        sortable: false,
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "ACTIONS",
        sortable: false,
      },
    ];

    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell className={classes.tableCellHeader}>
            <Checkbox size="small" className={classes.box}></Checkbox>
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.tableCellHeader}
              key={headCell.id}
              align="left"
            >
              <b> {headCell.label || headCell.sortable}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Box p={1}>
      <Grid container spacing={2} justifyContent="space-between"  alignItems="center" padding="10px">
        <Grid item xs={1}>
          <BDebtTypography variant="body2" className={classes.pageHeader}>
            <b>FORMS</b>
          </BDebtTypography>
        </Grid>
        <Grid item xs={9}>
          <BDebtTextField
            fullWidth
            id="input-with-icon-textfield"
            placeholder="Search User name or Email Id..."
            size="small"
            variant="outlined"
            onChange={onSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
            className={classes.inputField}
          />
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <AddCustomForm />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Paper>
            <TableContainer
              className={`${
                classes.tablebodyConainer
              } ${"scrollbox"} ${"on-scrollbar"}`}
            >
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableHead />
                <TableBody>
                  {CustomData.map((row: any, index: number) => (
                    <TableRow key={row?.activityName}>
                      <TableCell className={classes.tableCellStyle}>
                        <Checkbox
                          size="small"
                          color="primary"
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                        {SearchandHighlight(row?.activityName, value)}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                        {SearchandHighlight(row?.activityDescription, value)}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                        {SearchandHighlight(row?.displayOrder, value)}
                      </TableCell>
                      <TableCell className={classes.tableCellStyle}>
                      <EditIcon onClick={() => handleEditClick(row)} className={classes.editIcon}  />
                      <DeleteOutlineIcon className={classes.deleteIcon}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={100}
              page={page}
              size="small"
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      {isEditCustomOpen && (
        <EditCustomForm
          open={isEditCustomOpen}
          onClose={handleEditFormClose}
          selectedRow={selectedRow}
        />
      )}
    </Box>
  );
};

export default CustomForm;