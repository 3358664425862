import { TableHead, styled } from '@mui/material';

const BDebtTablehead = styled(TableHead)({
  '&.MuiTableHead-root': {
    color: 'white !important',
    background: '#3b4a54',
  },
});

export default BDebtTablehead;
