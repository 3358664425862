/* eslint-disable @typescript-eslint/no-unused-vars */
import {IconButton, Tooltip, Typography} from '@material-ui/core';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import useStyles from './ProspectUserCss';
import BDebtDialogTitleHeader from "../../../GlobalControl/DialogBox/BDebtDialogTitleHeader";
import rows from '../../../MockData/ProspectHistory.json'


const ProspectHistory = () => {
    const classes = useStyles();
    const[open, setOpen] = useState(false); 

    const handleOpen = () => {
        setOpen(true);
    }
    
    const handleClose = () => {
        setOpen(false);
    }

    function EnhancedTableHead() {
        const headCells = [
            { id: "activityname", numeric: false, disablePadding: false, label: "ACTIVITY NAME", sortable: false },
            { id: "email", numeric: false, disablePadding: false, label: "OLD EMAIL ADDRESS", sortable: false },
            { id: "newEmail", numeric: false, disablePadding: false, label: "NEW EMAIL ADDRESS", sortable: false },
            { id: "actionTaken", numeric: false, disablePadding: false, label: "ACTION TAKEN BY", sortable: false },
            { id: "emailSentTo", numeric: false, disablePadding: false, label: "EMAIL SENT TO", sortable: false },
            { id: "description", numeric: false, disablePadding: false, label: "DESCRIPTION", sortable: false },
            { id: "v", numeric: false, disablePadding: false, label: "ACTION DATE", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow >
                    {headCells.map(headCell => (
                        <TableCell className={classes.tableCellHeader}
                            key={headCell?.id}>
                            {headCell.label || headCell.sortable}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead >
        );
    }
       
    return (
        <div>
            <Tooltip title="HISTORY" arrow onClick={handleOpen}>
                <IconButton style={{color:'blue'}}><ManageHistoryIcon/></IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullScreen
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <BDebtDialogTitleHeader
                id="responsive-dialog-title"
                onClose={handleClose}
            >
                <Typography variant="h6" gutterBottom className={classes.titleheader}> <b>USER SIGNU AND ACTIVATION HISTORY</b> </Typography>
            </BDebtDialogTitleHeader>
            
            <DialogContent>
                <TableContainer component={Paper} className={`${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
                        <EnhancedTableHead />
                        <TableBody>
                            {rows.map((row) => (
                            <TableRow key={row?.id}>
                                <TableCell component="th" scope="row">
                                {row?.activityName}
                                </TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.newEmail}</TableCell>
                                <TableCell align="left">{row.actionTaken}</TableCell>
                                <TableCell align="left">{row.emailSentTo}</TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                                <TableCell align="left">{row.actionDate}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            </Dialog>
        </div>
    )
}

export default ProspectHistory