/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';

const baseURL = 'https://debtor365api.azurewebsites.net/api/';
const baseURLDev = 'https://localhost:7068/api/';

export const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})
