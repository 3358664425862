import React from "react";
import useStyles from "./TemplateCss";
import BDebtTypography from "../../GlobalControl/BDebtTypography";
import BDebtTextField from "../../GlobalControl/BDebtTextField";
import { Grid } from "@material-ui/core";
import BDebtButton from "../../GlobalControl/BDebtButton";
import BDebtCheckbox from "../../GlobalControl/BDebtCheckBox";

function ControlProperties(props: any) {
  const classes = useStyles();
  const { editedControlProps, setEditedControlProps, handleSaveControlProps } = props;

  return (
    <div>
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <BDebtTypography variant="body2" className={classes.ContainerStyle}>
            CONTROL PROPERTIES
          </BDebtTypography>
        </Grid>
        <Grid item xs={4} className={classes.properties}>
          ID:
        </Grid>
        <Grid item xs={8} className={classes.properties}>
          <BDebtTextField
            fullWidth
            size="small"
            value={editedControlProps.id}
            onChange={(e) => setEditedControlProps({ ...editedControlProps, id: e.target.value })}
          />
        </Grid>
        <Grid item xs={4} className={classes.properties}>
          Name:
        </Grid>
        <Grid item xs={8} className={classes.properties}>
          <BDebtTextField
            fullWidth
            size="small"
            value={editedControlProps.name}
            onChange={(e) => setEditedControlProps({ ...editedControlProps, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={4} >
          Required:
        </Grid>
        <Grid item xs={8} >
          <BDebtCheckbox size="small"
            checked={editedControlProps.required}
            onChange={(e) => setEditedControlProps({ ...editedControlProps, required: e.target.checked })}
          />
        </Grid>
        <Grid item xs={4} className={classes.properties}>
          Label:
        </Grid>
        <Grid item xs={8} className={classes.properties}>
          <BDebtTextField
            fullWidth
            size="small"
            value={editedControlProps.label}
            onChange={(e) => setEditedControlProps({ ...editedControlProps, label: e.target.value })}
          />
        </Grid>

        {editedControlProps.type === "textfield" && (
          <>
            <Grid item xs={4} className={classes.properties}>
              Regex:
            </Grid>
            <Grid item xs={8} className={classes.properties}>
              <BDebtTextField
                fullWidth
                size="small"
                value={editedControlProps.regex || ""}
                onChange={(e) => setEditedControlProps({ ...editedControlProps, regex: e.target.value })}
              />
            </Grid>

            <Grid item xs={4} className={classes.properties}>
              Max Length:
            </Grid>
            <Grid item xs={8} className={classes.properties}>
              <BDebtTextField
                fullWidth
                size="small"
                value={editedControlProps.maxLength || ""}
                onChange={(e) =>
                  setEditedControlProps({ ...editedControlProps, maxLength: parseInt(e.target.value, 10) })
                }
              />
            </Grid>
          </>
        )}

        {["radio", "checkbox"].includes(editedControlProps.type) && (
          <>
            <Grid item xs={4} className={classes.properties}>
              Value:
            </Grid>
            <Grid item xs={8} className={classes.properties}>
              <BDebtTextField
                fullWidth
                size="small"
                value={editedControlProps.value || ""}
                onChange={(e) => setEditedControlProps({ ...editedControlProps, value: e.target.value })}
              />
            </Grid>
          </>
        )}

        {["dropdown", "select"].includes(editedControlProps.type) && (
          <>
            <Grid item xs={4} className={classes.properties}>
              Options:
            </Grid>
            <Grid item xs={8} className={classes.properties}>
              <BDebtTextField
                fullWidth
                size="small"
                value={editedControlProps.options ? editedControlProps.options.join(", ") : ""}
                onChange={(e) =>
                  setEditedControlProps({
                    ...editedControlProps,
                    options: e.target.value.split(", "),
                  })
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} className={classes.properties}>
          <BDebtButton className={classes.confirmstyle} onClick={handleSaveControlProps}>
            Save
          </BDebtButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default ControlProperties;
