//Mui Components
import {
  FormControlLabel,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  DialogActions,
  DialogTitle,
  FormLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
//Scrollbars
import Scrollbars from "react-custom-scrollbars";
//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@material-ui/icons/Search";
//BDebtCustom Components
import BDebtAlert from "../GlobalControl/BDebtAlert";
import BDebtTypography from "../GlobalControl/BDebtTypography";
import BDebtCard from "../GlobalControl/Card/BDebtCard";
import BDebtCardContent from "../GlobalControl/Card/BDebtCardContent";
import BDebtTable from "../GlobalControl/Table/BDebtTable";
import BDebtTableCell from "../GlobalControl/Table/BDebtTableCell";
import BDebtTableCellHead from "../GlobalControl/Table/BDebtTableCellHead";
import BDebtTableRow from "../GlobalControl/Table/BDebtTableRow";
import BDebtTablehead from "../GlobalControl/Table/BDebtTablehead";
import BDebtAccordionSummary from "../GlobalControl/BDebtAccordionSummary";
import BDebtAccordionDetails from "../GlobalControl/BDebtAccordionDetails";
import BDebtAccordion from "../GlobalControl/BDebtAccordion";
import BDebtTextField from "../GlobalControl/BDebtTextField";
import BDebtButton from "../GlobalControl/BDebtButton";
import BDebtAutoComplete from "../GlobalControl/BDebtAutocomplete";
import BDebtFileUpload from "../GlobalControl/BDebtFileUpload";
import BDebtCheckbox from "../GlobalControl/BDebtCheckBox";
import BDebtDialogContentText from "../GlobalControl/DialogBox/BDebtDialogContentText";
import BDebtRadioButton from "../GlobalControl/BDebtRadioButton";
import BDebtRadioGroup from "../GlobalControl/BDebtRadioGroup";
import BDebtDialog from "../GlobalControl/DialogBox/BDebtDialog";
import BDebtDialogContent from "../GlobalControl/DialogBox/BDebtDialogContent";
//Json data
import TypographyData from "../MockData/TypographyData.json";
import { Test1, Test2 } from "../MockData/GlobalControl";
import BDebtTablePagination from "../GlobalControl/Table/BDebtTablePagination";

const GlobalControlView = () => {
  const { headings, body, subtitle, others } = TypographyData;
  const [open, setOpen] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchDebtor, setSearchDebtor] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box p={1} m={1}>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={650}
        thumbMinSize={30}
        universal={true}
      >
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Alert
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtAlert severity="error">
                    This is an error alert — check it out!
                  </BDebtAlert>
                  <br />
                  <BDebtAlert severity="warning">
                    This is a warning alert — check it out!
                  </BDebtAlert>
                  <br />
                  <BDebtAlert severity="info">
                    This is an info alert — check it out!
                  </BDebtAlert>
                  <br />
                  <BDebtAlert severity="success">
                    This is a success alert — check it out!
                  </BDebtAlert>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center" color="brown">
                  Table
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <TableContainer component={Paper}>
                    <BDebtTable aria-label="simple table">
                      <BDebtTablehead>
                        <BDebtTableRow>
                          <BDebtTableCellHead>Dessert </BDebtTableCellHead>
                          <BDebtTableCellHead align="right">
                            Calories
                          </BDebtTableCellHead>
                          <BDebtTableCellHead align="right">
                            Fat&nbsp;(g)
                          </BDebtTableCellHead>
                          <BDebtTableCellHead align="right">
                            Carbs&nbsp;(g)
                          </BDebtTableCellHead>
                          <BDebtTableCellHead align="right">
                            Protein&nbsp;(g)
                          </BDebtTableCellHead>
                        </BDebtTableRow>
                      </BDebtTablehead>
                      <TableBody>
                        <BDebtTableRow>
                          <BDebtTableCell component="th" scope="row">
                            Test1
                          </BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                        </BDebtTableRow>
                      </TableBody>
                      <TableBody>
                        <BDebtTableRow>
                          <BDebtTableCell component="th" scope="row">
                            Test1
                          </BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                        </BDebtTableRow>
                      </TableBody>
                      <TableBody>
                        <BDebtTableRow>
                          <BDebtTableCell component="th" scope="row">
                            Test1
                          </BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                        </BDebtTableRow>
                      </TableBody>
                      <TableBody>
                        <BDebtTableRow>
                          <BDebtTableCell component="th" scope="row">
                            Test1
                          </BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                          <BDebtTableCell align="right">Test1</BDebtTableCell>
                        </BDebtTableRow>
                      </TableBody>
                    </BDebtTable>
                  </TableContainer>
                  <BDebtTablePagination
                            component="div"
                            count={100}
                            page={page}
                            size="small"
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Autocomplete
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    options={Test1}
                    size="small"
                    renderInput={(params) => (
                      <BDebtTextField {...params} label="Movie" />
                    )}
                  />
                  <br />
                  <Autocomplete
                    multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={Test2}
                    getOptionLabel={(option) => option.title}
                    defaultValue={[Test2[2]]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Size small"
                        placeholder="Favorites"
                      />
                    )}
                  />
                  <br />
                  <BDebtTypography variant="h6" textAlign="center">Dialog</BDebtTypography>
                  <hr className="lineBreak" />
                  <BDebtCardContent>
                    <BDebtButton variant="outlined" onClick={handleClickOpen}>
                      Open alert dialog
                    </BDebtButton>
                    <BDebtDialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle>
                      <BDebtDialogContent>
                        <BDebtDialogContentText id="alert-dialog-description">
                          Let Google help apps determine location. This means
                          sending anonymous location data to Google, even when
                          no apps are running.
                        </BDebtDialogContentText>
                      </BDebtDialogContent>
                      <DialogActions>
                        <BDebtButton onClick={handleClose}>
                          Disagree
                        </BDebtButton>
                        <BDebtButton onClick={handleClose} autoFocus>
                          Agree
                        </BDebtButton>
                      </DialogActions>
                    </BDebtDialog>
                  </BDebtCardContent>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Typography
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  {Object.entries(headings).map(([variant, text]) => (
                    <BDebtTypography key={variant} variant={variant as any}>
                      {text}
                    </BDebtTypography>
                  ))}
                  {Object.entries(body).map(([variant, text]) => (
                    <BDebtTypography key={variant} variant={variant as any}>
                      {text}
                    </BDebtTypography>
                  ))}
                  {Object.entries(subtitle).map(([variant, text]) => (
                    <BDebtTypography key={variant} variant={variant as any}>
                      {text}
                    </BDebtTypography>
                  ))}
                  {Object.entries(others).map(([variant, text]) => (
                    <BDebtTypography
                      key={variant}
                      variant={variant as any}
                      display="block"
                    >
                      {text}
                    </BDebtTypography>
                  ))}
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Text Field
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtTextField
                    id="outlined-basic"
                    label="Test1"
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                  <br />
                  <br />
                  <BDebtTextField
                    id="filled-basic"
                    label="disabled"
                    variant="filled"
                    fullWidth
                    size="small"
                    disabled
                  />
                  <br />
                  <br />
                  <BDebtTextField
                    fullWidth
                    size="small"
                    id="standard-bare"
                    variant="outlined"
                    value={searchDebtor}
                    type="text"
                    placeholder="Search All"
                    color="success"
                    focused
                    onChange={(e) => setSearchDebtor(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter" && searchDebtor !== "") {
                        setShowSearchIcon(false);
                      } else if (event.key === "Enter" && searchDebtor === "") {
                        setShowSearchIcon(true);
                      }
                    }}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showSearchIcon === true ? (
                            <IconButton
                              onClick={(e) => {
                                setShowSearchIcon(false);
                              }}
                              style={{ padding: "3px" }}
                            >
                              <SearchIcon />
                            </IconButton>
                          ) : (
                            <IconButton style={{ padding: "3px" }}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                    File Upload
                  </BDebtTypography>
                  <hr className="lineBreak" />
                  <BDebtCardContent>
                    <BDebtFileUpload
                      acceptedFiles={["application/pdf"]}
                      showPreviews={false}
                      showPreviewsInDropzone={false}
                      filesLimit={1}
                      dropzoneText="Drag and Drop an PDF file here or Browse"
                      minHeight={150}
                      maxHeight={300}
                    />
                  </BDebtCardContent>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  OUTLINED BUTTONS
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtButton variant="outlined" color="primary" size="large">
                    primary
                  </BDebtButton>
                  <BDebtButton variant="outlined" size="medium">
                    Secondary
                  </BDebtButton>
                  <BDebtButton variant="outlined" color="success" size="small">
                    Success
                  </BDebtButton>
                  <BDebtButton variant="outlined" color="error" size="small">
                    Error
                  </BDebtButton>
                  <BDebtButton variant="outlined" color="warning" size="small">
                    Warning
                  </BDebtButton>
                </BDebtCardContent>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  CONTAINED BUTTONS
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtButton
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    primary
                  </BDebtButton>
                  <BDebtButton variant="contained" size="medium">
                    Secondary
                  </BDebtButton>
                  <BDebtButton variant="contained" color="success" size="small">
                    Success
                  </BDebtButton>
                  <BDebtButton variant="contained" color="error" size="small">
                    Error
                  </BDebtButton>
                  <BDebtButton variant="contained" color="warning" size="small">
                    Warning
                  </BDebtButton>
                </BDebtCardContent>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  TEXT BUTTONS
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtButton variant="text" color="primary" size="large">
                    primary
                  </BDebtButton>
                  <BDebtButton variant="text" size="medium">
                    Secondary
                  </BDebtButton>
                  <BDebtButton variant="text" color="success" size="small">
                    Success
                  </BDebtButton>
                  <BDebtButton variant="text" color="error" size="small">
                    Error
                  </BDebtButton>
                  <BDebtButton variant="text" color="warning" size="small">
                    Warning
                  </BDebtButton>
                </BDebtCardContent>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  DASHED BUTTONS
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtButton dashed color="primary" size="large">
                    Primary
                  </BDebtButton>
                  <BDebtButton dashed color="secondary" size="medium">
                    Secondary
                  </BDebtButton>
                  <BDebtButton dashed color="success" size="small">
                    Success
                  </BDebtButton>
                  <BDebtButton dashed variant="text" color="error" size="small">
                    Error
                  </BDebtButton>
                  <BDebtButton
                    dashed
                    variant="text"
                    color="warning"
                    size="small"
                  >
                    Warning
                  </BDebtButton>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography
                  variant="h6"
                  textPadding="6px 20px"
                  color="primary"
                  textAlign="center"
                >
                  CheckBox
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <FormControlLabel
                    control={<BDebtCheckbox defaultChecked />}
                    label="Default Checked"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="secondary" />}
                    label="Secondary"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="success" />}
                    label="Success"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="warning" />}
                    label="Warning"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="error" />}
                    label="Error"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox disabled />}
                    label="Disabled"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox disabled checked />}
                    label="Disabled Checked"
                  />
                </BDebtCardContent>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <FormControlLabel
                    control={<BDebtCheckbox defaultChecked size="small" />}
                    label="Default Checked"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="secondary" size="small" />}
                    label="Secondary"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="success" size="small" />}
                    label="Success"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="warning" size="small" />}
                    label="Warning"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox color="error" size="small" />}
                    label="Error"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox disabled size="small" />}
                    label="Disabled"
                  />
                  <FormControlLabel
                    control={<BDebtCheckbox disabled checked size="small" />}
                    label="Disabled Checked"
                  />
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Accordion
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <BDebtAccordion>
                    <BDebtAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Accordion 1</Typography>
                    </BDebtAccordionSummary>
                    <BDebtAccordionDetails>
                      {Object.entries(body).map(([variant, text]) => (
                        <BDebtTypography key={variant} variant={variant as any}>
                          {text}
                        </BDebtTypography>
                      ))}
                    </BDebtAccordionDetails>
                  </BDebtAccordion>
                  <BDebtAccordion>
                    <BDebtAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Accordion 2</Typography>
                    </BDebtAccordionSummary>
                    <BDebtAccordionDetails>
                      {Object.entries(body).map(([variant, text]) => (
                        <BDebtTypography key={variant} variant={variant as any}>
                          {text}
                        </BDebtTypography>
                      ))}
                    </BDebtAccordionDetails>
                  </BDebtAccordion>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
            <Grid item xs={4}>
              <BDebtCard>
                <BDebtTypography variant="h6" textPadding="6px 20px" textAlign="center">
                  Radio Button
                </BDebtTypography>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <FormLabel>Gender</FormLabel>
                  <BDebtRadioGroup row defaultValue="female">
                    <FormControlLabel
                      value="female"
                      control={<BDebtRadioButton color="primary" />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<BDebtRadioButton color="warning" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="other"
                      control={<BDebtRadioButton color="success" />}
                      label="Other"
                    />
                  </BDebtRadioGroup>
                </BDebtCardContent>
                <hr className="lineBreak" />
                <BDebtCardContent>
                  <FormLabel>Gender</FormLabel>
                  <BDebtRadioGroup row defaultValue="male">
                    <FormControlLabel
                      value="female"
                      control={
                        <BDebtRadioButton size="small" color="primary" />
                      }
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={
                        <BDebtRadioButton size="small" color="warning" />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      value="other"
                      control={
                        <BDebtRadioButton size="small" color="success" />
                      }
                      label="Other"
                    />
                  </BDebtRadioGroup>
                </BDebtCardContent>
              </BDebtCard>
            </Grid>
          </Grid>
        </Box>
      </Scrollbars>
    </Box>
  );
};

export default GlobalControlView;
